@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size: 14px;
    color: #616161;
    font-weight: 500;
    line-height: 24px
}

body, html {
    -moz-osx-font-smoothing: grayscale
}

a, a:active, a:focus {
    color: #616161;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s
}

a:hover, a:active {
    color: #232323;
    text-decoration: none
}

a:focus, a:active, button:focus, button:active, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none
}

img {
    max-width: 100%;
    height: auto
}

input, textarea, select {
    border: 1px solid #d1d1d1;
    font-size: 14px;
    padding: 8px 15px;
    width: 100%;
    margin: 0 0 15px 0;
    max-width: 100%;
    resize: none
}

input[type="button"], input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea, input[type="submit"] {
    -webkit-appearance: none;
    outline: none
}

input:focus, textarea:focus {
    border-color: #585858;
    outline: none
}

input[type="button"]:focus {
    outline: none
}

select::-ms-expand {
    display: none
}

iframe {
    border: 0
}

p {
    margin: 0 0 20px;
    word-spacing: 1px
}

b, strong {
    font-weight: 600
}

.height-50 {
    height: 50%
}

.height-100 {
    height: 100%
}

.height-100vh {
    height: 100vh
}

.last-paragraph-no-margin p:last-of-type {
    margin-bottom: 0
}

.alt-font strong {
    font-weight: 700
}

.white-space {
    white-space: normal
}

.word-wrap {
    word-wrap: break-word
}

.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

ul, ol, dl {
    list-style-position: outside
}

ul li {
    list-style: none
}

::selection {
    color: #000;
    background: #dbdbdb
}

::-moz-selection {
    color: #000;
    background: #dbdbdb
}

::-webkit-input-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis
}

::-moz-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis;
    opacity: 1
}

:-ms-input-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis;
    opacity: 1
}

.half-gutter {
    margin: 0 -8px
}

.half-gutter > [class*="col-"] {
    padding: 7px
}

.alt-font {
    font-family: 'Poppins', sans-serif;
    font-weight: 500
}

.main-font {
    font-family: 'Poppins', sans-serif
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 20px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #232323;
    line-height: normal
}

h1 {
    font-size: 42px
}

h2 {
    font-size: 34px
}

h3 {
    font-size: 32px
}

h4 {
    font-size: 30px
}

h5 {
    font-size: 26px
}

h6 {
    font-size: 22px
}

.font-size11 {
    font-size: 11px
}

.font-size12 {
    font-size: 12px
}

.font-size13 {
    font-size: 13px
}

.font-size14 {
    font-size: 14px
}

.font-size15 {
    font-size: 15px
}

.font-size16 {
    font-size: 16px
}

.font-size17 {
    font-size: 17px
}

.font-size18 {
    font-size: 18px
}

.font-size19 {
    font-size: 19px
}

.font-size20 {
    font-size: 20px
}

.font-size22 {
    font-size: 22px
}

.font-size24 {
    font-size: 24px
}

.font-size26 {
    font-size: 26px
}

.font-size28 {
    font-size: 28px
}

.font-size30 {
    font-size: 30px
}

.font-size32 {
    font-size: 32px
}

.font-size34 {
    font-size: 34px
}

.font-size36 {
    font-size: 36px
}

.font-size38 {
    font-size: 38px
}

.font-size40 {
    font-size: 40px
}

.font-size42 {
    font-size: 42px
}

.font-size44 {
    font-size: 44px
}

.font-size46 {
    font-size: 46px
}

.font-size48 {
    font-size: 48px
}

.font-size50 {
    font-size: 50px
}

.font-size55 {
    font-size: 55px
}

.font-size60 {
    font-size: 60px
}

.font-size80 {
    font-size: 80px
}

.font-size100 {
    font-size: 100px
}

.font-size120 {
    font-size: 120px
}

.font-size130 {
    font-size: 130px
}

.text-white {
    color: #fff
}

.text-black {
    color: #000
}

.text-extra-dark-gray {
    color: #232323
}

.text-dark-gray {
    color: #626262
}

.text-extra-medium-gray {
    color: #757575
}

.text-medium-gray {
    color: #939393
}

.text-extra-light-gray {
    color: #b7b7b7
}

.text-light-gray {
    color: #d6d5d5
}

.text-very-light-gray {
    color: #ededed
}

.text-default-color {
    color: #6f6f6f
}

.text-green {
    color: #59c17a
}

.text-blue {
    color: #1bb4b9
}

.no-letter-spacing {
    letter-spacing: 0px
}

.letter-spacing-1 {
    letter-spacing: 1px
}

.letter-spacing-2 {
    letter-spacing: 2px
}

.letter-spacing-3 {
    letter-spacing: 3px
}

.letter-spacing-4 {
    letter-spacing: 4px
}

.letter-spacing-5 {
    letter-spacing: 5px
}

.font-weight-100 {
    font-weight: 100
}

.font-weight-200 {
    font-weight: 200
}

.font-weight-300 {
    font-weight: 300
}

.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.font-weight-800 {
    font-weight: 800
}

.font-weight-900 {
    font-weight: 900
}

.line-height-normal {
    line-height: normal
}

.line-height-10 {
    line-height: 10px
}

.line-height-13 {
    line-height: 13px
}

.line-height-18 {
    line-height: 18px
}

.line-height-20 {
    line-height: 20px
}

.line-height-24 {
    line-height: 24px
}

.line-height-22 {
    line-height: 22px
}

.line-height-26 {
    line-height: 26px
}

.line-height-28 {
    line-height: 28px
}

.line-height-30 {
    line-height: 30px
}

.line-height-35 {
    line-height: 35px
}

.line-height-40 {
    line-height: 40px
}

.line-height-45 {
    line-height: 45px
}

.line-height-50 {
    line-height: 50px
}

.line-height-55 {
    line-height: 55px
}

.line-height-60 {
    line-height: 60px
}

.line-height-65 {
    line-height: 65px
}

.line-height-70 {
    line-height: 70px
}

.line-height-75 {
    line-height: 75px
}

.line-height-80 {
    line-height: 80px
}

.line-height-85 {
    line-height: 85px
}

.line-height-90 {
    line-height: 90px
}

.line-height-95 {
    line-height: 95px
}

.line-height-100 {
    line-height: 100px
}

.line-height-110 {
    line-height: 110px
}

.line-height-120 {
    line-height: 120px
}

.bg-transparent {
    background-color: transparent
}

.bg-white {
    background-color: #fff
}

.bg-black {
    background-color: #000
}

.bg-extra-dark-gray {
    background-color: #1c1c1c
}

.bg-dark-black {
    background-color: #232323
}

.bg-medium-dark-gray {
    background-color: #191919
}

.bg-dark-gray {
    background-color: #757575
}

.bg-extra-medium-gray {
    background-color: #939393
}

.bg-medium-gray {
    background-color: #dbdbdb
}

.bg-extra-light-gray {
    background-color: #e0e0e0
}

.bg-medium-light-gray {
    background-color: #ededed
}

.bg-light-gray {
    background-color: #f7f7f7
}

.bg-very-light-gray {
    background-color: #fafafa
}

.bg-grey {
    background-color: #f9f9f9
}

.bg-red {
    background-color: #ed1b24
}

.bg-transparent-white {
    background-color: rgba(255, 255, 255, 0.3);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, #fff 96%, #fff 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, #fff 96%, #fff 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, #fff 96%, #fff 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, #fff 96%, #fff 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, #fff 96%, #fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1)
}

.bg-transparent-black {
    background-color: transparent;
    background: -moz-linear-gradient(left, transparent 0%, transparent 37%, #000 96%, #000 100%);
    background: -webkit-linear-gradient(left, transparent 0%, transparent 37%, #000 96%, #000 100%);
    background: -o-linear-gradient(left, transparent 0%, transparent 37%, #000 96%, #000 100%);
    background: -ms-linear-gradient(left, transparent 0%, transparent 37%, #000 96%, #000 100%);
    background: linear-gradient(to right, transparent 0%, transparent 37%, #000 96%, #000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1)
}

.bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.85)
}

.bg-black-opacity {
    background-color: rgba(0, 0, 0, 0.85)
}

.bg-black-opacity-light {
    background-color: rgba(0, 0, 0, 0.5)
}

.center-col {
    float: none;
    margin-left: auto;
    margin-right: auto
}

section {
    padding: 90px 0;
    overflow: hidden;
    background: #fff
}

.big-input, .big-textarea, .big-select select {
    padding: 18px 25px;
    font-size: 14px;
    border-radius: 0
}

.medium-input, .medium-textarea, .medium-select select {
    padding: 12px 20px;
    font-size: 14px;
    line-height: normal;
    border-radius: 0
}

.small-input, .small-textarea {
    padding: 12px 15px;
    font-size: 11px;
    line-height: normal
}

.small-select select {
    padding: 15px 15px;
    line-height: normal
}

.medium-input-light, .medium-textarea-light, .medium-select-light select {
    padding: 12px 25px;
    font-size: 12px;
    line-height: normal
}

.required-error {
    border: 1px solid #ee2323 !important
}

.input-border-bottom.required-error {
    border-width: 0 !important;
    border-bottom-width: 1px !important;
    border-bottom-color: #ee2323 !important
}

#success-contact-form {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

[data-overlay-dark], [data-overlay-light] {
    position: relative
}

[data-overlay-dark] .position-unset, [data-overlay-light] .position-unset {
    position: static !important
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    [data-overlay-dark] .position-unset, [data-overlay-light] .position-unset {
        position: unset !important
    }
}

[data-overlay-dark] .container, [data-overlay-light] .container {
    position: relative;
    z-index: 2
}[data-overlay-dark] .container-fluid, [data-overlay-light] .container {
    position: relative;
    z-index: 2
}

[data-overlay-dark]:before, [data-overlay-light]:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1
}

[data-overlay-dark]:before {
    background: #000
}

[data-overlay-light]:before {
    background: #fff
}

[data-overlay-dark="0"]:before, [data-overlay-light="0"]:before {
    opacity: 0
}

[data-overlay-dark="1"]:before, [data-overlay-light="1"]:before {
    opacity: 0.1
}

[data-overlay-dark="2"]:before, [data-overlay-light="2"]:before {
    opacity: 0.2
}

[data-overlay-dark="3"]:before, [data-overlay-light="3"]:before {
    opacity: 0.3
}

[data-overlay-dark="4"]:before, [data-overlay-light="4"]:before {
    opacity: 0.4
}

[data-overlay-dark="5"]:before, [data-overlay-light="5"]:before {
    opacity: 0.5
}

[data-overlay-dark="6"]:before, [data-overlay-light="6"]:before {
    opacity: 0.6
}

[data-overlay-dark="7"]:before, [data-overlay-light="7"]:before {
    opacity: 0.7
}

[data-overlay-dark="8"]:before, [data-overlay-light="8"]:before {
    opacity: 0.8
}

[data-overlay-dark="9"]:before, [data-overlay-light="9"]:before {
    opacity: 0.9
}

[data-overlay-dark="91"]:before, [data-overlay-light="91"]:before {
    opacity: 0.91
}

[data-overlay-dark="92"]:before, [data-overlay-light="92"]:before {
    opacity: 0.92
}

[data-overlay-dark="93"]:before, [data-overlay-light="93"]:before {
    opacity: 0.93
}

[data-overlay-dark="94"]:before, [data-overlay-light="94"]:before {
    opacity: 0.94
}

[data-overlay-dark="95"]:before, [data-overlay-light="95"]:before {
    opacity: 0.95
}

[data-overlay-dark="96"]:before, [data-overlay-light="96"]:before {
    opacity: 0.96
}

[data-overlay-dark="97"]:before, [data-overlay-light="97"]:before {
    opacity: 0.97
}

[data-overlay-dark="98"]:before, [data-overlay-light="98"]:before {
    opacity: 0.98
}

[data-overlay-dark="99"]:before, [data-overlay-light="99"]:before {
    opacity: 0.99
}

[data-overlay-dark="10"]:before, [data-overlay-light="10"]:before {
    opacity: 1
}

.separator-line-verticle-extra-small {
    width: 1px;
    height: 8px
}

.separator-line-verticle-small-thick {
    width: 17px;
    height: 3px
}

.separator-line-verticle-small {
    width: 1px;
    height: 13px
}

.separator-line-verticle-small-thick2 {
    width: 64px;
    height: 7px
}

.separator-line-verticle-large {
    width: 1px;
    height: 20px
}

.separator-line-verticle-extra-large {
    width: 1px;
    height: 30px
}

.separator-line-verticle-medium-thick-full {
    width: 8px;
    height: 40px
}

.separator-line-verticle-large-thick {
    width: 109px;
    height: 11px
}

.separator-line-horrizontal-medium-light {
    width: 36px;
    height: 3px
}

.separator-line-horrizontal-medium-light2 {
    width: 50px;
    height: 1px
}

.separator-line-horrizontal-medium-light3 {
    width: 18%;
    height: 1px
}

.separator-line-horrizontal-medium-thick {
    width: 50px;
    height: 5px
}

.separator-line-horrizontal-full {
    width: 100%;
    height: 1px
}

.list-style-none li {
    list-style: none
}

.list-style-1 li {
    padding: 5px 0
}

.list-style-2 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-2 li {
    position: relative;
    padding: 0 0 0 12px;
    margin: 0 0 9px 0
}

.list-style-2 li:before {
    content: "";
    width: 5px;
    height: 1px;
    background-color: #232323;
    position: absolute;
    top: 12px;
    left: 0
}

.list-style-3 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-3 li {
    position: relative;
    padding: 0 0 0 22px;
    margin: 0 0 15px 0
}

.list-style-3 li:before {
    content: "";
    width: 10px;
    height: 1px;
    background-color: #232323;
    position: absolute;
    top: 13px;
    left: 0
}

.list-style-4 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-4 li {
    position: relative;
    padding: 0 0 10px 22px;
    margin: 0 0 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-4 li:before {
    content: "\f152";
    position: absolute;
    top: 0;
    left: 0;
    font-family: Font Awesome\ 5 Free
}

.list-style-4 li:last-child {
    border-bottom: 0
}

.list-style-4.list-style-color li {
    border-color: rgba(255, 255, 255, 0.15)
}

.list-style-4.list-style-color li:before {
    color: #ff214f
}

.list-style-5 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-5 li {
    position: relative;
    padding: 0 0 10px 25px;
    margin: 0 0 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-5 li:before {
    content: "\f192";
    position: absolute;
    top: 0;
    left: 0;
    font-family: Font Awesome\ 5 Free;
    color: #c0c0c0
}

.list-style-5 li:first-child {
    padding-top: 10px
}

.list-style-5 li:first-child:before {
    top: 11px
}

.list-style-5 li:last-child {
    border-bottom: 0;
    margin-bottom: 0
}

.list-style-5.list-style-color li {
    border-color: rgba(255, 255, 255, 0.15)
}

.list-style-5.list-style-color li:before {
    color: #232323
}

.list-style-6 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-6 li {
    position: relative;
    padding: 0 15px 8px 0;
    margin: 0 0 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-6 li span {
    position: absolute;
    top: 3px;
    right: 0
}

.list-style-6 li:last-child {
    border-bottom: none
}

.list-style-6 li:before {
    content: ""
}

.list-style-7 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-7 li {
    padding: 0 0 13px 0;
    margin: 0 0 10px 0
}

.list-style-7 li:last-child {
    margin: 0;
    padding: 0
}

.list-style-8 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-8 li {
    position: relative;
    padding: 15px 0;
    margin: 0 0 4px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-8 li span {
    margin-bottom: 2px
}

.list-style-8 li p {
    margin-bottom: 0
}

.list-style-8 li:last-child {
    border-bottom: none
}

.list-style-8 li:before {
    content: ""
}

.list-style-9 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-9 li {
    position: relative;
    padding: 15px 0;
    margin: 0 0 4px 0;
    border-bottom: 1px solid #373737
}

.list-style-9 li span {
    margin-bottom: 2px
}

.list-style-9 li p {
    margin-bottom: 0
}

.list-style-9 li:last-child {
    border-bottom: none
}

.list-style-9 li:before {
    content: ""
}

.list-style-10 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-10 li {
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-10 li i {
    float: left;
    margin-top: 5px
}

.list-style-10 li span {
    padding-left: 15px;
    display: table
}

.list-style-10 li:last-child {
    border-bottom: none
}

.list-style-11 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-11 li {
    position: relative;
    padding: 0 0 8px 0;
    margin: 0 0 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-11 li span {
    position: absolute;
    top: 3px;
    right: 0
}

.list-style-11 li:last-child {
    border-bottom: none
}

.list-style-11 li:before {
    content: ""
}

.list-style-12 {
    position: relative
}

.list-style-12 li {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative
}

.list-style-12 li i {
    left: 0;
    line-height: normal;
    position: absolute;
    top: 2px
}

.list-style-13 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-13 li {
    position: relative;
    padding: 0 0 3px 25px;
    margin: 0 0 3px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.list-style-13 li:before {
    content: "\f192";
    position: absolute;
    top: 0;
    left: 0;
    font-family: Font Awesome\ 5 Free;
    color: #c0c0c0
}

.list-style-13 li:first-child {
    padding-top: 5px
}

.list-style-13 li:first-child:before {
    top: 6px
}

.list-style-13 li:last-child {
    border-bottom: 0;
    margin-bottom: 0
}

.list-style-14 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-14 li {
    position: relative;
    padding: 0 0 5px 25px;
    margin: 0 0 5px 0
}

.list-style-14 li:before {
    content: "\f192";
    position: absolute;
    top: 0;
    left: 0;
    font-family: Font Awesome\ 5 Free;
    color: #c0c0c0
}

.list-style-14 li:first-child {
    padding-top: 5px
}

.list-style-14 li:first-child:before {
    top: 6px
}

.list-style-14 li:last-child {
    border-bottom: 0;
    margin-bottom: 0
}

.list-style-16 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style-16 li {
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.list-style-16 li:after {
    content: '\f14a';
    font-family: Font Awesome\ 5 Free;
    position: absolute;
    left: 0px;
    top: 1px;
    z-index: 1;
    color: #232323
}

.list-style-16 li:hover {
    padding-left: 40px
}

.list-style-16 li:last-child {
    margin-bottom: 0
}

.divider-full {
    width: 100%;
    height: 1px;
    display: inline-block
}

.opacity-very-light {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    top: 0;
    left: 0
}

.opacity-light {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    top: 0;
    left: 0
}

.opacity-extra-medium {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    top: 0;
    left: 0
}

.opacity-medium {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.75;
    top: 0;
    left: 0
}

.opacity-full {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    top: 0;
    left: 0
}

.opacity-full-dark {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    top: 0;
    left: 0
}

.opacity1 {
    opacity: .1
}

.opacity2 {
    opacity: .2
}

.opacity3 {
    opacity: .3
}

.opacity4 {
    opacity: .4
}

.opacity5 {
    opacity: .5
}

.opacity6 {
    opacity: .6
}

.opacity7 {
    opacity: .7
}

.opacity8 {
    opacity: .8
}

.opacity9 {
    opacity: .9
}

.btn-shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3)
}

.box-shadow-light {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1)
}

.box-shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2)
}

.box-shadow-dark {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4)
}

.box-shadow-large {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1)
}

.box-shadow-primary {
    -webkit-box-shadow: 0 18px 38px rgba(0, 0, 0, 0.2);
    box-shadow: 0 18px 38px rgba(0, 0, 0, 0.2)
}

.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important
}

.z-index-1111 {
    z-index: 1111
}

.z-index-111 {
    z-index: 111
}

.z-index-1 {
    z-index: 1
}

.z-index-2 {
    z-index: 2
}

.z-index-3 {
    z-index: 3
}

.z-index-4 {
    z-index: 4
}

.z-index-5 {
    z-index: 5
}

.z-index-9 {
    z-index: 9
}

.z-index-0 {
    z-index: 0
}

.z-index-minus2 {
    z-index: -2
}

.vertical-align-middle {
    vertical-align: middle
}

.vertical-align-top {
    vertical-align: top
}

.vertical-align-bottom {
    vertical-align: bottom
}

.vertical-middle {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0
}

.clear-both {
    clear: both
}

.line-break {
    display: block
}

.no-transition * {
    transition-timing-function: unset;
    -moz-transition-timing-function: unset;
    -webkit-transition-timing-function: unset;
    -o-transition-timing-function: unset;
    -ms-transition-timing-function: none;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    -ms-transition-duration: 0s
}

.absolute-middle-center {
    left: 50%;
    top: 50%;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%)
}

.border-all {
    border: 1px solid #ededed
}

.border-width-1 {
    border-width: 1px !important
}

.border-width-2 {
    border-width: 2px !important
}

.border-width-3 {
    border-width: 3px !important
}

.border-width-4 {
    border-width: 4px !important
}

.border-width-5 {
    border-width: 5px !important
}

.border-width-6 {
    border-width: 6px !important
}

.border-width-7 {
    border-width: 7px !important
}

.border-width-8 {
    border-width: 8px !important
}

.border-width-9 {
    border-width: 9px !important
}

.border-width-10 {
    border-width: 10px !important
}

.border-width-20 {
    border-width: 20px !important
}

.border-top {
    border-top: 1px solid
}

.border-bottom {
    border-bottom: 1px solid
}

.border-left {
    border-left: 1px solid
}

.border-right {
    border-right: 1px solid
}

.border-lr {
    border-left: 1px solid;
    border-right: 1px solid
}

.border-tb {
    border-top: 1px solid;
    border-bottom: 1px solid
}

.border-color-white {
    border-color: #fff !important
}

.border-color-black {
    border-color: #000 !important
}

.border-color-extra-dark-gray {
    border-color: #232323 !important
}

.border-color-medium-dark-gray {
    border-color: #363636 !important
}

.border-color-dark-gray {
    border-color: #939393 !important
}

.border-color-extra-medium-gray {
    border-color: #dbdbdb !important
}

.border-color-medium-gray {
    border-color: #e4e4e4 !important
}

.border-color-extra-light-gray {
    border-color: #ededed !important
}

.border-color-light-gray {
    border-color: #f5f5f5 !important
}

.border-color-light-white {
    border-color: rgba(255, 255, 255, 0.2) !important
}

.border-color-light-black {
    border-color: rgba(0, 0, 0, 0.1) !important
}

.border-dotted {
    border-style: dotted !important
}

.border-dashed {
    border-style: dashed !important
}

.border-solid {
    border-style: solid !important
}

.border-double {
    border-style: double !important
}

.border-groove {
    border-style: groove !important
}

.border-ridge {
    border-style: ridge !important
}

.border-inset {
    border-style: inset !important
}

.border-outset {
    border-style: outset !important
}

.border-none {
    border-style: none !important
}

.border-hidden {
    border-style: hidden !important
}

.border-transperent {
    border-color: transparent !important
}

.no-border-all {
    border: none !important
}

.no-border-top {
    border-top: 0 !important
}

.no-border-bottom {
    border-bottom: 0 !important
}

.no-border-right {
    border-right: 0 !important
}

.no-border-left {
    border-left: 0 !important
}

.inner-border {
    border: 8px solid rgba(255, 255, 255, 0.35);
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    left: 20px;
    top: 20px;
    position: absolute
}

.inner-border2 {
    border: 8px solid rgba(255, 255, 255, 0.35);
    height: calc(100% - 80px);
    width: calc(100% - 110px);
    left: 55px;
    top: 40px;
    position: absolute
}

.border-black-light {
    border-color: rgba(0, 0, 0, 0.1) !important
}

.border-white-light {
    border-color: rgba(255, 255, 255, 0.1) !important
}

.border-radius-1 {
    border-radius: 1px
}

.border-radius-2 {
    border-radius: 2px
}

.border-radius-3 {
    border-radius: 3px
}

.border-radius-4 {
    border-radius: 4px
}

.border-radius-5 {
    border-radius: 5px
}

.border-radius-6 {
    border-radius: 6px
}

.border-radius-7 {
    border-radius: 7px
}

.border-radius-8 {
    border-radius: 8px
}

.border-radius-9 {
    border-radius: 9px
}

.border-radius-10 {
    border-radius: 10px
}

.border-radius-50 {
    border-radius: 50%
}

.border-radius-100 {
    border-radius: 100%
}

.border-radius-none {
    border-radius: 0 !important
}

.parallax {
    position: relative;
    background-size: cover;
    overflow: hidden;
    background-attachment: fixed
}

.fix-background {
    position: relative;
    background-size: cover;
    animation-duration: 0s;
    animation-fill-mode: none;
    -webkit-animation-duration: 0s;
    -webkit-animation-fill-mode: none;
    -moz-animation-duration: 0s;
    -moz-animation-fill-mode: none;
    -ms-animation-duration: 0s;
    -ms-animation-fill-mode: none;
    -o-animation-fill-mode: none;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed
}

.cover-background {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important
}

.background-size-inherit {
    background-size: inherit
}

.background-attachment-inherit {
    background-attachment: inherit
}

.background-position-left {
    background-position: left center
}

.background-position-left-bottom {
    background-position: left bottom
}

.background-position-right {
    background-position: right center
}

.background-position-top {
    background-position: center top !important
}

.up-down-ani {
    display: table;
    position: relative;
    -webkit-animation-name: up-down-animation;
    -webkit-animation-duration: .7s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: .7s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -moz-animation-name: up-down-animation;
    -moz-animation-duration: .7s;
    -moz-animation-timing-function: linear;
    -moz-animation-delay: .7s;
    -moz-animation-iteration-count: infinite;
    -ms-animation-name: up-down-animation;
    -ms-animation-duration: .7s;
    -ms-animation-timing-function: linear;
    -ms-animation-delay: .7s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-direction: alternate;
    -o-animation-direction: alternate;
    -o-animation-name: up-down-animation;
    -o-animation-duration: .7s;
    -o-animation-timing-function: linear;
    -o-animation-delay: .7s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: alternate;
    animation-direction: alternate;
    animation-name: up-down-animation;
    animation-duration: .7s;
    animation-timing-function: linear;
    animation-delay: .7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    margin: 0 auto
}

.background-position-x-50 {
    background-position-x: 50% !important
}

.background-size-contain {
    background-size: contain
}

.margin-half-all {
    margin: .5%
}

.margin-one-all {
    margin: 1%
}

.margin-one-half-all {
    margin: 1.5%
}

.margin-two-all {
    margin: 2%
}

.margin-two-half-all {
    margin: 2.5%
}

.margin-three-all {
    margin: 3%
}

.margin-three-half-all {
    margin: 3.5%
}

.margin-four-all {
    margin: 4%
}

.margin-four-half-all {
    margin: 4.5%
}

.margin-five-all {
    margin: 5%
}

.margin-five-half-all {
    margin: 5.5%
}

.margin-six-all {
    margin: 6%
}

.margin-six-half-all {
    margin: 6.5%
}

.margin-seven-all {
    margin: 7%
}

.margin-seven-half-all {
    margin: 7.5%
}

.margin-eight-all {
    margin: 8%
}

.margin-eight-half-all {
    margin: 8.5%
}

.margin-nine-all {
    margin: 9%
}

.margin-nine-half-all {
    margin: 9.5%
}

.margin-ten-all {
    margin: 10%
}

.margin-ten-half-all {
    margin: 10.5%
}

.margin-eleven-all {
    margin: 11%
}

.margin-eleven-half-all {
    margin: 11.5%
}

.margin-twelve-all {
    margin: 12%
}

.margin-twelve-half-all {
    margin: 12.5%
}

.margin-thirteen-all {
    margin: 13%
}

.margin-thirteen-half-all {
    margin: 13.5%
}

.margin-fourteen-all {
    margin: 14%
}

.margin-fourteen-half-all {
    margin: 14.5%
}

.margin-fifteen-all {
    margin: 15%
}

.margin-fifteen-half-all {
    margin: 15.5%
}

.margin-sixteen-all {
    margin: 16%
}

.margin-sixteen-half-all {
    margin: 16.5%
}

.margin-seventeen-all {
    margin: 17%
}

.margin-seventeen-half-all {
    margin: 17.5%
}

.margin-eighteen-all {
    margin: 18%
}

.margin-eighteen-half-all {
    margin: 18.5%
}

.margin-nineteen-all {
    margin: 19%
}

.margin-nineteen-half-all {
    margin: 19.5%
}

.margin-twenty-all {
    margin: 20%
}

.margin-twenty-half-all {
    margin: 20.5%
}

.margin-twenty-one-all {
    margin: 21%
}

.margin-twenty-one-half-all {
    margin: 21.5%
}

.margin-twenty-two-all {
    margin: 22%
}

.margin-twenty-two-half-all {
    margin: 22.5%
}

.margin-twenty-three-all {
    margin: 23%
}

.margin-twenty-three-half-all {
    margin: 23.5%
}

.margin-twenty-four-all {
    margin: 24%
}

.margin-twenty-four-half-all {
    margin: 24.5%
}

.margin-twenty-five-all {
    margin: 25%
}

.margin-5px-all {
    margin: 5px
}

.margin-8px-all {
    margin: 8px
}

.margin-10px-all {
    margin: 10px
}

.margin-15px-all {
    margin: 15px
}

.margin-20px-all {
    margin: 20px
}

.margin-25px-all {
    margin: 25px
}

.margin-30px-all {
    margin: 30px
}

.margin-35px-all {
    margin: 35px
}

.margin-40px-all {
    margin: 40px
}

.margin-45px-all {
    margin: 45px
}

.margin-50px-all {
    margin: 50px
}

.margin-55px-all {
    margin: 55px
}

.margin-60px-all {
    margin: 60px
}

.margin-65px-all {
    margin: 65px
}

.margin-70px-all {
    margin: 70px
}

.margin-75px-all {
    margin: 75px
}

.margin-80px-all {
    margin: 80px
}

.margin-85px-all {
    margin: 85px
}

.margin-90px-all {
    margin: 90px
}

.margin-95px-all {
    margin: 95px
}

.margin-100px-all {
    margin: 100px
}

.no-margin {
    margin: 0 !important
}

.no-margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.no-margin-tb {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.no-margin-top {
    margin-top: 0 !important
}

.no-margin-bottom {
    margin-bottom: 0 !important
}

.no-margin-left {
    margin-left: 0 !important
}

.no-margin-right {
    margin-right: 0 !important
}

.margin-lr-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.margin-auto {
    margin: 0 auto !important
}

.margin-one-top {
    margin-top: 1%
}

.margin-one-half-top {
    margin-top: 1.5%
}

.margin-two-top {
    margin-top: 2%
}

.margin-two-half-top {
    margin-top: 2.5%
}

.margin-three-top {
    margin-top: 3%
}

.margin-three-half-top {
    margin-top: 3.5%
}

.margin-four-top {
    margin-top: 4%
}

.margin-four-half-top {
    margin-top: 4.5%
}

.margin-five-top {
    margin-top: 5%
}

.margin-five-half-top {
    margin-top: 5.5%
}

.margin-six-top {
    margin-top: 6%
}

.margin-six-half-top {
    margin-top: 6.5%
}

.margin-seven-top {
    margin-top: 7%
}

.margin-seven-half-top {
    margin-top: 7.5%
}

.margin-eight-top {
    margin-top: 8%
}

.margin-eight-half-top {
    margin-top: 8.5%
}

.margin-nine-top {
    margin-top: 9%
}

.margin-nine-half-top {
    margin-top: 9.5%
}

.margin-ten-top {
    margin-top: 10%
}

.margin-ten-half-top {
    margin-top: 10.5%
}

.margin-eleven-top {
    margin-top: 11%
}

.margin-eleven-half-top {
    margin-top: 11.5%
}

.margin-twelve-top {
    margin-top: 12%
}

.margin-twelve-half-top {
    margin-top: 12.5%
}

.margin-thirteen-top {
    margin-top: 13%
}

.margin-thirteen-half-top {
    margin-top: 13.5%
}

.margin-fourteen-top {
    margin-top: 14%
}

.margin-fourteen-half-top {
    margin-top: 14.5%
}

.margin-fifteen-top {
    margin-top: 15%
}

.margin-fifteen-half-top {
    margin-top: 15.5%
}

.margin-sixteen-top {
    margin-top: 16%
}

.margin-sixteen-half-top {
    margin-top: 16.5%
}

.margin-seventeen-top {
    margin-top: 17%
}

.margin-seventeen-half-top {
    margin-top: 17.5%
}

.margin-eighteen-top {
    margin-top: 18%
}

.margin-eighteen-half-top {
    margin-top: 18.5%
}

.margin-nineteen-top {
    margin-top: 19%
}

.margin-nineteen-half-top {
    margin-top: 19.5%
}

.margin-twenty-top {
    margin-top: 20%
}

.margin-twenty-half-top {
    margin-top: 20.5%
}

.margin-twenty-one-top {
    margin-top: 21%
}

.margin-twenty-one-half-top {
    margin-top: 21.5%
}

.margin-twenty-two-top {
    margin-top: 22%
}

.margin-twenty-two-half-top {
    margin-top: 22.5%
}

.margin-twenty-three-top {
    margin-top: 23%
}

.margin-twenty-three-half-top {
    margin-top: 23.5%
}

.margin-twenty-four-top {
    margin-top: 24%
}

.margin-twenty-four-half-top {
    margin-top: 24.5%
}

.margin-twenty-five-top {
    margin-top: 25%
}

.margin-5px-top {
    margin-top: 5px
}

.margin-8px-top {
    margin-top: 8px
}

.margin-10px-top {
    margin-top: 10px
}

.margin-15px-top {
    margin-top: 15px
}

.margin-20px-top {
    margin-top: 20px
}

.margin-25px-top {
    margin-top: 25px
}

.margin-30px-top {
    margin-top: 30px
}

.margin-35px-top {
    margin-top: 35px
}

.margin-40px-top {
    margin-top: 40px
}

.margin-45px-top {
    margin-top: 45px
}

.margin-50px-top {
    margin-top: 50px
}

.margin-55px-top {
    margin-top: 55px
}

.margin-60px-top {
    margin-top: 60px
}

.margin-65px-top {
    margin-top: 65px
}

.margin-70px-top {
    margin-top: 70px
}

.margin-75px-top {
    margin-top: 75px
}

.margin-80px-top {
    margin-top: 80px
}

.margin-90px-top {
    margin-top: 90px
}

.margin-100px-top {
    margin-top: 100px
}

.margin-one-bottom {
    margin-bottom: 1%
}

.margin-one-half-bottom {
    margin-bottom: 1.5%
}

.margin-two-bottom {
    margin-bottom: 2%
}

.margin-two-half-bottom {
    margin-bottom: 2.5%
}

.margin-three-bottom {
    margin-bottom: 3%
}

.margin-three-half-bottom {
    margin-bottom: 3.5%
}

.margin-four-bottom {
    margin-bottom: 4%
}

.margin-four-half-bottom {
    margin-bottom: 4.5%
}

.margin-five-bottom {
    margin-bottom: 5%
}

.margin-five-half-bottom {
    margin-bottom: 5.5%
}

.margin-six-bottom {
    margin-bottom: 6%
}

.margin-six-half-bottom {
    margin-bottom: 6.5%
}

.margin-seven-bottom {
    margin-bottom: 7%
}

.margin-seven-half-bottom {
    margin-bottom: 7.5%
}

.margin-eight-bottom {
    margin-bottom: 8%
}

.margin-eight-half-bottom {
    margin-bottom: 8.5%
}

.margin-nine-bottom {
    margin-bottom: 9%
}

.margin-nine-half-bottom {
    margin-bottom: 9.5%
}

.margin-ten-bottom {
    margin-bottom: 10%
}

.margin-ten-half-bottom {
    margin-bottom: 10.5%
}

.margin-eleven-bottom {
    margin-bottom: 11%
}

.margin-eleven-half-bottom {
    margin-bottom: 11.5%
}

.margin-twelve-bottom {
    margin-bottom: 12%
}

.margin-twelve-half-bottom {
    margin-bottom: 12.5%
}

.margin-thirteen-bottom {
    margin-bottom: 13%
}

.margin-thirteen-half-bottom {
    margin-bottom: 13.5%
}

.margin-fourteen-bottom {
    margin-bottom: 14%
}

.margin-fourteen-half-bottom {
    margin-bottom: 14.5%
}

.margin-fifteen-bottom {
    margin-bottom: 15%
}

.margin-fifteen-half-bottom {
    margin-bottom: 15.5%
}

.margin-sixteen-bottom {
    margin-bottom: 16%
}

.margin-sixteen-half-bottom {
    margin-bottom: 16.5%
}

.margin-seventeen-bottom {
    margin-bottom: 17%
}

.margin-seventeen-half-bottom {
    margin-bottom: 17.5%
}

.margin-eighteen-bottom {
    margin-bottom: 18%
}

.margin-eighteen-half-bottom {
    margin-bottom: 18.5%
}

.margin-nineteen-bottom {
    margin-bottom: 19%
}

.margin-nineteen-half-bottom {
    margin-bottom: 19.5%
}

.margin-twenty-bottom {
    margin-bottom: 20%
}

.margin-twenty-half-bottom {
    margin-bottom: 20.5%
}

.margin-twenty-one-bottom {
    margin-bottom: 21%
}

.margin-twenty-one-half-bottom {
    margin-bottom: 21.5%
}

.margin-twenty-two-bottom {
    margin-bottom: 22%
}

.margin-twenty-two-half-bottom {
    margin-bottom: 22.5%
}

.margin-twenty-three-bottom {
    margin-bottom: 23%
}

.margin-twenty-three-half-bottom {
    margin-bottom: 23.5%
}

.margin-twenty-four-bottom {
    margin-bottom: 24%
}

.margin-twenty-four-half-bottom {
    margin-bottom: 24.5%
}

.margin-twenty-five-bottom {
    margin-bottom: 25%
}

.margin-5px-bottom {
    margin-bottom: 5px
}

.margin-8px-bottom {
    margin-bottom: 8px
}

.margin-10px-bottom {
    margin-bottom: 10px
}

.margin-15px-bottom {
    margin-bottom: 15px
}

.margin-20px-bottom {
    margin-bottom: 20px
}

.margin-25px-bottom {
    margin-bottom: 25px
}

.margin-30px-bottom {
    margin-bottom: 30px
}

.margin-35px-bottom {
    margin-bottom: 35px
}

.margin-40px-bottom {
    margin-bottom: 40px
}

.margin-45px-bottom {
    margin-bottom: 45px
}

.margin-50px-bottom {
    margin-bottom: 50px
}

.margin-55px-bottom {
    margin-bottom: 55px
}

.margin-60px-bottom {
    margin-bottom: 60px
}

.margin-65px-bottom {
    margin-bottom: 65px
}

.margin-70px-bottom {
    margin-bottom: 70px
}

.margin-75px-bottom {
    margin-bottom: 75px
}

.margin-80px-bottom {
    margin-bottom: 80px
}

.margin-85px-bottom {
    margin-bottom: 85px
}

.margin-90px-bottom {
    margin-bottom: 90px
}

.margin-95px-bottom {
    margin-bottom: 95px
}

.margin-100px-bottom {
    margin-bottom: 100px
}

.margin-one-right {
    margin-right: 1%
}

.margin-one-half-right {
    margin-right: 1.5%
}

.margin-two-right {
    margin-right: 2%
}

.margin-two-half-right {
    margin-right: 2.5%
}

.margin-three-right {
    margin-right: 3%
}

.margin-three-half-right {
    margin-right: 3.5%
}

.margin-four-right {
    margin-right: 4%
}

.margin-four-half-right {
    margin-right: 4.5%
}

.margin-five-right {
    margin-right: 5%
}

.margin-five-half-right {
    margin-right: 5.5%
}

.margin-six-right {
    margin-right: 6%
}

.margin-six-half-right {
    margin-right: 6.5%
}

.margin-seven-right {
    margin-right: 7%
}

.margin-seven-half-right {
    margin-right: 7.5%
}

.margin-eight-right {
    margin-right: 8%
}

.margin-eight-half-right {
    margin-right: 8.5%
}

.margin-nine-right {
    margin-right: 9%
}

.margin-nine-half-right {
    margin-right: 9.5%
}

.margin-ten-right {
    margin-right: 10%
}

.margin-ten-half-right {
    margin-right: 10.5%
}

.margin-eleven-right {
    margin-right: 11%
}

.margin-eleven-half-right {
    margin-right: 11.5%
}

.margin-twelve-right {
    margin-right: 12%
}

.margin-twelve-half-right {
    margin-right: 12.5%
}

.margin-thirteen-right {
    margin-right: 13%
}

.margin-thirteen-half-right {
    margin-right: 13.5%
}

.margin-fourteen-right {
    margin-right: 14%
}

.margin-fourteen-half-right {
    margin-right: 14.5%
}

.margin-fifteen-right {
    margin-right: 15%
}

.margin-fifteen-half-right {
    margin-right: 15.5%
}

.margin-sixteen-right {
    margin-right: 16%
}

.margin-sixteen-half-right {
    margin-right: 16.5%
}

.margin-seventeen-right {
    margin-right: 17%
}

.margin-seventeen-half-right {
    margin-right: 17.5%
}

.margin-eighteen-right {
    margin-right: 18%
}

.margin-eighteen-half-right {
    margin-right: 18.5%
}

.margin-nineteen-right {
    margin-right: 19%
}

.margin-nineteen-half-right {
    margin-right: 19.5%
}

.margin-twenty-right {
    margin-right: 20%
}

.margin-twenty-half-right {
    margin-right: 20.5%
}

.margin-twenty-right {
    margin-right: 21%
}

.margin-twenty-one-half-right {
    margin-right: 21.5%
}

.margin-twenty-two-right {
    margin-right: 22%
}

.margin-twenty-two-half-right {
    margin-right: 22.5%
}

.margin-twenty-three-right {
    margin-right: 23%
}

.margin-twenty-three-half-right {
    margin-right: 23.5%
}

.margin-twenty-four-right {
    margin-right: 24%
}

.margin-twenty-four-half-right {
    margin-right: 24.5%
}

.margin-twenty-five-right {
    margin-right: 25%
}

.margin-5px-right {
    margin-right: 5px
}

.margin-10px-right {
    margin-right: 10px
}

.margin-15px-right {
    margin-right: 15px
}

.margin-20px-right {
    margin-right: 20px
}

.margin-25px-right {
    margin-right: 25px
}

.margin-30px-right {
    margin-right: 30px
}

.margin-35px-right {
    margin-right: 35px
}

.margin-40px-right {
    margin-right: 40px
}

.margin-45px-right {
    margin-right: 45px
}

.margin-50px-right {
    margin-right: 50px
}

.margin-55px-right {
    margin-right: 55px
}

.margin-60px-right {
    margin-right: 60px
}

.margin-65px-right {
    margin-right: 65px
}

.margin-70px-right {
    margin-right: 70px
}

.margin-75px-right {
    margin-right: 75px
}

.margin-80px-right {
    margin-right: 80px
}

.margin-85px-right {
    margin-right: 85px
}

.margin-90px-right {
    margin-right: 90px
}

.margin-95px-right {
    margin-right: 95px
}

.margin-100px-right {
    margin-right: 100px
}

.margin-one-left {
    margin-left: 1%
}

.margin-one-half-left {
    margin-left: 1.5%
}

.margin-two-left {
    margin-left: 2%
}

.margin-two-half-left {
    margin-left: 2.5%
}

.margin-three-left {
    margin-left: 3%
}

.margin-three-half-left {
    margin-left: 3.5%
}

.margin-four-left {
    margin-left: 4%
}

.margin-four-half-left {
    margin-left: 4.5%
}

.margin-five-left {
    margin-left: 5%
}

.margin-five-half-left {
    margin-left: 5.5%
}

.margin-six-left {
    margin-left: 6%
}

.margin-six-half-left {
    margin-left: 6.5%
}

.margin-seven-left {
    margin-left: 7%
}

.margin-seven-half-left {
    margin-left: 7.5%
}

.margin-eight-left {
    margin-left: 8%
}

.margin-eight-half-left {
    margin-left: 8.5%
}

.margin-nine-left {
    margin-left: 9%
}

.margin-nine-half-left {
    margin-left: 9.5%
}

.margin-ten-left {
    margin-left: 10%
}

.margin-ten-half-left {
    margin-left: 10.5%
}

.margin-eleven-left {
    margin-left: 11%
}

.margin-eleven-half-left {
    margin-left: 11.5%
}

.margin-twelve-left {
    margin-left: 12%
}

.margin-twelve-half-left {
    margin-left: 12.5%
}

.margin-thirteen-left {
    margin-left: 13%
}

.margin-thirteen-half-left {
    margin-left: 13.5%
}

.margin-fourteen-left {
    margin-left: 14%
}

.margin-fourteen-half-left {
    margin-left: 14.5%
}

.margin-fifteen-left {
    margin-left: 15%
}

.margin-fifteen-half-left {
    margin-left: 15.5%
}

.margin-sixteen-left {
    margin-left: 16%
}

.margin-sixteen-half-left {
    margin-left: 16.5%
}

.margin-seventeen-left {
    margin-left: 17%
}

.margin-seventeen-half-left {
    margin-left: 17.5%
}

.margin-eighteen-left {
    margin-left: 18%
}

.margin-eighteen-half-left {
    margin-left: 18.5%
}

.margin-nineteen-left {
    margin-left: 19%
}

.margin-nineteen-half-left {
    margin-left: 19.5%
}

.margin-twenty-left {
    margin-left: 20%
}

.margin-twenty-half-left {
    margin-left: 20.5%
}

.margin-twenty-one-left {
    margin-left: 21%
}

.margin-twenty-one-half-left {
    margin-left: 21.5%
}

.margin-twenty-two-left {
    margin-left: 22%
}

.margin-twenty-two-half-left {
    margin-left: 22.5%
}

.margin-twenty-three-left {
    margin-left: 23%
}

.margin-twenty-three-half-left {
    margin-left: 23.5%
}

.margin-twenty-four-left {
    margin-left: 24%
}

.margin-twenty-four-half-left {
    margin-left: 24.5%
}

.margin-twenty-five-left {
    margin-left: 25%
}

.margin-5px-left {
    margin-left: 5px
}

.margin-10px-left {
    margin-left: 10px
}

.margin-15px-left {
    margin-left: 15px
}

.margin-20px-left {
    margin-left: 20px
}

.margin-25px-left {
    margin-left: 25px
}

.margin-30px-left {
    margin-left: 30px
}

.margin-35px-left {
    margin-left: 35px
}

.margin-40px-left {
    margin-left: 40px
}

.margin-45px-left {
    margin-left: 45px
}

.margin-50px-left {
    margin-left: 50px
}

.margin-55px-left {
    margin-left: 55px
}

.margin-60px-left {
    margin-left: 60px
}

.margin-65px-left {
    margin-left: 65px
}

.margin-70px-left {
    margin-left: 70px
}

.margin-75px-left {
    margin-left: 75px
}

.margin-80px-left {
    margin-left: 80px
}

.margin-85px-left {
    margin-left: 85px
}

.margin-90px-left {
    margin-left: 90px
}

.margin-95px-left {
    margin-left: 95px
}

.margin-100px-left {
    margin-left: 100px
}

.margin-one-lr {
    margin-left: 1%;
    margin-right: 1%
}

.margin-one-half-lr {
    margin-left: 1.5%;
    margin-right: 1.5%
}

.margin-two-lr {
    margin-left: 2%;
    margin-right: 2%
}

.margin-two-half-lr {
    margin-left: 2.5%;
    margin-right: 2.5%
}

.margin-three-lr {
    margin-left: 3%;
    margin-right: 3%
}

.margin-three-half-lr {
    margin-left: 3.5%;
    margin-right: 3.5%
}

.margin-four-lr {
    margin-left: 4%;
    margin-right: 4%
}

.margin-four-half-lr {
    margin-left: 4.5%;
    margin-right: 4.5%
}

.margin-five-lr {
    margin-left: 5%;
    margin-right: 5%
}

.margin-five-half-lr {
    margin-left: 5.5%;
    margin-right: 5.5%
}

.margin-six-lr {
    margin-left: 6%;
    margin-right: 6%
}

.margin-six-half-lr {
    margin-left: 6.5%;
    margin-right: 6.5%
}

.margin-seven-lr {
    margin-left: 7%;
    margin-right: 7%
}

.margin-seven-half-lr {
    margin-left: 7.5%;
    margin-right: 7.5%
}

.margin-eight-lr {
    margin-left: 8%;
    margin-right: 8%
}

.margin-eight-half-lr {
    margin-left: 8.5%;
    margin-right: 8.5%
}

.margin-nine-lr {
    margin-left: 9%;
    margin-right: 9%
}

.margin-nine-half-lr {
    margin-left: 9.5%;
    margin-right: 9.5%
}

.margin-ten-lr {
    margin-left: 10%;
    margin-right: 10%
}

.margin-ten-half-lr {
    margin-left: 10.5%;
    margin-right: 10.5%
}

.margin-eleven-lr {
    margin-left: 11%;
    margin-right: 11%
}

.margin-eleven-half-lr {
    margin-left: 11.5%;
    margin-right: 11.5%
}

.margin-twelve-lr {
    margin-left: 12%;
    margin-right: 12%
}

.margin-twelve-half-lr {
    margin-left: 12.5%;
    margin-right: 12.5%
}

.margin-thirteen-lr {
    margin-left: 13%;
    margin-right: 13%
}

.margin-thirteen-half-lr {
    margin-left: 13.5%;
    margin-right: 13.5%
}

.margin-fourteen-lr {
    margin-left: 14%;
    margin-right: 14%
}

.margin-fourteen-half-lr {
    margin-left: 14.5%;
    margin-right: 14.5%
}

.margin-fifteen-lr {
    margin-left: 15%;
    margin-right: 15%
}

.margin-fifteen-half-lr {
    margin-left: 15.5%;
    margin-right: 15.5%
}

.margin-sixteen-lr {
    margin-left: 16%;
    margin-right: 16%
}

.margin-sixteen-half-lr {
    margin-left: 16.5%;
    margin-right: 16.5%
}

.margin-seventeen-lr {
    margin-left: 17%;
    margin-right: 17%
}

.margin-seventeen-half-lr {
    margin-left: 17.5%;
    margin-right: 17.5%
}

.margin-eighteen-lr {
    margin-left: 18%;
    margin-right: 18%
}

.margin-eighteen-half-lr {
    margin-left: 18.5%;
    margin-right: 18.5%
}

.margin-nineteen-lr {
    margin-left: 19%;
    margin-right: 19%
}

.margin-nineteen-half-lr {
    margin-left: 19.5%;
    margin-right: 19.5%
}

.margin-twenty-lr {
    margin-left: 20%;
    margin-right: 20%
}

.margin-twenty-half-lr {
    margin-left: 20.5%;
    margin-right: 20.5%
}

.margin-twenty-one-lr {
    margin-left: 21%;
    margin-right: 21%
}

.margin-twenty-one-half-lr {
    margin-left: 21.5%;
    margin-right: 21.5%
}

.margin-twenty-two-lr {
    margin-left: 22%;
    margin-right: 22%
}

.margin-twenty-two-half-lr {
    margin-left: 22.5%;
    margin-right: 22.5%
}

.margin-twenty-three-lr {
    margin-left: 23%;
    margin-right: 23%
}

.margin-twenty-three-half-lr {
    margin-left: 23.5%;
    margin-right: 23.5%
}

.margin-twenty-four-lr {
    margin-left: 24%;
    margin-right: 24%
}

.margin-twenty-four-half-lr {
    margin-left: 24.5%;
    margin-right: 24.5%
}

.margin-twenty-five-lr {
    margin-left: 25%;
    margin-right: 25%
}

.margin-5px-lr {
    margin-left: 5px;
    margin-right: 5px
}

.margin-10px-lr {
    margin-left: 10px;
    margin-right: 10px
}

.margin-15px-lr {
    margin-left: 15px;
    margin-right: 15px
}

.margin-20px-lr {
    margin-left: 20px;
    margin-right: 20px
}

.margin-25px-lr {
    margin-left: 25px;
    margin-right: 25px
}

.margin-30px-lr {
    margin-left: 30px;
    margin-right: 30px
}

.margin-35px-lr {
    margin-left: 35px;
    margin-right: 35px
}

.margin-40px-lr {
    margin-left: 40px;
    margin-right: 40px
}

.margin-45px-lr {
    margin-left: 45px;
    margin-right: 45px
}

.margin-50px-lr {
    margin-left: 50px;
    margin-right: 50px
}

.margin-55px-lr {
    margin-left: 55px;
    margin-right: 55px
}

.margin-60px-lr {
    margin-left: 60px;
    margin-right: 60px
}

.margin-65px-lr {
    margin-left: 65px;
    margin-right: 65px
}

.margin-70px-lr {
    margin-left: 70px;
    margin-right: 70px
}

.margin-75px-lr {
    margin-left: 75px;
    margin-right: 75px
}

.margin-80px-lr {
    margin-left: 80px;
    margin-right: 80px
}

.margin-85px-lr {
    margin-left: 85px;
    margin-right: 85px
}

.margin-90px-lr {
    margin-left: 90px;
    margin-right: 90px
}

.margin-95px-lr {
    margin-left: 95px;
    margin-right: 95px
}

.margin-100px-lr {
    margin-left: 100px;
    margin-right: 100px
}

.margin-one-tb {
    margin-top: 1%;
    margin-bottom: 1%
}

.margin-one-half-tb {
    margin-top: 1.5%;
    margin-bottom: 1.5%
}

.margin-two-tb {
    margin-top: 2%;
    margin-bottom: 2%
}

.margin-two-half-tb {
    margin-top: 2.5%;
    margin-bottom: 2.5%
}

.margin-three-tb {
    margin-top: 3%;
    margin-bottom: 3%
}

.margin-three-half-tb {
    margin-top: 3.5%;
    margin-bottom: 3.5%
}

.margin-four-tb {
    margin-top: 4%;
    margin-bottom: 4%
}

.margin-four-half-tb {
    margin-top: 4.5%;
    margin-bottom: 4.5%
}

.margin-five-tb {
    margin-top: 5%;
    margin-bottom: 5%
}

.margin-five-half-tb {
    margin-top: 5.5%;
    margin-bottom: 5.5%
}

.margin-six-tb {
    margin-top: 6%;
    margin-bottom: 6%
}

.margin-six-half-tb {
    margin-top: 6.5%;
    margin-bottom: 6.5%
}

.margin-seven-tb {
    margin-top: 7%;
    margin-bottom: 7%
}

.margin-seven-half-tb {
    margin-top: 7.5%;
    margin-bottom: 7.5%
}

.margin-eight-tb {
    margin-top: 8%;
    margin-bottom: 8%
}

.margin-eight-half-tb {
    margin-top: 8.5%;
    margin-bottom: 8.5%
}

.margin-nine-tb {
    margin-top: 9%;
    margin-bottom: 9%
}

.margin-nine-half-tb {
    margin-top: 9.5%;
    margin-bottom: 9.5%
}

.margin-ten-tb {
    margin-top: 10%;
    margin-bottom: 10%
}

.margin-ten-half-tb {
    margin-top: 10.5%;
    margin-bottom: 10.5%
}

.margin-eleven-tb {
    margin-top: 11%;
    margin-bottom: 11%
}

.margin-eleven-half-tb {
    margin-top: 11.5%;
    margin-bottom: 11.5%
}

.margin-twelve-tb {
    margin-top: 12%;
    margin-bottom: 12%
}

.margin-twelve-half-tb {
    margin-top: 12.5%;
    margin-bottom: 12.5%
}

.margin-thirteen-tb {
    margin-top: 13%;
    margin-bottom: 13%
}

.margin-thirteen-half-tb {
    margin-top: 13.5%;
    margin-bottom: 13.5%
}

.margin-fourteen-tb {
    margin-top: 14%;
    margin-bottom: 14%
}

.margin-fourteen-half-tb {
    margin-top: 14.5%;
    margin-bottom: 14.5%
}

.margin-fifteen-tb {
    margin-top: 15%;
    margin-bottom: 15%
}

.margin-fifteen-half-tb {
    margin-top: 15.5%;
    margin-bottom: 15.5%
}

.margin-sixteen-tb {
    margin-top: 16%;
    margin-bottom: 16%
}

.margin-sixteen-half-tb {
    margin-top: 16.5%;
    margin-bottom: 16.5%
}

.margin-seventeen-tb {
    margin-top: 17%;
    margin-bottom: 17%
}

.margin-seventeen-half-tb {
    margin-top: 17.5%;
    margin-bottom: 17.5%
}

.margin-eighteen-tb {
    margin-top: 18%;
    margin-bottom: 18%
}

.margin-eighteen-half-tb {
    margin-top: 18.5%;
    margin-bottom: 18.5%
}

.margin-nineteen-tb {
    margin-top: 19%;
    margin-bottom: 19%
}

.margin-nineteen-half-tb {
    margin-top: 19.5%;
    margin-bottom: 19.5%
}

.margin-twenty-tb {
    margin-top: 20%;
    margin-bottom: 20%
}

.margin-twenty-half-tb {
    margin-top: 20.5%;
    margin-bottom: 20.5%
}

.margin-twenty-one-tb {
    margin-top: 21%;
    margin-bottom: 21%
}

.margin-twenty-one-half-tb {
    margin-top: 21.5%;
    margin-bottom: 21.5%
}

.margin-twenty-two-tb {
    margin-top: 22%;
    margin-bottom: 22%
}

.margin-twenty-two-half-tb {
    margin-top: 22.5%;
    margin-bottom: 22.5%
}

.margin-twenty-three-tb {
    margin-top: 23%;
    margin-bottom: 23%
}

.margin-twenty-three-half-tb {
    margin-top: 23.5%;
    margin-bottom: 23.5%
}

.margin-twenty-four-tb {
    margin-top: 24%;
    margin-bottom: 24%
}

.margin-twenty-four-half-tb {
    margin-top: 24.5%;
    margin-bottom: 24.5%
}

.margin-twenty-five-tb {
    margin-top: 25%;
    margin-bottom: 25%
}

.margin-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px
}

.margin-10px-tb {
    margin-top: 10px;
    margin-bottom: 10px
}

.margin-15px-tb {
    margin-top: 15px;
    margin-bottom: 15px
}

.margin-20px-tb {
    margin-top: 20px;
    margin-bottom: 20px
}

.margin-25px-tb {
    margin-top: 25px;
    margin-bottom: 25px
}

.margin-30px-tb {
    margin-top: 30px;
    margin-bottom: 30px
}

.margin-35px-tb {
    margin-top: 35px;
    margin-bottom: 35px
}

.margin-40px-tb {
    margin-top: 40px;
    margin-bottom: 40px
}

.margin-45px-tb {
    margin-top: 45px;
    margin-bottom: 45px
}

.margin-50px-tb {
    margin-top: 50px;
    margin-bottom: 50px
}

.margin-55px-tb {
    margin-top: 55px;
    margin-bottom: 55px
}

.margin-60px-tb {
    margin-top: 60px;
    margin-bottom: 60px
}

.margin-65px-tb {
    margin-top: 65px;
    margin-bottom: 65px
}

.margin-70px-tb {
    margin-top: 70px;
    margin-bottom: 70px
}

.margin-75px-tb {
    margin-top: 75px;
    margin-bottom: 75px
}

.margin-80px-tb {
    margin-top: 80px;
    margin-bottom: 80px
}

.margin-85px-tb {
    margin-top: 85px;
    margin-bottom: 85px
}

.margin-90px-tb {
    margin-top: 90px;
    margin-bottom: 90px
}

.margin-95px-tb {
    margin-top: 95px;
    margin-bottom: 95px
}

.margin-100px-tb {
    margin-top: 100px;
    margin-bottom: 100px
}

.no-padding {
    padding: 0 !important
}

.no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.no-padding-top {
    padding-top: 0 !important
}

.no-padding-bottom {
    padding-bottom: 0 !important
}

.no-padding-left {
    padding-left: 0 !important
}

.no-padding-right {
    padding-right: 0 !important
}

.padding-one-all {
    padding: 1%
}

.padding-one-half-all {
    padding: 1.5%
}

.padding-two-all {
    padding: 2%
}

.padding-two-half-all {
    padding: 2.5%
}

.padding-three-all {
    padding: 3%
}

.padding-three-half-all {
    padding: 3.5%
}

.padding-four-all {
    padding: 4%
}

.padding-four-half-all {
    padding: 4.5%
}

.padding-five-all {
    padding: 5%
}

.padding-five-half-all {
    padding: 5.5%
}

.padding-six-all {
    padding: 6%
}

.padding-six-half-all {
    padding: 6.5%
}

.padding-seven-all {
    padding: 7%
}

.padding-seven-half-all {
    padding: 7.5%
}

.padding-eight-all {
    padding: 8%
}

.padding-eight-half-all {
    padding: 8.5%
}

.padding-nine-all {
    padding: 9%
}

.padding-nine-half-all {
    padding: 9.5%
}

.padding-ten-all {
    padding: 10%
}

.padding-ten-half-all {
    padding: 10.5%
}

.padding-eleven-all {
    padding: 11%
}

.padding-eleven-half-all {
    padding: 11.5%
}

.padding-twelve-all {
    padding: 12%
}

.padding-twelve-half-all {
    padding: 12.5%
}

.padding-thirteen-all {
    padding: 13%
}

.padding-thirteen-half-all {
    padding: 13.5%
}

.padding-fourteen-all {
    padding: 14%
}

.padding-fourteen-half-all {
    padding: 14.5%
}

.padding-fifteen-all {
    padding: 15%
}

.padding-fifteen-half-all {
    padding: 15.5%
}

.padding-sixteen-all {
    padding: 16%
}

.padding-sixteen-half-all {
    padding: 16.5%
}

.padding-seventeen-all {
    padding: 17%
}

.padding-seventeen-half-all {
    padding: 17.5%
}

.padding-eighteen-all {
    padding: 18%
}

.padding-eighteen-half-all {
    padding: 18.5%
}

.padding-nineteen-all {
    padding: 19%
}

.padding-nineteen-half-all {
    padding: 19.5%
}

.padding-twenty-all {
    padding: 20%
}

.padding-twenty-half-all {
    padding: 20.5%
}

.padding-twenty-one-all {
    padding: 21%
}

.padding-twenty-one-half-all {
    padding: 21.5%
}

.padding-twenty-two-all {
    padding: 22%
}

.padding-twenty-two-half-all {
    padding: 22.5%
}

.padding-twenty-three-all {
    padding: 23%
}

.padding-twenty-three-half-all {
    padding: 23.5%
}

.padding-twenty-four-all {
    padding: 24%
}

.padding-twenty-four-half-all {
    padding: 24.5%
}

.padding-twenty-five-all {
    padding: 25%
}

.padding-twenty-five-half-all {
    padding: 25.5%
}

.padding-5px-all {
    padding: 5px
}

.padding-10px-all {
    padding: 10px
}

.padding-15px-all {
    padding: 15px
}

.padding-20px-all {
    padding: 20px
}

.padding-25px-all {
    padding: 25px
}

.padding-30px-all {
    padding: 30px
}

.padding-35px-all {
    padding: 35px
}

.padding-40px-all {
    padding: 40px
}

.padding-45px-all {
    padding: 45px
}

.padding-50px-all {
    padding: 50px
}

.padding-55px-all {
    padding: 55px
}

.padding-60px-all {
    padding: 60px
}

.padding-65px-all {
    padding: 65px
}

.padding-70px-all {
    padding: 70px
}

.padding-75px-all {
    padding: 75px
}

.padding-80px-all {
    padding: 80px
}

.padding-85px-all {
    padding: 85px
}

.padding-90px-all {
    padding: 90px
}

.padding-95px-all {
    padding: 95px
}

.padding-100px-all {
    padding: 100px
}

.padding-one-top {
    padding-top: 1%
}

.padding-one-half-top {
    padding-top: 1.5%
}

.padding-two-top {
    padding-top: 2%
}

.padding-two-half-top {
    padding-top: 2.5%
}

.padding-three-top {
    padding-top: 3%
}

.padding-three-half-top {
    padding-top: 3.5%
}

.padding-four-top {
    padding-top: 4%
}

.padding-four-half-top {
    padding-top: 4.5%
}

.padding-five-top {
    padding-top: 5%
}

.padding-five-half-top {
    padding-top: 5.5%
}

.padding-six-top {
    padding-top: 6%
}

.padding-six-half-top {
    padding-top: 6.5%
}

.padding-seven-top {
    padding-top: 7%
}

.padding-seven-half-top {
    padding-top: 7.5%
}

.padding-eight-top {
    padding-top: 8%
}

.padding-eight-half-top {
    padding-top: 8.5%
}

.padding-nine-top {
    padding-top: 9%
}

.padding-nine-half-top {
    padding-top: 9.5%
}

.padding-ten-top {
    padding-top: 10%
}

.padding-ten-half-top {
    padding-top: 10.5%
}

.padding-eleven-top {
    padding-top: 11%
}

.padding-eleven-half-top {
    padding-top: 11.5%
}

.padding-twelve-top {
    padding-top: 12%
}

.padding-twelve-half-top {
    padding-top: 12.5%
}

.padding-thirteen-top {
    padding-top: 13%
}

.padding-thirteen-half-top {
    padding-top: 13.5%
}

.padding-fourteen-top {
    padding-top: 14%
}

.padding-fourteen-half-top {
    padding-top: 14.5%
}

.padding-fifteen-top {
    padding-top: 15%
}

.padding-fifteen-half-top {
    padding-top: 15.5%
}

.padding-sixteen-top {
    padding-top: 16%
}

.padding-sixteen-half-top {
    padding-top: 16.5%
}

.padding-seventeen-top {
    padding-top: 17%
}

.padding-seventeen-half-top {
    padding-top: 17.5%
}

.padding-eighteen-top {
    padding-top: 18%
}

.padding-eighteen-half-top {
    padding-top: 18.5%
}

.padding-nineteen-top {
    padding-top: 19%
}

.padding-nineteen-half-top {
    padding-top: 19.5%
}

.padding-twenty-top {
    padding-top: 20%
}

.padding-twenty-half-top {
    padding-top: 20.5%
}

.padding-twenty-one-top {
    padding-top: 21%
}

.padding-twenty-one-half-top {
    padding-top: 21.5%
}

.padding-twenty-two-top {
    padding-top: 22%
}

.padding-twenty-two-half-top {
    padding-top: 22.5%
}

.padding-twenty-two-top {
    padding-top: 22%
}

.padding-twenty-two-half-top {
    padding-top: 22.5%
}

.padding-twenty-three-top {
    padding-top: 23%
}

.padding-twenty-three-half-top {
    padding-top: 23.5%
}

.padding-twenty-four-top {
    padding-top: 24%
}

.padding-twenty-four-half-top {
    padding-top: 24.5%
}

.padding-twenty-five-top {
    padding-top: 25%
}

.padding-5px-top {
    padding-top: 5px
}

.padding-10px-top {
    padding-top: 10px
}

.padding-15px-top {
    padding-top: 15px
}

.padding-20px-top {
    padding-top: 20px
}

.padding-25px-top {
    padding-top: 25px
}

.padding-30px-top {
    padding-top: 30px
}

.padding-35px-top {
    padding-top: 35px
}

.padding-40px-top {
    padding-top: 40px
}

.padding-45px-top {
    padding-top: 45px
}

.padding-50px-top {
    padding-top: 50px
}

.padding-55px-top {
    padding-top: 55px
}

.padding-60px-top {
    padding-top: 60px
}

.padding-65px-top {
    padding-top: 65px
}

.padding-70px-top {
    padding-top: 70px
}

.padding-75px-top {
    padding-top: 75px
}

.padding-80px-top {
    padding-top: 80px
}

.padding-85px-top {
    padding-top: 85px
}

.padding-90px-top {
    padding-top: 90px
}

.padding-95px-top {
    padding-top: 95px
}

.padding-100px-top {
    padding-top: 100px
}

.padding-one-bottom {
    padding-bottom: 1%
}

.padding-one-half-bottom {
    padding-bottom: 1.5%
}

.padding-two-bottom {
    padding-bottom: 2%
}

.padding-two-half-bottom {
    padding-bottom: 2.5%
}

.padding-three-bottom {
    padding-bottom: 3%
}

.padding-three-half-bottom {
    padding-bottom: 3.5%
}

.padding-four-bottom {
    padding-bottom: 4%
}

.padding-four-half-bottom {
    padding-bottom: 4.5%
}

.padding-five-bottom {
    padding-bottom: 5%
}

.padding-five-half-bottom {
    padding-bottom: 5.5%
}

.padding-six-bottom {
    padding-bottom: 6%
}

.padding-six-half-bottom {
    padding-bottom: 6.5%
}

.padding-seven-bottom {
    padding-bottom: 7%
}

.padding-seven-half-bottom {
    padding-bottom: 7.5%
}

.padding-eight-bottom {
    padding-bottom: 8%
}

.padding-eight-half-bottom {
    padding-bottom: 8.5%
}

.padding-nine-bottom {
    padding-bottom: 9%
}

.padding-nine-half-bottom {
    padding-bottom: 9.5%
}

.padding-ten-bottom {
    padding-bottom: 10%
}

.padding-ten-half-bottom {
    padding-bottom: 10.5%
}

.padding-eleven-bottom {
    padding-bottom: 11%
}

.padding-eleven-half-bottom {
    padding-bottom: 11.5%
}

.padding-twelve-bottom {
    padding-bottom: 12%
}

.padding-twelve-half-bottom {
    padding-bottom: 12.5%
}

.padding-thirteen-bottom {
    padding-bottom: 13%
}

.padding-thirteen-half-bottom {
    padding-bottom: 13.5%
}

.padding-fourteen-bottom {
    padding-bottom: 14%
}

.padding-fourteen-half-bottom {
    padding-bottom: 14.5%
}

.padding-fifteen-bottom {
    padding-bottom: 15%
}

.padding-fifteen-half-bottom {
    padding-bottom: 15.5%
}

.padding-sixteen-bottom {
    padding-bottom: 16%
}

.padding-sixteen-half-bottom {
    padding-bottom: 16.5%
}

.padding-seventeen-bottom {
    padding-bottom: 17%
}

.padding-seventeen-half-bottom {
    padding-bottom: 17.5%
}

.padding-eighteen-bottom {
    padding-bottom: 18%
}

.padding-eighteen-half-bottom {
    padding-bottom: 18.5%
}

.padding-nineteen-bottom {
    padding-bottom: 19%
}

.padding-nineteen-half-bottom {
    padding-bottom: 19.5%
}

.padding-twenty-bottom {
    padding-bottom: 20%
}

.padding-twenty-half-bottom {
    padding-bottom: 20.5%
}

.padding-twenty-one-bottom {
    padding-bottom: 21%
}

.padding-twenty-one-half-bottom {
    padding-bottom: 21.5%
}

.padding-twenty-two-bottom {
    padding-bottom: 22%
}

.padding-twenty-two-half-bottom {
    padding-bottom: 22.5%
}

.padding-twenty-three-bottom {
    padding-bottom: 23%
}

.padding-twenty-three-half-bottom {
    padding-bottom: 23.5%
}

.padding-twenty-four-bottom {
    padding-bottom: 24%
}

.padding-twenty-four-half-bottom {
    padding-bottom: 24.5%
}

.padding-twenty-five-bottom {
    padding-bottom: 25%
}

.padding-5px-bottom {
    padding-bottom: 5px
}

.padding-10px-bottom {
    padding-bottom: 10px
}

.padding-15px-bottom {
    padding-bottom: 15px
}

.padding-20px-bottom {
    padding-bottom: 20px
}

.padding-25px-bottom {
    padding-bottom: 25px
}

.padding-30px-bottom {
    padding-bottom: 30px
}

.padding-35px-bottom {
    padding-bottom: 35px
}

.padding-40px-bottom {
    padding-bottom: 40px
}

.padding-45px-bottom {
    padding-bottom: 45px
}

.padding-50px-bottom {
    padding-bottom: 50px
}

.padding-55px-bottom {
    padding-bottom: 55px
}

.padding-60px-bottom {
    padding-bottom: 60px
}

.padding-65px-bottom {
    padding-bottom: 65px
}

.padding-70px-bottom {
    padding-bottom: 70px
}

.padding-75px-bottom {
    padding-bottom: 75px
}

.padding-80px-bottom {
    padding-bottom: 80px
}

.padding-85px-bottom {
    padding-bottom: 85px
}

.padding-90px-bottom {
    padding-bottom: 90px
}

.padding-95px-bottom {
    padding-bottom: 95px
}

.padding-100px-bottom {
    padding-bottom: 100px
}

.padding-one-right {
    padding-right: 1%
}

.padding-one-half-right {
    padding-right: 1.5%
}

.padding-two-right {
    padding-right: 2%
}

.padding-two-half-right {
    padding-right: 2.5%
}

.padding-three-right {
    padding-right: 3%
}

.padding-three-half-right {
    padding-right: 3.5%
}

.padding-four-right {
    padding-right: 4%
}

.padding-four-half-right {
    padding-right: 4.5%
}

.padding-five-right {
    padding-right: 5%
}

.padding-five-half-right {
    padding-right: 5.5%
}

.padding-six-right {
    padding-right: 6%
}

.padding-six-half-right {
    padding-right: 6.5%
}

.padding-seven-right {
    padding-right: 7%
}

.padding-seven-half-right {
    padding-right: 7.5%
}

.padding-eight-right {
    padding-right: 8%
}

.padding-eight-half-right {
    padding-right: 8.5%
}

.padding-nine-right {
    padding-right: 9%
}

.padding-nine-half-right {
    padding-right: 9.5%
}

.padding-ten-right {
    padding-right: 10%
}

.padding-ten-half-right {
    padding-right: 10.5%
}

.padding-eleven-right {
    padding-right: 11%
}

.padding-eleven-half-right {
    padding-right: 11.5%
}

.padding-twelve-right {
    padding-right: 12%
}

.padding-twelve-half-right {
    padding-right: 12.5%
}

.padding-thirteen-right {
    padding-right: 13%
}

.padding-thirteen-half-right {
    padding-right: 13.5%
}

.padding-fourteen-right {
    padding-right: 14%
}

.padding-fourteen-half-right {
    padding-right: 14.5%
}

.padding-fifteen-right {
    padding-right: 15%
}

.padding-fifteen-half-right {
    padding-right: 15.5%
}

.padding-sixteen-right {
    padding-right: 16%
}

.padding-sixteen-half-right {
    padding-right: 16.5%
}

.padding-seventeen-right {
    padding-right: 17%
}

.padding-seventeen-half-right {
    padding-right: 17.5%
}

.padding-eighteen-right {
    padding-right: 18%
}

.padding-eighteen-half-right {
    padding-right: 18.5%
}

.padding-nineteen-right {
    padding-right: 19%
}

.padding-nineteen-half-right {
    padding-right: 19.5%
}

.padding-twenty-right {
    padding-right: 20%
}

.padding-twenty-half-right {
    padding-right: 20.5%
}

.padding-twenty-one-right {
    padding-right: 21%
}

.padding-twenty-one-half-right {
    padding-right: 21.5%
}

.padding-twenty-two-right {
    padding-right: 22%
}

.padding-twenty-two-half-right {
    padding-right: 22.5%
}

.padding-twenty-three-right {
    padding-right: 23%
}

.padding-twenty-three-half-right {
    padding-right: 23.5%
}

.padding-twenty-four-right {
    padding-right: 24%
}

.padding-twenty-four-half-right {
    padding-right: 24.5%
}

.padding-twenty-five-right {
    padding-right: 25%
}

.padding-5px-right {
    padding-right: 5px
}

.padding-10px-right {
    padding-right: 10px
}

.padding-15px-right {
    padding-right: 15px
}

.padding-20px-right {
    padding-right: 20px
}

.padding-25px-right {
    padding-right: 25px
}

.padding-30px-right {
    padding-right: 30px
}

.padding-35px-right {
    padding-right: 35px
}

.padding-40px-right {
    padding-right: 40px
}

.padding-45px-right {
    padding-right: 45px
}

.padding-50px-right {
    padding-right: 50px
}

.padding-55px-right {
    padding-right: 55px
}

.padding-60px-right {
    padding-right: 60px
}

.padding-65px-right {
    padding-right: 65px
}

.padding-70px-right {
    padding-right: 70px
}

.padding-75px-right {
    padding-right: 75px
}

.padding-80px-right {
    padding-right: 80px
}

.padding-85px-right {
    padding-right: 85px
}

.padding-90px-right {
    padding-right: 90px
}

.padding-95px-right {
    padding-right: 95px
}

.padding-100px-right {
    padding-right: 100px
}

.padding-one-left {
    padding-left: 1%
}

.padding-one-half-left {
    padding-left: 1.5%
}

.padding-two-left {
    padding-left: 2%
}

.padding-two-half-left {
    padding-left: 2.5%
}

.padding-three-left {
    padding-left: 3%
}

.padding-three-half-left {
    padding-left: 3.5%
}

.padding-four-left {
    padding-left: 4%
}

.padding-four-half-left {
    padding-left: 4.5%
}

.padding-five-left {
    padding-left: 5%
}

.padding-five-half-left {
    padding-left: 5.5%
}

.padding-six-left {
    padding-left: 6%
}

.padding-six-half-left {
    padding-left: 6.5%
}

.padding-seven-left {
    padding-left: 7%
}

.padding-seven-half-left {
    padding-left: 7.5%
}

.padding-eight-left {
    padding-left: 8%
}

.padding-eight-half-left {
    padding-left: 8.5%
}

.padding-nine-left {
    padding-left: 9%
}

.padding-nine-half-left {
    padding-left: 9.5%
}

.padding-ten-left {
    padding-left: 10%
}

.padding-ten-half-left {
    padding-left: 10.5%
}

.padding-eleven-left {
    padding-left: 11%
}

.padding-eleven-half-left {
    padding-left: 11.5%
}

.padding-twelve-left {
    padding-left: 12%
}

.padding-twelve-half-left {
    padding-left: 12.5%
}

.padding-thirteen-left {
    padding-left: 13%
}

.padding-thirteen-half-left {
    padding-left: 13.5%
}

.padding-fourteen-left {
    padding-left: 14%
}

.padding-fourteen-half-left {
    padding-left: 14.5%
}

.padding-fifteen-left {
    padding-left: 15%
}

.padding-fifteen-half-left {
    padding-left: 15.5%
}

.padding-sixteen-left {
    padding-left: 16%
}

.padding-sixteen-half-left {
    padding-left: 16.5%
}

.padding-seventeen-left {
    padding-left: 17%
}

.padding-seventeen-half-left {
    padding-left: 17.5%
}

.padding-eighteen-left {
    padding-left: 18%
}

.padding-eighteen-half-left {
    padding-left: 18.5%
}

.padding-nineteen-left {
    padding-left: 19%
}

.padding-nineteen-half-left {
    padding-left: 19.5%
}

.padding-twenty-left {
    padding-left: 20%
}

.padding-twenty-half-left {
    padding-left: 20.5%
}

.padding-twenty-one-left {
    padding-left: 21%
}

.padding-twenty-one-half-left {
    padding-left: 21.5%
}

.padding-twenty-two-left {
    padding-left: 22%
}

.padding-twenty-two-half-left {
    padding-left: 22.5%
}

.padding-twenty-three-left {
    padding-left: 23%
}

.padding-twenty-three-half-left {
    padding-left: 23.5%
}

.padding-twenty-four-left {
    padding-left: 24%
}

.padding-twenty-four-half-left {
    padding-left: 24.5%
}

.padding-twenty-five-left {
    padding-left: 25%
}

.padding-5px-left {
    padding-left: 5px
}

.padding-10px-left {
    padding-left: 10px
}

.padding-15px-left {
    padding-left: 15px
}

.padding-20px-left {
    padding-left: 20px
}

.padding-25px-left {
    padding-left: 25px
}

.padding-30px-left {
    padding-left: 30px
}

.padding-35px-left {
    padding-left: 35px
}

.padding-40px-left {
    padding-left: 40px
}

.padding-45px-left {
    padding-left: 45px
}

.padding-50px-left {
    padding-left: 50px
}

.padding-55px-left {
    padding-left: 55px
}

.padding-60px-left {
    padding-left: 60px
}

.padding-65px-left {
    padding-left: 65px
}

.padding-70px-left {
    padding-left: 70px
}

.padding-75px-left {
    padding-left: 75px
}

.padding-80px-left {
    padding-left: 80px
}

.padding-85px-left {
    padding-left: 85px
}

.padding-90px-left {
    padding-left: 90px
}

.padding-95px-left {
    padding-left: 95px
}

.padding-100px-left {
    padding-left: 100px
}

.padding-one-tb {
    padding-top: 1%;
    padding-bottom: 1%
}

.padding-one-half-tb {
    padding-top: 1.5%;
    padding-bottom: 1.5%
}

.padding-two-tb {
    padding-top: 2%;
    padding-bottom: 2%
}

.padding-two-half-tb {
    padding-top: 2.5%;
    padding-bottom: 2.5%
}

.padding-three-tb {
    padding-top: 3%;
    padding-bottom: 3%
}

.padding-three-half-tb {
    padding-top: 3.5%;
    padding-bottom: 3.5%
}

.padding-four-tb {
    padding-top: 4%;
    padding-bottom: 4%
}

.padding-four-half-tb {
    padding-top: 4.5%;
    padding-bottom: 4.5%
}

.padding-five-tb {
    padding-top: 5%;
    padding-bottom: 5%
}

.padding-five-half-tb {
    padding-top: 5.5%;
    padding-bottom: 5.5%
}

.padding-six-tb {
    padding-top: 6%;
    padding-bottom: 6%
}

.padding-six-half-tb {
    padding-top: 6.5%;
    padding-bottom: 6.5%
}

.padding-seven-tb {
    padding-top: 7%;
    padding-bottom: 7%
}

.padding-seven-half-tb {
    padding-top: 7.5%;
    padding-bottom: 7.5%
}

.padding-eight-tb {
    padding-top: 8%;
    padding-bottom: 8%
}

.padding-eight-half-tb {
    padding-top: 8.5%;
    padding-bottom: 8.5%
}

.padding-nine-tb {
    padding-top: 9%;
    padding-bottom: 9%
}

.padding-nine-half-tb {
    padding-top: 9.5%;
    padding-bottom: 9.5%
}

.padding-ten-tb {
    padding-top: 10%;
    padding-bottom: 10%
}

.padding-ten-half-tb {
    padding-top: 10.5%;
    padding-bottom: 10.5%
}

.padding-eleven-tb {
    padding-top: 11%;
    padding-bottom: 11%
}

.padding-eleven-half-tb {
    padding-top: 11.5%;
    padding-bottom: 11.5%
}

.padding-twelve-tb {
    padding-top: 12%;
    padding-bottom: 12%
}

.padding-twelve-half-tb {
    padding-top: 12.5%;
    padding-bottom: 12.5%
}

.padding-thirteen-tb {
    padding-top: 13%;
    padding-bottom: 13%
}

.padding-thirteen-half-tb {
    padding-top: 13.5%;
    padding-bottom: 13.5%
}

.padding-fourteen-tb {
    padding-top: 14%;
    padding-bottom: 14%
}

.padding-fourteen-half-tb {
    padding-top: 14.5%;
    padding-bottom: 14.5%
}

.padding-fifteen-tb {
    padding-top: 15%;
    padding-bottom: 15%
}

.padding-fifteen-half-tb {
    padding-top: 15.5%;
    padding-bottom: 15.5%
}

.padding-sixteen-tb {
    padding-top: 16%;
    padding-bottom: 16%
}

.padding-sixteen-half-tb {
    padding-top: 16.5%;
    padding-bottom: 16.5%
}

.padding-seventeen-tb {
    padding-top: 17%;
    padding-bottom: 17%
}

.padding-seventeen-half-tb {
    padding-top: 17.5%;
    padding-bottom: 17.5%
}

.padding-eighteen-tb {
    padding-top: 18%;
    padding-bottom: 18%
}

.padding-eighteen-half-tb {
    padding-top: 18.5%;
    padding-bottom: 18.5%
}

.padding-nineteen-tb {
    padding-top: 19%;
    padding-bottom: 19%
}

.padding-nineteen-half-tb {
    padding-top: 19.5%;
    padding-bottom: 19.5%
}

.padding-twenty-tb {
    padding-top: 20%;
    padding-bottom: 20%
}

.padding-twenty-half-tb {
    padding-top: 20.5%;
    padding-bottom: 20%
}

.padding-twenty-one-tb {
    padding-top: 21%;
    padding-bottom: 21%
}

.padding-twenty-one-half-tb {
    padding-top: 21.5%;
    padding-bottom: 21.5%
}

.padding-twenty-two-tb {
    padding-top: 22%;
    padding-bottom: 22%
}

.padding-twenty-two-half-tb {
    padding-top: 22.5%;
    padding-bottom: 22.5%
}

.padding-twenty-three-tb {
    padding-top: 23%;
    padding-bottom: 23%
}

.padding-twenty-three-half-tb {
    padding-top: 23.5%;
    padding-bottom: 23.5%
}

.padding-twenty-four-tb {
    padding-top: 24%;
    padding-bottom: 24%
}

.padding-twenty-four-half-tb {
    padding-top: 24.5%;
    padding-bottom: 24.5%
}

.padding-twenty-five-tb {
    padding-top: 25%;
    padding-bottom: 25%
}

.padding-thirty-tb {
    padding-top: 30%;
    padding-bottom: 30%
}

.padding-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px
}

.padding-8px-tb {
    padding-top: 8px;
    padding-bottom: 8px
}

.padding-10px-tb {
    padding-top: 10px;
    padding-bottom: 10px
}

.padding-15px-tb {
    padding-top: 15px;
    padding-bottom: 15px
}

.padding-20px-tb {
    padding-top: 20px;
    padding-bottom: 20px
}

.padding-25px-tb {
    padding-top: 25px;
    padding-bottom: 25px
}

.padding-30px-tb {
    padding-top: 30px;
    padding-bottom: 30px
}

.padding-35px-tb {
    padding-top: 35px;
    padding-bottom: 35px
}

.padding-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px
}

.padding-45px-tb {
    padding-top: 45px;
    padding-bottom: 45px
}

.padding-50px-tb {
    padding-top: 50px;
    padding-bottom: 50px
}

.padding-55px-tb {
    padding-top: 55px;
    padding-bottom: 55px
}

.padding-60px-tb {
    padding-top: 60px;
    padding-bottom: 60px
}

.padding-65px-tb {
    padding-top: 65px;
    padding-bottom: 65px
}

.padding-70px-tb {
    padding-top: 70px;
    padding-bottom: 70px
}

.padding-75px-tb {
    padding-top: 75px;
    padding-bottom: 75px
}

.padding-80px-tb {
    padding-top: 80px;
    padding-bottom: 80px
}

.padding-85px-tb {
    padding-top: 85px;
    padding-bottom: 85px
}

.padding-90px-tb {
    padding-top: 90px;
    padding-bottom: 90px
}

.padding-95px-tb {
    padding-top: 95px;
    padding-bottom: 95px
}

.padding-100px-tb {
    padding-top: 100px;
    padding-bottom: 100px
}

.padding-one-lr {
    padding-left: 1%;
    padding-right: 1%
}

.padding-one-half-lr {
    padding-left: 1.5%;
    padding-right: 1.5%
}

.padding-two-lr {
    padding-left: 2%;
    padding-right: 2%
}

.padding-two-half-lr {
    padding-left: 2.5%;
    padding-right: 2.5%
}

.padding-three-lr {
    padding-left: 3%;
    padding-right: 3%
}

.padding-three-half-lr {
    padding-left: 3.5%;
    padding-right: 3.5%
}

.padding-four-lr {
    padding-left: 4%;
    padding-right: 4%
}

.padding-four-half-lr {
    padding-left: 4.5%;
    padding-right: 4.5%
}

.padding-five-lr {
    padding-left: 5%;
    padding-right: 5%
}

.padding-five-half-lr {
    padding-left: 5.5%;
    padding-right: 5.5%
}

.padding-six-lr {
    padding-left: 6%;
    padding-right: 6%
}

.padding-six-half-lr {
    padding-left: 6.5%;
    padding-right: 6.5%
}

.padding-seven-lr {
    padding-left: 7%;
    padding-right: 7%
}

.padding-seven-half-lr {
    padding-left: 7.5%;
    padding-right: 7.5%
}

.padding-eight-lr {
    padding-left: 8%;
    padding-right: 8%
}

.padding-eight-half-lr {
    padding-left: 8.5%;
    padding-right: 8.5%
}

.padding-nine-lr {
    padding-left: 9%;
    padding-right: 9%
}

.padding-nine-half-lr {
    padding-left: 9.5%;
    padding-right: 9.5%
}

.padding-ten-lr {
    padding-left: 10%;
    padding-right: 10%
}

.padding-ten-half-lr {
    padding-left: 10.5%;
    padding-right: 10.5%
}

.padding-eleven-lr {
    padding-left: 11%;
    padding-right: 11%
}

.padding-eleven-half-lr {
    padding-left: 11.5%;
    padding-right: 11.5%
}

.padding-twelve-lr {
    padding-left: 12%;
    padding-right: 12%
}

.padding-twelve-half-lr {
    padding-left: 12.5%;
    padding-right: 12.5%
}

.padding-thirteen-lr {
    padding-left: 13%;
    padding-right: 13%
}

.padding-thirteen-half-lr {
    padding-left: 13.5%;
    padding-right: 13.5%
}

.padding-fourteen-lr {
    padding-left: 14%;
    padding-right: 14%
}

.padding-fourteen-half-lr {
    padding-left: 14.5%;
    padding-right: 14.5%
}

.padding-fifteen-lr {
    padding-left: 15%;
    padding-right: 15%
}

.padding-fifteen-half-lr {
    padding-left: 15.5%;
    padding-right: 15.5%
}

.padding-sixteen-lr {
    padding-left: 16%;
    padding-right: 16%
}

.padding-sixteen-half-lr {
    padding-left: 16.5%;
    padding-right: 16.5%
}

.padding-seventeen-lr {
    padding-left: 17%;
    padding-right: 17%
}

.padding-seventeen-half-lr {
    padding-left: 17.5%;
    padding-right: 17.5%
}

.padding-eighteen-lr {
    padding-left: 18%;
    padding-right: 18%
}

.padding-eighteen-half-lr {
    padding-left: 18.5%;
    padding-right: 18.5%
}

.padding-nineteen-lr {
    padding-left: 19%;
    padding-right: 19%
}

.padding-nineteen-half-lr {
    padding-left: 19.5%;
    padding-right: 19.5%
}

.padding-twenty-lr {
    padding-left: 20%;
    padding-right: 20%
}

.padding-twenty-half-lr {
    padding-left: 20.5%;
    padding-right: 20.5%
}

.padding-twenty-one-lr {
    padding-left: 21%;
    padding-right: 21%
}

.padding-twenty-one-half-lr {
    padding-left: 21.5%;
    padding-right: 21.5%
}

.padding-twenty-two-lr {
    padding-left: 22%;
    padding-right: 22%
}

.padding-twenty-two-half-lr {
    padding-left: 22.5%;
    padding-right: 22.5%
}

.padding-twenty-three-lr {
    padding-left: 23%;
    padding-right: 23%
}

.padding-twenty-three-half-lr {
    padding-left: 23.5%;
    padding-right: 23.5%
}

.padding-twenty-four-lr {
    padding-left: 24%;
    padding-right: 24%
}

.padding-twenty-four-half-lr {
    padding-left: 24.5%;
    padding-right: 24.5%
}

.padding-twenty-five-lr {
    padding-left: 25%;
    padding-right: 25%
}

.padding-5px-lr {
    padding-left: 5px;
    padding-right: 5px
}

.padding-10px-lr {
    padding-left: 10px;
    padding-right: 10px
}

.padding-15px-lr {
    padding-left: 15px;
    padding-right: 15px
}

.padding-20px-lr {
    padding-left: 20px;
    padding-right: 20px
}

.padding-25px-lr {
    padding-left: 25px;
    padding-right: 25px
}

.padding-30px-lr {
    padding-left: 30px;
    padding-right: 30px
}

.padding-35px-lr {
    padding-left: 35px;
    padding-right: 35px
}

.padding-40px-lr {
    padding-left: 40px;
    padding-right: 40px
}

.padding-45px-lr {
    padding-left: 45px;
    padding-right: 45px
}

.padding-50px-lr {
    padding-left: 50px;
    padding-right: 50px
}

.padding-55px-lr {
    padding-left: 55px;
    padding-right: 55px
}

.padding-60px-lr {
    padding-left: 60px;
    padding-right: 60px
}

.padding-65px-lr {
    padding-left: 65px;
    padding-right: 65px
}

.padding-70px-lr {
    padding-left: 70px;
    padding-right: 70px
}

.padding-75px-lr {
    padding-left: 75px;
    padding-right: 75px
}

.padding-80px-lr {
    padding-left: 80px;
    padding-right: 80px
}

.padding-85px-lr {
    padding-left: 85px;
    padding-right: 85px
}

.padding-90px-lr {
    padding-left: 90px;
    padding-right: 90px
}

.padding-95px-lr {
    padding-left: 95px;
    padding-right: 95px
}

.padding-100px-lr {
    padding-left: 100px;
    padding-right: 100px
}

.display-block {
    display: block !important
}

.display-inline-block {
    display: inline-block !important
}

.display-inline {
    display: inline !important
}

.display-none {
    display: none !important
}

.display-inherit {
    display: inherit !important
}

.display-table {
    display: table !important
}

.display-table-cell {
    display: table-cell !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-auto {
    overflow: auto !important
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

.position-inherit {
    position: inherit !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-right {
    right: 0 !important
}

.position-left {
    left: 0 !important
}

.position-top {
    top: 0 !important
}

.position-bottom {
    bottom: 0 !important
}

.width-1px {
    width: 1px
}

.width-2px {
    width: 2px
}

.width-3px {
    width: 3px
}

.width-4px {
    width: 4px
}

.width-5px {
    width: 5px
}

.width-6px {
    width: 6px
}

.width-7px {
    width: 7px
}

.width-8px {
    width: 8px
}

.width-9px {
    width: 9px
}

.width-10px {
    width: 10px
}

.width-15px {
    width: 15px
}

.width-20px {
    width: 20px
}

.width-25px {
    width: 25px
}

.width-30px {
    width: 30px
}

.width-35px {
    width: 35px
}

.width-40px {
    width: 40px
}

.width-50px {
    width: 50px
}

.width-55px {
    width: 55px
}

.width-60px {
    width: 60px
}

.width-65px {
    width: 65px
}

.width-70px {
    width: 70px
}

.width-75px {
    width: 75px
}

.width-80px {
    width: 80px
}

.width-85px {
    width: 85px
}

.width-90px {
    width: 90px
}

.width-100px {
    width: 100px
}

.width-120px {
    width: 120px
}

.width-130px {
    width: 130px
}

.width-150px {
    width: 150px
}

.width-180px {
    width: 180px
}

.width-200px {
    width: 200px
}

.width-250px {
    width: 250px
}

.width-300px {
    width: 300px
}

.width-350px {
    width: 350px
}

.width-400px {
    width: 400px
}

.width-450px {
    width: 450px
}

.width-500px {
    width: 500px
}

.width-550px {
    width: 550px
}

.width-600px {
    width: 600px
}

.width-650px {
    width: 650px
}

.width-700px {
    width: 700px
}

.width-750px {
    width: 750px
}

.width-800px {
    width: 800px
}

.width-850px {
    width: 850px
}

.width-900px {
    width: 900px
}

.width-950px {
    width: 950px
}

.width-1000px {
    width: 1000px
}

.width-10 {
    width: 10%
}

.width-12 {
    width: 12%
}

.width-15 {
    width: 15%
}

.width-20 {
    width: 20%
}

.width-25 {
    width: 25%
}

.width-30 {
    width: 30%
}

.width-35 {
    width: 35%
}

.width-40 {
    width: 40%
}

.width-45 {
    width: 45%
}

.width-50 {
    width: 50%
}

.width-55 {
    width: 55%
}

.width-60 {
    width: 60%
}

.width-65 {
    width: 65%
}

.width-70 {
    width: 70%
}

.width-75 {
    width: 75%
}

.width-80 {
    width: 80%
}

.width-85 {
    width: 85%
}

.width-90 {
    width: 90%
}

.width-95 {
    width: 95%
}

.width-100 {
    width: 100%
}

.width-auto {
    width: auto
}

@media screen and (max-width: 1199px) {
    section {
        padding: 70px 0
    }

    .md-height-auto {
        height: auto
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 18px;
        padding: 0;
        letter-spacing: 0
    }

    h1 {
        font-size: 40px
    }

    h2 {
        font-size: 32px
    }

    h3 {
        font-size: 30px
    }

    h4 {
        font-size: 28px
    }

    h5 {
        font-size: 24px
    }

    h6 {
        font-size: 20px
    }

    .md-line-height-normal {
        line-height: normal
    }

    .md-line-height-10 {
        line-height: 10px
    }

    .md-line-height-13 {
        line-height: 13px
    }

    .md-line-height-18 {
        line-height: 18px
    }

    .md-line-height-20 {
        line-height: 20px
    }

    .md-line-height-24 {
        line-height: 24px
    }

    .md-line-height-22 {
        line-height: 22px
    }

    .md-line-height-26 {
        line-height: 26px
    }

    .md-line-height-28 {
        line-height: 28px
    }

    .md-line-height-30 {
        line-height: 30px
    }

    .md-line-height-35 {
        line-height: 35px
    }

    .md-line-height-40 {
        line-height: 40px
    }

    .md-line-height-45 {
        line-height: 45px
    }

    .md-line-height-50 {
        line-height: 50px
    }

    .md-line-height-55 {
        line-height: 55px
    }

    .md-line-height-60 {
        line-height: 60px
    }

    .md-line-height-65 {
        line-height: 65px
    }

    .md-line-height-70 {
        line-height: 70px
    }

    .md-line-height-75 {
        line-height: 75px
    }

    .md-line-height-80 {
        line-height: 80px
    }

    .md-line-height-85 {
        line-height: 85px
    }

    .md-line-height-90 {
        line-height: 90px
    }

    .md-line-height-95 {
        line-height: 95px
    }

    .md-line-height-100 {
        line-height: 100px
    }

    .md-line-height-110 {
        line-height: 110px
    }

    .md-line-height-120 {
        line-height: 120px
    }

    .md-font-size11 {
        font-size: 11px
    }

    .md-font-size12 {
        font-size: 12px
    }

    .md-font-size13 {
        font-size: 13px
    }

    .md-font-size14 {
        font-size: 14px
    }

    .md-font-size15 {
        font-size: 15px
    }

    .md-font-size16 {
        font-size: 16px
    }

    .md-font-size17 {
        font-size: 17px
    }

    .md-font-size18 {
        font-size: 18px
    }

    .md-font-size19 {
        font-size: 19px
    }

    .md-font-size20 {
        font-size: 20px
    }

    .md-font-size22 {
        font-size: 22px
    }

    .md-font-size24 {
        font-size: 24px
    }

    .md-font-size26 {
        font-size: 26px
    }

    .md-font-size28 {
        font-size: 28px
    }

    .md-font-size30 {
        font-size: 30px
    }

    .md-font-size32 {
        font-size: 32px
    }

    .md-font-size34 {
        font-size: 34px
    }

    .md-font-size36 {
        font-size: 36px
    }

    .md-font-size38 {
        font-size: 38px
    }

    .md-font-size40 {
        font-size: 40px
    }

    .md-font-size42 {
        font-size: 42px
    }

    .md-font-size44 {
        font-size: 44px
    }

    .md-font-size46 {
        font-size: 46px
    }

    .md-font-size48 {
        font-size: 48px
    }

    .md-font-size50 {
        font-size: 50px
    }

    .md-font-size55 {
        font-size: 55px
    }

    .md-font-size60 {
        font-size: 60px
    }

    .md-font-size80 {
        font-size: 80px
    }

    .md-font-size100 {
        font-size: 100px
    }

    .md-font-size120 {
        font-size: 120px
    }

    .md-font-size130 {
        font-size: 130px
    }

    .md-text-center {
        text-align: center
    }

    .md-text-left {
        text-align: left
    }

    .md-text-right {
        text-align: right
    }

    .md-float-left {
        float: left !important
    }

    .md-float-right {
        float: right !important
    }

    .md-float-none {
        float: none !important
    }

    .md-vertical-align-middle {
        vertical-align: middle
    }

    .md-vertical-align-top {
        vertical-align: top
    }

    .md-vertical-align-bottom {
        vertical-align: bottom
    }

    .md-absolute-middle-inherit {
        left: 0;
        top: 0;
        position: relative;
        -ms-transform: translateX(0) translateY(0);
        -moz-transform: translateX(0) translateY(0);
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        -o-transform: translateX(0) translateY(0)
    }

    .md-background-image-left {
        background-position: left
    }

    .md-background-image-right {
        background-position: right
    }

    .md-background-image-center {
        background-position: center
    }

    .md-margin-one-all {
        margin: 1%
    }

    .md-margin-one-half-all {
        margin: 1.5%
    }

    .md-margin-two-all {
        margin: 2%
    }

    .md-margin-two-half-all {
        margin: 2.5%
    }

    .md-margin-three-all {
        margin: 3%
    }

    .md-margin-three-half-all {
        margin: 3.5%
    }

    .md-margin-four-all {
        margin: 4%
    }

    .md-margin-four-half-all {
        margin: 4.5%
    }

    .md-margin-five-all {
        margin: 5%
    }

    .md-margin-five-half-all {
        margin: 5.5%
    }

    .md-margin-six-all {
        margin: 6%
    }

    .md-margin-six-half-all {
        margin: 6.5%
    }

    .md-margin-seven-all {
        margin: 7%
    }

    .md-margin-seven-half-all {
        margin: 7.5%
    }

    .md-margin-eight-all {
        margin: 8%
    }

    .md-margin-eight-half-all {
        margin: 8.5%
    }

    .md-margin-nine-all {
        margin: 9%
    }

    .md-margin-nine-half-all {
        margin: 9.5%
    }

    .md-margin-ten-all {
        margin: 10%
    }

    .md-margin-ten-half-all {
        margin: 10.5%
    }

    .md-margin-eleven-all {
        margin: 11%
    }

    .md-margin-eleven-half-all {
        margin: 11.5%
    }

    .md-margin-twelve-all {
        margin: 12%
    }

    .md-margin-twelve-half-all {
        margin: 12.5%
    }

    .md-margin-thirteen-all {
        margin: 13%
    }

    .md-margin-thirteen-half-all {
        margin: 13.5%
    }

    .md-margin-fourteen-all {
        margin: 14%
    }

    .md-margin-fourteen-half-all {
        margin: 14.5%
    }

    .md-margin-fifteen-all {
        margin: 15%
    }

    .md-margin-fifteen-half-all {
        margin: 15.5%
    }

    .md-margin-sixteen-all {
        margin: 16%
    }

    .md-margin-sixteen-half-all {
        margin: 16.5%
    }

    .md-margin-seventeen-all {
        margin: 17%
    }

    .md-margin-seventeen-half-all {
        margin: 17.5%
    }

    .md-margin-eighteen-all {
        margin: 18%
    }

    .md-margin-eighteen-half-all {
        margin: 18.5%
    }

    .md-margin-nineteen-all {
        margin: 19%
    }

    .md-margin-nineteen-half-all {
        margin: 19.5%
    }

    .md-margin-twenty-all {
        margin: 20%
    }

    .md-margin-twenty-half-all {
        margin: 20.5%
    }

    .md-margin-twenty-one-all {
        margin: 21%
    }

    .md-margin-twenty-one-half-all {
        margin: 21.5%
    }

    .md-margin-twenty-two-all {
        margin: 22%
    }

    .md-margin-twenty-two-half-all {
        margin: 22.5%
    }

    .md-margin-twenty-three-all {
        margin: 23%
    }

    .md-margin-twenty-three-half-all {
        margin: 23.5%
    }

    .md-margin-twenty-four-all {
        margin: 24%
    }

    .md-margin-twenty-four-half-all {
        margin: 24.5%
    }

    .md-margin-twenty-five-all {
        margin: 25%
    }

    .md-margin-5px-all {
        margin: 5px !important
    }

    .md-margin-10px-all {
        margin: 10px !important
    }

    .md-margin-15px-all {
        margin: 15px !important
    }

    .md-margin-20px-all {
        margin: 20px !important
    }

    .md-margin-25px-all {
        margin: 25px !important
    }

    .md-margin-30px-all {
        margin: 30px !important
    }

    .md-margin-35px-all {
        margin: 35px !important
    }

    .md-margin-40px-all {
        margin: 40px !important
    }

    .md-margin-45px-all {
        margin: 45px !important
    }

    .md-margin-50px-all {
        margin: 50px !important
    }

    .md-margin-55px-all {
        margin: 55px !important
    }

    .md-margin-60px-all {
        margin: 60px !important
    }

    .md-margin-65px-all {
        margin: 65px !important
    }

    .md-margin-70px-all {
        margin: 70px !important
    }

    .md-margin-75px-all {
        margin: 75px !important
    }

    .md-margin-80px-all {
        margin: 80px !important
    }

    .md-margin-85px-all {
        margin: 85px !important
    }

    .md-margin-90px-all {
        margin: 90px !important
    }

    .md-margin-95px-all {
        margin: 95px !important
    }

    .md-margin-100px-all {
        margin: 100px !important
    }

    .md-no-margin {
        margin: 0 !important
    }

    .md-no-margin-lr {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .md-no-margin-tb {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .md-no-margin-top {
        margin-top: 0 !important
    }

    .md-no-margin-bottom {
        margin-bottom: 0 !important
    }

    .md-no-margin-left {
        margin-left: 0 !important
    }

    .md-no-margin-right {
        margin-right: 0 !important
    }

    .md-margin-lr-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .md-margin-auto {
        margin: 0 auto !important
    }

    .md-margin-one-top {
        margin-top: 1%
    }

    .md-margin-one-half-top {
        margin-top: 1.5%
    }

    .md-margin-two-top {
        margin-top: 2%
    }

    .md-margin-two-half-top {
        margin-top: 2.5%
    }

    .md-margin-three-top {
        margin-top: 3%
    }

    .md-margin-three-half-top {
        margin-top: 3.5%
    }

    .md-margin-four-top {
        margin-top: 4%
    }

    .md-margin-four-half-top {
        margin-top: 4.5%
    }

    .md-margin-five-top {
        margin-top: 5%
    }

    .md-margin-five-half-top {
        margin-top: 5.5%
    }

    .md-margin-six-top {
        margin-top: 6%
    }

    .md-margin-six-half-top {
        margin-top: 6.5%
    }

    .md-margin-seven-top {
        margin-top: 7%
    }

    .md-margin-seven-half-top {
        margin-top: 7.5%
    }

    .md-margin-eight-top {
        margin-top: 8%
    }

    .md-margin-eight-half-top {
        margin-top: 8.5%
    }

    .md-margin-nine-top {
        margin-top: 9%
    }

    .md-margin-nine-half-top {
        margin-top: 9.5%
    }

    .md-margin-ten-top {
        margin-top: 10%
    }

    .md-margin-ten-half-top {
        margin-top: 10.5%
    }

    .md-margin-eleven-top {
        margin-top: 11%
    }

    .md-margin-eleven-half-top {
        margin-top: 11.5%
    }

    .md-margin-twelve-top {
        margin-top: 12%
    }

    .md-margin-twelve-half-top {
        margin-top: 12.5%
    }

    .md-margin-thirteen-top {
        margin-top: 13%
    }

    .md-margin-thirteen-half-top {
        margin-top: 13.5%
    }

    .md-margin-fourteen-top {
        margin-top: 14%
    }

    .md-margin-fourteen-half-top {
        margin-top: 14.5%
    }

    .md-margin-fifteen-top {
        margin-top: 15%
    }

    .md-margin-fifteen-half-top {
        margin-top: 15.5%
    }

    .md-margin-sixteen-top {
        margin-top: 16%
    }

    .md-margin-sixteen-half-top {
        margin-top: 16.5%
    }

    .md-margin-seventeen-top {
        margin-top: 17%
    }

    .md-margin-seventeen-half-top {
        margin-top: 17.5%
    }

    .md-margin-eighteen-top {
        margin-top: 18%
    }

    .md-margin-eighteen-half-top {
        margin-top: 18.5%
    }

    .md-margin-nineteen-top {
        margin-top: 19%
    }

    .md-margin-nineteen-half-top {
        margin-top: 19.5%
    }

    .md-margin-twenty-top {
        margin-top: 20%
    }

    .md-margin-twenty-half-top {
        margin-top: 20.5%
    }

    .md-margin-twenty-one-top {
        margin-top: 21%
    }

    .md-margin-twenty-one-half-top {
        margin-top: 21.5%
    }

    .md-margin-twenty-two-top {
        margin-top: 22%
    }

    .md-margin-twenty-two-half-top {
        margin-top: 22.5%
    }

    .md-margin-twenty-top {
        margin-top: 23%
    }

    .md-margin-twenty-half-top {
        margin-top: 23.5%
    }

    .md-margin-twenty-four-top {
        margin-top: 24%
    }

    .md-margin-twenty-four-half-top {
        margin-top: 24.5%
    }

    .md-margin-twenty-five-top {
        margin-top: 25%
    }

    .md-margin-5px-top {
        margin-top: 5px !important
    }

    .md-margin-8px-top {
        margin-top: 8px !important
    }

    .md-margin-10px-top {
        margin-top: 10px !important
    }

    .md-margin-15px-top {
        margin-top: 15px !important
    }

    .md-margin-20px-top {
        margin-top: 20px !important
    }

    .md-margin-25px-top {
        margin-top: 25px !important
    }

    .md-margin-30px-top {
        margin-top: 30px !important
    }

    .md-margin-35px-top {
        margin-top: 35px !important
    }

    .md-margin-40px-top {
        margin-top: 40px !important
    }

    .md-margin-45px-top {
        margin-top: 45px !important
    }

    .md-margin-50px-top {
        margin-top: 50px !important
    }

    .md-margin-55px-top {
        margin-top: 55px !important
    }

    .md-margin-60px-top {
        margin-top: 60px !important
    }

    .md-margin-65px-top {
        margin-top: 65px !important
    }

    .md-margin-70px-top {
        margin-top: 70px !important
    }

    .md-margin-75px-top {
        margin-top: 75px !important
    }

    .md-margin-80px-top {
        margin-top: 80px !important
    }

    .md-margin-85px-top {
        margin-top: 85px !important
    }

    .md-margin-90px-top {
        margin-top: 90px !important
    }

    .md-margin-95px-top {
        margin-top: 95px !important
    }

    .md-margin-100px-top {
        margin-top: 100px !important
    }

    .md-margin-one-bottom {
        margin-bottom: 1%
    }

    .md-margin-one-half-bottom {
        margin-bottom: 1.5%
    }

    .md-margin-two-bottom {
        margin-bottom: 2%
    }

    .md-margin-two-half-bottom {
        margin-bottom: 2.5%
    }

    .md-margin-three-bottom {
        margin-bottom: 3%
    }

    .md-margin-three-half-bottom {
        margin-bottom: 3.5%
    }

    .md-margin-four-bottom {
        margin-bottom: 4%
    }

    .md-margin-four-half-bottom {
        margin-bottom: 4.5%
    }

    .md-margin-five-bottom {
        margin-bottom: 5%
    }

    .md-margin-five-half-bottom {
        margin-bottom: 5.5%
    }

    .md-margin-six-bottom {
        margin-bottom: 6%
    }

    .md-margin-six-half-bottom {
        margin-bottom: 6.5%
    }

    .md-margin-seven-bottom {
        margin-bottom: 7%
    }

    .md-margin-seven-half-bottom {
        margin-bottom: 7.5%
    }

    .md-margin-eight-bottom {
        margin-bottom: 8%
    }

    .md-margin-eight-half-bottom {
        margin-bottom: 8.5%
    }

    .md-margin-nine-bottom {
        margin-bottom: 9%
    }

    .md-margin-nine-half-bottom {
        margin-bottom: 9.5%
    }

    .md-margin-ten-bottom {
        margin-bottom: 10%
    }

    .md-margin-ten-half-bottom {
        margin-bottom: 10.5%
    }

    .md-margin-eleven-bottom {
        margin-bottom: 11%
    }

    .md-margin-eleven-half-bottom {
        margin-bottom: 11.5%
    }

    .md-margin-twelve-bottom {
        margin-bottom: 12%
    }

    .md-margin-twelve-half-bottom {
        margin-bottom: 12.5%
    }

    .md-margin-thirteen-bottom {
        margin-bottom: 13%
    }

    .md-margin-thirteen-half-bottom {
        margin-bottom: 13.5%
    }

    .md-margin-fourteen-bottom {
        margin-bottom: 14%
    }

    .md-margin-fourteen-half-bottom {
        margin-bottom: 14.5%
    }

    .md-margin-fifteen-bottom {
        margin-bottom: 15%
    }

    .md-margin-fifteen-half-bottom {
        margin-bottom: 15.5%
    }

    .md-margin-sixteen-bottom {
        margin-bottom: 16%
    }

    .md-margin-sixteen-half-bottom {
        margin-bottom: 16.5%
    }

    .md-margin-seventeen-bottom {
        margin-bottom: 17%
    }

    .md-margin-seventeen-half-bottom {
        margin-bottom: 17.5%
    }

    .md-margin-eighteen-bottom {
        margin-bottom: 18%
    }

    .md-margin-eighteen-half-bottom {
        margin-bottom: 18.5%
    }

    .md-margin-nineteen-bottom {
        margin-bottom: 19%
    }

    .md-margin-nineteen-half-bottom {
        margin-bottom: 19.5%
    }

    .md-margin-twenty-bottom {
        margin-bottom: 20%
    }

    .md-margin-twenty-half-bottom {
        margin-bottom: 20.5%
    }

    .md-margin-twenty-one-bottom {
        margin-bottom: 21%
    }

    .md-margin-twenty-one-half-bottom {
        margin-bottom: 21.5%
    }

    .md-margin-twenty-two-bottom {
        margin-bottom: 22%
    }

    .md-margin-twenty-two-half-bottom {
        margin-bottom: 22.5%
    }

    .md-margin-twenty-three-bottom {
        margin-bottom: 23%
    }

    .md-margin-twenty-three-half-bottom {
        margin-bottom: 23.5%
    }

    .md-margin-twenty-five-bottom {
        margin-bottom: 24%
    }

    .md-margin-twenty-five-half-bottom {
        margin-bottom: 24.5%
    }

    .md-margin-twenty-five-bottom {
        margin-bottom: 25%
    }

    .md-margin-5px-bottom {
        margin-bottom: 5px !important
    }

    .md-margin-8px-bottom {
        margin-bottom: 8px !important
    }

    .md-margin-10px-bottom {
        margin-bottom: 10px !important
    }

    .md-margin-15px-bottom {
        margin-bottom: 15px !important
    }

    .md-margin-20px-bottom {
        margin-bottom: 20px !important
    }

    .md-margin-25px-bottom {
        margin-bottom: 25px !important
    }

    .md-margin-30px-bottom {
        margin-bottom: 30px !important
    }

    .md-margin-35px-bottom {
        margin-bottom: 35px !important
    }

    .md-margin-40px-bottom {
        margin-bottom: 40px !important
    }

    .md-margin-45px-bottom {
        margin-bottom: 45px !important
    }

    .md-margin-50px-bottom {
        margin-bottom: 50px !important
    }

    .md-margin-55px-bottom {
        margin-bottom: 55px !important
    }

    .md-margin-60px-bottom {
        margin-bottom: 60px !important
    }

    .md-margin-65px-bottom {
        margin-bottom: 65px !important
    }

    .md-margin-70px-bottom {
        margin-bottom: 70px !important
    }

    .md-margin-75px-bottom {
        margin-bottom: 75px !important
    }

    .md-margin-80px-bottom {
        margin-bottom: 80px !important
    }

    .md-margin-85px-bottom {
        margin-bottom: 85px !important
    }

    .md-margin-90px-bottom {
        margin-bottom: 90px !important
    }

    .md-margin-95px-bottom {
        margin-bottom: 95px !important
    }

    .md-margin-100px-bottom {
        margin-bottom: 100px !important
    }

    .md-margin-one-right {
        margin-right: 1%
    }

    .md-margin-one-half-right {
        margin-right: 1.5%
    }

    .md-margin-two-right {
        margin-right: 2%
    }

    .md-margin-two-half-right {
        margin-right: 2.5%
    }

    .md-margin-three-right {
        margin-right: 3%
    }

    .md-margin-three-half-right {
        margin-right: 3.5%
    }

    .md-margin-four-right {
        margin-right: 4%
    }

    .md-margin-four-half-right {
        margin-right: 4.5%
    }

    .md-margin-five-right {
        margin-right: 5%
    }

    .md-margin-five-half-right {
        margin-right: 5.5%
    }

    .md-margin-six-right {
        margin-right: 6%
    }

    .md-margin-six-half-right {
        margin-right: 6.5%
    }

    .md-margin-seven-right {
        margin-right: 7%
    }

    .md-margin-seven-half-right {
        margin-right: 7.5%
    }

    .md-margin-eight-right {
        margin-right: 8%
    }

    .md-margin-eight-half-right {
        margin-right: 8.5%
    }

    .md-margin-nine-right {
        margin-right: 9%
    }

    .md-margin-nine-half-right {
        margin-right: 9.5%
    }

    .md-margin-ten-right {
        margin-right: 10%
    }

    .md-margin-ten-half-right {
        margin-right: 10.5%
    }

    .md-margin-eleven-right {
        margin-right: 11%
    }

    .md-margin-eleven-half-right {
        margin-right: 11.5%
    }

    .md-margin-twelve-right {
        margin-right: 12%
    }

    .md-margin-twelve-half-right {
        margin-right: 12.5%
    }

    .md-margin-thirteen-right {
        margin-right: 13%
    }

    .md-margin-thirteen-half-right {
        margin-right: 13.5%
    }

    .md-margin-fourteen-right {
        margin-right: 14%
    }

    .md-margin-fourteen-half-right {
        margin-right: 14.5%
    }

    .md-margin-fifteen-right {
        margin-right: 15%
    }

    .md-margin-fifteen-half-right {
        margin-right: 15.5%
    }

    .md-margin-sixteen-right {
        margin-right: 16%
    }

    .md-margin-sixteen-half-right {
        margin-right: 16.5%
    }

    .md-margin-seventeen-right {
        margin-right: 17%
    }

    .md-margin-seventeen-half-right {
        margin-right: 17.5%
    }

    .md-margin-eighteen-right {
        margin-right: 18%
    }

    .md-margin-eighteen-half-right {
        margin-right: 18.5%
    }

    .md-margin-nineteen-right {
        margin-right: 19%
    }

    .md-margin-nineteen-half-right {
        margin-right: 19.5%
    }

    .md-margin-twenty-right {
        margin-right: 20%
    }

    .md-margin-twenty-half-right {
        margin-right: 20.5%
    }

    .md-margin-twenty-one-right {
        margin-right: 21%
    }

    .md-margin-twenty-one-half-right {
        margin-right: 21.5%
    }

    .md-margin-twenty-two-right {
        margin-right: 22%
    }

    .md-margin-twenty-two-half-right {
        margin-right: 22.5%
    }

    .md-margin-twenty-three-right {
        margin-right: 23%
    }

    .md-margin-twenty-three-half-right {
        margin-right: 23.5%
    }

    .md-margin-twenty-four-right {
        margin-right: 24%
    }

    .md-margin-twenty-four-half-right {
        margin-right: 24.5%
    }

    .md-margin-twenty-five-right {
        margin-right: 25%
    }

    .md-margin-10px-right {
        margin-right: 10px !important
    }

    .md-margin-15px-right {
        margin-right: 15px !important
    }

    .md-margin-20px-right {
        margin-right: 20px !important
    }

    .md-margin-25px-right {
        margin-right: 25px !important
    }

    .md-margin-30px-right {
        margin-right: 30px !important
    }

    .md-margin-35px-right {
        margin-right: 35px !important
    }

    .md-margin-40px-right {
        margin-right: 40px !important
    }

    .md-margin-45px-right {
        margin-right: 45px !important
    }

    .md-margin-50px-right {
        margin-right: 50px !important
    }

    .md-margin-55px-right {
        margin-right: 55px !important
    }

    .md-margin-60px-right {
        margin-right: 60px !important
    }

    .md-margin-65px-right {
        margin-right: 65px !important
    }

    .md-margin-70px-right {
        margin-right: 70px !important
    }

    .md-margin-75px-right {
        margin-right: 75px !important
    }

    .md-margin-80px-right {
        margin-right: 80px !important
    }

    .md-margin-85px-right {
        margin-right: 85px !important
    }

    .md-margin-90px-right {
        margin-right: 90px !important
    }

    .md-margin-95px-right {
        margin-right: 95px !important
    }

    .md-margin-100px-right {
        margin-right: 100px !important
    }

    .md-margin-one-left {
        margin-left: 1%
    }

    .md-margin-one-half-left {
        margin-left: 1.5%
    }

    .md-margin-two-left {
        margin-left: 2%
    }

    .md-margin-two-half-left {
        margin-left: 2.5%
    }

    .md-margin-three-left {
        margin-left: 3%
    }

    .md-margin-three-half-left {
        margin-left: 3.5%
    }

    .md-margin-four-left {
        margin-left: 4%
    }

    .md-margin-four-half-left {
        margin-left: 4.5%
    }

    .md-margin-five-left {
        margin-left: 5%
    }

    .md-margin-five-half-left {
        margin-left: 5.5%
    }

    .md-margin-six-left {
        margin-left: 6%
    }

    .md-margin-six-half-left {
        margin-left: 6.5%
    }

    .md-margin-seven-left {
        margin-left: 7%
    }

    .md-margin-seven-half-left {
        margin-left: 7.5%
    }

    .md-margin-eight-left {
        margin-left: 8%
    }

    .md-margin-eight-half-left {
        margin-left: 8.5%
    }

    .md-margin-nine-left {
        margin-left: 9%
    }

    .md-margin-nine-half-left {
        margin-left: 9.5%
    }

    .md-margin-ten-left {
        margin-left: 10%
    }

    .md-margin-ten-half-left {
        margin-left: 10.5%
    }

    .md-margin-eleven-left {
        margin-left: 11%
    }

    .md-margin-eleven-half-left {
        margin-left: 11.5%
    }

    .md-margin-twelve-left {
        margin-left: 12%
    }

    .md-margin-twelve-half-left {
        margin-left: 12.5%
    }

    .md-margin-thirteen-left {
        margin-left: 13%
    }

    .md-margin-thirteen-half-left {
        margin-left: 13.5%
    }

    .md-margin-fourteen-left {
        margin-left: 14%
    }

    .md-margin-fourteen-half-left {
        margin-left: 14.5%
    }

    .md-margin-fifteen-left {
        margin-left: 15%
    }

    .md-margin-fifteen-half-left {
        margin-left: 15.5%
    }

    .md-margin-sixteen-left {
        margin-left: 16%
    }

    .md-margin-sixteen-half-left {
        margin-left: 16.5%
    }

    .md-margin-seventeen-left {
        margin-left: 17%
    }

    .md-margin-seventeen-half-left {
        margin-left: 17.5%
    }

    .md-margin-eighteen-left {
        margin-left: 18%
    }

    .md-margin-eighteen-half-left {
        margin-left: 18.5%
    }

    .md-margin-nineteen-left {
        margin-left: 19%
    }

    .md-margin-nineteen-half-left {
        margin-left: 19.5%
    }

    .md-margin-twenty-left {
        margin-left: 20%
    }

    .md-margin-twenty-half-left {
        margin-left: 20.5%
    }

    .md-margin-twenty-one-left {
        margin-left: 21%
    }

    .md-margin-twenty-one-half-left {
        margin-left: 21.5%
    }

    .md-margin-twenty-two-left {
        margin-left: 22%
    }

    .md-margin-twenty-two-half-left {
        margin-left: 22.5%
    }

    .md-margin-twenty-three-left {
        margin-left: 23%
    }

    .md-margin-twenty-three-half-left {
        margin-left: 23.5%
    }

    .md-margin-twenty-four-left {
        margin-left: 24%
    }

    .md-margin-twenty-four-half-left {
        margin-left: 24.5%
    }

    .md-margin-twenty-five-left {
        margin-left: 25%
    }

    .md-margin-5px-left {
        margin-left: 5px !important
    }

    .md-margin-10px-left {
        margin-left: 10px !important
    }

    .md-margin-15px-left {
        margin-left: 15px !important
    }

    .md-margin-20px-left {
        margin-left: 20px !important
    }

    .md-margin-25px-left {
        margin-left: 25px !important
    }

    .md-margin-30px-left {
        margin-left: 30px !important
    }

    .md-margin-35px-left {
        margin-left: 35px !important
    }

    .md-margin-40px-left {
        margin-left: 40px !important
    }

    .md-margin-45px-left {
        margin-left: 45px !important
    }

    .md-margin-50px-left {
        margin-left: 50px !important
    }

    .md-margin-55px-left {
        margin-left: 55px !important
    }

    .md-margin-60px-left {
        margin-left: 60px !important
    }

    .md-margin-65px-left {
        margin-left: 65px !important
    }

    .md-margin-70px-left {
        margin-left: 70px !important
    }

    .md-margin-75px-left {
        margin-left: 75px !important
    }

    .md-margin-80px-left {
        margin-left: 80px !important
    }

    .md-margin-85px-left {
        margin-left: 85px !important
    }

    .md-margin-90px-left {
        margin-left: 90px !important
    }

    .md-margin-95px-left {
        margin-left: 95px !important
    }

    .md-margin-100px-left {
        margin-left: 100px !important
    }

    .md-margin-one-lr {
        margin-left: 1%;
        margin-right: 1%
    }

    .md-margin-one-half-lr {
        margin-left: 1.5%;
        margin-right: 1.5%
    }

    .md-margin-two-lr {
        margin-left: 2%;
        margin-right: 2%
    }

    .md-margin-two-half-lr {
        margin-left: 2.5%;
        margin-right: 2.5%
    }

    .md-margin-three-lr {
        margin-left: 3%;
        margin-right: 3%
    }

    .md-margin-three-half-lr {
        margin-left: 3.5%;
        margin-right: 3.5%
    }

    .md-margin-four-lr {
        margin-left: 4%;
        margin-right: 4%
    }

    .md-margin-four-half-lr {
        margin-left: 4.5%;
        margin-right: 4.5%
    }

    .md-margin-five-lr {
        margin-left: 5%;
        margin-right: 5%
    }

    .md-margin-five-half-lr {
        margin-left: 5.5%;
        margin-right: 5.5%
    }

    .md-margin-six-lr {
        margin-left: 6%;
        margin-right: 6%
    }

    .md-margin-six-half-lr {
        margin-left: 6.5%;
        margin-right: 6.5%
    }

    .md-margin-seven-lr {
        margin-left: 7%;
        margin-right: 7%
    }

    .md-margin-seven-half-lr {
        margin-left: 7.5%;
        margin-right: 7.5%
    }

    .md-margin-eight-lr {
        margin-left: 8%;
        margin-right: 8%
    }

    .md-margin-eight-half-lr {
        margin-left: 8.5%;
        margin-right: 8.5%
    }

    .md-margin-nine-lr {
        margin-left: 9%;
        margin-right: 9%
    }

    .md-margin-nine-half-lr {
        margin-left: 9.5%;
        margin-right: 9.5%
    }

    .md-margin-ten-lr {
        margin-left: 10%;
        margin-right: 10%
    }

    .md-margin-ten-half-lr {
        margin-left: 10.5%;
        margin-right: 10.5%
    }

    .md-margin-eleven-lr {
        margin-left: 11%;
        margin-right: 11%
    }

    .md-margin-eleven-half-lr {
        margin-left: 11.5%;
        margin-right: 11.5%
    }

    .md-margin-twelve-lr {
        margin-left: 12%;
        margin-right: 12%
    }

    .md-margin-twelve-half-lr {
        margin-left: 12.5%;
        margin-right: 12.5%
    }

    .md-margin-thirteen-lr {
        margin-left: 13%;
        margin-right: 13%
    }

    .md-margin-thirteen-half-lr {
        margin-left: 13.5%;
        margin-right: 13.5%
    }

    .md-margin-fourteen-lr {
        margin-left: 14%;
        margin-right: 14%
    }

    .md-margin-fourteen-half-lr {
        margin-left: 14.5%;
        margin-right: 14.5%
    }

    .md-margin-fifteen-lr {
        margin-left: 15%;
        margin-right: 15%
    }

    .md-margin-fifteen-half-lr {
        margin-left: 15.5%;
        margin-right: 15.5%
    }

    .md-margin-sixteen-lr {
        margin-left: 16%;
        margin-right: 16%
    }

    .md-margin-sixteen-half-lr {
        margin-left: 16.5%;
        margin-right: 16.5%
    }

    .md-margin-seventeen-lr {
        margin-left: 17%;
        margin-right: 17%
    }

    .md-margin-seventeen-half-lr {
        margin-left: 17.5%;
        margin-right: 17.5%
    }

    .md-margin-eighteen-lr {
        margin-left: 18%;
        margin-right: 18%
    }

    .md-margin-eighteen-half-lr {
        margin-left: 18.5%;
        margin-right: 18.5%
    }

    .md-margin-nineteen-lr {
        margin-left: 19%;
        margin-right: 19%
    }

    .md-margin-nineteen-half-lr {
        margin-left: 19.5%;
        margin-right: 19.5%
    }

    .md-margin-twenty-lr {
        margin-left: 20%;
        margin-right: 20%
    }

    .md-margin-twenty-half-lr {
        margin-left: 20.5%;
        margin-right: 20.5%
    }

    .md-margin-twenty-one-lr {
        margin-left: 21%;
        margin-right: 21%
    }

    .md-margin-twenty-one-half-lr {
        margin-left: 21.5%;
        margin-right: 21.5%
    }

    .md-margin-twenty-two-lr {
        margin-left: 22%;
        margin-right: 22%
    }

    .md-margin-twenty-two-half-lr {
        margin-left: 22.5%;
        margin-right: 22.5%
    }

    .md-margin-twenty-three-lr {
        margin-left: 23%;
        margin-right: 23%
    }

    .md-margin-twenty-three-half-lr {
        margin-left: 23.5%;
        margin-right: 23.5%
    }

    .md-margin-twenty-four-lr {
        margin-left: 24%;
        margin-right: 24%
    }

    .md-margin-twenty-four-half-lr {
        margin-left: 24.5%;
        margin-right: 24.5%
    }

    .md-margin-twenty-five-lr {
        margin-left: 25%;
        margin-right: 25%
    }

    .md-margin-5px-lr {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .md-margin-10px-lr {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .md-margin-15px-lr {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .md-margin-20px-lr {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .md-margin-25px-lr {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .md-margin-30px-lr {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .md-margin-35px-lr {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .md-margin-40px-lr {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .md-margin-45px-lr {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .md-margin-50px-lr {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .md-margin-55px-lr {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .md-margin-60px-lr {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .md-margin-65px-lr {
        margin-left: 65px !important;
        margin-right: 60px !important
    }

    .md-margin-70px-lr {
        margin-left: 70px !important;
        margin-right: 65px !important
    }

    .md-margin-75px-lr {
        margin-left: 75px !important;
        margin-right: 70px !important
    }

    .md-margin-80px-lr {
        margin-left: 80px !important;
        margin-right: 75px !important
    }

    .md-margin-85px-lr {
        margin-left: 85px !important;
        margin-right: 80px !important
    }

    .md-margin-90px-lr {
        margin-left: 90px !important;
        margin-right: 85px !important
    }

    .md-margin-95px-lr {
        margin-left: 95px !important;
        margin-right: 90px !important
    }

    .md-margin-100px-lr {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .md-margin-one-tb {
        margin-top: 1%;
        margin-bottom: 1%
    }

    .md-margin-one-half-tb {
        margin-top: 1.5%;
        margin-bottom: 1.5%
    }

    .md-margin-two-tb {
        margin-top: 2%;
        margin-bottom: 2%
    }

    .md-margin-two-half-tb {
        margin-top: 2.5%;
        margin-bottom: 2.5%
    }

    .md-margin-three-tb {
        margin-top: 3%;
        margin-bottom: 3%
    }

    .md-margin-three-half-tb {
        margin-top: 3.5%;
        margin-bottom: 3.5%
    }

    .md-margin-four-tb {
        margin-top: 4%;
        margin-bottom: 4%
    }

    .md-margin-four-half-tb {
        margin-top: 4.5%;
        margin-bottom: 4.5%
    }

    .md-margin-five-tb {
        margin-top: 5%;
        margin-bottom: 5%
    }

    .md-margin-five-half-tb {
        margin-top: 5.5%;
        margin-bottom: 5.5%
    }

    .md-margin-six-tb {
        margin-top: 6%;
        margin-bottom: 6%
    }

    .md-margin-six-half-tb {
        margin-top: 6.5%;
        margin-bottom: 6.5%
    }

    .md-margin-seven-tb {
        margin-top: 7%;
        margin-bottom: 7%
    }

    .md-margin-seven-half-tb {
        margin-top: 7.5%;
        margin-bottom: 7.5%
    }

    .md-margin-eight-tb {
        margin-top: 8%;
        margin-bottom: 8%
    }

    .md-margin-eight-half-tb {
        margin-top: 8.5%;
        margin-bottom: 8.5%
    }

    .md-margin-nine-tb {
        margin-top: 9%;
        margin-bottom: 9%
    }

    .md-margin-nine-half-tb {
        margin-top: 9.5%;
        margin-bottom: 9.5%
    }

    .md-margin-ten-tb {
        margin-top: 10%;
        margin-bottom: 10%
    }

    .md-margin-ten-half-tb {
        margin-top: 10.5%;
        margin-bottom: 10.5%
    }

    .md-margin-eleven-tb {
        margin-top: 11%;
        margin-bottom: 11%
    }

    .md-margin-eleven-half-tb {
        margin-top: 11.5%;
        margin-bottom: 11.5%
    }

    .md-margin-twelve-tb {
        margin-top: 12%;
        margin-bottom: 12%
    }

    .md-margin-twelve-half-tb {
        margin-top: 12.5%;
        margin-bottom: 12.5%
    }

    .md-margin-thirteen-tb {
        margin-top: 13%;
        margin-bottom: 13%
    }

    .md-margin-thirteen-half-tb {
        margin-top: 13.5%;
        margin-bottom: 13.5%
    }

    .md-margin-fourteen-tb {
        margin-top: 14%;
        margin-bottom: 14%
    }

    .md-margin-fourteen-half-tb {
        margin-top: 14.5%;
        margin-bottom: 14.5%
    }

    .md-margin-fifteen-tb {
        margin-top: 15%;
        margin-bottom: 15%
    }

    .md-margin-fifteen-half-tb {
        margin-top: 15.5%;
        margin-bottom: 15.5%
    }

    .md-margin-sixteen-tb {
        margin-top: 16%;
        margin-bottom: 16%
    }

    .md-margin-sixteen-half-tb {
        margin-top: 16.5%;
        margin-bottom: 16.5%
    }

    .md-margin-seventeen-tb {
        margin-top: 17%;
        margin-bottom: 17%
    }

    .md-margin-seventeen-half-tb {
        margin-top: 17.5%;
        margin-bottom: 17.5%
    }

    .md-margin-eighteen-tb {
        margin-top: 18%;
        margin-bottom: 18%
    }

    .md-margin-eighteen-half-tb {
        margin-top: 18.5%;
        margin-bottom: 18.5%
    }

    .md-margin-nineteen-tb {
        margin-top: 19%;
        margin-bottom: 19%
    }

    .md-margin-nineteen-half-tb {
        margin-top: 19.5%;
        margin-bottom: 19.5%
    }

    .md-margin-twenty-tb {
        margin-top: 20%;
        margin-bottom: 20%
    }

    .md-margin-twenty-half-tb {
        margin-top: 20.5%;
        margin-bottom: 20.5%
    }

    .md-margin-twenty-one-tb {
        margin-top: 21%;
        margin-bottom: 21%
    }

    .md-margin-twenty-one-half-tb {
        margin-top: 21.5%;
        margin-bottom: 21.5%
    }

    .md-margin-twenty-two-tb {
        margin-top: 22%;
        margin-bottom: 22%
    }

    .md-margin-twenty-two-half-tb {
        margin-top: 22.5%;
        margin-bottom: 22.5%
    }

    .md-margin-twenty-three-tb {
        margin-top: 23%;
        margin-bottom: 23%
    }

    .md-margin-twenty-three-half-tb {
        margin-top: 23.5%;
        margin-bottom: 23.5%
    }

    .md-margin-twenty-four-tb {
        margin-top: 24%;
        margin-bottom: 24%
    }

    .md-margin-twenty-four-half-tb {
        margin-top: 24.5%;
        margin-bottom: 24.5%
    }

    .md-margin-twenty-five-tb {
        margin-top: 25%;
        margin-bottom: 25%
    }

    .md-margin-5px-tb {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .md-margin-10px-tb {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .md-margin-15px-tb {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .md-margin-20px-tb {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .md-margin-25px-tb {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .md-margin-30px-tb {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .md-margin-35px-tb {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .md-margin-40px-tb {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .md-margin-45px-tb {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .md-margin-50px-tb {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .md-margin-55px-tb {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .md-margin-60px-tb {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .md-margin-65px-tb {
        margin-top: 65px !important;
        margin-bottom: 60px !important
    }

    .md-margin-70px-tb {
        margin-top: 70px !important;
        margin-bottom: 65px !important
    }

    .md-margin-75px-tb {
        margin-top: 75px !important;
        margin-bottom: 70px !important
    }

    .md-margin-80px-tb {
        margin-top: 80px !important;
        margin-bottom: 75px !important
    }

    .md-margin-85px-tb {
        margin-top: 85px !important;
        margin-bottom: 80px !important
    }

    .md-margin-90px-tb {
        margin-top: 90px !important;
        margin-bottom: 85px !important
    }

    .md-margin-95px-tb {
        margin-top: 95px !important;
        margin-bottom: 90px !important
    }

    .md-margin-100px-tb {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .md-no-padding {
        padding: 0 !important
    }

    .md-no-padding-lr {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .md-no-padding-tb {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .md-no-padding-top {
        padding-top: 0 !important
    }

    .md-no-padding-bottom {
        padding-bottom: 0 !important
    }

    .md-no-padding-left {
        padding-left: 0 !important
    }

    .md-no-padding-right {
        padding-right: 0 !important
    }

    .md-padding-one-all {
        padding: 1%
    }

    .md-padding-one-half-all {
        padding: 1.5%
    }

    .md-padding-two-all {
        padding: 2%
    }

    .md-padding-two-half-all {
        padding: 2.5%
    }

    .md-padding-three-all {
        padding: 3%
    }

    .md-padding-three-half-all {
        padding: 3.5%
    }

    .md-padding-four-all {
        padding: 4%
    }

    .md-padding-four-half-all {
        padding: 4.5%
    }

    .md-padding-five-all {
        padding: 5%
    }

    .md-padding-five-half-all {
        padding: 5.5%
    }

    .md-padding-six-all {
        padding: 6%
    }

    .md-padding-six-half-all {
        padding: 6.5%
    }

    .md-padding-seven-all {
        padding: 7%
    }

    .md-padding-seven-half-all {
        padding: 7.5%
    }

    .md-padding-eight-all {
        padding: 8%
    }

    .md-padding-eight-half-all {
        padding: 8.5%
    }

    .md-padding-nine-all {
        padding: 9%
    }

    .md-padding-nine-half-all {
        padding: 9.5%
    }

    .md-padding-ten-all {
        padding: 10%
    }

    .md-padding-ten-half-all {
        padding: 10.5%
    }

    .md-padding-eleven-all {
        padding: 11%
    }

    .md-padding-eleven-half-all {
        padding: 11.5%
    }

    .md-padding-twelve-all {
        padding: 12%
    }

    .md-padding-twelve-half-all {
        padding: 12.5%
    }

    .md-padding-thirteen-all {
        padding: 13%
    }

    .md-padding-thirteen-half-all {
        padding: 13.5%
    }

    .md-padding-fourteen-all {
        padding: 14%
    }

    .md-padding-fourteen-half-all {
        padding: 14.5%
    }

    .md-padding-fifteen-all {
        padding: 15%
    }

    .md-padding-fifteen-half-all {
        padding: 15.5%
    }

    .md-padding-sixteen-all {
        padding: 16%
    }

    .md-padding-sixteen-half-all {
        padding: 16.5%
    }

    .md-padding-seventeen-all {
        padding: 17%
    }

    .md-padding-seventeen-half-all {
        padding: 17.5%
    }

    .md-padding-eighteen-all {
        padding: 18%
    }

    .md-padding-eighteen-half-all {
        padding: 18.5%
    }

    .md-padding-nineteen-all {
        padding: 19%
    }

    .md-padding-nineteen-half-all {
        padding: 19.5%
    }

    .md-padding-twenty-all {
        padding: 20%
    }

    .md-padding-twenty-half-all {
        padding: 20.5%
    }

    .md-padding-twenty-one-all {
        padding: 21%
    }

    .md-padding-twenty-one-half-all {
        padding: 21.5%
    }

    .md-padding-twenty-two-all {
        padding: 22%
    }

    .md-padding-twenty-two-half-all {
        padding: 22.5%
    }

    .md-padding-twenty-three-all {
        padding: 23%
    }

    .md-padding-twenty-three-half-all {
        padding: 23.5%
    }

    .md-padding-twenty-four-all {
        padding: 24%
    }

    .md-padding-twenty-four-half-all {
        padding: 24.5%
    }

    .md-padding-twenty-five-all {
        padding: 25%
    }

    .md-padding-5px-all {
        padding: 5px !important
    }

    .md-padding-10px-all {
        padding: 10px !important
    }

    .md-padding-15px-all {
        padding: 15px !important
    }

    .md-padding-20px-all {
        padding: 20px !important
    }

    .md-padding-25px-all {
        padding: 25px !important
    }

    .md-padding-30px-all {
        padding: 30px !important
    }

    .md-padding-35px-all {
        padding: 35px !important
    }

    .md-padding-40px-all {
        padding: 40px !important
    }

    .md-padding-45px-all {
        padding: 45px !important
    }

    .md-padding-50px-all {
        padding: 50px !important
    }

    .md-padding-55px-all {
        padding: 55px !important
    }

    .md-padding-60px-all {
        padding: 60px !important
    }

    .md-padding-65px-all {
        padding: 65px !important
    }

    .md-padding-70px-all {
        padding: 70px !important
    }

    .md-padding-75px-all {
        padding: 75px !important
    }

    .md-padding-80px-all {
        padding: 80px !important
    }

    .md-padding-85px-all {
        padding: 85px !important
    }

    .md-padding-90px-all {
        padding: 90px !important
    }

    .md-padding-95px-all {
        padding: 95px !important
    }

    .md-padding-100px-all {
        padding: 100px !important
    }

    .md-padding-one-top {
        padding-top: 1%
    }

    .md-padding-one-half-top {
        padding-top: 1.5%
    }

    .md-padding-two-top {
        padding-top: 2%
    }

    .md-padding-two-half-top {
        padding-top: 2.5%
    }

    .md-padding-three-top {
        padding-top: 3%
    }

    .md-padding-three-half-top {
        padding-top: 3.5%
    }

    .md-padding-four-top {
        padding-top: 4%
    }

    .md-padding-four-half-top {
        padding-top: 4.5%
    }

    .md-padding-five-top {
        padding-top: 5%
    }

    .md-padding-five-half-top {
        padding-top: 5.5%
    }

    .md-padding-six-top {
        padding-top: 6%
    }

    .md-padding-six-half-top {
        padding-top: 6.5%
    }

    .md-padding-seven-top {
        padding-top: 7%
    }

    .md-padding-seven-half-top {
        padding-top: 7.5%
    }

    .md-padding-eight-top {
        padding-top: 8%
    }

    .md-padding-eight-half-top {
        padding-top: 8.5%
    }

    .md-padding-nine-top {
        padding-top: 9%
    }

    .md-padding-nine-half-top {
        padding-top: 9.5%
    }

    .md-padding-ten-top {
        padding-top: 10%
    }

    .md-padding-ten-half-top {
        padding-top: 10.5%
    }

    .md-padding-eleven-top {
        padding-top: 11%
    }

    .md-padding-eleven-half-top {
        padding-top: 11.5%
    }

    .md-padding-twelve-top {
        padding-top: 12%
    }

    .md-padding-twelve-half-top {
        padding-top: 12.5%
    }

    .md-padding-thirteen-top {
        padding-top: 13%
    }

    .md-padding-thirteen-half-top {
        padding-top: 13.5%
    }

    .md-padding-fourteen-top {
        padding-top: 14%
    }

    .md-padding-fourteen-half-top {
        padding-top: 14.5%
    }

    .md-padding-fifteen-top {
        padding-top: 15%
    }

    .md-padding-fifteen-half-top {
        padding-top: 15.5%
    }

    .md-padding-sixteen-top {
        padding-top: 16%
    }

    .md-padding-sixteen-half-top {
        padding-top: 16.5%
    }

    .md-padding-seventeen-top {
        padding-top: 17%
    }

    .md-padding-seventeen-half-top {
        padding-top: 17.5%
    }

    .md-padding-eighteen-top {
        padding-top: 18%
    }

    .md-padding-eighteen-half-top {
        padding-top: 18.5%
    }

    .md-padding-nineteen-top {
        padding-top: 19%
    }

    .md-padding-nineteen-half-top {
        padding-top: 19.5%
    }

    .md-padding-twenty-top {
        padding-top: 20%
    }

    .md-padding-twenty-half-top {
        padding-top: 20.5%
    }

    .md-padding-twenty-one-top {
        padding-top: 21%
    }

    .md-padding-twenty-one-half-top {
        padding-top: 21.5%
    }

    .md-padding-twenty-two-top {
        padding-top: 22%
    }

    .md-padding-twenty-two-half-top {
        padding-top: 22.5%
    }

    .md-padding-twenty-three-top {
        padding-top: 23%
    }

    .md-padding-twenty-three-half-top {
        padding-top: 23.5%
    }

    .md-padding-twenty-four-top {
        padding-top: 24%
    }

    .md-padding-twenty-four-half-top {
        padding-top: 24.5%
    }

    .md-padding-twenty-five-top {
        padding-top: 25%
    }

    .md-padding-5px-top {
        padding-top: 5px !important
    }

    .md-padding-10px-top {
        padding-top: 10px !important
    }

    .md-padding-15px-top {
        padding-top: 15px !important
    }

    .md-padding-20px-top {
        padding-top: 20px !important
    }

    .md-padding-25px-top {
        padding-top: 25px !important
    }

    .md-padding-30px-top {
        padding-top: 30px !important
    }

    .md-padding-35px-top {
        padding-top: 35px !important
    }

    .md-padding-40px-top {
        padding-top: 40px !important
    }

    .md-padding-45px-top {
        padding-top: 45px !important
    }

    .md-padding-50px-top {
        padding-top: 50px !important
    }

    .md-padding-55px-top {
        padding-top: 55px !important
    }

    .md-padding-60px-top {
        padding-top: 60px !important
    }

    .md-padding-65px-top {
        padding-top: 65px !important
    }

    .md-padding-70px-top {
        padding-top: 70px !important
    }

    .md-padding-75px-top {
        padding-top: 75px !important
    }

    .md-padding-80px-top {
        padding-top: 80px !important
    }

    .md-padding-85px-top {
        padding-top: 85px !important
    }

    .md-padding-90px-top {
        padding-top: 90px !important
    }

    .md-padding-95px-top {
        padding-top: 95px !important
    }

    .md-padding-100px-top {
        padding-top: 100px !important
    }

    .md-padding-one-bottom {
        padding-bottom: 1%
    }

    .md-padding-one-half-bottom {
        padding-bottom: 1.5%
    }

    .md-padding-two-bottom {
        padding-bottom: 2%
    }

    .md-padding-two-half-bottom {
        padding-bottom: 2.5%
    }

    .md-padding-three-bottom {
        padding-bottom: 3%
    }

    .md-padding-three-half-bottom {
        padding-bottom: 3.5%
    }

    .md-padding-four-bottom {
        padding-bottom: 4%
    }

    .md-padding-four-half-bottom {
        padding-bottom: 4.5%
    }

    .md-padding-five-bottom {
        padding-bottom: 5%
    }

    .md-padding-five-half-bottom {
        padding-bottom: 5.5%
    }

    .md-padding-six-bottom {
        padding-bottom: 6%
    }

    .md-padding-six-half-bottom {
        padding-bottom: 6.5%
    }

    .md-padding-seven-bottom {
        padding-bottom: 7%
    }

    .md-padding-seven-half-bottom {
        padding-bottom: 7.5%
    }

    .md-padding-eight-bottom {
        padding-bottom: 8%
    }

    .md-padding-eight-half-bottom {
        padding-bottom: 8.5%
    }

    .md-padding-nine-bottom {
        padding-bottom: 9%
    }

    .md-padding-nine-half-bottom {
        padding-bottom: 9.5%
    }

    .md-padding-ten-bottom {
        padding-bottom: 10%
    }

    .md-padding-ten-half-bottom {
        padding-bottom: 10.5%
    }

    .md-padding-eleven-bottom {
        padding-bottom: 11%
    }

    .md-padding-eleven-half-bottom {
        padding-bottom: 11.5%
    }

    .md-padding-twelve-bottom {
        padding-bottom: 12%
    }

    .md-padding-twelve-half-bottom {
        padding-bottom: 12.5%
    }

    .md-padding-thirteen-bottom {
        padding-bottom: 13%
    }

    .md-padding-thirteen-half-bottom {
        padding-bottom: 13.5%
    }

    .md-padding-fourteen-bottom {
        padding-bottom: 14%
    }

    .md-padding-fourteen-half-bottom {
        padding-bottom: 14.5%
    }

    .md-padding-fifteen-bottom {
        padding-bottom: 15%
    }

    .md-padding-fifteen-half-bottom {
        padding-bottom: 15.5%
    }

    .md-padding-sixteen-bottom {
        padding-bottom: 16%
    }

    .md-padding-sixteen-half-bottom {
        padding-bottom: 16.5%
    }

    .md-padding-seventeen-bottom {
        padding-bottom: 17%
    }

    .md-padding-seventeen-half-bottom {
        padding-bottom: 17.5%
    }

    .md-padding-eighteen-bottom {
        padding-bottom: 18%
    }

    .md-padding-eighteen-half-bottom {
        padding-bottom: 18.5%
    }

    .md-padding-nineteen-bottom {
        padding-bottom: 19%
    }

    .md-padding-nineteen-half-bottom {
        padding-bottom: 19.5%
    }

    .md-padding-twenty-bottom {
        padding-bottom: 20%
    }

    .md-padding-twenty-half-bottom {
        padding-bottom: 20.5%
    }

    .md-padding-twenty-one-bottom {
        padding-bottom: 21%
    }

    .md-padding-twenty-one-half-bottom {
        padding-bottom: 21.5%
    }

    .md-padding-twenty-two-bottom {
        padding-bottom: 22%
    }

    .md-padding-twenty-two-half-bottom {
        padding-bottom: 22.5%
    }

    .md-padding-twenty-three-bottom {
        padding-bottom: 23%
    }

    .md-padding-twenty-three-half-bottom {
        padding-bottom: 23.5%
    }

    .md-padding-twenty-four-bottom {
        padding-bottom: 24%
    }

    .md-padding-twenty-four-half-bottom {
        padding-bottom: 24.5%
    }

    .md-padding-twenty-five-bottom {
        padding-bottom: 25%
    }

    .md-padding-5px-bottom {
        padding-bottom: 5px !important
    }

    .md-padding-10px-bottom {
        padding-bottom: 10px !important
    }

    .md-padding-15px-bottom {
        padding-bottom: 15px !important
    }

    .md-padding-20px-bottom {
        padding-bottom: 20px !important
    }

    .md-padding-25px-bottom {
        padding-bottom: 25px !important
    }

    .md-padding-30px-bottom {
        padding-bottom: 30px !important
    }

    .md-padding-35px-bottom {
        padding-bottom: 35px !important
    }

    .md-padding-40px-bottom {
        padding-bottom: 40px !important
    }

    .md-padding-45px-bottom {
        padding-bottom: 45px !important
    }

    .md-padding-50px-bottom {
        padding-bottom: 50px !important
    }

    .md-padding-55px-bottom {
        padding-bottom: 55px !important
    }

    .md-padding-60px-bottom {
        padding-bottom: 60px !important
    }

    .md-padding-65px-bottom {
        padding-bottom: 65px !important
    }

    .md-padding-70px-bottom {
        padding-bottom: 70px !important
    }

    .md-padding-75px-bottom {
        padding-bottom: 75px !important
    }

    .md-padding-80px-bottom {
        padding-bottom: 80px !important
    }

    .md-padding-85px-bottom {
        padding-bottom: 85px !important
    }

    .md-padding-90px-bottom {
        padding-bottom: 90px !important
    }

    .md-padding-95px-bottom {
        padding-bottom: 95px !important
    }

    .md-padding-100px-bottom {
        padding-bottom: 100px !important
    }

    .md-padding-one-right {
        padding-right: 1%
    }

    .md-padding-one-half-right {
        padding-right: 1.5%
    }

    .md-padding-two-right {
        padding-right: 2%
    }

    .md-padding-two-half-right {
        padding-right: 2.5%
    }

    .md-padding-three-right {
        padding-right: 3%
    }

    .md-padding-three-half-right {
        padding-right: 3.5%
    }

    .md-padding-four-right {
        padding-right: 4%
    }

    .md-padding-four-half-right {
        padding-right: 4.5%
    }

    .md-padding-five-right {
        padding-right: 5%
    }

    .md-padding-five-half-right {
        padding-right: 5.5%
    }

    .md-padding-six-right {
        padding-right: 6%
    }

    .md-padding-six-half-right {
        padding-right: 6.5%
    }

    .md-padding-seven-right {
        padding-right: 7%
    }

    .md-padding-seven-half-right {
        padding-right: 7.5%
    }

    .md-padding-eight-right {
        padding-right: 8%
    }

    .md-padding-eight-half-right {
        padding-right: 8.5%
    }

    .md-padding-nine-right {
        padding-right: 9%
    }

    .md-padding-nine-half-right {
        padding-right: 9.5%
    }

    .md-padding-ten-right {
        padding-right: 10%
    }

    .md-padding-ten-half-right {
        padding-right: 10.5%
    }

    .md-padding-eleven-right {
        padding-right: 11%
    }

    .md-padding-eleven-half-right {
        padding-right: 11.5%
    }

    .md-padding-twelve-right {
        padding-right: 12%
    }

    .md-padding-twelve-half-right {
        padding-right: 12.5%
    }

    .md-padding-thirteen-right {
        padding-right: 13%
    }

    .md-padding-thirteen-half-right {
        padding-right: 13.5%
    }

    .md-padding-fourteen-right {
        padding-right: 14%
    }

    .md-padding-fourteen-half-right {
        padding-right: 14.5%
    }

    .md-padding-fifteen-right {
        padding-right: 15%
    }

    .md-padding-fifteen-half-right {
        padding-right: 15.5%
    }

    .md-padding-sixteen-right {
        padding-right: 16%
    }

    .md-padding-sixteen-half-right {
        padding-right: 16.5%
    }

    .md-padding-seventeen-right {
        padding-right: 17%
    }

    .md-padding-seventeen-half-right {
        padding-right: 17.5%
    }

    .md-padding-eighteen-right {
        padding-right: 18%
    }

    .md-padding-eighteen-half-right {
        padding-right: 18.5%
    }

    .md-padding-nineteen-right {
        padding-right: 19%
    }

    .md-padding-nineteen-half-right {
        padding-right: 19.5%
    }

    .md-padding-twenty-right {
        padding-right: 20%
    }

    .md-padding-twenty-half-right {
        padding-right: 20.5%
    }

    .md-padding-twenty-one-right {
        padding-right: 21%
    }

    .md-padding-twenty-one-half-right {
        padding-right: 21.5%
    }

    .md-padding-twenty-two-right {
        padding-right: 22%
    }

    .md-padding-twenty-two-half-right {
        padding-right: 22.5%
    }

    .md-padding-twenty-three-right {
        padding-right: 23%
    }

    .md-padding-twenty-three-half-right {
        padding-right: 23.5%
    }

    .md-padding-twenty-four-right {
        padding-right: 24%
    }

    .md-padding-twenty-four-half-right {
        padding-right: 24.5%
    }

    .md-padding-twenty-five-right {
        padding-right: 25%
    }

    .md-padding-5px-right {
        padding-right: 5px !important
    }

    .md-padding-10px-right {
        padding-right: 10px !important
    }

    .md-padding-15px-right {
        padding-right: 15px !important
    }

    .md-padding-20px-right {
        padding-right: 20px !important
    }

    .md-padding-25px-right {
        padding-right: 25px !important
    }

    .md-padding-30px-right {
        padding-right: 30px !important
    }

    .md-padding-35px-right {
        padding-right: 35px !important
    }

    .md-padding-40px-right {
        padding-right: 40px !important
    }

    .md-padding-45px-right {
        padding-right: 45px !important
    }

    .md-padding-50px-right {
        padding-right: 50px !important
    }

    .md-padding-55px-right {
        padding-right: 55px !important
    }

    .md-padding-60px-right {
        padding-right: 60px !important
    }

    .md-padding-65px-right {
        padding-right: 65px !important
    }

    .md-padding-70px-right {
        padding-right: 70px !important
    }

    .md-padding-75px-right {
        padding-right: 75px !important
    }

    .md-padding-80px-right {
        padding-right: 80px !important
    }

    .md-padding-85px-right {
        padding-right: 85px !important
    }

    .md-padding-90px-right {
        padding-right: 90px !important
    }

    .md-padding-95px-right {
        padding-right: 95px !important
    }

    .md-padding-100px-right {
        padding-right: 100px !important
    }

    .md-padding-one-left {
        padding-left: 1%
    }

    .md-padding-one-half-left {
        padding-left: 1.5%
    }

    .md-padding-two-left {
        padding-left: 2%
    }

    .md-padding-two-half-left {
        padding-left: 2.5%
    }

    .md-padding-three-left {
        padding-left: 3%
    }

    .md-padding-three-half-left {
        padding-left: 3.5%
    }

    .md-padding-four-left {
        padding-left: 4%
    }

    .md-padding-four-half-left {
        padding-left: 4.5%
    }

    .md-padding-five-left {
        padding-left: 5%
    }

    .md-padding-five-half-left {
        padding-left: 5.5%
    }

    .md-padding-six-left {
        padding-left: 6%
    }

    .md-padding-six-half-left {
        padding-left: 6.5%
    }

    .md-padding-seven-left {
        padding-left: 7%
    }

    .md-padding-seven-half-left {
        padding-left: 7.5%
    }

    .md-padding-eight-left {
        padding-left: 8%
    }

    .md-padding-eight-half-left {
        padding-left: 8.5%
    }

    .md-padding-nine-left {
        padding-left: 9%
    }

    .md-padding-nine-half-left {
        padding-left: 9.5%
    }

    .md-padding-ten-left {
        padding-left: 10%
    }

    .md-padding-ten-half-left {
        padding-left: 10.5%
    }

    .md-padding-eleven-left {
        padding-left: 11%
    }

    .md-padding-eleven-half-left {
        padding-left: 11.5%
    }

    .md-padding-twelve-left {
        padding-left: 12%
    }

    .md-padding-twelve-half-left {
        padding-left: 12.5%
    }

    .md-padding-thirteen-left {
        padding-left: 13%
    }

    .md-padding-thirteen-half-left {
        padding-left: 13.5%
    }

    .md-padding-fourteen-left {
        padding-left: 14%
    }

    .md-padding-fourteen-half-left {
        padding-left: 14.5%
    }

    .md-padding-fifteen-left {
        padding-left: 15%
    }

    .md-padding-fifteen-half-left {
        padding-left: 15.5%
    }

    .md-padding-sixteen-left {
        padding-left: 16%
    }

    .md-padding-sixteen-half-left {
        padding-left: 16.5%
    }

    .md-padding-seventeen-left {
        padding-left: 17%
    }

    .md-padding-seventeen-half-left {
        padding-left: 17.5%
    }

    .md-padding-eighteen-left {
        padding-left: 18%
    }

    .md-padding-eighteen-half-left {
        padding-left: 18.5%
    }

    .md-padding-nineteen-left {
        padding-left: 19%
    }

    .md-padding-nineteen-half-left {
        padding-left: 19.5%
    }

    .md-padding-twenty-left {
        padding-left: 20%
    }

    .md-padding-twenty-half-left {
        padding-left: 20.5%
    }

    .md-padding-twenty-one-left {
        padding-left: 21%
    }

    .md-padding-twenty-one-half-left {
        padding-left: 21.5%
    }

    .md-padding-twenty-two-left {
        padding-left: 22%
    }

    .md-padding-twenty-two-half-left {
        padding-left: 22.5%
    }

    .md-padding-twenty-three-left {
        padding-left: 23%
    }

    .md-padding-twenty-three-half-left {
        padding-left: 23.5%
    }

    .md-padding-twenty-four-left {
        padding-left: 24%
    }

    .md-padding-twenty-four-half-left {
        padding-left: 24.5%
    }

    .md-padding-twenty-five-left {
        padding-left: 25%
    }

    .md-padding-5px-left {
        padding-left: 5px !important
    }

    .md-padding-10px-left {
        padding-left: 10px !important
    }

    .md-padding-15px-left {
        padding-left: 15px !important
    }

    .md-padding-20px-left {
        padding-left: 20px !important
    }

    .md-padding-25px-left {
        padding-left: 25px !important
    }

    .md-padding-30px-left {
        padding-left: 30px !important
    }

    .md-padding-35px-left {
        padding-left: 35px !important
    }

    .md-padding-40px-left {
        padding-left: 40px !important
    }

    .md-padding-45px-left {
        padding-left: 45px !important
    }

    .md-padding-50px-left {
        padding-left: 50px !important
    }

    .md-padding-55px-left {
        padding-left: 55px !important
    }

    .md-padding-60px-left {
        padding-left: 60px !important
    }

    .md-padding-65px-left {
        padding-left: 65px !important
    }

    .md-padding-70px-left {
        padding-left: 70px !important
    }

    .md-padding-75px-left {
        padding-left: 75px !important
    }

    .md-padding-80px-left {
        padding-left: 80px !important
    }

    .md-padding-85px-left {
        padding-left: 85px !important
    }

    .md-padding-90px-left {
        padding-left: 90px !important
    }

    .md-padding-95px-left {
        padding-left: 95px !important
    }

    .md-padding-100px-left {
        padding-left: 100px !important
    }

    .md-padding-one-tb {
        padding-top: 1%;
        padding-bottom: 1%
    }

    .md-padding-one-half-tb {
        padding-top: 1.5%;
        padding-bottom: 1.5%
    }

    .md-padding-two-tb {
        padding-top: 2%;
        padding-bottom: 2%
    }

    .md-padding-two-half-tb {
        padding-top: 2.5%;
        padding-bottom: 2.5%
    }

    .md-padding-three-tb {
        padding-top: 3%;
        padding-bottom: 3%
    }

    .md-padding-three-half-tb {
        padding-top: 3.5%;
        padding-bottom: 3.5%
    }

    .md-padding-four-tb {
        padding-top: 4%;
        padding-bottom: 4%
    }

    .md-padding-four-half-tb {
        padding-top: 4.5%;
        padding-bottom: 4.5%
    }

    .md-padding-five-tb {
        padding-top: 5%;
        padding-bottom: 5%
    }

    .md-padding-five-half-tb {
        padding-top: 5.5%;
        padding-bottom: 5.5%
    }

    .md-padding-six-tb {
        padding-top: 6%;
        padding-bottom: 6%
    }

    .md-padding-six-half-tb {
        padding-top: 6.5%;
        padding-bottom: 6.5%
    }

    .md-padding-seven-tb {
        padding-top: 7%;
        padding-bottom: 7%
    }

    .md-padding-seven-half-tb {
        padding-top: 7.5%;
        padding-bottom: 7.5%
    }

    .md-padding-eight-tb {
        padding-top: 8%;
        padding-bottom: 8%
    }

    .md-padding-eight-half-tb {
        padding-top: 8.5%;
        padding-bottom: 8.5%
    }

    .md-padding-nine-tb {
        padding-top: 9%;
        padding-bottom: 9%
    }

    .md-padding-nine-half-tb {
        padding-top: 9.5%;
        padding-bottom: 9.5%
    }

    .md-padding-ten-tb {
        padding-top: 10%;
        padding-bottom: 10%
    }

    .md-padding-ten-half-tb {
        padding-top: 10.5%;
        padding-bottom: 10.5%
    }

    .md-padding-eleven-tb {
        padding-top: 11%;
        padding-bottom: 11%
    }

    .md-padding-eleven-half-tb {
        padding-top: 11.5%;
        padding-bottom: 11.5%
    }

    .md-padding-twelve-tb {
        padding-top: 12%;
        padding-bottom: 12%
    }

    .md-padding-twelve-half-tb {
        padding-top: 12.5%;
        padding-bottom: 12.5%
    }

    .md-padding-thirteen-tb {
        padding-top: 13%;
        padding-bottom: 13%
    }

    .md-padding-thirteen-half-tb {
        padding-top: 13.5%;
        padding-bottom: 13.5%
    }

    .md-padding-fourteen-tb {
        padding-top: 14%;
        padding-bottom: 14%
    }

    .md-padding-fourteen-half-tb {
        padding-top: 14.5%;
        padding-bottom: 14.5%
    }

    .md-padding-fifteen-tb {
        padding-top: 15%;
        padding-bottom: 15%
    }

    .md-padding-fifteen-half-tb {
        padding-top: 15.5%;
        padding-bottom: 15.5%
    }

    .md-padding-sixteen-tb {
        padding-top: 16%;
        padding-bottom: 16%
    }

    .md-padding-sixteen-half-tb {
        padding-top: 16.5%;
        padding-bottom: 16.5%
    }

    .md-padding-seventeen-tb {
        padding-top: 17%;
        padding-bottom: 17%
    }

    .md-padding-seventeen-half-tb {
        padding-top: 17.5%;
        padding-bottom: 17.5%
    }

    .md-padding-eighteen-tb {
        padding-top: 18%;
        padding-bottom: 18%
    }

    .md-padding-eighteen-half-tb {
        padding-top: 18.5%;
        padding-bottom: 18.5%
    }

    .md-padding-nineteen-tb {
        padding-top: 19%;
        padding-bottom: 19%
    }

    .md-padding-nineteen-half-tb {
        padding-top: 19.5%;
        padding-bottom: 19.5%
    }

    .md-padding-twenty-tb {
        padding-top: 20%;
        padding-bottom: 20%
    }

    .md-padding-twenty-half-tb {
        padding-top: 20.5%;
        padding-bottom: 20.5%
    }

    .md-padding-twenty-one-tb {
        padding-top: 21%;
        padding-bottom: 21%
    }

    .md-padding-twenty-one-half-tb {
        padding-top: 21.5%;
        padding-bottom: 21.5%
    }

    .md-padding-twenty-two-tb {
        padding-top: 22%;
        padding-bottom: 22%
    }

    .md-padding-twenty-two-half-tb {
        padding-top: 22.5%;
        padding-bottom: 22.5%
    }

    .md-padding-twenty-three-tb {
        padding-top: 23%;
        padding-bottom: 23%
    }

    .md-padding-twenty-three-half-tb {
        padding-top: 23.5%;
        padding-bottom: 23.5%
    }

    .md-padding-twenty-four-tb {
        padding-top: 24%;
        padding-bottom: 24%
    }

    .md-padding-twenty-four-half-tb {
        padding-top: 24.5%;
        padding-bottom: 24.5%
    }

    .md-padding-twenty-five-tb {
        padding-top: 25%;
        padding-bottom: 25%
    }

    .md-padding-5px-tb {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .md-padding-8px-tb {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .md-padding-10px-tb {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .md-padding-15px-tb {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .md-padding-20px-tb {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .md-padding-25px-tb {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .md-padding-30px-tb {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .md-padding-35px-tb {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .md-padding-40px-tb {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .md-padding-45px-tb {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .md-padding-50px-tb {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .md-padding-55px-tb {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .md-padding-60px-tb {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .md-padding-65px-tb {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .md-padding-70px-tb {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .md-padding-75px-tb {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .md-padding-80px-tb {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .md-padding-85px-tb {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .md-padding-90px-tb {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .md-padding-95px-tb {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .md-padding-100px-tb {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .md-padding-one-lr {
        padding-left: 1%;
        padding-right: 1%
    }

    .md-padding-one-half-lr {
        padding-left: 1.5%;
        padding-right: 1.5%
    }

    .md-padding-two-lr {
        padding-left: 2%;
        padding-right: 2%
    }

    .md-padding-two-half-lr {
        padding-left: 2.5%;
        padding-right: 2.5%
    }

    .md-padding-three-lr {
        padding-left: 3%;
        padding-right: 3%
    }

    .md-padding-three-half-lr {
        padding-left: 3.5%;
        padding-right: 3.5%
    }

    .md-padding-four-lr {
        padding-left: 4%;
        padding-right: 4%
    }

    .md-padding-four-half-lr {
        padding-left: 4.5%;
        padding-right: 4.5%
    }

    .md-padding-five-lr {
        padding-left: 5%;
        padding-right: 5%
    }

    .md-padding-five-half-lr {
        padding-left: 5.5%;
        padding-right: 5.5%
    }

    .md-padding-six-lr {
        padding-left: 6%;
        padding-right: 6%
    }

    .md-padding-six-half-lr {
        padding-left: 6.5%;
        padding-right: 6.5%
    }

    .md-padding-seven-lr {
        padding-left: 7%;
        padding-right: 7%
    }

    .md-padding-seven-half-lr {
        padding-left: 7.5%;
        padding-right: 7.5%
    }

    .md-padding-eight-lr {
        padding-left: 8%;
        padding-right: 8%
    }

    .md-padding-eight-half-lr {
        padding-left: 8.5%;
        padding-right: 8.5%
    }

    .md-padding-nine-lr {
        padding-left: 9%;
        padding-right: 9%
    }

    .md-padding-nine-half-lr {
        padding-left: 9.5%;
        padding-right: 9.5%
    }

    .md-padding-ten-lr {
        padding-left: 10%;
        padding-right: 10%
    }

    .md-padding-ten-half-lr {
        padding-left: 10.5%;
        padding-right: 10.5%
    }

    .md-padding-eleven-lr {
        padding-left: 11%;
        padding-right: 11%
    }

    .md-padding-eleven-half-lr {
        padding-left: 11.5%;
        padding-right: 11.5%
    }

    .md-padding-twelve-lr {
        padding-left: 12%;
        padding-right: 12%
    }

    .md-padding-twelve-half-lr {
        padding-left: 12.5%;
        padding-right: 12.5%
    }

    .md-padding-thirteen-lr {
        padding-left: 13%;
        padding-right: 13%
    }

    .md-padding-thirteen-half-lr {
        padding-left: 13.5%;
        padding-right: 13.5%
    }

    .md-padding-fourteen-lr {
        padding-left: 14%;
        padding-right: 14%
    }

    .md-padding-fourteen-half-lr {
        padding-left: 14.5%;
        padding-right: 14.5%
    }

    .md-padding-fifteen-lr {
        padding-left: 15%;
        padding-right: 15%
    }

    .md-padding-fifteen-half-lr {
        padding-left: 15.5%;
        padding-right: 15.5%
    }

    .md-padding-sixteen-lr {
        padding-left: 16%;
        padding-right: 16%
    }

    .md-padding-sixteen-half-lr {
        padding-left: 16.5%;
        padding-right: 16.5%
    }

    .md-padding-seventeen-lr {
        padding-left: 17%;
        padding-right: 17%
    }

    .md-padding-seventeen-half-lr {
        padding-left: 17.5%;
        padding-right: 17.5%
    }

    .md-padding-eighteen-lr {
        padding-left: 18%;
        padding-right: 18%
    }

    .md-padding-eighteen-half-lr {
        padding-left: 18.5%;
        padding-right: 18.5%
    }

    .md-padding-nineteen-lr {
        padding-left: 19%;
        padding-right: 19%
    }

    .md-padding-nineteen-half-lr {
        padding-left: 19.5%;
        padding-right: 19.5%
    }

    .md-padding-twenty-lr {
        padding-left: 20%;
        padding-right: 20%
    }

    .md-padding-twenty-half-lr {
        padding-left: 20.5%;
        padding-right: 20.5%
    }

    .md-padding-twenty-one-lr {
        padding-left: 21%;
        padding-right: 21%
    }

    .md-padding-twenty-one-half-lr {
        padding-left: 21.5%;
        padding-right: 21.5%
    }

    .md-padding-twenty-two-lr {
        padding-left: 22%;
        padding-right: 22%
    }

    .md-padding-twenty-two-half-lr {
        padding-left: 22.5%;
        padding-right: 22.5%
    }

    .md-padding-twenty-three-lr {
        padding-left: 23%;
        padding-right: 23%
    }

    .md-padding-twenty-three-half-lr {
        padding-left: 23.5%;
        padding-right: 23.5%
    }

    .md-padding-twenty-four-lr {
        padding-left: 24%;
        padding-right: 24%
    }

    .md-padding-twenty-four-half-lr {
        padding-left: 24.5%;
        padding-right: 24.5%
    }

    .md-padding-twenty-five-lr {
        padding-left: 25%;
        padding-right: 25%
    }

    .md-padding-5px-lr {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .md-padding-10px-lr {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .md-padding-15px-lr {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .md-padding-20px-lr {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .md-padding-25px-lr {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .md-padding-30px-lr {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .md-padding-35px-lr {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .md-padding-40px-lr {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .md-padding-45px-lr {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .md-padding-50px-lr {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .md-padding-55px-lr {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .md-padding-60px-lr {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .md-padding-65px-lr {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .md-padding-70px-lr {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .md-padding-75px-lr {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .md-padding-80px-lr {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .md-padding-85px-lr {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .md-padding-90px-lr {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .md-padding-95px-lr {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .md-padding-100px-lr {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .md-width-10 {
        width: 10%
    }

    .md-width-15 {
        width: 15%
    }

    .md-width-20 {
        width: 20%
    }

    .md-width-25 {
        width: 25%
    }

    .md-width-30 {
        width: 30%
    }

    .md-width-35 {
        width: 35%
    }

    .md-width-40 {
        width: 40%
    }

    .md-width-45 {
        width: 45%
    }

    .md-width-50 {
        width: 50%
    }

    .md-width-55 {
        width: 55%
    }

    .md-width-60 {
        width: 60%
    }

    .md-width-65 {
        width: 65%
    }

    .md-width-70 {
        width: 70%
    }

    .md-width-75 {
        width: 75%
    }

    .md-width-80 {
        width: 80%
    }

    .md-width-85 {
        width: 85%
    }

    .md-width-90 {
        width: 90%
    }

    .md-width-95 {
        width: 95%
    }

    .md-width-100 {
        width: 100%
    }

    .md-width-auto {
        width: auto
    }

    .md-width-50px {
        width: 50px
    }

    .md-width-70px {
        width: 70px
    }

    .md-width-75px {
        width: 75px
    }

    .md-width-80px {
        width: 80px
    }

    .md-width-85px {
        width: 85px
    }

    .md-width-90px {
        width: 90px
    }

    .md-width-95px {
        width: 95px
    }

    .md-width-100px {
        width: 100px
    }

    .md-width-120px {
        width: 120px
    }

    .md-width-130px {
        width: 130px
    }

    .md-width-150px {
        width: 150px
    }

    .md-width-180px {
        width: 180px
    }

    .md-width-200px {
        width: 200px
    }

    .md-width-250px {
        width: 250px
    }

    .md-width-300px {
        width: 300px
    }

    .md-width-350px {
        width: 350px
    }

    .md-width-400px {
        width: 400px
    }

    .md-width-450px {
        width: 450px
    }

    .md-width-500px {
        width: 500px
    }

    .md-width-550px {
        width: 550px
    }

    .md-width-600px {
        width: 600px
    }

    .md-width-650px {
        width: 650px
    }

    .md-width-700px {
        width: 700px
    }

    .md-width-750px {
        width: 750px
    }

    .md-width-800px {
        width: 800px
    }

    .md-width-850px {
        width: 850px
    }

    .md-width-900px {
        width: 900px
    }

    .md-width-950px {
        width: 950px
    }

    .md-width-1000px {
        width: 1000px
    }

    .md-display-block {
        display: block !important
    }

    .md-overflow-hidden {
        overflow: hidden !important
    }

    .md-overflow-auto {
        overflow: auto !important
    }

    .md-display-inline-block {
        display: inline-block !important
    }

    .md-display-inline {
        display: inline !important
    }

    .md-display-none {
        display: none !important
    }

    .md-display-inherit {
        display: inherit !important
    }

    .md-display-table {
        display: table !important
    }

    .md-display-table-cell {
        display: table-cell !important
    }

    .md-position-inherit {
        position: inherit
    }

    .md-position-relative {
        position: relative;
        z-index: 5
    }

    .md-position-absolute {
        position: absolute
    }

    .md-position-fixed {
        position: fixed
    }

    .md-border-top {
        border-top: 1px solid
    }

    .md-border-bottom {
        border-bottom: 1px solid
    }

    .md-border-left {
        border-left: 1px solid
    }

    .md-border-right {
        border-right: 1px solid
    }

    .md-border-lr {
        border-left: 1px solid;
        border-right: 1px solid
    }

    .md-border-tb {
        border-top: 1px solid;
        border-bottom: 1px solid
    }

    .md-no-border-top {
        border-top: 0 !important
    }

    .md-no-border-bottom {
        border-bottom: 0 !important
    }

    .md-no-border-right {
        border-right: 0 !important
    }

    .md-no-border-left {
        border-left: 0 !important
    }
}

@media screen and (max-width: 1050px) {
    .parallax {
        background-attachment: inherit
    }
}

@media screen and (max-width: 991px) {
    .sm-height-auto {
        height: auto
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 16px;
        padding: 0;
        letter-spacing: 0
    }

    h1 {
        font-size: 38px
    }

    h2 {
        font-size: 30px
    }

    h3 {
        font-size: 28px
    }

    h4 {
        font-size: 26px
    }

    h5 {
        font-size: 22px
    }

    h6 {
        font-size: 18px
    }

    p {
        margin: 0 0 20px
    }

    .sm-font-size11 {
        font-size: 11px !important;
    }

    .sm-font-size12 {
        font-size: 12px !important;
    }

    .sm-font-size13 {
        font-size: 13px !important;
    }

    .sm-font-size14 {
        font-size: 14px !important;
    }

    .sm-font-size15 {
        font-size: 15px !important;
    }

    .sm-font-size16 {
        font-size: 16px !important;
    }

    .sm-font-size17 {
        font-size: 17px !important;
    }

    .sm-font-size18 {
        font-size: 18px !important;
    }

    .sm-font-size19 {
        font-size: 19px !important;
    }

    .sm-font-size20 {
        font-size: 20px !important;
    }

    .sm-font-size22 {
        font-size: 22px !important;
    }

    .sm-font-size24 {
        font-size: 24px !important;
    }

    .sm-font-size26 {
        font-size: 26px !important;
    }

    .sm-font-size28 {
        font-size: 28px !important;
    }

    .sm-font-size30 {
        font-size: 30px !important;
    }

    .sm-font-size32 {
        font-size: 32px !important;
    }

    .sm-font-size34 {
        font-size: 34px !important;
    }

    .sm-font-size36 {
        font-size: 36px !important;
    }

    .sm-font-size38 {
        font-size: 38px !important;
    }

    .sm-font-size40 {
        font-size: 40px !important;
    }

    .sm-font-size42 {
        font-size: 42px !important;
    }

    .sm-font-size44 {
        font-size: 44px !important;
    }

    .sm-font-size46 {
        font-size: 46px !important;
    }

    .sm-font-size48 {
        font-size: 48px !important;
    }

    .sm-font-size50 {
        font-size: 50px !important;
    }

    .sm-font-size55 {
        font-size: 55px !important;
    }

    .sm-font-size60 {
        font-size: 60px !important;
    }

    .sm-font-size80 {
        font-size: 80px !important;
    }

    .sm-font-size100 {
        font-size: 100px !important;
    }

    .sm-font-size120 {
        font-size: 120px !important;
    }

    .sm-font-size130 {
        font-size: 130px !important;
    }

    .sm-line-height-normal {
        line-height: normal
    }

    .sm-line-height-10 {
        line-height: 10px
    }

    .sm-line-height-13 {
        line-height: 13px
    }

    .sm-line-height-18 {
        line-height: 18px
    }

    .sm-line-height-20 {
        line-height: 20px
    }

    .sm-line-height-24 {
        line-height: 24px
    }

    .sm-line-height-22 {
        line-height: 22px
    }

    .sm-line-height-26 {
        line-height: 26px
    }

    .sm-line-height-28 {
        line-height: 28px
    }

    .sm-line-height-30 {
        line-height: 30px
    }

    .sm-line-height-35 {
        line-height: 35px
    }

    .sm-line-height-40 {
        line-height: 40px
    }

    .sm-line-height-45 {
        line-height: 45px
    }

    .sm-line-height-50 {
        line-height: 50px
    }

    .sm-line-height-55 {
        line-height: 55px
    }

    .sm-line-height-60 {
        line-height: 60px
    }

    .sm-line-height-65 {
        line-height: 65px
    }

    .sm-line-height-70 {
        line-height: 70px
    }

    .sm-line-height-75 {
        line-height: 75px
    }

    .sm-line-height-80 {
        line-height: 80px
    }

    .sm-line-height-85 {
        line-height: 85px
    }

    .sm-line-height-90 {
        line-height: 90px
    }

    .sm-line-height-95 {
        line-height: 95px
    }

    .sm-line-height-100 {
        line-height: 100px
    }

    .sm-line-height-110 {
        line-height: 110px
    }

    .sm-line-height-120 {
        line-height: 120px
    }

    section {
        padding: 50px 0
    }

    .sm-center-col {
        float: none;
        margin: 0 auto
    }

    .sm-float-left {
        float: left !important
    }

    .sm-float-right {
        float: right !important
    }

    .sm-float-none {
        float: none !important
    }

    .sm-text-center {
        text-align: center !important
    }

    .sm-text-left {
        text-align: left !important
    }

    .sm-text-right {
        text-align: right !important
    }

    .sm-vertical-align-middle {
        vertical-align: middle
    }

    .sm-vertical-align-top {
        vertical-align: top
    }

    .sm-vertical-align-bottom {
        vertical-align: bottom
    }

    .sm-absolute-middle-inherit {
        left: 0;
        top: 0;
        position: relative;
        -ms-transform: translateX(0) translateY(0);
        -moz-transform: translateX(0) translateY(0);
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        -o-transform: translateX(0) translateY(0)
    }

    .sm-background-image-left {
        background-position: left
    }

    .sm-background-image-right {
        background-position: right
    }

    .sm-background-image-center {
        background-position: center
    }

    .sm-margin-one-all {
        margin: 1%
    }

    .sm-margin-one-half-all {
        margin: 1.5%
    }

    .sm-margin-two-all {
        margin: 2%
    }

    .sm-margin-two-half-all {
        margin: 2.5%
    }

    .sm-margin-three-all {
        margin: 3%
    }

    .sm-margin-three-half-all {
        margin: 3.5%
    }

    .sm-margin-four-all {
        margin: 4%
    }

    .sm-margin-four-half-all {
        margin: 4.5%
    }

    .sm-margin-five-all {
        margin: 5%
    }

    .sm-margin-five-half-all {
        margin: 5.5%
    }

    .sm-margin-six-all {
        margin: 6%
    }

    .sm-margin-six-half-all {
        margin: 6.5%
    }

    .sm-margin-seven-all {
        margin: 7%
    }

    .sm-margin-seven-half-all {
        margin: 7.5%
    }

    .sm-margin-eight-all {
        margin: 8%
    }

    .sm-margin-eight-half-all {
        margin: 8.5%
    }

    .sm-margin-nine-all {
        margin: 9%
    }

    .sm-margin-nine-half-all {
        margin: 9.5%
    }

    .sm-margin-ten-all {
        margin: 10%
    }

    .sm-margin-ten-half-all {
        margin: 10.5%
    }

    .sm-margin-eleven-all {
        margin: 11%
    }

    .sm-margin-eleven-half-all {
        margin: 11.5%
    }

    .sm-margin-twelve-all {
        margin: 12%
    }

    .sm-margin-twelve-half-all {
        margin: 12.5%
    }

    .sm-margin-thirteen-all {
        margin: 13%
    }

    .sm-margin-thirteen-half-all {
        margin: 13.5%
    }

    .sm-margin-fourteen-all {
        margin: 14%
    }

    .sm-margin-fourteen-half-all {
        margin: 14.5%
    }

    .sm-margin-fifteen-all {
        margin: 15%
    }

    .sm-margin-fifteen-half-all {
        margin: 15.5%
    }

    .sm-margin-sixteen-all {
        margin: 16%
    }

    .sm-margin-sixteen-half-all {
        margin: 16.5%
    }

    .sm-margin-seventeen-all {
        margin: 17%
    }

    .sm-margin-seventeen-half-all {
        margin: 17.5%
    }

    .sm-margin-eighteen-all {
        margin: 18%
    }

    .sm-margin-eighteen-half-all {
        margin: 18.5%
    }

    .sm-margin-nineteen-all {
        margin: 19%
    }

    .sm-margin-nineteen-half-all {
        margin: 19.5%
    }

    .sm-margin-twenty-all {
        margin: 20%
    }

    .sm-margin-twenty-half-all {
        margin: 20.5%
    }

    .sm-margin-twenty-one-all {
        margin: 21%
    }

    .sm-margin-twenty-one-half-all {
        margin: 21.5%
    }

    .sm-margin-twenty-two-all {
        margin: 22%
    }

    .sm-margin-twenty-two-half-all {
        margin: 22.5%
    }

    .sm-margin-twenty-three-all {
        margin: 23%
    }

    .sm-margin-twenty-three-half-all {
        margin: 23.5%
    }

    .sm-margin-twenty-four-all {
        margin: 24%
    }

    .sm-margin-twenty-four-half-all {
        margin: 24.5%
    }

    .sm-margin-twenty-five-all {
        margin: 25%
    }

    .sm-margin-5px-all {
        margin: 5px !important
    }

    .sm-margin-10px-all {
        margin: 10px !important
    }

    .sm-margin-15px-all {
        margin: 15px !important
    }

    .sm-margin-20px-all {
        margin: 20px !important
    }

    .sm-margin-25px-all {
        margin: 25px !important
    }

    .sm-margin-30px-all {
        margin: 30px !important
    }

    .sm-margin-35px-all {
        margin: 35px !important
    }

    .sm-margin-40px-all {
        margin: 40px !important
    }

    .sm-margin-45px-all {
        margin: 45px !important
    }

    .sm-margin-50px-all {
        margin: 50px !important
    }

    .sm-margin-55px-all {
        margin: 55px !important
    }

    .sm-margin-60px-all {
        margin: 60px !important
    }

    .sm-margin-65px-all {
        margin: 65px !important
    }

    .sm-margin-70px-all {
        margin: 70px !important
    }

    .sm-margin-75px-all {
        margin: 75px !important
    }

    .sm-margin-80px-all {
        margin: 80px !important
    }

    .sm-margin-85px-all {
        margin: 85px !important
    }

    .sm-margin-90px-all {
        margin: 90px !important
    }

    .sm-margin-95px-all {
        margin: 95px !important
    }

    .sm-margin-100px-all {
        margin: 100px !important
    }

    .sm-no-margin {
        margin: 0 !important
    }

    .sm-no-margin-lr {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .sm-no-margin-tb {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .sm-no-margin-top {
        margin-top: 0 !important
    }

    .sm-no-margin-bottom {
        margin-bottom: 0 !important
    }

    .sm-no-margin-left {
        margin-left: 0 !important
    }

    .sm-no-margin-right {
        margin-right: 0 !important
    }

    .sm-margin-lr-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .sm-margin-auto {
        margin: 0 auto !important
    }

    .sm-margin-one-top {
        margin-top: 1%
    }

    .sm-margin-one-half-top {
        margin-top: 1.5%
    }

    .sm-margin-two-top {
        margin-top: 2%
    }

    .sm-margin-two-half-top {
        margin-top: 2.5%
    }

    .sm-margin-three-top {
        margin-top: 3%
    }

    .sm-margin-three-half-top {
        margin-top: 3.5%
    }

    .sm-margin-four-top {
        margin-top: 4%
    }

    .sm-margin-four-half-top {
        margin-top: 4.5%
    }

    .sm-margin-five-top {
        margin-top: 5%
    }

    .sm-margin-five-half-top {
        margin-top: 5.5%
    }

    .sm-margin-six-top {
        margin-top: 6%
    }

    .sm-margin-six-half-top {
        margin-top: 6.5%
    }

    .sm-margin-seven-top {
        margin-top: 7%
    }

    .sm-margin-seven-half-top {
        margin-top: 7.5%
    }

    .sm-margin-eight-top {
        margin-top: 8%
    }

    .sm-margin-eight-half-top {
        margin-top: 8.5%
    }

    .sm-margin-nine-top {
        margin-top: 9%
    }

    .sm-margin-nine-half-top {
        margin-top: 9.5%
    }

    .sm-margin-ten-top {
        margin-top: 10%
    }

    .sm-margin-ten-half-top {
        margin-top: 10.5%
    }

    .sm-margin-eleven-top {
        margin-top: 11%
    }

    .sm-margin-eleven-half-top {
        margin-top: 11.5%
    }

    .sm-margin-twelve-top {
        margin-top: 12%
    }

    .sm-margin-twelve-half-top {
        margin-top: 12.5%
    }

    .sm-margin-thirteen-top {
        margin-top: 13%
    }

    .sm-margin-thirteen-half-top {
        margin-top: 13.5%
    }

    .sm-margin-fourteen-top {
        margin-top: 14%
    }

    .sm-margin-fourteen-half-top {
        margin-top: 14.5%
    }

    .sm-margin-fifteen-top {
        margin-top: 15%
    }

    .sm-margin-fifteen-half-top {
        margin-top: 15.5%
    }

    .sm-margin-sixteen-top {
        margin-top: 16%
    }

    .sm-margin-sixteen-half-top {
        margin-top: 16.5%
    }

    .sm-margin-seventeen-top {
        margin-top: 17%
    }

    .sm-margin-seventeen-half-top {
        margin-top: 17.5%
    }

    .sm-margin-eighteen-top {
        margin-top: 18%
    }

    .sm-margin-eighteen-half-top {
        margin-top: 18.5%
    }

    .sm-margin-nineteen-top {
        margin-top: 19%
    }

    .sm-margin-nineteen-half-top {
        margin-top: 19.5%
    }

    .sm-margin-twenty-top {
        margin-top: 20%
    }

    .sm-margin-twenty-half-top {
        margin-top: 20.5%
    }

    .sm-margin-twenty-one-top {
        margin-top: 21%
    }

    .sm-margin-twenty-one-half-top {
        margin-top: 21.5%
    }

    .sm-margin-twenty-two-top {
        margin-top: 22%
    }

    .sm-margin-twenty-two-half-top {
        margin-top: 22.5%
    }

    .sm-margin-twenty-three-top {
        margin-top: 23%
    }

    .sm-margin-twenty-three-half-top {
        margin-top: 23.5%
    }

    .sm-margin-twenty-four-top {
        margin-top: 24%
    }

    .sm-margin-twenty-four-half-top {
        margin-top: 24.5%
    }

    .sm-margin-twenty-five-top {
        margin-top: 25%
    }

    .sm-margin-5px-top {
        margin-top: 5px !important
    }

    .sm-margin-8px-top {
        margin-top: 8px !important
    }

    .sm-margin-10px-top {
        margin-top: 10px !important
    }

    .sm-margin-15px-top {
        margin-top: 15px !important
    }

    .sm-margin-20px-top {
        margin-top: 20px !important
    }

    .sm-margin-25px-top {
        margin-top: 25px !important
    }

    .sm-margin-30px-top {
        margin-top: 30px !important
    }

    .sm-margin-35px-top {
        margin-top: 35px !important
    }

    .sm-margin-40px-top {
        margin-top: 40px !important
    }

    .sm-margin-45px-top {
        margin-top: 45px !important
    }

    .sm-margin-50px-top {
        margin-top: 50px !important
    }

    .sm-margin-55px-top {
        margin-top: 55px !important
    }

    .sm-margin-60px-top {
        margin-top: 60px !important
    }

    .sm-margin-65px-top {
        margin-top: 65px !important
    }

    .sm-margin-70px-top {
        margin-top: 70px !important
    }

    .sm-margin-75px-top {
        margin-top: 75px !important
    }

    .sm-margin-80px-top {
        margin-top: 80px !important
    }

    .sm-margin-85px-top {
        margin-top: 85px !important
    }

    .sm-margin-90px-top {
        margin-top: 90px !important
    }

    .sm-margin-95px-top {
        margin-top: 95px !important
    }

    .sm-margin-100px-top {
        margin-top: 100px !important
    }

    .sm-margin-one-bottom {
        margin-bottom: 1%
    }

    .sm-margin-one-half-bottom {
        margin-bottom: 1.5%
    }

    .sm-margin-two-bottom {
        margin-bottom: 2%
    }

    .sm-margin-two-half-bottom {
        margin-bottom: 2.5%
    }

    .sm-margin-three-bottom {
        margin-bottom: 3%
    }

    .sm-margin-three-half-bottom {
        margin-bottom: 3.5%
    }

    .sm-margin-four-bottom {
        margin-bottom: 4%
    }

    .sm-margin-four-half-bottom {
        margin-bottom: 4.5%
    }

    .sm-margin-five-bottom {
        margin-bottom: 5%
    }

    .sm-margin-five-half-bottom {
        margin-bottom: 5.5%
    }

    .sm-margin-six-bottom {
        margin-bottom: 6%
    }

    .sm-margin-six-half-bottom {
        margin-bottom: 6.5%
    }

    .sm-margin-seven-bottom {
        margin-bottom: 7%
    }

    .sm-margin-seven-half-bottom {
        margin-bottom: 7.5%
    }

    .sm-margin-eight-bottom {
        margin-bottom: 8%
    }

    .sm-margin-eight-half-bottom {
        margin-bottom: 8.5%
    }

    .sm-margin-nine-bottom {
        margin-bottom: 9%
    }

    .sm-margin-nine-half-bottom {
        margin-bottom: 9.5%
    }

    .sm-margin-ten-bottom {
        margin-bottom: 10%
    }

    .sm-margin-ten-half-bottom {
        margin-bottom: 10.5%
    }

    .sm-margin-eleven-bottom {
        margin-bottom: 11%
    }

    .sm-margin-eleven-half-bottom {
        margin-bottom: 11.5%
    }

    .sm-margin-twelve-bottom {
        margin-bottom: 12%
    }

    .sm-margin-twelve-half-bottom {
        margin-bottom: 12.5%
    }

    .sm-margin-thirteen-bottom {
        margin-bottom: 13%
    }

    .sm-margin-thirteen-half-bottom {
        margin-bottom: 13.5%
    }

    .sm-margin-fourteen-bottom {
        margin-bottom: 14%
    }

    .sm-margin-fourteen-half-bottom {
        margin-bottom: 14.5%
    }

    .sm-margin-fifteen-bottom {
        margin-bottom: 15%
    }

    .sm-margin-fifteen-half-bottom {
        margin-bottom: 15.5%
    }

    .sm-margin-sixteen-bottom {
        margin-bottom: 16%
    }

    .sm-margin-sixteen-half-bottom {
        margin-bottom: 16.5%
    }

    .sm-margin-seventeen-bottom {
        margin-bottom: 17%
    }

    .sm-margin-seventeen-half-bottom {
        margin-bottom: 17.5%
    }

    .sm-margin-eighteen-bottom {
        margin-bottom: 18%
    }

    .sm-margin-eighteen-half-bottom {
        margin-bottom: 18.5%
    }

    .sm-margin-nineteen-bottom {
        margin-bottom: 19%
    }

    .sm-margin-nineteen-half-bottom {
        margin-bottom: 19.5%
    }

    .sm-margin-twenty-bottom {
        margin-bottom: 20%
    }

    .sm-margin-twenty-half-bottom {
        margin-bottom: 20.5%
    }

    .sm-margin-twenty-one-bottom {
        margin-bottom: 21%
    }

    .sm-margin-twenty-one-half-bottom {
        margin-bottom: 21.5%
    }

    .sm-margin-twenty-two-bottom {
        margin-bottom: 22%
    }

    .sm-margin-twenty-two-half-bottom {
        margin-bottom: 22.5%
    }

    .sm-margin-twenty-three-bottom {
        margin-bottom: 23%
    }

    .sm-margin-twenty-three-half-bottom {
        margin-bottom: 23.5%
    }

    .sm-margin-twenty-four-bottom {
        margin-bottom: 24%
    }

    .sm-margin-twenty-four-half-bottom {
        margin-bottom: 24.5%
    }

    .sm-margin-twenty-five-bottom {
        margin-bottom: 25%
    }

    .sm-margin-5px-bottom {
        margin-bottom: 5px !important
    }

    .sm-margin-8px-bottom {
        margin-bottom: 8px !important
    }

    .sm-margin-10px-bottom {
        margin-bottom: 10px !important
    }

    .sm-margin-15px-bottom {
        margin-bottom: 15px !important
    }

    .sm-margin-20px-bottom {
        margin-bottom: 20px !important
    }

    .sm-margin-25px-bottom {
        margin-bottom: 25px !important
    }

    .sm-margin-30px-bottom {
        margin-bottom: 30px !important
    }

    .sm-margin-35px-bottom {
        margin-bottom: 35px !important
    }

    .sm-margin-40px-bottom {
        margin-bottom: 40px !important
    }

    .sm-margin-45px-bottom {
        margin-bottom: 45px !important
    }

    .sm-margin-50px-bottom {
        margin-bottom: 50px !important
    }

    .sm-margin-55px-bottom {
        margin-bottom: 55px !important
    }

    .sm-margin-60px-bottom {
        margin-bottom: 60px !important
    }

    .sm-margin-65px-bottom {
        margin-bottom: 65px !important
    }

    .sm-margin-70px-bottom {
        margin-bottom: 70px !important
    }

    .sm-margin-75px-bottom {
        margin-bottom: 75px !important
    }

    .sm-margin-80px-bottom {
        margin-bottom: 80px !important
    }

    .sm-margin-85px-bottom {
        margin-bottom: 85px !important
    }

    .sm-margin-90px-bottom {
        margin-bottom: 90px !important
    }

    .sm-margin-95px-bottom {
        margin-bottom: 95px !important
    }

    .sm-margin-100px-bottom {
        margin-bottom: 100px !important
    }

    .sm-margin-one-right {
        margin-right: 1%
    }

    .sm-margin-one-half-right {
        margin-right: 1.5%
    }

    .sm-margin-two-right {
        margin-right: 2%
    }

    .sm-margin-two-half-right {
        margin-right: 2.5%
    }

    .sm-margin-three-right {
        margin-right: 3%
    }

    .sm-margin-three-half-right {
        margin-right: 3.5%
    }

    .sm-margin-four-right {
        margin-right: 4%
    }

    .sm-margin-four-half-right {
        margin-right: 4.5%
    }

    .sm-margin-five-right {
        margin-right: 5%
    }

    .sm-margin-five-half-right {
        margin-right: 5.5%
    }

    .sm-margin-six-right {
        margin-right: 6%
    }

    .sm-margin-six-half-right {
        margin-right: 6.5%
    }

    .sm-margin-seven-right {
        margin-right: 7%
    }

    .sm-margin-seven-half-right {
        margin-right: 7.5%
    }

    .sm-margin-eight-right {
        margin-right: 8%
    }

    .sm-margin-eight-half-right {
        margin-right: 8.5%
    }

    .sm-margin-nine-right {
        margin-right: 9%
    }

    .sm-margin-nine-half-right {
        margin-right: 9.5%
    }

    .sm-margin-ten-right {
        margin-right: 10%
    }

    .sm-margin-ten-half-right {
        margin-right: 10.5%
    }

    .sm-margin-eleven-right {
        margin-right: 11%
    }

    .sm-margin-eleven-half-right {
        margin-right: 11.5%
    }

    .sm-margin-twelve-right {
        margin-right: 12%
    }

    .sm-margin-twelve-half-right {
        margin-right: 12.5%
    }

    .sm-margin-thirteen-right {
        margin-right: 13%
    }

    .sm-margin-thirteen-half-right {
        margin-right: 13.5%
    }

    .sm-margin-fourteen-right {
        margin-right: 14%
    }

    .sm-margin-fourteen-half-right {
        margin-right: 14.5%
    }

    .sm-margin-fifteen-right {
        margin-right: 15%
    }

    .sm-margin-fifteen-half-right {
        margin-right: 15.5%
    }

    .sm-margin-sixteen-right {
        margin-right: 16%
    }

    .sm-margin-sixteen-half-right {
        margin-right: 16.5%
    }

    .sm-margin-seventeen-right {
        margin-right: 17%
    }

    .sm-margin-seventeen-half-right {
        margin-right: 17.5%
    }

    .sm-margin-eighteen-right {
        margin-right: 18%
    }

    .sm-margin-eighteen-half-right {
        margin-right: 18.5%
    }

    .sm-margin-nineteen-right {
        margin-right: 19%
    }

    .sm-margin-nineteen-half-right {
        margin-right: 19.5%
    }

    .sm-margin-twenty-right {
        margin-right: 20%
    }

    .sm-margin-twenty-half-right {
        margin-right: 20.5%
    }

    .sm-margin-twenty-one-right {
        margin-right: 21%
    }

    .sm-margin-twenty-one-half-right {
        margin-right: 21.5%
    }

    .sm-margin-twenty-two-right {
        margin-right: 22%
    }

    .sm-margin-twenty-two-half-right {
        margin-right: 22.5%
    }

    .sm-margin-twenty-three-right {
        margin-right: 23%
    }

    .sm-margin-twenty-three-half-right {
        margin-right: 23.5%
    }

    .sm-margin-twenty-four-right {
        margin-right: 24%
    }

    .sm-margin-twenty-four-half-right {
        margin-right: 24.5%
    }

    .sm-margin-twenty-five-right {
        margin-right: 25%
    }

    .sm-margin-10px-right {
        margin-right: 10px !important
    }

    .sm-margin-15px-right {
        margin-right: 15px !important
    }

    .sm-margin-20px-right {
        margin-right: 20px !important
    }

    .sm-margin-25px-right {
        margin-right: 25px !important
    }

    .sm-margin-30px-right {
        margin-right: 30px !important
    }

    .sm-margin-35px-right {
        margin-right: 35px !important
    }

    .sm-margin-40px-right {
        margin-right: 40px !important
    }

    .sm-margin-45px-right {
        margin-right: 45px !important
    }

    .sm-margin-50px-right {
        margin-right: 50px !important
    }

    .sm-margin-55px-right {
        margin-right: 55px !important
    }

    .sm-margin-60px-right {
        margin-right: 60px !important
    }

    .sm-margin-65px-right {
        margin-right: 65px !important
    }

    .sm-margin-70px-right {
        margin-right: 70px !important
    }

    .sm-margin-75px-right {
        margin-right: 75px !important
    }

    .sm-margin-80px-right {
        margin-right: 80px !important
    }

    .sm-margin-85px-right {
        margin-right: 85px !important
    }

    .sm-margin-90px-right {
        margin-right: 90px !important
    }

    .sm-margin-95px-right {
        margin-right: 95px !important
    }

    .sm-margin-100px-right {
        margin-right: 100px !important
    }

    .sm-margin-one-left {
        margin-left: 1%
    }

    .sm-margin-one-half-left {
        margin-left: 1.5%
    }

    .sm-margin-two-left {
        margin-left: 2%
    }

    .sm-margin-two-half-left {
        margin-left: 2.5%
    }

    .sm-margin-three-left {
        margin-left: 3%
    }

    .sm-margin-three-half-left {
        margin-left: 3.5%
    }

    .sm-margin-four-left {
        margin-left: 4%
    }

    .sm-margin-four-half-left {
        margin-left: 4.5%
    }

    .sm-margin-five-left {
        margin-left: 5%
    }

    .sm-margin-five-half-left {
        margin-left: 5.5%
    }

    .sm-margin-six-left {
        margin-left: 6%
    }

    .sm-margin-six-half-left {
        margin-left: 6.5%
    }

    .sm-margin-seven-left {
        margin-left: 7%
    }

    .sm-margin-seven-half-left {
        margin-left: 7.5%
    }

    .sm-margin-eight-left {
        margin-left: 8%
    }

    .sm-margin-eight-half-left {
        margin-left: 8.5%
    }

    .sm-margin-nine-left {
        margin-left: 9%
    }

    .sm-margin-nine-half-left {
        margin-left: 9.5%
    }

    .sm-margin-ten-left {
        margin-left: 10%
    }

    .sm-margin-ten-half-left {
        margin-left: 10.5%
    }

    .sm-margin-eleven-left {
        margin-left: 11%
    }

    .sm-margin-eleven-half-left {
        margin-left: 11.5%
    }

    .sm-margin-twelve-left {
        margin-left: 12%
    }

    .sm-margin-twelve-half-left {
        margin-left: 12.5%
    }

    .sm-margin-thirteen-left {
        margin-left: 13%
    }

    .sm-margin-thirteen-half-left {
        margin-left: 13.5%
    }

    .sm-margin-fourteen-left {
        margin-left: 14%
    }

    .sm-margin-fourteen-half-left {
        margin-left: 14.5%
    }

    .sm-margin-fifteen-left {
        margin-left: 15%
    }

    .sm-margin-fifteen-half-left {
        margin-left: 15.5%
    }

    .sm-margin-sixteen-left {
        margin-left: 16%
    }

    .sm-margin-sixteen-half-left {
        margin-left: 16.5%
    }

    .sm-margin-seventeen-left {
        margin-left: 17%
    }

    .sm-margin-seventeen-half-left {
        margin-left: 17.5%
    }

    .sm-margin-eighteen-left {
        margin-left: 18%
    }

    .sm-margin-eighteen-half-left {
        margin-left: 18.5%
    }

    .sm-margin-nineteen-left {
        margin-left: 19%
    }

    .sm-margin-nineteen-half-left {
        margin-left: 19.5%
    }

    .sm-margin-twenty-left {
        margin-left: 20%
    }

    .sm-margin-twenty-half-left {
        margin-left: 20.5%
    }

    .sm-margin-twenty-one-left {
        margin-left: 21%
    }

    .sm-margin-twenty-one-half-left {
        margin-left: 21.5%
    }

    .sm-margin-twenty-two-left {
        margin-left: 22%
    }

    .sm-margin-twenty-two-half-left {
        margin-left: 22.5%
    }

    .sm-margin-twenty-three-left {
        margin-left: 23%
    }

    .sm-margin-twenty-three-half-left {
        margin-left: 23.5%
    }

    .sm-margin-twenty-four-left {
        margin-left: 24%
    }

    .sm-margin-twenty-four-half-left {
        margin-left: 24.5%
    }

    .sm-margin-twenty-five-left {
        margin-left: 25%
    }

    .sm-margin-5px-left {
        margin-left: 5px !important
    }

    .sm-margin-10px-left {
        margin-left: 10px !important
    }

    .sm-margin-15px-left {
        margin-left: 15px !important
    }

    .sm-margin-20px-left {
        margin-left: 20px !important
    }

    .sm-margin-25px-left {
        margin-left: 25px !important
    }

    .sm-margin-30px-left {
        margin-left: 30px !important
    }

    .sm-margin-35px-left {
        margin-left: 35px !important
    }

    .sm-margin-40px-left {
        margin-left: 40px !important
    }

    .sm-margin-45px-left {
        margin-left: 45px !important
    }

    .sm-margin-50px-left {
        margin-left: 50px !important
    }

    .sm-margin-55px-left {
        margin-left: 55px !important
    }

    .sm-margin-60px-left {
        margin-left: 60px !important
    }

    .sm-margin-65px-left {
        margin-left: 65px !important
    }

    .sm-margin-70px-left {
        margin-left: 70px !important
    }

    .sm-margin-75px-left {
        margin-left: 75px !important
    }

    .sm-margin-80px-left {
        margin-left: 80px !important
    }

    .sm-margin-85px-left {
        margin-left: 85px !important
    }

    .sm-margin-90px-left {
        margin-left: 90px !important
    }

    .sm-margin-95px-left {
        margin-left: 95px !important
    }

    .sm-margin-100px-left {
        margin-left: 100px !important
    }

    .sm-margin-one-lr {
        margin-left: 1%;
        margin-right: 1%
    }

    .sm-margin-one-lr {
        margin-left: 1.5%;
        margin-right: 1.5%
    }

    .sm-margin-two-lr {
        margin-left: 2%;
        margin-right: 2%
    }

    .sm-margin-two-lr {
        margin-left: 2.5%;
        margin-right: 2.5%
    }

    .sm-margin-three-lr {
        margin-left: 3%;
        margin-right: 3%
    }

    .sm-margin-three-lr {
        margin-left: 3.5%;
        margin-right: 3.5%
    }

    .sm-margin-four-lr {
        margin-left: 4%;
        margin-right: 4%
    }

    .sm-margin-four-lr {
        margin-left: 4.5%;
        margin-right: 4.5%
    }

    .sm-margin-five-lr {
        margin-left: 5%;
        margin-right: 5%
    }

    .sm-margin-five-lr {
        margin-left: 5.5%;
        margin-right: 5.5%
    }

    .sm-margin-six-lr {
        margin-left: 6%;
        margin-right: 6%
    }

    .sm-margin-six-lr {
        margin-left: 6.5%;
        margin-right: 6.5%
    }

    .sm-margin-seven-lr {
        margin-left: 7%;
        margin-right: 7%
    }

    .sm-margin-seven-lr {
        margin-left: 7.5%;
        margin-right: 7.5%
    }

    .sm-margin-eight-lr {
        margin-left: 8%;
        margin-right: 8%
    }

    .sm-margin-eight-lr {
        margin-left: 8.5%;
        margin-right: 8.5%
    }

    .sm-margin-nine-lr {
        margin-left: 9%;
        margin-right: 9%
    }

    .sm-margin-nine-lr {
        margin-left: 9.5%;
        margin-right: 9.5%
    }

    .sm-margin-ten-lr {
        margin-left: 10%;
        margin-right: 10%
    }

    .sm-margin-ten-lr {
        margin-left: 10.5%;
        margin-right: 10.5%
    }

    .sm-margin-eleven-lr {
        margin-left: 11%;
        margin-right: 11%
    }

    .sm-margin-eleven-lr {
        margin-left: 11.5%;
        margin-right: 11.5%
    }

    .sm-margin-twelve-lr {
        margin-left: 12%;
        margin-right: 12%
    }

    .sm-margin-twelve-lr {
        margin-left: 12.5%;
        margin-right: 12.5%
    }

    .sm-margin-thirteen-lr {
        margin-left: 13%;
        margin-right: 13%
    }

    .sm-margin-thirteen-half-lr {
        margin-left: 13.5%;
        margin-right: 13.5%
    }

    .sm-margin-fourteen-lr {
        margin-left: 14%;
        margin-right: 14%
    }

    .sm-margin-fourteen-half-lr {
        margin-left: 14.5%;
        margin-right: 14.5%
    }

    .sm-margin-fifteen-lr {
        margin-left: 15%;
        margin-right: 15%
    }

    .sm-margin-fifteen-half-lr {
        margin-left: 15.5%;
        margin-right: 15.5%
    }

    .sm-margin-sixteen-lr {
        margin-left: 16%;
        margin-right: 16%
    }

    .sm-margin-sixteen-half-lr {
        margin-left: 16.5%;
        margin-right: 16.5%
    }

    .sm-margin-seventeen-lr {
        margin-left: 17%;
        margin-right: 17%
    }

    .sm-margin-seventeen-half-lr {
        margin-left: 17.5%;
        margin-right: 17.5%
    }

    .sm-margin-eighteen-lr {
        margin-left: 18%;
        margin-right: 18%
    }

    .sm-margin-eighteen-half-lr {
        margin-left: 18.5%;
        margin-right: 18.5%
    }

    .sm-margin-nineteen-lr {
        margin-left: 19%;
        margin-right: 19%
    }

    .sm-margin-nineteen-half-lr {
        margin-left: 19.5%;
        margin-right: 19.5%
    }

    .sm-margin-twenty-lr {
        margin-left: 20%;
        margin-right: 20%
    }

    .sm-margin-twenty-half-lr {
        margin-left: 20.5%;
        margin-right: 20.5%
    }

    .sm-margin-twenty-one-lr {
        margin-left: 21%;
        margin-right: 21%
    }

    .sm-margin-twenty-one-half-lr {
        margin-left: 21.5%;
        margin-right: 21.5%
    }

    .sm-margin-twenty-two-lr {
        margin-left: 22%;
        margin-right: 22%
    }

    .sm-margin-twenty-two-half-lr {
        margin-left: 22.5%;
        margin-right: 22.5%
    }

    .sm-margin-twenty-three-lr {
        margin-left: 23%;
        margin-right: 23%
    }

    .sm-margin-twenty-three-half-lr {
        margin-left: 23.5%;
        margin-right: 23.5%
    }

    .sm-margin-twenty-four-lr {
        margin-left: 24%;
        margin-right: 24%
    }

    .sm-margin-twenty-four-half-lr {
        margin-left: 24.5%;
        margin-right: 24.5%
    }

    .sm-margin-twenty-five-lr {
        margin-left: 25%;
        margin-right: 25%
    }

    .sm-margin-5px-lr {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .sm-margin-10px-lr {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .sm-margin-15px-lr {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .sm-margin-20px-lr {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .sm-margin-25px-lr {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .sm-margin-30px-lr {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .sm-margin-35px-lr {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .sm-margin-40px-lr {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .sm-margin-45px-lr {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .sm-margin-50px-lr {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .sm-margin-55px-lr {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .sm-margin-60px-lr {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .sm-margin-65px-lr {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .sm-margin-70px-lr {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .sm-margin-75px-lr {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .sm-margin-80px-lr {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .sm-margin-85px-lr {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .sm-margin-90px-lr {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .sm-margin-95px-lr {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .sm-margin-100px-lr {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .sm-margin-one-tb {
        margin-top: 1%;
        margin-bottom: 1%
    }

    .sm-margin-one-tb {
        margin-top: 1.5%;
        margin-bottom: 1.5%
    }

    .sm-margin-two-tb {
        margin-top: 2%;
        margin-bottom: 2%
    }

    .sm-margin-two-tb {
        margin-top: 2.5%;
        margin-bottom: 2.5%
    }

    .sm-margin-three-tb {
        margin-top: 3%;
        margin-bottom: 3%
    }

    .sm-margin-three-tb {
        margin-top: 3.5%;
        margin-bottom: 3.5%
    }

    .sm-margin-four-tb {
        margin-top: 4%;
        margin-bottom: 4%
    }

    .sm-margin-four-tb {
        margin-top: 4.5%;
        margin-bottom: 4.5%
    }

    .sm-margin-five-tb {
        margin-top: 5%;
        margin-bottom: 5%
    }

    .sm-margin-five-tb {
        margin-top: 5.5%;
        margin-bottom: 5.5%
    }

    .sm-margin-six-tb {
        margin-top: 6%;
        margin-bottom: 6%
    }

    .sm-margin-six-tb {
        margin-top: 6.5%;
        margin-bottom: 6.5%
    }

    .sm-margin-seven-tb {
        margin-top: 7%;
        margin-bottom: 7%
    }

    .sm-margin-seven-tb {
        margin-top: 7.5%;
        margin-bottom: 7.5%
    }

    .sm-margin-eight-tb {
        margin-top: 8%;
        margin-bottom: 8%
    }

    .sm-margin-eight-tb {
        margin-top: 8.5%;
        margin-bottom: 8.5%
    }

    .sm-margin-nine-tb {
        margin-top: 9%;
        margin-bottom: 9%
    }

    .sm-margin-nine-tb {
        margin-top: 9.5%;
        margin-bottom: 9.5%
    }

    .sm-margin-ten-tb {
        margin-top: 10%;
        margin-bottom: 10%
    }

    .sm-margin-ten-tb {
        margin-top: 10.5%;
        margin-bottom: 10.5%
    }

    .sm-margin-eleven-tb {
        margin-top: 11%;
        margin-bottom: 11%
    }

    .sm-margin-eleven-tb {
        margin-top: 11.5%;
        margin-bottom: 11.5%
    }

    .sm-margin-twelve-tb {
        margin-top: 12%;
        margin-bottom: 12%
    }

    .sm-margin-twelve-tb {
        margin-top: 12.5%;
        margin-bottom: 12.5%
    }

    .sm-margin-thirteen-tb {
        margin-top: 13%;
        margin-bottom: 13%
    }

    .sm-margin-thirteen-half-tb {
        margin-top: 13.5%;
        margin-bottom: 13.5%
    }

    .sm-margin-fourteen-tb {
        margin-top: 14%;
        margin-bottom: 14%
    }

    .sm-margin-fourteen-half-tb {
        margin-top: 14.5%;
        margin-bottom: 14.5%
    }

    .sm-margin-fifteen-tb {
        margin-top: 15%;
        margin-bottom: 15%
    }

    .sm-margin-fifteen-half-tb {
        margin-top: 15.5%;
        margin-bottom: 15.5%
    }

    .sm-margin-sixteen-tb {
        margin-top: 16%;
        margin-bottom: 16%
    }

    .sm-margin-sixteen-half-tb {
        margin-top: 16.5%;
        margin-bottom: 16.5%
    }

    .sm-margin-seventeen-tb {
        margin-top: 17%;
        margin-bottom: 17%
    }

    .sm-margin-seventeen-half-tb {
        margin-top: 17.5%;
        margin-bottom: 17.5%
    }

    .sm-margin-eighteen-tb {
        margin-top: 18%;
        margin-bottom: 18%
    }

    .sm-margin-eighteen-half-tb {
        margin-top: 18.5%;
        margin-bottom: 18.5%
    }

    .sm-margin-nineteen-tb {
        margin-top: 19%;
        margin-bottom: 19%
    }

    .sm-margin-nineteen-half-tb {
        margin-top: 19.5%;
        margin-bottom: 19.5%
    }

    .sm-margin-twenty-tb {
        margin-top: 20%;
        margin-bottom: 20%
    }

    .sm-margin-twenty-half-tb {
        margin-top: 20.5%;
        margin-bottom: 20.5%
    }

    .sm-margin-twenty-one-tb {
        margin-top: 21%;
        margin-bottom: 21%
    }

    .sm-margin-twenty-one-half-tb {
        margin-top: 21.5%;
        margin-bottom: 21.5%
    }

    .sm-margin-twenty-two-tb {
        margin-top: 22%;
        margin-bottom: 22%
    }

    .sm-margin-twenty-two-half-tb {
        margin-top: 22.5%;
        margin-bottom: 22.5%
    }

    .sm-margin-twenty-three-tb {
        margin-top: 23%;
        margin-bottom: 23%
    }

    .sm-margin-twenty-three-half-tb {
        margin-top: 23.5%;
        margin-bottom: 23.5%
    }

    .sm-margin-twenty-four-tb {
        margin-top: 24%;
        margin-bottom: 24%
    }

    .sm-margin-twenty-four-half-tb {
        margin-top: 24.5%;
        margin-bottom: 24.5%
    }

    .sm-margin-twenty-five-tb {
        margin-top: 25%;
        margin-bottom: 25%
    }

    .sm-margin-5px-tb {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .sm-margin-10px-tb {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .sm-margin-15px-tb {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .sm-margin-20px-tb {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .sm-margin-25px-tb {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .sm-margin-30px-tb {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .sm-margin-35px-tb {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .sm-margin-40px-tb {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .sm-margin-45px-tb {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .sm-margin-50px-tb {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .sm-margin-55px-tb {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .sm-margin-60px-tb {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .sm-margin-65px-tb {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .sm-margin-70px-tb {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .sm-margin-75px-tb {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .sm-margin-80px-tb {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .sm-margin-85px-tb {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .sm-margin-90px-tb {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .sm-margin-95px-tb {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .sm-margin-100px-tb {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .sm-no-padding {
        padding: 0 !important
    }

    .sm-no-padding-lr {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .sm-no-padding-tb {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .sm-no-padding-top {
        padding-top: 0 !important
    }

    .sm-no-padding-bottom {
        padding-bottom: 0 !important
    }

    .sm-no-padding-left {
        padding-left: 0 !important
    }

    .sm-no-padding-right {
        padding-right: 0 !important
    }

    .sm-padding-one-all {
        padding: 1%
    }

    .sm-padding-one-half-all {
        padding: 1.5%
    }

    .sm-padding-two-all {
        padding: 2%
    }

    .sm-padding-two-half-all {
        padding: 2.5%
    }

    .sm-padding-three-all {
        padding: 3%
    }

    .sm-padding-three-half-all {
        padding: 3.5%
    }

    .sm-padding-four-all {
        padding: 4%
    }

    .sm-padding-four-half-all {
        padding: 4.5%
    }

    .sm-padding-five-all {
        padding: 5%
    }

    .sm-padding-five-half-all {
        padding: 5.5%
    }

    .sm-padding-six-all {
        padding: 6%
    }

    .sm-padding-six-half-all {
        padding: 6.5%
    }

    .sm-padding-seven-all {
        padding: 7%
    }

    .sm-padding-seven-half-all {
        padding: 7.5%
    }

    .sm-padding-eight-all {
        padding: 8%
    }

    .sm-padding-eight-half-all {
        padding: 8.5%
    }

    .sm-padding-nine-all {
        padding: 9%
    }

    .sm-padding-nine-half-all {
        padding: 9.5%
    }

    .sm-padding-ten-all {
        padding: 10%
    }

    .sm-padding-ten-half-all {
        padding: 10.5%
    }

    .sm-padding-eleven-all {
        padding: 11%
    }

    .sm-padding-eleven-half-all {
        padding: 11.5%
    }

    .sm-padding-twelve-all {
        padding: 12%
    }

    .sm-padding-twelve-half-all {
        padding: 12.5%
    }

    .sm-padding-thirteen-all {
        padding: 13%
    }

    .sm-padding-thirteen-half-all {
        padding: 13.5%
    }

    .sm-padding-fourteen-all {
        padding: 14%
    }

    .sm-padding-fourteen-half-all {
        padding: 14.5%
    }

    .sm-padding-fifteen-all {
        padding: 15%
    }

    .sm-padding-fifteen-half-all {
        padding: 15.5%
    }

    .sm-padding-sixteen-all {
        padding: 16%
    }

    .sm-padding-sixteen-half-all {
        padding: 16.5%
    }

    .sm-padding-seventeen-all {
        padding: 17%
    }

    .sm-padding-seventeen-half-all {
        padding: 17.5%
    }

    .sm-padding-eighteen-all {
        padding: 18%
    }

    .sm-padding-eighteen-half-all {
        padding: 18.5%
    }

    .sm-padding-nineteen-all {
        padding: 19%
    }

    .sm-padding-nineteen-half-all {
        padding: 19.5%
    }

    .sm-padding-twenty-all {
        padding: 20%
    }

    .sm-padding-twenty-half-all {
        padding: 20.5%
    }

    .sm-padding-twenty-one-all {
        padding: 21%
    }

    .sm-padding-twenty-one-half-all {
        padding: 21.5%
    }

    .sm-padding-twenty-two-all {
        padding: 22%
    }

    .sm-padding-twenty-two-half-all {
        padding: 22.5%
    }

    .sm-padding-twenty-three-all {
        padding: 23%
    }

    .sm-padding-twenty-three-half-all {
        padding: 23.5%
    }

    .sm-padding-twenty-four-all {
        padding: 24%
    }

    .sm-padding-twenty-four-half-all {
        padding: 24.5%
    }

    .sm-padding-twenty-five-all {
        padding: 25%
    }

    .sm-padding-5px-all {
        padding: 5px !important
    }

    .sm-padding-10px-all {
        padding: 10px !important
    }

    .sm-padding-15px-all {
        padding: 15px !important
    }

    .sm-padding-20px-all {
        padding: 20px !important
    }

    .sm-padding-25px-all {
        padding: 25px !important
    }

    .sm-padding-30px-all {
        padding: 30px !important
    }

    .sm-padding-35px-all {
        padding: 35px !important
    }

    .sm-padding-40px-all {
        padding: 40px !important
    }

    .sm-padding-45px-all {
        padding: 45px !important
    }

    .sm-padding-50px-all {
        padding: 50px !important
    }

    .sm-padding-55px-all {
        padding: 55px !important
    }

    .sm-padding-60px-all {
        padding: 60px !important
    }

    .sm-padding-65px-all {
        padding: 65px !important
    }

    .sm-padding-70px-all {
        padding: 70px !important
    }

    .sm-padding-75px-all {
        padding: 75px !important
    }

    .sm-padding-80px-all {
        padding: 80px !important
    }

    .sm-padding-85px-all {
        padding: 85px !important
    }

    .sm-padding-90px-all {
        padding: 90px !important
    }

    .sm-padding-95px-all {
        padding: 95px !important
    }

    .sm-padding-100px-all {
        padding: 100px !important
    }

    .sm-padding-one-top {
        padding-top: 1%
    }

    .sm-padding-one-half-top {
        padding-top: 1.5%
    }

    .sm-padding-two-top {
        padding-top: 2%
    }

    .sm-padding-two-half-top {
        padding-top: 2.5%
    }

    .sm-padding-three-top {
        padding-top: 3%
    }

    .sm-padding-three-half-top {
        padding-top: 3.5%
    }

    .sm-padding-four-top {
        padding-top: 4%
    }

    .sm-padding-four-half-top {
        padding-top: 4.5%
    }

    .sm-padding-five-top {
        padding-top: 5%
    }

    .sm-padding-five-half-top {
        padding-top: 5.5%
    }

    .sm-padding-six-top {
        padding-top: 6%
    }

    .sm-padding-six-half-top {
        padding-top: 6.5%
    }

    .sm-padding-seven-top {
        padding-top: 7%
    }

    .sm-padding-seven-half-top {
        padding-top: 7.5%
    }

    .sm-padding-eight-top {
        padding-top: 8%
    }

    .sm-padding-eight-half-top {
        padding-top: 8.5%
    }

    .sm-padding-nine-top {
        padding-top: 9%
    }

    .sm-padding-nine-half-top {
        padding-top: 9.5%
    }

    .sm-padding-ten-top {
        padding-top: 10%
    }

    .sm-padding-ten-half-top {
        padding-top: 10.5%
    }

    .sm-padding-eleven-top {
        padding-top: 11%
    }

    .sm-padding-eleven-half-top {
        padding-top: 11.5%
    }

    .sm-padding-twelve-top {
        padding-top: 12%
    }

    .sm-padding-twelve-half-top {
        padding-top: 12.5%
    }

    .sm-padding-thirteen-top {
        padding-top: 13%
    }

    .sm-padding-thirteen-half-top {
        padding-top: 13.5%
    }

    .sm-padding-fourteen-top {
        padding-top: 14%
    }

    .sm-padding-fourteen-half-top {
        padding-top: 14.5%
    }

    .sm-padding-fifteen-top {
        padding-top: 15%
    }

    .sm-padding-fifteen-half-top {
        padding-top: 15.5%
    }

    .sm-padding-sixteen-top {
        padding-top: 16%
    }

    .sm-padding-sixteen-half-top {
        padding-top: 16.5%
    }

    .sm-padding-seventeen-top {
        padding-top: 17%
    }

    .sm-padding-seventeen-half-top {
        padding-top: 17.5%
    }

    .sm-padding-eighteen-top {
        padding-top: 18%
    }

    .sm-padding-eighteen-half-top {
        padding-top: 18.5%
    }

    .sm-padding-nineteen-top {
        padding-top: 19%
    }

    .sm-padding-nineteen-half-top {
        padding-top: 19.5%
    }

    .sm-padding-twenty-top {
        padding-top: 20%
    }

    .sm-padding-twenty-half-top {
        padding-top: 20.5%
    }

    .sm-padding-twenty-one-top {
        padding-top: 21%
    }

    .sm-padding-twenty-one-half-top {
        padding-top: 21.5%
    }

    .sm-padding-twenty-two-top {
        padding-top: 22%
    }

    .sm-padding-twenty-two-half-top {
        padding-top: 22.5%
    }

    .sm-padding-twenty-three-top {
        padding-top: 23%
    }

    .sm-padding-twenty-three-half-top {
        padding-top: 23.5%
    }

    .sm-padding-twenty-four-top {
        padding-top: 24%
    }

    .sm-padding-twenty-four-half-top {
        padding-top: 24.5%
    }

    .sm-padding-twenty-five-top {
        padding-top: 25%
    }

    .sm-padding-5px-top {
        padding-top: 5px !important
    }

    .sm-padding-10px-top {
        padding-top: 10px !important
    }

    .sm-padding-15px-top {
        padding-top: 15px !important
    }

    .sm-padding-20px-top {
        padding-top: 20px !important
    }

    .sm-padding-25px-top {
        padding-top: 25px !important
    }

    .sm-padding-30px-top {
        padding-top: 30px !important
    }

    .sm-padding-35px-top {
        padding-top: 35px !important
    }

    .sm-padding-40px-top {
        padding-top: 40px !important
    }

    .sm-padding-45px-top {
        padding-top: 45px !important
    }

    .sm-padding-50px-top {
        padding-top: 50px !important
    }

    .sm-padding-55px-top {
        padding-top: 55px !important
    }

    .sm-padding-60px-top {
        padding-top: 60px !important
    }

    .sm-padding-65px-top {
        padding-top: 65px !important
    }

    .sm-padding-70px-top {
        padding-top: 70px !important
    }

    .sm-padding-75px-top {
        padding-top: 75px !important
    }

    .sm-padding-80px-top {
        padding-top: 80px !important
    }

    .sm-padding-85px-top {
        padding-top: 85px !important
    }

    .sm-padding-90px-top {
        padding-top: 90px !important
    }

    .sm-padding-95px-top {
        padding-top: 95px !important
    }

    .sm-padding-100px-top {
        padding-top: 100px !important
    }

    .sm-padding-one-bottom {
        padding-bottom: 1%
    }

    .sm-padding-one-half-bottom {
        padding-bottom: 1.5%
    }

    .sm-padding-two-bottom {
        padding-bottom: 2%
    }

    .sm-padding-two-half-bottom {
        padding-bottom: 2.5%
    }

    .sm-padding-three-bottom {
        padding-bottom: 3%
    }

    .sm-padding-three-half-bottom {
        padding-bottom: 3.5%
    }

    .sm-padding-four-bottom {
        padding-bottom: 4%
    }

    .sm-padding-four-half-bottom {
        padding-bottom: 4.5%
    }

    .sm-padding-five-bottom {
        padding-bottom: 5%
    }

    .sm-padding-five-half-bottom {
        padding-bottom: 5.5%
    }

    .sm-padding-six-bottom {
        padding-bottom: 6%
    }

    .sm-padding-six-half-bottom {
        padding-bottom: 6.5%
    }

    .sm-padding-seven-bottom {
        padding-bottom: 7%
    }

    .sm-padding-seven-half-bottom {
        padding-bottom: 7.5%
    }

    .sm-padding-eight-bottom {
        padding-bottom: 8%
    }

    .sm-padding-eight-half-bottom {
        padding-bottom: 8.5%
    }

    .sm-padding-nine-bottom {
        padding-bottom: 9%
    }

    .sm-padding-nine-half-bottom {
        padding-bottom: 9.5%
    }

    .sm-padding-ten-bottom {
        padding-bottom: 10%
    }

    .sm-padding-ten-half-bottom {
        padding-bottom: 10.5%
    }

    .sm-padding-eleven-bottom {
        padding-bottom: 11%
    }

    .sm-padding-eleven-half-bottom {
        padding-bottom: 11.5%
    }

    .sm-padding-twelve-bottom {
        padding-bottom: 12%
    }

    .sm-padding-twelve-half-bottom {
        padding-bottom: 12.5%
    }

    .sm-padding-thirteen-bottom {
        padding-bottom: 13%
    }

    .sm-padding-thirteen-half-bottom {
        padding-bottom: 13.5%
    }

    .sm-padding-fourteen-bottom {
        padding-bottom: 14%
    }

    .sm-padding-fourteen-half-bottom {
        padding-bottom: 14.5%
    }

    .sm-padding-fifteen-bottom {
        padding-bottom: 15%
    }

    .sm-padding-fifteen-half-bottom {
        padding-bottom: 15.5%
    }

    .sm-padding-sixteen-bottom {
        padding-bottom: 16%
    }

    .sm-padding-sixteen-half-bottom {
        padding-bottom: 16.5%
    }

    .sm-padding-seventeen-bottom {
        padding-bottom: 17%
    }

    .sm-padding-seventeen-half-bottom {
        padding-bottom: 17.5%
    }

    .sm-padding-eighteen-bottom {
        padding-bottom: 18%
    }

    .sm-padding-eighteen-half-bottom {
        padding-bottom: 18.5%
    }

    .sm-padding-nineteen-bottom {
        padding-bottom: 19%
    }

    .sm-padding-nineteen-half-bottom {
        padding-bottom: 19.5%
    }

    .sm-padding-twenty-bottom {
        padding-bottom: 20%
    }

    .sm-padding-twenty-half-bottom {
        padding-bottom: 20.5%
    }

    .sm-padding-twenty-one-bottom {
        padding-bottom: 21%
    }

    .sm-padding-twenty-one-half-bottom {
        padding-bottom: 21.5%
    }

    .sm-padding-twenty-two-bottom {
        padding-bottom: 22%
    }

    .sm-padding-twenty-two-half-bottom {
        padding-bottom: 22.5%
    }

    .sm-padding-twenty-three-bottom {
        padding-bottom: 23%
    }

    .sm-padding-twenty-three-half-bottom {
        padding-bottom: 23.5%
    }

    .sm-padding-twenty-four-bottom {
        padding-bottom: 24%
    }

    .sm-padding-twenty-four-half-bottom {
        padding-bottom: 24.5%
    }

    .sm-padding-twenty-five-bottom {
        padding-bottom: 25%
    }

    .sm-padding-5px-bottom {
        padding-bottom: 5px !important
    }

    .sm-padding-10px-bottom {
        padding-bottom: 10px !important
    }

    .sm-padding-15px-bottom {
        padding-bottom: 15px !important
    }

    .sm-padding-20px-bottom {
        padding-bottom: 20px !important
    }

    .sm-padding-25px-bottom {
        padding-bottom: 25px !important
    }

    .sm-padding-30px-bottom {
        padding-bottom: 30px !important
    }

    .sm-padding-35px-bottom {
        padding-bottom: 35px !important
    }

    .sm-padding-40px-bottom {
        padding-bottom: 40px !important
    }

    .sm-padding-45px-bottom {
        padding-bottom: 45px !important
    }

    .sm-padding-50px-bottom {
        padding-bottom: 50px !important
    }

    .sm-padding-55px-bottom {
        padding-bottom: 55px !important
    }

    .sm-padding-60px-bottom {
        padding-bottom: 60px !important
    }

    .sm-padding-65px-bottom {
        padding-bottom: 65px !important
    }

    .sm-padding-70px-bottom {
        padding-bottom: 70px !important
    }

    .sm-padding-75px-bottom {
        padding-bottom: 75px !important
    }

    .sm-padding-80px-bottom {
        padding-bottom: 80px !important
    }

    .sm-padding-85px-bottom {
        padding-bottom: 85px !important
    }

    .sm-padding-90px-bottom {
        padding-bottom: 90px !important
    }

    .sm-padding-95px-bottom {
        padding-bottom: 95px !important
    }

    .sm-padding-100px-bottom {
        padding-bottom: 100px !important
    }

    .sm-padding-one-right {
        padding-right: 1%
    }

    .sm-padding-one-half-right {
        padding-right: 1.5%
    }

    .sm-padding-two-right {
        padding-right: 2%
    }

    .sm-padding-two-half-right {
        padding-right: 2.5%
    }

    .sm-padding-three-right {
        padding-right: 3%
    }

    .sm-padding-three-half-right {
        padding-right: 3.5%
    }

    .sm-padding-four-right {
        padding-right: 4%
    }

    .sm-padding-four-half-right {
        padding-right: 4.5%
    }

    .sm-padding-five-right {
        padding-right: 5%
    }

    .sm-padding-five-half-right {
        padding-right: 5.5%
    }

    .sm-padding-six-right {
        padding-right: 6%
    }

    .sm-padding-six-half-right {
        padding-right: 6.5%
    }

    .sm-padding-seven-right {
        padding-right: 7%
    }

    .sm-padding-seven-half-right {
        padding-right: 7.5%
    }

    .sm-padding-eight-right {
        padding-right: 8%
    }

    .sm-padding-eight-half-right {
        padding-right: 8.5%
    }

    .sm-padding-nine-right {
        padding-right: 9%
    }

    .sm-padding-nine-half-right {
        padding-right: 9.5%
    }

    .sm-padding-ten-right {
        padding-right: 10%
    }

    .sm-padding-ten-half-right {
        padding-right: 10.5%
    }

    .sm-padding-eleven-right {
        padding-right: 11%
    }

    .sm-padding-eleven-half-right {
        padding-right: 11.5%
    }

    .sm-padding-twelve-right {
        padding-right: 12%
    }

    .sm-padding-twelve-half-right {
        padding-right: 12.5%
    }

    .sm-padding-thirteen-right {
        padding-right: 13%
    }

    .sm-padding-thirteen-half-right {
        padding-right: 13.5%
    }

    .sm-padding-fourteen-right {
        padding-right: 14%
    }

    .sm-padding-fourteen-half-right {
        padding-right: 14.5%
    }

    .sm-padding-fifteen-right {
        padding-right: 15%
    }

    .sm-padding-fifteen-half-right {
        padding-right: 15.5%
    }

    .sm-padding-sixteen-right {
        padding-right: 16%
    }

    .sm-padding-sixteen-half-right {
        padding-right: 16.5%
    }

    .sm-padding-seventeen-right {
        padding-right: 17%
    }

    .sm-padding-seventeen-half-right {
        padding-right: 17.5%
    }

    .sm-padding-eighteen-right {
        padding-right: 18%
    }

    .sm-padding-eighteen-half-right {
        padding-right: 18.5%
    }

    .sm-padding-nineteen-right {
        padding-right: 19%
    }

    .sm-padding-nineteen-half-right {
        padding-right: 19.5%
    }

    .sm-padding-twenty-right {
        padding-right: 20%
    }

    .sm-padding-twenty-half-right {
        padding-right: 20.5%
    }

    .sm-padding-twenty-one-right {
        padding-right: 21%
    }

    .sm-padding-twenty-one-half-right {
        padding-right: 21.5%
    }

    .sm-padding-twenty-two-right {
        padding-right: 22%
    }

    .sm-padding-twenty-two-half-right {
        padding-right: 22.5%
    }

    .sm-padding-twenty-three-right {
        padding-right: 23%
    }

    .sm-padding-twenty-three-half-right {
        padding-right: 23.5%
    }

    .sm-padding-twenty-four-right {
        padding-right: 24%
    }

    .sm-padding-twenty-four-half-right {
        padding-right: 24.5%
    }

    .sm-padding-twenty-five-right {
        padding-right: 25%
    }

    .sm-padding-5px-right {
        padding-right: 5px !important
    }

    .sm-padding-10px-right {
        padding-right: 10px !important
    }

    .sm-padding-15px-right {
        padding-right: 15px !important
    }

    .sm-padding-20px-right {
        padding-right: 20px !important
    }

    .sm-padding-25px-right {
        padding-right: 25px !important
    }

    .sm-padding-30px-right {
        padding-right: 30px !important
    }

    .sm-padding-35px-right {
        padding-right: 35px !important
    }

    .sm-padding-40px-right {
        padding-right: 40px !important
    }

    .sm-padding-45px-right {
        padding-right: 45px !important
    }

    .sm-padding-50px-right {
        padding-right: 50px !important
    }

    .sm-padding-55px-right {
        padding-right: 55px !important
    }

    .sm-padding-60px-right {
        padding-right: 60px !important
    }

    .sm-padding-65px-right {
        padding-right: 65px !important
    }

    .sm-padding-70px-right {
        padding-right: 70px !important
    }

    .sm-padding-75px-right {
        padding-right: 75px !important
    }

    .sm-padding-80px-right {
        padding-right: 80px !important
    }

    .sm-padding-85px-right {
        padding-right: 85px !important
    }

    .sm-padding-90px-right {
        padding-right: 90px !important
    }

    .sm-padding-95px-right {
        padding-right: 95px !important
    }

    .sm-padding-100px-right {
        padding-right: 100px !important
    }

    .sm-padding-one-left {
        padding-left: 1%
    }

    .sm-padding-one-half-left {
        padding-left: 1.5%
    }

    .sm-padding-two-left {
        padding-left: 2%
    }

    .sm-padding-two-half-left {
        padding-left: 2.5%
    }

    .sm-padding-three-left {
        padding-left: 3%
    }

    .sm-padding-three-half-left {
        padding-left: 3.5%
    }

    .sm-padding-four-left {
        padding-left: 4%
    }

    .sm-padding-four-half-left {
        padding-left: 4.5%
    }

    .sm-padding-five-left {
        padding-left: 5%
    }

    .sm-padding-five-half-left {
        padding-left: 5.5%
    }

    .sm-padding-six-left {
        padding-left: 6%
    }

    .sm-padding-six-half-left {
        padding-left: 6.5%
    }

    .sm-padding-seven-left {
        padding-left: 7%
    }

    .sm-padding-seven-half-left {
        padding-left: 7.5%
    }

    .sm-padding-eight-left {
        padding-left: 8%
    }

    .sm-padding-eight-half-left {
        padding-left: 8.5%
    }

    .sm-padding-nine-left {
        padding-left: 9%
    }

    .sm-padding-nine-half-left {
        padding-left: 9.5%
    }

    .sm-padding-ten-left {
        padding-left: 10%
    }

    .sm-padding-ten-half-left {
        padding-left: 10.5%
    }

    .sm-padding-eleven-left {
        padding-left: 11%
    }

    .sm-padding-eleven-half-left {
        padding-left: 11.5%
    }

    .sm-padding-twelve-left {
        padding-left: 12%
    }

    .sm-padding-twelve-half-left {
        padding-left: 12.5%
    }

    .sm-padding-thirteen-left {
        padding-left: 13%
    }

    .sm-padding-thirteen-half-left {
        padding-left: 13.5%
    }

    .sm-padding-fourteen-left {
        padding-left: 14%
    }

    .sm-padding-fourteen-half-left {
        padding-left: 14.5%
    }

    .sm-padding-fifteen-left {
        padding-left: 15%
    }

    .sm-padding-fifteen-half-left {
        padding-left: 15.5%
    }

    .sm-padding-sixteen-left {
        padding-left: 16%
    }

    .sm-padding-sixteen-half-left {
        padding-left: 16.5%
    }

    .sm-padding-seventeen-left {
        padding-left: 17%
    }

    .sm-padding-seventeen-half-left {
        padding-left: 17.5%
    }

    .sm-padding-eighteen-left {
        padding-left: 18%
    }

    .sm-padding-eighteen-half-left {
        padding-left: 18.5%
    }

    .sm-padding-nineteen-left {
        padding-left: 19%
    }

    .sm-padding-nineteen-half-left {
        padding-left: 19.5%
    }

    .sm-padding-twenty-left {
        padding-left: 20%
    }

    .sm-padding-twenty-half-left {
        padding-left: 20.5%
    }

    .sm-padding-twenty-one-left {
        padding-left: 21%
    }

    .sm-padding-twenty-one-half-left {
        padding-left: 21.5%
    }

    .sm-padding-twenty-two-left {
        padding-left: 22%
    }

    .sm-padding-twenty-two-half-left {
        padding-left: 22.5%
    }

    .sm-padding-twenty-three-left {
        padding-left: 23%
    }

    .sm-padding-twenty-three-half-left {
        padding-left: 23.5%
    }

    .sm-padding-twenty-four-left {
        padding-left: 24%
    }

    .sm-padding-twenty-four-half-left {
        padding-left: 24.5%
    }

    .sm-padding-twenty-five-left {
        padding-left: 25%
    }

    .sm-padding-5px-left {
        padding-left: 5px !important
    }

    .sm-padding-10px-left {
        padding-left: 10px !important
    }

    .sm-padding-15px-left {
        padding-left: 15px !important
    }

    .sm-padding-20px-left {
        padding-left: 20px !important
    }

    .sm-padding-25px-left {
        padding-left: 25px !important
    }

    .sm-padding-30px-left {
        padding-left: 30px !important
    }

    .sm-padding-35px-left {
        padding-left: 35px !important
    }

    .sm-padding-40px-left {
        padding-left: 40px !important
    }

    .sm-padding-45px-left {
        padding-left: 45px !important
    }

    .sm-padding-50px-left {
        padding-left: 50px !important
    }

    .sm-padding-55px-left {
        padding-left: 55px !important
    }

    .sm-padding-60px-left {
        padding-left: 60px !important
    }

    .sm-padding-65px-left {
        padding-left: 65px !important
    }

    .sm-padding-70px-left {
        padding-left: 70px !important
    }

    .sm-padding-75px-left {
        padding-left: 75px !important
    }

    .sm-padding-80px-left {
        padding-left: 80px !important
    }

    .sm-padding-85px-left {
        padding-left: 85px !important
    }

    .sm-padding-90px-left {
        padding-left: 90px !important
    }

    .sm-padding-95px-left {
        padding-left: 95px !important
    }

    .sm-padding-100px-left {
        padding-left: 100px !important
    }

    .sm-padding-one-tb {
        padding-top: 1%;
        padding-bottom: 1%
    }

    .sm-padding-one-half-tb {
        padding-top: 1.5%;
        padding-bottom: 1.5%
    }

    .sm-padding-two-tb {
        padding-top: 2%;
        padding-bottom: 2%
    }

    .sm-padding-two-half-tb {
        padding-top: 2.5%;
        padding-bottom: 2.5%
    }

    .sm-padding-three-tb {
        padding-top: 3%;
        padding-bottom: 3%
    }

    .sm-padding-three-half-tb {
        padding-top: 3.5%;
        padding-bottom: 3.5%
    }

    .sm-padding-four-tb {
        padding-top: 4%;
        padding-bottom: 4%
    }

    .sm-padding-four-half-tb {
        padding-top: 4.5%;
        padding-bottom: 4.5%
    }

    .sm-padding-five-tb {
        padding-top: 5%;
        padding-bottom: 5%
    }

    .sm-padding-five-half-tb {
        padding-top: 5.5%;
        padding-bottom: 5.5%
    }

    .sm-padding-six-tb {
        padding-top: 6%;
        padding-bottom: 6%
    }

    .sm-padding-six-half-tb {
        padding-top: 6.5%;
        padding-bottom: 6.5%
    }

    .sm-padding-seven-tb {
        padding-top: 7%;
        padding-bottom: 7%
    }

    .sm-padding-seven-half-tb {
        padding-top: 7.5%;
        padding-bottom: 7.5%
    }

    .sm-padding-eight-tb {
        padding-top: 8%;
        padding-bottom: 8%
    }

    .sm-padding-eight-half-tb {
        padding-top: 8.5%;
        padding-bottom: 8.5%
    }

    .sm-padding-nine-tb {
        padding-top: 9%;
        padding-bottom: 9%
    }

    .sm-padding-nine-half-tb {
        padding-top: 9.5%;
        padding-bottom: 9.5%
    }

    .sm-padding-ten-tb {
        padding-top: 10%;
        padding-bottom: 10%
    }

    .sm-padding-ten-half-tb {
        padding-top: 10.5%;
        padding-bottom: 10.5%
    }

    .sm-padding-eleven-tb {
        padding-top: 11%;
        padding-bottom: 11%
    }

    .sm-padding-eleven-half-tb {
        padding-top: 11.5%;
        padding-bottom: 11.5%
    }

    .sm-padding-twelve-tb {
        padding-top: 12%;
        padding-bottom: 12%
    }

    .sm-padding-twelve-half-tb {
        padding-top: 12.5%;
        padding-bottom: 12.5%
    }

    .sm-padding-thirteen-tb {
        padding-top: 13%;
        padding-bottom: 13%
    }

    .sm-padding-thirteen-half-tb {
        padding-top: 13.5%;
        padding-bottom: 13.5%
    }

    .sm-padding-fourteen-tb {
        padding-top: 14%;
        padding-bottom: 14%
    }

    .sm-padding-fourteen-half-tb {
        padding-top: 14.5%;
        padding-bottom: 14.5%
    }

    .sm-padding-fifteen-tb {
        padding-top: 15%;
        padding-bottom: 15%
    }

    .sm-padding-fifteen-half-tb {
        padding-top: 15.5%;
        padding-bottom: 15.5%
    }

    .sm-padding-sixteen-tb {
        padding-top: 16%;
        padding-bottom: 16%
    }

    .sm-padding-sixteen-half-tb {
        padding-top: 16.5%;
        padding-bottom: 16.5%
    }

    .sm-padding-seventeen-tb {
        padding-top: 17%;
        padding-bottom: 17%
    }

    .sm-padding-seventeen-half-tb {
        padding-top: 17.5%;
        padding-bottom: 17.5%
    }

    .sm-padding-eighteen-tb {
        padding-top: 18%;
        padding-bottom: 18%
    }

    .sm-padding-eighteen-half-tb {
        padding-top: 18.5%;
        padding-bottom: 18.5%
    }

    .sm-padding-nineteen-tb {
        padding-top: 19%;
        padding-bottom: 19%
    }

    .sm-padding-nineteen-half-tb {
        padding-top: 19.5%;
        padding-bottom: 19.5%
    }

    .sm-padding-twenty-tb {
        padding-top: 20%;
        padding-bottom: 20%
    }

    .sm-padding-twenty-half-tb {
        padding-top: 20.5%;
        padding-bottom: 20.5%
    }

    .sm-padding-twenty-one-tb {
        padding-top: 21%;
        padding-bottom: 21%
    }

    .sm-padding-twenty-one-half-tb {
        padding-top: 21.5%;
        padding-bottom: 21.5%
    }

    .sm-padding-twenty-two-tb {
        padding-top: 22%;
        padding-bottom: 22%
    }

    .sm-padding-twenty-two-half-tb {
        padding-top: 22.5%;
        padding-bottom: 22.5%
    }

    .sm-padding-twenty-three-tb {
        padding-top: 23%;
        padding-bottom: 23%
    }

    .sm-padding-twenty-three-half-tb {
        padding-top: 23.5%;
        padding-bottom: 23.5%
    }

    .sm-padding-twenty-four-tb {
        padding-top: 24%;
        padding-bottom: 24%
    }

    .sm-padding-twenty-four-half-tb {
        padding-top: 24.5%;
        padding-bottom: 24.5%
    }

    .sm-padding-twenty-tb {
        padding-top: 25%;
        padding-bottom: 25%
    }

    .sm-padding-5px-tb {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .sm-padding-8px-tb {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .sm-padding-10px-tb {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .sm-padding-15px-tb {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .sm-padding-20px-tb {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .sm-padding-25px-tb {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .sm-padding-30px-tb {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .sm-padding-35px-tb {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .sm-padding-40px-tb {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .sm-padding-45px-tb {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .sm-padding-50px-tb {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .sm-padding-55px-tb {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .sm-padding-60px-tb {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .sm-padding-65px-tb {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .sm-padding-70px-tb {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .sm-padding-75px-tb {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .sm-padding-80px-tb {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .sm-padding-85px-tb {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .sm-padding-90px-tb {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .sm-padding-95px-tb {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .sm-padding-100px-tb {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .sm-padding-one-lr {
        padding-left: 1%;
        padding-right: 1%
    }

    .sm-padding-one-half-lr {
        padding-left: 1.5%;
        padding-right: 1.5%
    }

    .sm-padding-two-lr {
        padding-left: 2%;
        padding-right: 2%
    }

    .sm-padding-two-half-lr {
        padding-left: 2.5%;
        padding-right: 2.5%
    }

    .sm-padding-three-lr {
        padding-left: 3%;
        padding-right: 3%
    }

    .sm-padding-three-half-lr {
        padding-left: 3.5%;
        padding-right: 3.5%
    }

    .sm-padding-four-lr {
        padding-left: 4%;
        padding-right: 4%
    }

    .sm-padding-four-half-lr {
        padding-left: 4.5%;
        padding-right: 4.5%
    }

    .sm-padding-five-lr {
        padding-left: 5%;
        padding-right: 5%
    }

    .sm-padding-five-half-lr {
        padding-left: 5.5%;
        padding-right: 5.5%
    }

    .sm-padding-six-lr {
        padding-left: 6%;
        padding-right: 6%
    }

    .sm-padding-six-half-lr {
        padding-left: 6.5%;
        padding-right: 6.5%
    }

    .sm-padding-seven-lr {
        padding-left: 7%;
        padding-right: 7%
    }

    .sm-padding-seven-half-lr {
        padding-left: 7.5%;
        padding-right: 7.5%
    }

    .sm-padding-eight-lr {
        padding-left: 8%;
        padding-right: 8%
    }

    .sm-padding-eight-half-lr {
        padding-left: 8.5%;
        padding-right: 8.5%
    }

    .sm-padding-nine-lr {
        padding-left: 9%;
        padding-right: 9%
    }

    .sm-padding-nine-half-lr {
        padding-left: 9.5%;
        padding-right: 9.5%
    }

    .sm-padding-ten-lr {
        padding-left: 10%;
        padding-right: 10%
    }

    .sm-padding-ten-half-lr {
        padding-left: 10.5%;
        padding-right: 10.5%
    }

    .sm-padding-eleven-lr {
        padding-left: 11%;
        padding-right: 11%
    }

    .sm-padding-eleven-half-lr {
        padding-left: 11.5%;
        padding-right: 11.5%
    }

    .sm-padding-twelve-lr {
        padding-left: 12%;
        padding-right: 12%
    }

    .sm-padding-twelve-half-lr {
        padding-left: 12.5%;
        padding-right: 12.5%
    }

    .sm-padding-thirteen-lr {
        padding-left: 13%;
        padding-right: 13%
    }

    .sm-padding-thirteen-half-lr {
        padding-left: 13.5%;
        padding-right: 13.5%
    }

    .sm-padding-fourteen-lr {
        padding-left: 14%;
        padding-right: 14%
    }

    .sm-padding-fourteen-half-lr {
        padding-left: 14.5%;
        padding-right: 14.5%
    }

    .sm-padding-fifteen-lr {
        padding-left: 15%;
        padding-right: 15%
    }

    .sm-padding-fifteen-half-lr {
        padding-left: 15.5%;
        padding-right: 15.5%
    }

    .sm-padding-sixteen-lr {
        padding-left: 16%;
        padding-right: 16%
    }

    .sm-padding-sixteen-half-lr {
        padding-left: 16.5%;
        padding-right: 16.5%
    }

    .sm-padding-seventeen-lr {
        padding-left: 17%;
        padding-right: 17%
    }

    .sm-padding-seventeen-half-lr {
        padding-left: 17.5%;
        padding-right: 17.5%
    }

    .sm-padding-eighteen-lr {
        padding-left: 18%;
        padding-right: 18%
    }

    .sm-padding-eighteen-half-lr {
        padding-left: 18.5%;
        padding-right: 18.5%
    }

    .sm-padding-nineteen-lr {
        padding-left: 19%;
        padding-right: 19%
    }

    .sm-padding-nineteen-half-lr {
        padding-left: 19.5%;
        padding-right: 19.5%
    }

    .sm-padding-twenty-lr {
        padding-left: 20%;
        padding-right: 20%
    }

    .sm-padding-twenty-half-lr {
        padding-left: 20.5%;
        padding-right: 20.5%
    }

    .sm-padding-twenty-one-lr {
        padding-left: 21%;
        padding-right: 21%
    }

    .sm-padding-twenty-one-half-lr {
        padding-left: 21.5%;
        padding-right: 21.5%
    }

    .sm-padding-twenty-two-lr {
        padding-left: 22%;
        padding-right: 22%
    }

    .sm-padding-twenty-two-half-lr {
        padding-left: 22.5%;
        padding-right: 22.5%
    }

    .sm-padding-twenty-three-lr {
        padding-left: 23%;
        padding-right: 23%
    }

    .sm-padding-twenty-three-half-lr {
        padding-left: 23.5%;
        padding-right: 23.5%
    }

    .sm-padding-twenty-four-lr {
        padding-left: 24%;
        padding-right: 24%
    }

    .sm-padding-twenty-four-half-lr {
        padding-left: 24.5%;
        padding-right: 24.5%
    }

    .sm-padding-twenty-five-lr {
        padding-left: 25%;
        padding-right: 25%
    }

    .sm-padding-5px-lr {
        padding-right: 5px !important;
        padding-left: 5px !important
    }

    .sm-padding-10px-lr {
        padding-right: 10px !important;
        padding-left: 10px !important
    }

    .sm-padding-15px-lr {
        padding-right: 15px !important;
        padding-left: 15px !important
    }

    .sm-padding-20px-lr {
        padding-right: 20px !important;
        padding-left: 20px !important
    }

    .sm-padding-25px-lr {
        padding-right: 25px !important;
        padding-left: 25px !important
    }

    .sm-padding-30px-lr {
        padding-right: 30px !important;
        padding-left: 30px !important
    }

    .sm-padding-35px-lr {
        padding-right: 35px !important;
        padding-left: 35px !important
    }

    .sm-padding-40px-lr {
        padding-right: 40px !important;
        padding-left: 40px !important
    }

    .sm-padding-45px-lr {
        padding-right: 45px !important;
        padding-left: 45px !important
    }

    .sm-padding-50px-lr {
        padding-right: 50px !important;
        padding-left: 50px !important
    }

    .sm-padding-55px-lr {
        padding-right: 55px !important;
        padding-left: 55px !important
    }

    .sm-padding-60px-lr {
        padding-right: 60px !important;
        padding-left: 60px !important
    }

    .sm-padding-65px-lr {
        padding-right: 65px !important;
        padding-left: 65px !important
    }

    .sm-padding-70px-lr {
        padding-right: 70px !important;
        padding-left: 70px !important
    }

    .sm-padding-75px-lr {
        padding-right: 75px !important;
        padding-left: 75px !important
    }

    .sm-padding-80px-lr {
        padding-right: 80px !important;
        padding-left: 80px !important
    }

    .sm-padding-85px-lr {
        padding-right: 85px !important;
        padding-left: 85px !important
    }

    .sm-padding-90px-lr {
        padding-right: 90px !important;
        padding-left: 90px !important
    }

    .sm-padding-95px-lr {
        padding-right: 95px !important;
        padding-left: 95px !important
    }

    .sm-padding-100px-lr {
        padding-right: 100px !important;
        padding-left: 100px !important
    }

    .sm-width-10 {
        width: 10%
    }

    .sm-width-15 {
        width: 15%
    }

    .sm-width-20 {
        width: 20%
    }

    .sm-width-25 {
        width: 25%
    }

    .sm-width-30 {
        width: 30%
    }

    .sm-width-35 {
        width: 35%
    }

    .sm-width-40 {
        width: 40%
    }

    .sm-width-45 {
        width: 45%
    }

    .sm-width-50 {
        width: 50%
    }

    .sm-width-55 {
        width: 55%
    }

    .sm-width-60 {
        width: 60%
    }

    .sm-width-65 {
        width: 65%
    }

    .sm-width-70 {
        width: 70%
    }

    .sm-width-75 {
        width: 75%
    }

    .sm-width-80 {
        width: 80%
    }

    .sm-width-85 {
        width: 85%
    }

    .sm-width-90 {
        width: 90%
    }

    .sm-width-95 {
        width: 95%
    }

    .sm-width-100 {
        width: 100%
    }

    .sm-width-auto {
        width: auto
    }

    .sm-width-50px {
        width: 50px
    }

    .sm-width-70px {
        width: 70px
    }

    .sm-width-75px {
        width: 75px
    }

    .sm-width-80px {
        width: 80px
    }

    .sm-width-85px {
        width: 85px
    }

    .sm-width-90px {
        width: 90px
    }

    .sm-width-95px {
        width: 95px
    }

    .sm-width-100px {
        width: 100px
    }

    .sm-width-120px {
        width: 120px
    }

    .sm-width-130px {
        width: 130px
    }

    .sm-width-150px {
        width: 150px
    }

    .sm-width-180px {
        width: 180px
    }

    .sm-width-200px {
        width: 200px
    }

    .sm-width-250px {
        width: 250px
    }

    .sm-width-300px {
        width: 300px
    }

    .sm-width-350px {
        width: 350px
    }

    .sm-width-400px {
        width: 400px
    }

    .sm-width-450px {
        width: 450px
    }

    .sm-width-500px {
        width: 500px
    }

    .sm-width-550px {
        width: 550px
    }

    .sm-width-600px {
        width: 600px
    }

    .sm-width-650px {
        width: 650px
    }

    .sm-width-700px {
        width: 700px
    }

    .sm-width-750px {
        width: 750px
    }

    .sm-width-800px {
        width: 800px
    }

    .sm-width-850px {
        width: 850px
    }

    .sm-width-900px {
        width: 900px
    }

    .sm-width-950px {
        width: 950px
    }

    .sm-width-1000px {
        width: 1000px
    }

    .sm-display-block {
        display: block !important
    }

    .sm-overflow-hidden {
        overflow: hidden !important
    }

    .sm-overflow-auto {
        overflow: auto !important
    }

    .sm-display-inline-block {
        display: inline-block !important
    }

    .sm-display-inline {
        display: inline !important
    }

    .sm-display-none {
        display: none !important
    }

    .sm-display-inherit {
        display: inherit !important
    }

    .sm-display-table {
        display: table !important
    }

    .sm-display-table-cell {
        display: table-cell !important
    }

    .sm-display-flex-inherit {
        -ms-box-orient: inherit;
        display: inherit;
        display: inherit;
        display: inherit;
        display: inherit;
        display: inherit;
        display: inherit;
        flex-flow: inherit;
        -webkit-flex-flow: inherit;
        -moz-flex-flow: inherit;
        -ms-flex-flow: inherit;
        -o-flex-flow: inherit
    }

    .sm-clear-both {
        clear: both
    }

    .sm-position-inherit {
        position: inherit !important
    }

    .sm-position-initial {
        position: initial !important
    }

    .sm-position-relative {
        position: relative !important;
        z-index: 5
    }

    .sm-position-absolute {
        position: absolute !important
    }

    .sm-position-fixed {
        position: fixed !important
    }

    .sm-border-top {
        border-top: 1px solid
    }

    .sm-border-bottom {
        border-bottom: 1px solid
    }

    .sm-border-left {
        border-left: 1px solid
    }

    .sm-border-right {
        border-right: 1px solid
    }

    .sm-border-lr {
        border-left: 1px solid;
        border-right: 1px solid
    }

    .sm-border-tb {
        border-top: 1px solid;
        border-bottom: 1px solid
    }

    .sm-no-border-top {
        border-top: 0 !important
    }

    .sm-no-border-bottom {
        border-bottom: 0 !important
    }

    .sm-no-border-right {
        border-right: 0 !important
    }

    .sm-no-border-left {
        border-left: 0 !important
    }

    .sm-no-border {
        border: 0 !important
    }

    .parallax {
        background-position: center
    }

    .sm-opacity1 {
        opacity: .1
    }

    .sm-opacity2 {
        opacity: .2
    }

    .sm-opacity3 {
        opacity: .3
    }

    .sm-opacity4 {
        opacity: .4
    }

    .sm-opacity5 {
        opacity: .5
    }

    .sm-opacity6 {
        opacity: .6
    }

    .sm-opacity7 {
        opacity: .7
    }

    .sm-opacity8 {
        opacity: .8
    }

    .sm-opacity9 {
        opacity: .9
    }

    .sm-opacity10 {
        opacity: 1
    }
}

@media screen and (max-width: 767px) {
    .xs-height-auto {
        height: auto
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 14px;
        padding: 0;
        letter-spacing: 0
    }

    h1 {
        font-size: 34px
    }

    h2 {
        font-size: 28px
    }

    h3 {
        font-size: 26px
    }

    h4 {
        font-size: 24px
    }

    h5 {
        font-size: 20px
    }

    h6 {
        font-size: 16px
    }

    .xs-font-size11 {
        font-size: 11px
    }

    .xs-font-size12 {
        font-size: 12px
    }

    .xs-font-size13 {
        font-size: 13px
    }

    .xs-font-size14 {
        font-size: 14px
    }

    .xs-font-size15 {
        font-size: 15px
    }

    .xs-font-size16 {
        font-size: 16px
    }

    .xs-font-size17 {
        font-size: 17px
    }

    .xs-font-size18 {
        font-size: 18px
    }

    .xs-font-size19 {
        font-size: 19px
    }

    .xs-font-size20 {
        font-size: 20px
    }

    .xs-font-size22 {
        font-size: 22px
    }

    .xs-font-size24 {
        font-size: 24px
    }

    .xs-font-size26 {
        font-size: 26px
    }

    .xs-font-size28 {
        font-size: 28px
    }

    .xs-font-size30 {
        font-size: 30px
    }

    .xs-font-size32 {
        font-size: 32px
    }

    .xs-font-size34 {
        font-size: 34px
    }

    .xs-font-size36 {
        font-size: 36px
    }

    .xs-font-size38 {
        font-size: 38px
    }

    .xs-font-size40 {
        font-size: 40px
    }

    .xs-font-size42 {
        font-size: 42px
    }

    .xs-font-size44 {
        font-size: 44px
    }

    .xs-font-size46 {
        font-size: 46px
    }

    .xs-font-size48 {
        font-size: 48px
    }

    .xs-font-size50 {
        font-size: 50px
    }

    .xs-font-size55 {
        font-size: 55px
    }

    .xs-font-size60 {
        font-size: 60px
    }

    .xs-font-size80 {
        font-size: 80px
    }

    .xs-font-size100 {
        font-size: 100px
    }

    .xs-font-size120 {
        font-size: 120px
    }

    .xs-font-size130 {
        font-size: 130px
    }

    .xs-line-height-normal {
        line-height: normal
    }

    .xs-line-height-10 {
        line-height: 10px
    }

    .xs-line-height-13 {
        line-height: 13px
    }

    .xs-line-height-18 {
        line-height: 18px
    }

    .xs-line-height-20 {
        line-height: 20px
    }

    .xs-line-height-24 {
        line-height: 24px
    }

    .xs-line-height-22 {
        line-height: 22px
    }

    .xs-line-height-26 {
        line-height: 26px
    }

    .xs-line-height-28 {
        line-height: 28px
    }

    .xs-line-height-30 {
        line-height: 30px
    }

    .xs-line-height-35 {
        line-height: 35px
    }

    .xs-line-height-40 {
        line-height: 40px
    }

    .xs-line-height-45 {
        line-height: 45px
    }

    .xs-line-height-50 {
        line-height: 50px
    }

    .xs-line-height-55 {
        line-height: 55px
    }

    .xs-line-height-60 {
        line-height: 60px
    }

    .xs-line-height-65 {
        line-height: 65px
    }

    .xs-line-height-70 {
        line-height: 70px
    }

    .xs-line-height-75 {
        line-height: 75px
    }

    .xs-line-height-80 {
        line-height: 80px
    }

    .xs-line-height-85 {
        line-height: 85px
    }

    .xs-line-height-90 {
        line-height: 90px
    }

    .xs-line-height-95 {
        line-height: 95px
    }

    .xs-line-height-100 {
        line-height: 100px
    }

    .xs-line-height-110 {
        line-height: 110px
    }

    .xs-line-height-120 {
        line-height: 120px
    }

    .xs-opacity1 {
        opacity: .1
    }

    .xs-opacity2 {
        opacity: .2
    }

    .xs-opacity3 {
        opacity: .3
    }

    .xs-opacity4 {
        opacity: .4
    }

    .xs-opacity5 {
        opacity: .5
    }

    .xs-opacity6 {
        opacity: .6
    }

    .xs-opacity7 {
        opacity: .7
    }

    .xs-opacity8 {
        opacity: .8
    }

    .xs-opacity9 {
        opacity: .9
    }

    .xs-opacity10 {
        opacity: 1
    }

    .xs-text-center {
        text-align: center !important
    }

    .xs-text-left {
        text-align: left !important
    }

    .xs-text-right {
        text-align: right !important
    }

    .xs-center-col {
        float: none;
        margin: 0 auto
    }

    .xs-float-left {
        float: left !important
    }

    .xs-float-right {
        float: right !important
    }

    .xs-float-none {
        float: none !important
    }

    .xs-vertical-align-middle {
        vertical-align: middle
    }

    .xs-vertical-align-top {
        vertical-align: top
    }

    .xs-vertical-align-bottom {
        vertical-align: bottom
    }

    .xs-absolute-middle-inherit {
        left: 0;
        top: 0;
        position: relative;
        -ms-transform: translateX(0) translateY(0);
        -moz-transform: translateX(0) translateY(0);
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        -o-transform: translateX(0) translateY(0)
    }

    .xs-background-image-left {
        background-position: left !important
    }

    .xs-background-image-right {
        background-position: right !important
    }

    .xs-background-image-center {
        background-position: center !important
    }

    .xs-margin-one-all {
        margin: 1%
    }

    .xs-margin-one-half-all {
        margin: 1.5%
    }

    .xs-margin-two-all {
        margin: 2%
    }

    .xs-margin-two-half-all {
        margin: 2.5%
    }

    .xs-margin-three-all {
        margin: 3%
    }

    .xs-margin-three-half-all {
        margin: 3.5%
    }

    .xs-margin-four-all {
        margin: 4%
    }

    .xs-margin-four-half-all {
        margin: 4.5%
    }

    .xs-margin-five-all {
        margin: 5%
    }

    .xs-margin-five-half-all {
        margin: 5.5%
    }

    .xs-margin-six-all {
        margin: 6%
    }

    .xs-margin-six-half-all {
        margin: 6.5%
    }

    .xs-margin-seven-all {
        margin: 7%
    }

    .xs-margin-seven-half-all {
        margin: 7.5%
    }

    .xs-margin-eight-all {
        margin: 8%
    }

    .xs-margin-eight-half-all {
        margin: 8.5%
    }

    .xs-margin-nine-all {
        margin: 9%
    }

    .xs-margin-nine-half-all {
        margin: 9.5%
    }

    .xs-margin-ten-all {
        margin: 10%
    }

    .xs-margin-ten-half-all {
        margin: 10.5%
    }

    .xs-margin-eleven-all {
        margin: 11%
    }

    .xs-margin-eleven-half-all {
        margin: 11.5%
    }

    .xs-margin-twelve-all {
        margin: 12%
    }

    .xs-margin-twelve-half-all {
        margin: 12.5%
    }

    .xs-margin-thirteen-all {
        margin: 13%
    }

    .xs-margin-thirteen-half-all {
        margin: 13.5%
    }

    .xs-margin-fourteen-all {
        margin: 14%
    }

    .xs-margin-fourteen-half-all {
        margin: 14.5%
    }

    .xs-margin-fifteen-all {
        margin: 15%
    }

    .xs-margin-fifteen-half-all {
        margin: 15.5%
    }

    .xs-margin-sixteen-all {
        margin: 16%
    }

    .xs-margin-sixteen-half-all {
        margin: 16.5%
    }

    .xs-margin-seventeen-all {
        margin: 17%
    }

    .xs-margin-seventeen-half-all {
        margin: 17.5%
    }

    .xs-margin-eighteen-all {
        margin: 18%
    }

    .xs-margin-eighteen-half-all {
        margin: 18.5%
    }

    .xs-margin-nineteen-all {
        margin: 19%
    }

    .xs-margin-nineteen-half-all {
        margin: 19.5%
    }

    .xs-margin-twenty-all {
        margin: 20%
    }

    .xs-margin-twenty-half-all {
        margin: 20.5%
    }

    .xs-margin-twenty-one-all {
        margin: 21%
    }

    .xs-margin-twenty-one-half-all {
        margin: 21.5%
    }

    .xs-margin-twenty-two-all {
        margin: 22%
    }

    .xs-margin-twenty-two-half-all {
        margin: 22.5%
    }

    .xs-margin-twenty-three-all {
        margin: 23%
    }

    .xs-margin-twenty-three-half-all {
        margin: 23.5%
    }

    .xs-margin-twenty-four-all {
        margin: 24%
    }

    .xs-margin-twenty-four-half-all {
        margin: 24.5%
    }

    .xs-margin-twenty-five-all {
        margin: 25%
    }

    .xs-no-margin {
        margin: 0 !important
    }

    .xs-no-margin-lr {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .xs-no-margin-tb {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .xs-no-margin-top {
        margin-top: 0 !important
    }

    .xs-no-margin-bottom {
        margin-bottom: 0 !important
    }

    .xs-no-margin-left {
        margin-left: 0 !important
    }

    .xs-no-margin-right {
        margin-right: 0 !important
    }

    .xs-margin-lr-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .xs-margin-auto {
        margin: 0 auto !important
    }

    .xs-margin-one-top {
        margin-top: 1%
    }

    .xs-margin-one-half-top {
        margin-top: 1.5%
    }

    .xs-margin-two-top {
        margin-top: 2%
    }

    .xs-margin-two-half-top {
        margin-top: 2.5%
    }

    .xs-margin-three-top {
        margin-top: 3%
    }

    .xs-margin-three-half-top {
        margin-top: 3.5%
    }

    .xs-margin-four-top {
        margin-top: 4%
    }

    .xs-margin-four-half-top {
        margin-top: 4.5%
    }

    .xs-margin-five-top {
        margin-top: 5%
    }

    .xs-margin-five-half-top {
        margin-top: 5.5%
    }

    .xs-margin-six-top {
        margin-top: 6%
    }

    .xs-margin-six-half-top {
        margin-top: 6.5%
    }

    .xs-margin-seven-top {
        margin-top: 7%
    }

    .xs-margin-seven-half-top {
        margin-top: 7.5%
    }

    .xs-margin-eight-top {
        margin-top: 8%
    }

    .xs-margin-eight-half-top {
        margin-top: 8.5%
    }

    .xs-margin-nine-top {
        margin-top: 9%
    }

    .xs-margin-nine-half-top {
        margin-top: 9.5%
    }

    .xs-margin-ten-top {
        margin-top: 10%
    }

    .xs-margin-ten-half-top {
        margin-top: 10.5%
    }

    .xs-margin-eleven-top {
        margin-top: 11%
    }

    .xs-margin-eleven-half-top {
        margin-top: 11.5%
    }

    .xs-margin-twelve-top {
        margin-top: 12%
    }

    .xs-margin-twelve-half-top {
        margin-top: 12.5%
    }

    .xs-margin-thirteen-top {
        margin-top: 13%
    }

    .xs-margin-thirteen-half-top {
        margin-top: 13.5%
    }

    .xs-margin-fourteen-top {
        margin-top: 14%
    }

    .xs-margin-fourteen-half-top {
        margin-top: 14.5%
    }

    .xs-margin-fifteen-top {
        margin-top: 15%
    }

    .xs-margin-fifteen-half-top {
        margin-top: 15.5%
    }

    .xs-margin-sixteen-top {
        margin-top: 16%
    }

    .xs-margin-sixteen-half-top {
        margin-top: 16.5%
    }

    .xs-margin-seventeen-top {
        margin-top: 17%
    }

    .xs-margin-seventeen-half-top {
        margin-top: 17.5%
    }

    .xs-margin-eighteen-top {
        margin-top: 18%
    }

    .xs-margin-eighteen-half-top {
        margin-top: 18.5%
    }

    .xs-margin-nineteen-top {
        margin-top: 19%
    }

    .xs-margin-nineteen-half-top {
        margin-top: 19.5%
    }

    .xs-margin-twenty-top {
        margin-top: 20%
    }

    .xs-margin-twenty-half-top {
        margin-top: 20.5%
    }

    .xs-margin-twenty-one-top {
        margin-top: 21%
    }

    .xs-margin-twenty-one-half-top {
        margin-top: 21.5%
    }

    .xs-margin-twenty-two-top {
        margin-top: 22%
    }

    .xs-margin-twenty-two-half-top {
        margin-top: 22.5%
    }

    .xs-margin-twenty-three-top {
        margin-top: 23%
    }

    .xs-margin-twenty-three-half-top {
        margin-top: 23.5%
    }

    .xs-margin-twenty-four-top {
        margin-top: 24%
    }

    .xs-margin-twenty-four-half-top {
        margin-top: 24.5%
    }

    .xs-margin-twenty-five-top {
        margin-top: 25%
    }

    .xs-margin-5px-top {
        margin-top: 5px !important
    }

    .xs-margin-8px-top {
        margin-top: 8px !important
    }

    .xs-margin-10px-top {
        margin-top: 10px !important
    }

    .xs-margin-15px-top {
        margin-top: 15px !important
    }

    .xs-margin-20px-top {
        margin-top: 20px !important
    }

    .xs-margin-25px-top {
        margin-top: 25px !important
    }

    .xs-margin-30px-top {
        margin-top: 30px !important
    }

    .xs-margin-35px-top {
        margin-top: 35px !important
    }

    .xs-margin-40px-top {
        margin-top: 40px !important
    }

    .xs-margin-45px-top {
        margin-top: 45px !important
    }

    .xs-margin-50px-top {
        margin-top: 50px !important
    }

    .xs-margin-55px-top {
        margin-top: 55px !important
    }

    .xs-margin-60px-top {
        margin-top: 60px !important
    }

    .xs-margin-65px-top {
        margin-top: 65px !important
    }

    .xs-margin-70px-top {
        margin-top: 70px !important
    }

    .xs-margin-75px-top {
        margin-top: 75px !important
    }

    .xs-margin-80px-top {
        margin-top: 80px !important
    }

    .xs-margin-85px-top {
        margin-top: 85px !important
    }

    .xs-margin-90px-top {
        margin-top: 90px !important
    }

    .xs-margin-95px-top {
        margin-top: 95px !important
    }

    .xs-margin-100px-top {
        margin-top: 100px !important
    }

    .xs-margin-one-bottom {
        margin-bottom: 1%
    }

    .xs-margin-one-half-bottom {
        margin-bottom: 1.5%
    }

    .xs-margin-two-bottom {
        margin-bottom: 2%
    }

    .xs-margin-two-half-bottom {
        margin-bottom: 2.5%
    }

    .xs-margin-three-bottom {
        margin-bottom: 3%
    }

    .xs-margin-three-half-bottom {
        margin-bottom: 3.5%
    }

    .xs-margin-four-bottom {
        margin-bottom: 4%
    }

    .xs-margin-four-half-bottom {
        margin-bottom: 4.5%
    }

    .xs-margin-five-bottom {
        margin-bottom: 5%
    }

    .xs-margin-five-half-bottom {
        margin-bottom: 5.5%
    }

    .xs-margin-six-bottom {
        margin-bottom: 6%
    }

    .xs-margin-six-half-bottom {
        margin-bottom: 6.5%
    }

    .xs-margin-seven-bottom {
        margin-bottom: 7%
    }

    .xs-margin-seven-half-bottom {
        margin-bottom: 7.5%
    }

    .xs-margin-eight-bottom {
        margin-bottom: 8%
    }

    .xs-margin-eight-half-bottom {
        margin-bottom: 8.5%
    }

    .xs-margin-nine-bottom {
        margin-bottom: 9%
    }

    .xs-margin-nine-half-bottom {
        margin-bottom: 9.5%
    }

    .xs-margin-ten-bottom {
        margin-bottom: 10%
    }

    .xs-margin-ten-half-bottom {
        margin-bottom: 10.5%
    }

    .xs-margin-eleven-bottom {
        margin-bottom: 11%
    }

    .xs-margin-eleven-half-bottom {
        margin-bottom: 11.5%
    }

    .xs-margin-twelve-bottom {
        margin-bottom: 12%
    }

    .xs-margin-twelve-half-bottom {
        margin-bottom: 12.5%
    }

    .xs-margin-thirteen-bottom {
        margin-bottom: 13%
    }

    .xs-margin-thirteen-half-bottom {
        margin-bottom: 13.5%
    }

    .xs-margin-fourteen-bottom {
        margin-bottom: 14%
    }

    .xs-margin-fourteen-half-bottom {
        margin-bottom: 14.5%
    }

    .xs-margin-fifteen-bottom {
        margin-bottom: 15%
    }

    .xs-margin-fifteen-half-bottom {
        margin-bottom: 15.5%
    }

    .xs-margin-sixteen-bottom {
        margin-bottom: 16%
    }

    .xs-margin-sixteen-half-bottom {
        margin-bottom: 16.5%
    }

    .xs-margin-seventeen-bottom {
        margin-bottom: 17%
    }

    .xs-margin-seventeen-half-bottom {
        margin-bottom: 17.5%
    }

    .xs-margin-eighteen-bottom {
        margin-bottom: 18%
    }

    .xs-margin-eighteen-half-bottom {
        margin-bottom: 18.5%
    }

    .xs-margin-nineteen-bottom {
        margin-bottom: 19%
    }

    .xs-margin-nineteen-half-bottom {
        margin-bottom: 19.5%
    }

    .xs-margin-twenty-bottom {
        margin-bottom: 20%
    }

    .xs-margin-twenty-half-bottom {
        margin-bottom: 20.5%
    }

    .xs-margin-twenty-one-bottom {
        margin-bottom: 21%
    }

    .xs-margin-twenty-one-half-bottom {
        margin-bottom: 21.5%
    }

    .xs-margin-twenty-two-bottom {
        margin-bottom: 22%
    }

    .xs-margin-twenty-two-half-bottom {
        margin-bottom: 22.5%
    }

    .xs-margin-twenty-three-bottom {
        margin-bottom: 23%
    }

    .xs-margin-twenty-three-half-bottom {
        margin-bottom: 23.5%
    }

    .xs-margin-twenty-four-bottom {
        margin-bottom: 24%
    }

    .xs-margin-twenty-four-half-bottom {
        margin-bottom: 24.5%
    }

    .xs-margin-twenty-five-bottom {
        margin-bottom: 25%
    }

    .xs-margin-5px-bottom {
        margin-bottom: 5px !important
    }

    .xs-margin-8px-bottom {
        margin-bottom: 8px !important
    }

    .xs-margin-10px-bottom {
        margin-bottom: 10px !important
    }

    .xs-margin-15px-bottom {
        margin-bottom: 15px !important
    }

    .xs-margin-20px-bottom {
        margin-bottom: 20px !important
    }

    .xs-margin-25px-bottom {
        margin-bottom: 25px !important
    }

    .xs-margin-30px-bottom {
        margin-bottom: 30px !important
    }

    .xs-margin-35px-bottom {
        margin-bottom: 35px !important
    }

    .xs-margin-40px-bottom {
        margin-bottom: 40px !important
    }

    .xs-margin-45px-bottom {
        margin-bottom: 45px !important
    }

    .xs-margin-50px-bottom {
        margin-bottom: 50px !important
    }

    .xs-margin-55px-bottom {
        margin-bottom: 55px !important
    }

    .xs-margin-60px-bottom {
        margin-bottom: 60px !important
    }

    .xs-margin-65px-bottom {
        margin-bottom: 65px !important
    }

    .xs-margin-70px-bottom {
        margin-bottom: 70px !important
    }

    .xs-margin-75px-bottom {
        margin-bottom: 75px !important
    }

    .xs-margin-80px-bottom {
        margin-bottom: 80px !important
    }

    .xs-margin-85px-bottom {
        margin-bottom: 85px !important
    }

    .xs-margin-90px-bottom {
        margin-bottom: 90px !important
    }

    .xs-margin-95px-bottom {
        margin-bottom: 95px !important
    }

    .xs-margin-100px-bottom {
        margin-bottom: 100px !important
    }

    .xs-margin-one-right {
        margin-right: 1%
    }

    .xs-margin-one-half-right {
        margin-right: 1.5%
    }

    .xs-margin-two-right {
        margin-right: 2%
    }

    .xs-margin-two-half-right {
        margin-right: 2.5%
    }

    .xs-margin-three-right {
        margin-right: 3%
    }

    .xs-margin-three-half-right {
        margin-right: 3.5%
    }

    .xs-margin-four-right {
        margin-right: 4%
    }

    .xs-margin-four-half-right {
        margin-right: 4.5%
    }

    .xs-margin-five-right {
        margin-right: 5%
    }

    .xs-margin-five-half-right {
        margin-right: 5.5%
    }

    .xs-margin-six-right {
        margin-right: 6%
    }

    .xs-margin-six-half-right {
        margin-right: 6.5%
    }

    .xs-margin-seven-right {
        margin-right: 7%
    }

    .xs-margin-seven-half-right {
        margin-right: 7.5%
    }

    .xs-margin-eight-right {
        margin-right: 8%
    }

    .xs-margin-eight-half-right {
        margin-right: 8.5%
    }

    .xs-margin-nine-right {
        margin-right: 9%
    }

    .xs-margin-nine-half-right {
        margin-right: 9.5%
    }

    .xs-margin-ten-right {
        margin-right: 10%
    }

    .xs-margin-ten-half-right {
        margin-right: 10.5%
    }

    .xs-margin-eleven-right {
        margin-right: 11%
    }

    .xs-margin-eleven-half-right {
        margin-right: 11.5%
    }

    .xs-margin-twelve-right {
        margin-right: 12%
    }

    .xs-margin-twelve-half-right {
        margin-right: 12.5%
    }

    .xs-margin-thirteen-right {
        margin-right: 13%
    }

    .xs-margin-thirteen-half-right {
        margin-right: 13.5%
    }

    .xs-margin-fourteen-right {
        margin-right: 14%
    }

    .xs-margin-fourteen-half-right {
        margin-right: 14.5%
    }

    .xs-margin-fifteen-right {
        margin-right: 15%
    }

    .xs-margin-fifteen-half-right {
        margin-right: 15.5%
    }

    .xs-margin-sixteen-right {
        margin-right: 16%
    }

    .xs-margin-sixteen-half-right {
        margin-right: 16.5%
    }

    .xs-margin-seventeen-right {
        margin-right: 17%
    }

    .xs-margin-seventeen-half-right {
        margin-right: 17.5%
    }

    .xs-margin-eighteen-right {
        margin-right: 18%
    }

    .xs-margin-eighteen-half-right {
        margin-right: 18.5%
    }

    .xs-margin-nineteen-right {
        margin-right: 19%
    }

    .xs-margin-nineteen-half-right {
        margin-right: 19.5%
    }

    .xs-margin-twenty-right {
        margin-right: 20%
    }

    .xs-margin-twenty-half-right {
        margin-right: 20.5%
    }

    .xs-margin-twenty-one-right {
        margin-right: 21%
    }

    .xs-margin-twenty-one-half-right {
        margin-right: 21.5%
    }

    .xs-margin-twenty-two-right {
        margin-right: 22%
    }

    .xs-margin-twenty-two-half-right {
        margin-right: 22.5%
    }

    .xs-margin-twenty-three-right {
        margin-right: 23%
    }

    .xs-margin-twenty-three-half-right {
        margin-right: 23.5%
    }

    .xs-margin-twenty-four-right {
        margin-right: 24%
    }

    .xs-margin-twenty-four-half-right {
        margin-right: 24.5%
    }

    .xs-margin-twenty-five-right {
        margin-right: 25%
    }

    .xs-margin-10px-right {
        margin-right: 10px !important
    }

    .xs-margin-15px-right {
        margin-right: 15px !important
    }

    .xs-margin-20px-right {
        margin-right: 20px !important
    }

    .xs-margin-25px-right {
        margin-right: 25px !important
    }

    .xs-margin-30px-right {
        margin-right: 30px !important
    }

    .xs-margin-35px-right {
        margin-right: 35px !important
    }

    .xs-margin-40px-right {
        margin-right: 40px !important
    }

    .xs-margin-45px-right {
        margin-right: 45px !important
    }

    .xs-margin-50px-right {
        margin-right: 50px !important
    }

    .xs-margin-55px-right {
        margin-right: 55px !important
    }

    .xs-margin-60px-right {
        margin-right: 60px !important
    }

    .xs-margin-65px-right {
        margin-right: 65px !important
    }

    .xs-margin-70px-right {
        margin-right: 70px !important
    }

    .xs-margin-75px-right {
        margin-right: 75px !important
    }

    .xs-margin-80px-right {
        margin-right: 80px !important
    }

    .xs-margin-85px-right {
        margin-right: 85px !important
    }

    .xs-margin-90px-right {
        margin-right: 90px !important
    }

    .xs-margin-95px-right {
        margin-right: 95px !important
    }

    .xs-margin-100px-right {
        margin-right: 100px !important
    }

    .xs-margin-one-left {
        margin-left: 1%
    }

    .xs-margin-one-half-left {
        margin-left: 1.5%
    }

    .xs-margin-two-left {
        margin-left: 2%
    }

    .xs-margin-two-half-left {
        margin-left: 2.5%
    }

    .xs-margin-three-left {
        margin-left: 3%
    }

    .xs-margin-three-half-left {
        margin-left: 3.5%
    }

    .xs-margin-four-left {
        margin-left: 4%
    }

    .xs-margin-four-half-left {
        margin-left: 4.5%
    }

    .xs-margin-five-left {
        margin-left: 5%
    }

    .xs-margin-five-half-left {
        margin-left: 5.5%
    }

    .xs-margin-six-left {
        margin-left: 6%
    }

    .xs-margin-six-half-left {
        margin-left: 6.5%
    }

    .xs-margin-seven-left {
        margin-left: 7%
    }

    .xs-margin-seven-half-left {
        margin-left: 7.5%
    }

    .xs-margin-eight-left {
        margin-left: 8%
    }

    .xs-margin-eight-half-left {
        margin-left: 8.5%
    }

    .xs-margin-nine-left {
        margin-left: 9%
    }

    .xs-margin-nine-half-left {
        margin-left: 9.5%
    }

    .xs-margin-ten-left {
        margin-left: 10%
    }

    .xs-margin-ten-half-left {
        margin-left: 10.5%
    }

    .xs-margin-eleven-left {
        margin-left: 11%
    }

    .xs-margin-eleven-half-left {
        margin-left: 11.5%
    }

    .xs-margin-twelve-left {
        margin-left: 12%
    }

    .xs-margin-twelve-half-left {
        margin-left: 12.5%
    }

    .xs-margin-thirteen-left {
        margin-left: 13%
    }

    .xs-margin-thirteen-half-left {
        margin-left: 13.5%
    }

    .xs-margin-fourteen-left {
        margin-left: 14%
    }

    .xs-margin-fourteen-half-left {
        margin-left: 14.5%
    }

    .xs-margin-fifteen-left {
        margin-left: 15%
    }

    .xs-margin-fifteen-half-left {
        margin-left: 15.5%
    }

    .xs-margin-sixteen-left {
        margin-left: 16%
    }

    .xs-margin-sixteen-half-left {
        margin-left: 16.5%
    }

    .xs-margin-seventeen-left {
        margin-left: 17%
    }

    .xs-margin-seventeen-half-left {
        margin-left: 17.5%
    }

    .xs-margin-eighteen-left {
        margin-left: 18%
    }

    .xs-margin-eighteen-half-left {
        margin-left: 18.5%
    }

    .xs-margin-nineteen-left {
        margin-left: 19%
    }

    .xs-margin-nineteen-half-left {
        margin-left: 19.5%
    }

    .xs-margin-twenty-left {
        margin-left: 20%
    }

    .xs-margin-twenty-half-left {
        margin-left: 20.5%
    }

    .xs-margin-twenty-one-left {
        margin-left: 21%
    }

    .xs-margin-twenty-one-half-left {
        margin-left: 21.5%
    }

    .xs-margin-twenty-two-left {
        margin-left: 22%
    }

    .xs-margin-twenty-two-half-left {
        margin-left: 22.5%
    }

    .xs-margin-twenty-three-left {
        margin-left: 23%
    }

    .xs-margin-twenty-three-half-left {
        margin-left: 23.5%
    }

    .xs-margin-twenty-four-left {
        margin-left: 24%
    }

    .xs-margin-twenty-four-half-left {
        margin-left: 24.5%
    }

    .xs-margin-twenty-five-left {
        margin-left: 25%
    }

    .xs-margin-5px-left {
        margin-left: 5px !important
    }

    .xs-margin-10px-left {
        margin-left: 10px !important
    }

    .xs-margin-15px-left {
        margin-left: 15px !important
    }

    .xs-margin-20px-left {
        margin-left: 20px !important
    }

    .xs-margin-25px-left {
        margin-left: 25px !important
    }

    .xs-margin-30px-left {
        margin-left: 30px !important
    }

    .xs-margin-35px-left {
        margin-left: 35px !important
    }

    .xs-margin-40px-left {
        margin-left: 40px !important
    }

    .xs-margin-45px-left {
        margin-left: 45px !important
    }

    .xs-margin-50px-left {
        margin-left: 50px !important
    }

    .xs-margin-55px-left {
        margin-left: 55px !important
    }

    .xs-margin-60px-left {
        margin-left: 60px !important
    }

    .xs-margin-65px-left {
        margin-left: 65px !important
    }

    .xs-margin-70px-left {
        margin-left: 70px !important
    }

    .xs-margin-75px-left {
        margin-left: 75px !important
    }

    .xs-margin-80px-left {
        margin-left: 80px !important
    }

    .xs-margin-85px-left {
        margin-left: 85px !important
    }

    .xs-margin-90px-left {
        margin-left: 90px !important
    }

    .xs-margin-95px-left {
        margin-left: 95px !important
    }

    .xs-margin-100px-left {
        margin-left: 100px !important
    }

    .xs-margin-one-lr {
        margin-left: 1%;
        margin-right: 1%
    }

    .xs-margin-one-lr {
        margin-left: 1.5%;
        margin-right: 1.5%
    }

    .xs-margin-two-lr {
        margin-left: 2%;
        margin-right: 2%
    }

    .xs-margin-two-lr {
        margin-left: 2.5%;
        margin-right: 2.5%
    }

    .xs-margin-three-lr {
        margin-left: 3%;
        margin-right: 3%
    }

    .xs-margin-three-lr {
        margin-left: 3.5%;
        margin-right: 3.5%
    }

    .xs-margin-four-lr {
        margin-left: 4%;
        margin-right: 4%
    }

    .xs-margin-four-lr {
        margin-left: 4.5%;
        margin-right: 4.5%
    }

    .xs-margin-five-lr {
        margin-left: 5%;
        margin-right: 5%
    }

    .xs-margin-five-lr {
        margin-left: 5.5%;
        margin-right: 5.5%
    }

    .xs-margin-six-lr {
        margin-left: 6%;
        margin-right: 6%
    }

    .xs-margin-six-lr {
        margin-left: 6.5%;
        margin-right: 6.5%
    }

    .xs-margin-seven-lr {
        margin-left: 7%;
        margin-right: 7%
    }

    .xs-margin-seven-lr {
        margin-left: 7.5%;
        margin-right: 7.5%
    }

    .xs-margin-eight-lr {
        margin-left: 8%;
        margin-right: 8%
    }

    .xs-margin-eight-lr {
        margin-left: 8.5%;
        margin-right: 8.5%
    }

    .xs-margin-nine-lr {
        margin-left: 9%;
        margin-right: 9%
    }

    .xs-margin-nine-lr {
        margin-left: 9.5%;
        margin-right: 9.5%
    }

    .xs-margin-ten-lr {
        margin-left: 10%;
        margin-right: 10%
    }

    .xs-margin-ten-lr {
        margin-left: 10.5%;
        margin-right: 10.5%
    }

    .xs-margin-eleven-lr {
        margin-left: 11%;
        margin-right: 11%
    }

    .xs-margin-eleven-lr {
        margin-left: 11.5%;
        margin-right: 11.5%
    }

    .xs-margin-twelve-lr {
        margin-left: 12%;
        margin-right: 12%
    }

    .xs-margin-twelve-lr {
        margin-left: 12.5%;
        margin-right: 12.5%
    }

    .xs-margin-thirteen-lr {
        margin-left: 13%;
        margin-right: 13%
    }

    .xs-margin-thirteen-half-lr {
        margin-left: 13.5%;
        margin-right: 13.5%
    }

    .xs-margin-fourteen-lr {
        margin-left: 14%;
        margin-right: 14%
    }

    .xs-margin-fourteen-half-lr {
        margin-left: 14.5%;
        margin-right: 14.5%
    }

    .xs-margin-fifteen-lr {
        margin-left: 15%;
        margin-right: 15%
    }

    .xs-margin-fifteen-half-lr {
        margin-left: 15.5%;
        margin-right: 15.5%
    }

    .xs-margin-sixteen-lr {
        margin-left: 16%;
        margin-right: 16%
    }

    .xs-margin-sixteen-half-lr {
        margin-left: 16.5%;
        margin-right: 16.5%
    }

    .xs-margin-seventeen-lr {
        margin-left: 17%;
        margin-right: 17%
    }

    .xs-margin-seventeen-half-lr {
        margin-left: 17.5%;
        margin-right: 17.5%
    }

    .xs-margin-eighteen-lr {
        margin-left: 18%;
        margin-right: 18%
    }

    .xs-margin-eighteen-half-lr {
        margin-left: 18.5%;
        margin-right: 18.5%
    }

    .xs-margin-nineteen-lr {
        margin-left: 19%;
        margin-right: 19%
    }

    .xs-margin-nineteen-half-lr {
        margin-left: 19.5%;
        margin-right: 19.5%
    }

    .xs-margin-twenty-lr {
        margin-left: 20%;
        margin-right: 20%
    }

    .xs-margin-twenty-half-lr {
        margin-left: 20.5%;
        margin-right: 20.5%
    }

    .xs-margin-twenty-one-lr {
        margin-left: 21%;
        margin-right: 21%
    }

    .xs-margin-twenty-one-half-lr {
        margin-left: 21.5%;
        margin-right: 21.5%
    }

    .xs-margin-twenty-two-lr {
        margin-left: 22%;
        margin-right: 22%
    }

    .xs-margin-twenty-two-half-lr {
        margin-left: 22.5%;
        margin-right: 22.5%
    }

    .xs-margin-twenty-three-lr {
        margin-left: 23%;
        margin-right: 23%
    }

    .xs-margin-twenty-three-half-lr {
        margin-left: 23.5%;
        margin-right: 23.5%
    }

    .xs-margin-twenty-four-lr {
        margin-left: 24%;
        margin-right: 24%
    }

    .xs-margin-twenty-four-half-lr {
        margin-left: 24.5%;
        margin-right: 24.5%
    }

    .xs-margin-twenty-five-lr {
        margin-left: 25%;
        margin-right: 25%
    }

    .xs-margin-5px-lr {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .xs-margin-10px-lr {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .xs-margin-15px-lr {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .xs-margin-20px-lr {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .xs-margin-25px-lr {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .xs-margin-30px-lr {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .xs-margin-35px-lr {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .xs-margin-40px-lr {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .xs-margin-45px-lr {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .xs-margin-50px-lr {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .xs-margin-55px-lr {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .xs-margin-60px-lr {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .xs-margin-65px-lr {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .xs-margin-70px-lr {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .xs-margin-75px-lr {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .xs-margin-80px-lr {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .xs-margin-85px-lr {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .xs-margin-90px-lr {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .xs-margin-95px-lr {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .xs-margin-100px-lr {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .xs-margin-one-tb {
        margin-top: 1%;
        margin-bottom: 1%
    }

    .xs-margin-one-tb {
        margin-top: 1.5%;
        margin-bottom: 1.5%
    }

    .xs-margin-two-tb {
        margin-top: 2%;
        margin-bottom: 2%
    }

    .xs-margin-two-tb {
        margin-top: 2.5%;
        margin-bottom: 2.5%
    }

    .xs-margin-three-tb {
        margin-top: 3%;
        margin-bottom: 3%
    }

    .xs-margin-three-tb {
        margin-top: 3.5%;
        margin-bottom: 3.5%
    }

    .xs-margin-four-tb {
        margin-top: 4%;
        margin-bottom: 4%
    }

    .xs-margin-four-tb {
        margin-top: 4.5%;
        margin-bottom: 4.5%
    }

    .xs-margin-five-tb {
        margin-top: 5%;
        margin-bottom: 5%
    }

    .xs-margin-five-tb {
        margin-top: 5.5%;
        margin-bottom: 5.5%
    }

    .xs-margin-six-tb {
        margin-top: 6%;
        margin-bottom: 6%
    }

    .xs-margin-six-tb {
        margin-top: 6.5%;
        margin-bottom: 6.5%
    }

    .xs-margin-seven-tb {
        margin-top: 7%;
        margin-bottom: 7%
    }

    .xs-margin-seven-tb {
        margin-top: 7.5%;
        margin-bottom: 7.5%
    }

    .xs-margin-eight-tb {
        margin-top: 8%;
        margin-bottom: 8%
    }

    .xs-margin-eight-tb {
        margin-top: 8.5%;
        margin-bottom: 8.5%
    }

    .xs-margin-nine-tb {
        margin-top: 9%;
        margin-bottom: 9%
    }

    .xs-margin-nine-tb {
        margin-top: 9.5%;
        margin-bottom: 9.5%
    }

    .xs-margin-ten-tb {
        margin-top: 10%;
        margin-bottom: 10%
    }

    .xs-margin-ten-tb {
        margin-top: 10.5%;
        margin-bottom: 10.5%
    }

    .xs-margin-eleven-tb {
        margin-top: 11%;
        margin-bottom: 11%
    }

    .xs-margin-eleven-tb {
        margin-top: 11.5%;
        margin-bottom: 11.5%
    }

    .xs-margin-twelve-tb {
        margin-top: 12%;
        margin-bottom: 12%
    }

    .xs-margin-twelve-tb {
        margin-top: 12.5%;
        margin-bottom: 12.5%
    }

    .xs-margin-thirteen-tb {
        margin-top: 13%;
        margin-bottom: 13%
    }

    .xs-margin-thirteen-half-tb {
        margin-top: 13.5%;
        margin-bottom: 13.5%
    }

    .xs-margin-fourteen-tb {
        margin-top: 14%;
        margin-bottom: 14%
    }

    .xs-margin-fourteen-half-tb {
        margin-top: 14.5%;
        margin-bottom: 14.5%
    }

    .xs-margin-fifteen-tb {
        margin-top: 15%;
        margin-bottom: 15%
    }

    .xs-margin-fifteen-half-tb {
        margin-top: 15.5%;
        margin-bottom: 15.5%
    }

    .xs-margin-sixteen-tb {
        margin-top: 16%;
        margin-bottom: 16%
    }

    .xs-margin-sixteen-half-tb {
        margin-top: 16.5%;
        margin-bottom: 16.5%
    }

    .xs-margin-seventeen-tb {
        margin-top: 17%;
        margin-bottom: 17%
    }

    .xs-margin-seventeen-half-tb {
        margin-top: 17.5%;
        margin-bottom: 17.5%
    }

    .xs-margin-eighteen-tb {
        margin-top: 18%;
        margin-bottom: 18%
    }

    .xs-margin-eighteen-half-tb {
        margin-top: 18.5%;
        margin-bottom: 18.5%
    }

    .xs-margin-nineteen-tb {
        margin-top: 19%;
        margin-bottom: 19%
    }

    .xs-margin-nineteen-half-tb {
        margin-top: 19.5%;
        margin-bottom: 19.5%
    }

    .xs-margin-twenty-tb {
        margin-top: 20%;
        margin-bottom: 20%
    }

    .xs-margin-twenty-half-tb {
        margin-top: 20.5%;
        margin-bottom: 20.5%
    }

    .xs-margin-twenty-one-tb {
        margin-top: 21%;
        margin-bottom: 21%
    }

    .xs-margin-twenty-one-half-tb {
        margin-top: 21.5%;
        margin-bottom: 21.5%
    }

    .xs-margin-twenty-two-tb {
        margin-top: 22%;
        margin-bottom: 22%
    }

    .xs-margin-twenty-two-half-tb {
        margin-top: 22.5%;
        margin-bottom: 22.5%
    }

    .xs-margin-twenty-three-tb {
        margin-top: 23%;
        margin-bottom: 23%
    }

    .xs-margin-twenty-three-half-tb {
        margin-top: 23.5%;
        margin-bottom: 23.5%
    }

    .xs-margin-twenty-four-tb {
        margin-top: 24%;
        margin-bottom: 24%
    }

    .xs-margin-twenty-four-half-tb {
        margin-top: 24.5%;
        margin-bottom: 24.5%
    }

    .xs-margin-twenty-five-tb {
        margin-top: 25%;
        margin-bottom: 25%
    }

    .xs-margin-5px-tb {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .xs-margin-10px-tb {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .xs-margin-15px-tb {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .xs-margin-20px-tb {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .xs-margin-25px-tb {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .xs-margin-30px-tb {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .xs-margin-35px-tb {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .xs-margin-40px-tb {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .xs-margin-45px-tb {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .xs-margin-50px-tb {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .xs-margin-55px-tb {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .xs-margin-60px-tb {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .xs-margin-65px-tb {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .xs-margin-70px-tb {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .xs-margin-75px-tb {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .xs-margin-80px-tb {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .xs-margin-85px-tb {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .xs-margin-90px-tb {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .xs-margin-95px-tb {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .xs-margin-100px-tb {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .xs-no-padding {
        padding: 0 !important
    }

    .xs-no-padding-lr {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .xs-no-padding-tb {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .xs-no-padding-top {
        padding-top: 0 !important
    }

    .xs-no-padding-bottom {
        padding-bottom: 0 !important
    }

    .xs-no-padding-left {
        padding-left: 0 !important
    }

    .xs-no-padding-right {
        padding-right: 0 !important
    }

    .xs-padding-one-all {
        padding: 1%
    }

    .xs-padding-one-half-all {
        padding: 1.5%
    }

    .xs-padding-two-all {
        padding: 2%
    }

    .xs-padding-two-half-all {
        padding: 2.5%
    }

    .xs-padding-three-all {
        padding: 3%
    }

    .xs-padding-three-half-all {
        padding: 3.5%
    }

    .xs-padding-four-all {
        padding: 4%
    }

    .xs-padding-four-half-all {
        padding: 4.5%
    }

    .xs-padding-five-all {
        padding: 5%
    }

    .xs-padding-five-half-all {
        padding: 5.5%
    }

    .xs-padding-six-all {
        padding: 6%
    }

    .xs-padding-six-half-all {
        padding: 6.5%
    }

    .xs-padding-seven-all {
        padding: 7%
    }

    .xs-padding-seven-half-all {
        padding: 7.5%
    }

    .xs-padding-eight-all {
        padding: 8%
    }

    .xs-padding-eight-half-all {
        padding: 8.5%
    }

    .xs-padding-nine-all {
        padding: 9%
    }

    .xs-padding-nine-half-all {
        padding: 9.5%
    }

    .xs-padding-ten-all {
        padding: 10%
    }

    .xs-padding-ten-half-all {
        padding: 10.5%
    }

    .xs-padding-eleven-all {
        padding: 11%
    }

    .xs-padding-eleven-half-all {
        padding: 11.5%
    }

    .xs-padding-twelve-all {
        padding: 12%
    }

    .xs-padding-twelve-half-all {
        padding: 12.5%
    }

    .xs-padding-thirteen-all {
        padding: 13%
    }

    .xs-padding-thirteen-half-all {
        padding: 13.5%
    }

    .xs-padding-fourteen-all {
        padding: 14%
    }

    .xs-padding-fourteen-half-all {
        padding: 14.5%
    }

    .xs-padding-fifteen-all {
        padding: 15%
    }

    .xs-padding-fifteen-half-all {
        padding: 15.5%
    }

    .xs-padding-sixteen-all {
        padding: 16%
    }

    .xs-padding-sixteen-half-all {
        padding: 16.5%
    }

    .xs-padding-seventeen-all {
        padding: 17%
    }

    .xs-padding-seventeen-half-all {
        padding: 17.5%
    }

    .xs-padding-eighteen-all {
        padding: 18%
    }

    .xs-padding-eighteen-half-all {
        padding: 18.5%
    }

    .xs-padding-nineteen-all {
        padding: 19%
    }

    .xs-padding-nineteen-half-all {
        padding: 19.5%
    }

    .xs-padding-twenty-all {
        padding: 20%
    }

    .xs-padding-twenty-half-all {
        padding: 20.5%
    }

    .xs-padding-twenty-one-all {
        padding: 21%
    }

    .xs-padding-twenty-one-half-all {
        padding: 21.5%
    }

    .xs-padding-twenty-two-all {
        padding: 22%
    }

    .xs-padding-twenty-two-half-all {
        padding: 22.5%
    }

    .xs-padding-twenty-three-all {
        padding: 23%
    }

    .xs-padding-twenty-three-half-all {
        padding: 23.5%
    }

    .xs-padding-twenty-four-all {
        padding: 24%
    }

    .xs-padding-twenty-four-half-all {
        padding: 24.5%
    }

    .xs-padding-twenty-five-all {
        padding: 25%
    }

    .xs-padding-5px-all {
        padding: 5px !important
    }

    .xs-padding-10px-all {
        padding: 10px !important
    }

    .xs-padding-15px-all {
        padding: 15px !important
    }

    .xs-padding-20px-all {
        padding: 20px !important
    }

    .xs-padding-25px-all {
        padding: 25px !important
    }

    .xs-padding-30px-all {
        padding: 30px !important
    }

    .xs-padding-35px-all {
        padding: 35px !important
    }

    .xs-padding-40px-all {
        padding: 40px !important
    }

    .xs-padding-45px-all {
        padding: 45px !important
    }

    .xs-padding-50px-all {
        padding: 50px !important
    }

    .xs-padding-55px-all {
        padding: 55px !important
    }

    .xs-padding-60px-all {
        padding: 60px !important
    }

    .xs-padding-65px-all {
        padding: 65px !important
    }

    .xs-padding-70px-all {
        padding: 70px !important
    }

    .xs-padding-75px-all {
        padding: 75px !important
    }

    .xs-padding-80px-all {
        padding: 80px !important
    }

    .xs-padding-85px-all {
        padding: 85px !important
    }

    .xs-padding-90px-all {
        padding: 90px !important
    }

    .xs-padding-95px-all {
        padding: 95px !important
    }

    .xs-padding-100px-all {
        padding: 100px !important
    }

    .xs-padding-one-top {
        padding-top: 1%
    }

    .xs-padding-one-half-top {
        padding-top: 1.5%
    }

    .xs-padding-two-top {
        padding-top: 2%
    }

    .xs-padding-two-half-top {
        padding-top: 2.5%
    }

    .xs-padding-three-top {
        padding-top: 3%
    }

    .xs-padding-three-half-top {
        padding-top: 3.5%
    }

    .xs-padding-four-top {
        padding-top: 4%
    }

    .xs-padding-four-half-top {
        padding-top: 4.5%
    }

    .xs-padding-five-top {
        padding-top: 5%
    }

    .xs-padding-five-half-top {
        padding-top: 5.5%
    }

    .xs-padding-six-top {
        padding-top: 6%
    }

    .xs-padding-six-half-top {
        padding-top: 6.5%
    }

    .xs-padding-seven-top {
        padding-top: 7%
    }

    .xs-padding-seven-half-top {
        padding-top: 7.5%
    }

    .xs-padding-eight-top {
        padding-top: 8%
    }

    .xs-padding-eight-half-top {
        padding-top: 8.5%
    }

    .xs-padding-nine-top {
        padding-top: 9%
    }

    .xs-padding-nine-half-top {
        padding-top: 9.5%
    }

    .xs-padding-ten-top {
        padding-top: 10%
    }

    .xs-padding-ten-half-top {
        padding-top: 10.5%
    }

    .xs-padding-eleven-top {
        padding-top: 11%
    }

    .xs-padding-eleven-half-top {
        padding-top: 11.5%
    }

    .xs-padding-twelve-top {
        padding-top: 12%
    }

    .xs-padding-twelve-half-top {
        padding-top: 12.5%
    }

    .xs-padding-thirteen-top {
        padding-top: 13%
    }

    .xs-padding-thirteen-half-top {
        padding-top: 13.5%
    }

    .xs-padding-fourteen-top {
        padding-top: 14%
    }

    .xs-padding-fourteen-half-top {
        padding-top: 14.5%
    }

    .xs-padding-fifteen-top {
        padding-top: 15%
    }

    .xs-padding-fifteen-half-top {
        padding-top: 15.5%
    }

    .xs-padding-sixteen-top {
        padding-top: 16%
    }

    .xs-padding-sixteen-half-top {
        padding-top: 16.5%
    }

    .xs-padding-seventeen-top {
        padding-top: 17%
    }

    .xs-padding-seventeen-half-top {
        padding-top: 17.5%
    }

    .xs-padding-eighteen-top {
        padding-top: 18%
    }

    .xs-padding-eighteen-half-top {
        padding-top: 18.5%
    }

    .xs-padding-nineteen-top {
        padding-top: 19%
    }

    .xs-padding-nineteen-half-top {
        padding-top: 19.5%
    }

    .xs-padding-twenty-top {
        padding-top: 20%
    }

    .xs-padding-twenty-half-top {
        padding-top: 20.5%
    }

    .xs-padding-twenty-one-top {
        padding-top: 21%
    }

    .xs-padding-twenty-one-half-top {
        padding-top: 21.5%
    }

    .xs-padding-twenty-two-top {
        padding-top: 22%
    }

    .xs-padding-twenty-two-half-top {
        padding-top: 22.5%
    }

    .xs-padding-twenty-three-top {
        padding-top: 23%
    }

    .xs-padding-twenty-three-half-top {
        padding-top: 23.5%
    }

    .xs-padding-twenty-four-top {
        padding-top: 24%
    }

    .xs-padding-twenty-four-half-top {
        padding-top: 24.5%
    }

    .xs-padding-twenty-five-top {
        padding-top: 25%
    }

    .xs-padding-5px-top {
        padding-top: 5px !important
    }

    .xs-padding-10px-top {
        padding-top: 10px !important
    }

    .xs-padding-15px-top {
        padding-top: 15px !important
    }

    .xs-padding-20px-top {
        padding-top: 20px !important
    }

    .xs-padding-25px-top {
        padding-top: 25px !important
    }

    .xs-padding-30px-top {
        padding-top: 30px !important
    }

    .xs-padding-35px-top {
        padding-top: 35px !important
    }

    .xs-padding-40px-top {
        padding-top: 40px !important
    }

    .xs-padding-45px-top {
        padding-top: 45px !important
    }

    .xs-padding-50px-top {
        padding-top: 50px !important
    }

    .xs-padding-55px-top {
        padding-top: 55px !important
    }

    .xs-padding-60px-top {
        padding-top: 60px !important
    }

    .xs-padding-65px-top {
        padding-top: 65px !important
    }

    .xs-padding-70px-top {
        padding-top: 70px !important
    }

    .xs-padding-75px-top {
        padding-top: 75px !important
    }

    .xs-padding-80px-top {
        padding-top: 80px !important
    }

    .xs-padding-85px-top {
        padding-top: 85px !important
    }

    .xs-padding-90px-top {
        padding-top: 90px !important
    }

    .xs-padding-95px-top {
        padding-top: 95px !important
    }

    .xs-padding-100px-top {
        padding-top: 100px !important
    }

    .xs-padding-one-bottom {
        padding-bottom: 1%
    }

    .xs-padding-one-half-bottom {
        padding-bottom: 1.5%
    }

    .xs-padding-two-bottom {
        padding-bottom: 2%
    }

    .xs-padding-two-half-bottom {
        padding-bottom: 2.5%
    }

    .xs-padding-three-bottom {
        padding-bottom: 3%
    }

    .xs-padding-three-half-bottom {
        padding-bottom: 3.5%
    }

    .xs-padding-four-bottom {
        padding-bottom: 4%
    }

    .xs-padding-four-half-bottom {
        padding-bottom: 4.5%
    }

    .xs-padding-five-bottom {
        padding-bottom: 5%
    }

    .xs-padding-five-half-bottom {
        padding-bottom: 5.5%
    }

    .xs-padding-six-bottom {
        padding-bottom: 6%
    }

    .xs-padding-six-half-bottom {
        padding-bottom: 6.5%
    }

    .xs-padding-seven-bottom {
        padding-bottom: 7%
    }

    .xs-padding-seven-half-bottom {
        padding-bottom: 7.5%
    }

    .xs-padding-eight-bottom {
        padding-bottom: 8%
    }

    .xs-padding-eight-half-bottom {
        padding-bottom: 8.5%
    }

    .xs-padding-nine-bottom {
        padding-bottom: 9%
    }

    .xs-padding-nine-half-bottom {
        padding-bottom: 9.5%
    }

    .xs-padding-ten-bottom {
        padding-bottom: 10%
    }

    .xs-padding-ten-half-bottom {
        padding-bottom: 10.5%
    }

    .xs-padding-eleven-bottom {
        padding-bottom: 11%
    }

    .xs-padding-eleven-half-bottom {
        padding-bottom: 11.5%
    }

    .xs-padding-twelve-bottom {
        padding-bottom: 12%
    }

    .xs-padding-twelve-half-bottom {
        padding-bottom: 12.5%
    }

    .xs-padding-thirteen-bottom {
        padding-bottom: 13%
    }

    .xs-padding-thirteen-half-bottom {
        padding-bottom: 13.5%
    }

    .xs-padding-fourteen-bottom {
        padding-bottom: 14%
    }

    .xs-padding-fourteen-half-bottom {
        padding-bottom: 14.5%
    }

    .xs-padding-fifteen-bottom {
        padding-bottom: 15%
    }

    .xs-padding-fifteen-half-bottom {
        padding-bottom: 15.5%
    }

    .xs-padding-sixteen-bottom {
        padding-bottom: 16%
    }

    .xs-padding-sixteen-half-bottom {
        padding-bottom: 16.5%
    }

    .xs-padding-seventeen-bottom {
        padding-bottom: 17%
    }

    .xs-padding-seventeen-half-bottom {
        padding-bottom: 17.5%
    }

    .xs-padding-eighteen-bottom {
        padding-bottom: 18%
    }

    .xs-padding-eighteen-half-bottom {
        padding-bottom: 18.5%
    }

    .xs-padding-nineteen-bottom {
        padding-bottom: 19%
    }

    .xs-padding-nineteen-half-bottom {
        padding-bottom: 19.5%
    }

    .xs-padding-twenty-bottom {
        padding-bottom: 20%
    }

    .xs-padding-twenty-half-bottom {
        padding-bottom: 20.5%
    }

    .xs-padding-twenty-one-bottom {
        padding-bottom: 21%
    }

    .xs-padding-twenty-one-half-bottom {
        padding-bottom: 21.5%
    }

    .xs-padding-twenty-two-bottom {
        padding-bottom: 22%
    }

    .xs-padding-twenty-two-half-bottom {
        padding-bottom: 22.5%
    }

    .xs-padding-twenty-three-bottom {
        padding-bottom: 23%
    }

    .xs-padding-twenty-three-half-bottom {
        padding-bottom: 23.5%
    }

    .xs-padding-twenty-four-bottom {
        padding-bottom: 24%
    }

    .xs-padding-twenty-four-half-bottom {
        padding-bottom: 24.5%
    }

    .xs-padding-twenty-five-bottom {
        padding-bottom: 25%
    }

    .xs-padding-5px-bottom {
        padding-bottom: 5px !important
    }

    .xs-padding-10px-bottom {
        padding-bottom: 10px !important
    }

    .xs-padding-15px-bottom {
        padding-bottom: 15px !important
    }

    .xs-padding-20px-bottom {
        padding-bottom: 20px !important
    }

    .xs-padding-25px-bottom {
        padding-bottom: 25px !important
    }

    .xs-padding-30px-bottom {
        padding-bottom: 30px !important
    }

    .xs-padding-35px-bottom {
        padding-bottom: 35px !important
    }

    .xs-padding-40px-bottom {
        padding-bottom: 40px !important
    }

    .xs-padding-45px-bottom {
        padding-bottom: 45px !important
    }

    .xs-padding-50px-bottom {
        padding-bottom: 50px !important
    }

    .xs-padding-55px-bottom {
        padding-bottom: 55px !important
    }

    .xs-padding-60px-bottom {
        padding-bottom: 60px !important
    }

    .xs-padding-65px-bottom {
        padding-bottom: 65px !important
    }

    .xs-padding-70px-bottom {
        padding-bottom: 70px !important
    }

    .xs-padding-75px-bottom {
        padding-bottom: 75px !important
    }

    .xs-padding-80px-bottom {
        padding-bottom: 80px !important
    }

    .xs-padding-85px-bottom {
        padding-bottom: 85px !important
    }

    .xs-padding-90px-bottom {
        padding-bottom: 90px !important
    }

    .xs-padding-95px-bottom {
        padding-bottom: 95px !important
    }

    .xs-padding-100px-bottom {
        padding-bottom: 100px !important
    }

    .xs-padding-one-right {
        padding-right: 1%
    }

    .xs-padding-one-half-right {
        padding-right: 1.5%
    }

    .xs-padding-two-right {
        padding-right: 2%
    }

    .xs-padding-two-half-right {
        padding-right: 2.5%
    }

    .xs-padding-three-right {
        padding-right: 3%
    }

    .xs-padding-three-half-right {
        padding-right: 3.5%
    }

    .xs-padding-four-right {
        padding-right: 4%
    }

    .xs-padding-four-half-right {
        padding-right: 4.5%
    }

    .xs-padding-five-right {
        padding-right: 5%
    }

    .xs-padding-five-half-right {
        padding-right: 5.5%
    }

    .xs-padding-six-right {
        padding-right: 6%
    }

    .xs-padding-six-half-right {
        padding-right: 6.5%
    }

    .xs-padding-seven-right {
        padding-right: 7%
    }

    .xs-padding-seven-half-right {
        padding-right: 7.5%
    }

    .xs-padding-eight-right {
        padding-right: 8%
    }

    .xs-padding-eight-half-right {
        padding-right: 8.5%
    }

    .xs-padding-nine-right {
        padding-right: 9%
    }

    .xs-padding-nine-half-right {
        padding-right: 9.5%
    }

    .xs-padding-ten-right {
        padding-right: 10%
    }

    .xs-padding-ten-half-right {
        padding-right: 10.5%
    }

    .xs-padding-eleven-right {
        padding-right: 11%
    }

    .xs-padding-eleven-half-right {
        padding-right: 11.5%
    }

    .xs-padding-twelve-right {
        padding-right: 12%
    }

    .xs-padding-twelve-half-right {
        padding-right: 12.5%
    }

    .xs-padding-thirteen-right {
        padding-right: 13%
    }

    .xs-padding-thirteen-half-right {
        padding-right: 13.5%
    }

    .xs-padding-fourteen-right {
        padding-right: 14%
    }

    .xs-padding-fourteen-half-right {
        padding-right: 14.5%
    }

    .xs-padding-fifteen-right {
        padding-right: 15%
    }

    .xs-padding-fifteen-half-right {
        padding-right: 15.5%
    }

    .xs-padding-sixteen-right {
        padding-right: 16%
    }

    .xs-padding-sixteen-half-right {
        padding-right: 16.5%
    }

    .xs-padding-seventeen-right {
        padding-right: 17%
    }

    .xs-padding-seventeen-half-right {
        padding-right: 17.5%
    }

    .xs-padding-eighteen-right {
        padding-right: 18%
    }

    .xs-padding-eighteen-half-right {
        padding-right: 18.5%
    }

    .xs-padding-nineteen-right {
        padding-right: 19%
    }

    .xs-padding-nineteen-half-right {
        padding-right: 19.5%
    }

    .xs-padding-twenty-right {
        padding-right: 20%
    }

    .xs-padding-twenty-hlaf-right {
        padding-right: 20.5%
    }

    .xs-padding-twenty-one-right {
        padding-right: 21%
    }

    .xs-padding-twenty-one-hlaf-right {
        padding-right: 21.5%
    }

    .xs-padding-twenty-two-right {
        padding-right: 22%
    }

    .xs-padding-twenty-two-hlaf-right {
        padding-right: 22.5%
    }

    .xs-padding-twenty-three-right {
        padding-right: 23%
    }

    .xs-padding-twenty-three-hlaf-right {
        padding-right: 23.5%
    }

    .xs-padding-twenty-four-right {
        padding-right: 24%
    }

    .xs-padding-twenty-four-hlaf-right {
        padding-right: 24.5%
    }

    .xs-padding-twenty-five-right {
        padding-right: 25%
    }

    .xs-padding-5px-right {
        padding-right: 5px !important
    }

    .xs-padding-10px-right {
        padding-right: 10px !important
    }

    .xs-padding-15px-right {
        padding-right: 15px !important
    }

    .xs-padding-20px-right {
        padding-right: 20px !important
    }

    .xs-padding-25px-right {
        padding-right: 25px !important
    }

    .xs-padding-30px-right {
        padding-right: 30px !important
    }

    .xs-padding-35px-right {
        padding-right: 35px !important
    }

    .xs-padding-40px-right {
        padding-right: 40px !important
    }

    .xs-padding-45px-right {
        padding-right: 45px !important
    }

    .xs-padding-50px-right {
        padding-right: 50px !important
    }

    .xs-padding-55px-right {
        padding-right: 55px !important
    }

    .xs-padding-60px-right {
        padding-right: 60px !important
    }

    .xs-padding-65px-right {
        padding-right: 65px !important
    }

    .xs-padding-70px-right {
        padding-right: 70px !important
    }

    .xs-padding-75px-right {
        padding-right: 75px !important
    }

    .xs-padding-80px-right {
        padding-right: 80px !important
    }

    .xs-padding-85px-right {
        padding-right: 85px !important
    }

    .xs-padding-90px-right {
        padding-right: 90px !important
    }

    .xs-padding-95px-right {
        padding-right: 95px !important
    }

    .xs-padding-100px-right {
        padding-right: 100px !important
    }

    .xs-padding-one-left {
        padding-left: 1%
    }

    .xs-padding-one-half-left {
        padding-left: 1.5%
    }

    .xs-padding-two-left {
        padding-left: 2%
    }

    .xs-padding-two-half-left {
        padding-left: 2.5%
    }

    .xs-padding-three-left {
        padding-left: 3%
    }

    .xs-padding-three-half-left {
        padding-left: 3.5%
    }

    .xs-padding-four-left {
        padding-left: 4%
    }

    .xs-padding-four-half-left {
        padding-left: 4.5%
    }

    .xs-padding-five-left {
        padding-left: 5%
    }

    .xs-padding-five-half-left {
        padding-left: 5.5%
    }

    .xs-padding-six-left {
        padding-left: 6%
    }

    .xs-padding-six-half-left {
        padding-left: 6.5%
    }

    .xs-padding-seven-left {
        padding-left: 7%
    }

    .xs-padding-seven-half-left {
        padding-left: 7.5%
    }

    .xs-padding-eight-left {
        padding-left: 8%
    }

    .xs-padding-eight-half-left {
        padding-left: 8.5%
    }

    .xs-padding-nine-left {
        padding-left: 9%
    }

    .xs-padding-nine-half-left {
        padding-left: 9.5%
    }

    .xs-padding-ten-left {
        padding-left: 10%
    }

    .xs-padding-ten-half-left {
        padding-left: 10.5%
    }

    .xs-padding-eleven-left {
        padding-left: 11%
    }

    .xs-padding-eleven-half-left {
        padding-left: 11.5%
    }

    .xs-padding-twelve-left {
        padding-left: 12%
    }

    .xs-padding-twelve-half-left {
        padding-left: 12.5%
    }

    .xs-padding-thirteen-left {
        padding-left: 13%
    }

    .xs-padding-thirteen-half-left {
        padding-left: 13.5%
    }

    .xs-padding-fourteen-left {
        padding-left: 14%
    }

    .xs-padding-fourteen-half-left {
        padding-left: 14.5%
    }

    .xs-padding-fifteen-left {
        padding-left: 15%
    }

    .xs-padding-fifteen-half-left {
        padding-left: 15.5%
    }

    .xs-padding-sixteen-left {
        padding-left: 16%
    }

    .xs-padding-sixteen-half-left {
        padding-left: 16.5%
    }

    .xs-padding-seventeen-left {
        padding-left: 17%
    }

    .xs-padding-seventeen-half-left {
        padding-left: 17.5%
    }

    .xs-padding-eighteen-left {
        padding-left: 18%
    }

    .xs-padding-eighteen-half-left {
        padding-left: 18.5%
    }

    .xs-padding-nineteen-left {
        padding-left: 19%
    }

    .xs-padding-nineteen-half-left {
        padding-left: 19.5%
    }

    .xs-padding-twenty-left {
        padding-left: 20%
    }

    .xs-padding-twenty-half-left {
        padding-left: 20.5%
    }

    .xs-padding-twenty-one-left {
        padding-left: 21%
    }

    .xs-padding-twenty-one-half-left {
        padding-left: 21.5%
    }

    .xs-padding-twenty-two-left {
        padding-left: 22%
    }

    .xs-padding-twenty-two-half-left {
        padding-left: 22.5%
    }

    .xs-padding-twenty-three-left {
        padding-left: 23%
    }

    .xs-padding-twenty-three-half-left {
        padding-left: 23.5%
    }

    .xs-padding-twenty-four-left {
        padding-left: 24%
    }

    .xs-padding-twenty-four-half-left {
        padding-left: 24.5%
    }

    .xs-padding-twenty-five-left {
        padding-left: 25%
    }

    .xs-padding-5px-left {
        padding-left: 5px !important
    }

    .xs-padding-10px-left {
        padding-left: 10px !important
    }

    .xs-padding-15px-left {
        padding-left: 15px !important
    }

    .xs-padding-20px-left {
        padding-left: 20px !important
    }

    .xs-padding-25px-left {
        padding-left: 25px !important
    }

    .xs-padding-30px-left {
        padding-left: 30px !important
    }

    .xs-padding-35px-left {
        padding-left: 35px !important
    }

    .xs-padding-40px-left {
        padding-left: 40px !important
    }

    .xs-padding-45px-left {
        padding-left: 45px !important
    }

    .xs-padding-50px-left {
        padding-left: 50px !important
    }

    .xs-padding-55px-left {
        padding-left: 55px !important
    }

    .xs-padding-60px-left {
        padding-left: 60px !important
    }

    .xs-padding-65px-left {
        padding-left: 65px !important
    }

    .xs-padding-70px-left {
        padding-left: 70px !important
    }

    .xs-padding-75px-left {
        padding-left: 75px !important
    }

    .xs-padding-80px-left {
        padding-left: 80px !important
    }

    .xs-padding-85px-left {
        padding-left: 85px !important
    }

    .xs-padding-90px-left {
        padding-left: 90px !important
    }

    .xs-padding-95px-left {
        padding-left: 95px !important
    }

    .xs-padding-100px-left {
        padding-left: 100px !important
    }

    .xs-padding-one-tb {
        padding-top: 1%;
        padding-bottom: 1%
    }

    .xs-padding-one-half-tb {
        padding-top: 1.5%;
        padding-bottom: 1.5%
    }

    .xs-padding-two-tb {
        padding-top: 2%;
        padding-bottom: 2%
    }

    .xs-padding-two-half-tb {
        padding-top: 2.5%;
        padding-bottom: 2.5%
    }

    .xs-padding-three-tb {
        padding-top: 3%;
        padding-bottom: 3%
    }

    .xs-padding-three-half-tb {
        padding-top: 3.5%;
        padding-bottom: 3.5%
    }

    .xs-padding-four-tb {
        padding-top: 4%;
        padding-bottom: 4%
    }

    .xs-padding-four-half-tb {
        padding-top: 4.5%;
        padding-bottom: 4.5%
    }

    .xs-padding-five-tb {
        padding-top: 5%;
        padding-bottom: 5%
    }

    .xs-padding-five-half-tb {
        padding-top: 5.5%;
        padding-bottom: 5.5%
    }

    .xs-padding-six-tb {
        padding-top: 6%;
        padding-bottom: 6%
    }

    .xs-padding-six-half-tb {
        padding-top: 6.5%;
        padding-bottom: 6.5%
    }

    .xs-padding-seven-tb {
        padding-top: 7%;
        padding-bottom: 7%
    }

    .xs-padding-seven-half-tb {
        padding-top: 7.5%;
        padding-bottom: 7.5%
    }

    .xs-padding-eight-tb {
        padding-top: 8%;
        padding-bottom: 8%
    }

    .xs-padding-eight-half-tb {
        padding-top: 8.5%;
        padding-bottom: 8.5%
    }

    .xs-padding-nine-tb {
        padding-top: 9%;
        padding-bottom: 9%
    }

    .xs-padding-nine-half-tb {
        padding-top: 9.5%;
        padding-bottom: 9.5%
    }

    .xs-padding-ten-tb {
        padding-top: 10%;
        padding-bottom: 10%
    }

    .xs-padding-ten-half-tb {
        padding-top: 10.5%;
        padding-bottom: 10.5%
    }

    .xs-padding-eleven-tb {
        padding-top: 11%;
        padding-bottom: 11%
    }

    .xs-padding-eleven-half-tb {
        padding-top: 11.5%;
        padding-bottom: 11.5%
    }

    .xs-padding-twelve-tb {
        padding-top: 12%;
        padding-bottom: 12%
    }

    .xs-padding-twelve-half-tb {
        padding-top: 12.5%;
        padding-bottom: 12.5%
    }

    .xs-padding-thirteen-tb {
        padding-top: 13%;
        padding-bottom: 13%
    }

    .xs-padding-thirteen-half-tb {
        padding-top: 13.5%;
        padding-bottom: 13.5%
    }

    .xs-padding-fourteen-tb {
        padding-top: 14%;
        padding-bottom: 14%
    }

    .xs-padding-fourteen-half-tb {
        padding-top: 14.5%;
        padding-bottom: 14.5%
    }

    .xs-padding-fifteen-tb {
        padding-top: 15%;
        padding-bottom: 15%
    }

    .xs-padding-fifteen-half-tb {
        padding-top: 15.5%;
        padding-bottom: 15.5%
    }

    .xs-padding-sixteen-tb {
        padding-top: 16%;
        padding-bottom: 16%
    }

    .xs-padding-sixteen-half-tb {
        padding-top: 16.5%;
        padding-bottom: 16.5%
    }

    .xs-padding-seventeen-tb {
        padding-top: 17%;
        padding-bottom: 17%
    }

    .xs-padding-seventeen-half-tb {
        padding-top: 17.5%;
        padding-bottom: 17.5%
    }

    .xs-padding-eighteen-tb {
        padding-top: 18%;
        padding-bottom: 18%
    }

    .xs-padding-eighteen-half-tb {
        padding-top: 18.5%;
        padding-bottom: 18.5%
    }

    .xs-padding-nineteen-tb {
        padding-top: 19%;
        padding-bottom: 19%
    }

    .xs-padding-nineteen-half-tb {
        padding-top: 19.5%;
        padding-bottom: 19.5%
    }

    .xs-padding-twenty-tb {
        padding-top: 20%;
        padding-bottom: 20%
    }

    .xs-padding-twenty-one-half-tb {
        padding-top: 20.5%;
        padding-bottom: 20.5%
    }

    .xs-padding-twenty-one-tb {
        padding-top: 21%;
        padding-bottom: 21%
    }

    .xs-padding-twenty-half-tb {
        padding-top: 21.5%;
        padding-bottom: 21.5%
    }

    .xs-padding-twenty-two-tb {
        padding-top: 22%;
        padding-bottom: 22%
    }

    .xs-padding-twenty-two-half-tb {
        padding-top: 22.5%;
        padding-bottom: 22.5%
    }

    .xs-padding-twenty-three-tb {
        padding-top: 23%;
        padding-bottom: 23%
    }

    .xs-padding-twenty-three-half-tb {
        padding-top: 23.5%;
        padding-bottom: 23.5%
    }

    .xs-padding-twenty-four-tb {
        padding-top: 24%;
        padding-bottom: 24%
    }

    .xs-padding-twenty-four-half-tb {
        padding-top: 24.5%;
        padding-bottom: 24.5%
    }

    .xs-padding-twenty-five-tb {
        padding-top: 25%;
        padding-bottom: 25%
    }

    .xs-padding-5px-tb {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .xs-padding-8px-tb {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .xs-padding-10px-tb {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .xs-padding-15px-tb {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .xs-padding-20px-tb {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .xs-padding-25px-tb {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .xs-padding-30px-tb {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .xs-padding-35px-tb {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .xs-padding-40px-tb {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .xs-padding-45px-tb {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .xs-padding-50px-tb {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .xs-padding-55px-tb {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .xs-padding-60px-tb {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .xs-padding-65px-tb {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .xs-padding-70px-tb {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .xs-padding-75px-tb {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .xs-padding-80px-tb {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .xs-padding-85px-tb {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .xs-padding-90px-tb {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .xs-padding-95px-tb {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .xs-padding-100px-tb {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .xs-padding-one-lr {
        padding-left: 1%;
        padding-right: 1%
    }

    .xs-padding-one-half-lr {
        padding-left: 1.5%;
        padding-right: 1.5%
    }

    .xs-padding-two-lr {
        padding-left: 2%;
        padding-right: 2%
    }

    .xs-padding-two-half-lr {
        padding-left: 2.5%;
        padding-right: 2.5%
    }

    .xs-padding-three-lr {
        padding-left: 3%;
        padding-right: 3%
    }

    .xs-padding-three-half-lr {
        padding-left: 3.5%;
        padding-right: 3.5%
    }

    .xs-padding-four-lr {
        padding-left: 4%;
        padding-right: 4%
    }

    .xs-padding-four-half-lr {
        padding-left: 4.5%;
        padding-right: 4.5%
    }

    .xs-padding-five-lr {
        padding-left: 5%;
        padding-right: 5%
    }

    .xs-padding-five-half-lr {
        padding-left: 5.5%;
        padding-right: 5.5%
    }

    .xs-padding-six-lr {
        padding-left: 6%;
        padding-right: 6%
    }

    .xs-padding-six-half-lr {
        padding-left: 6.5%;
        padding-right: 6.5%
    }

    .xs-padding-seven-lr {
        padding-left: 7%;
        padding-right: 7%
    }

    .xs-padding-seven-half-lr {
        padding-left: 7.5%;
        padding-right: 7.5%
    }

    .xs-padding-eight-lr {
        padding-left: 8%;
        padding-right: 8%
    }

    .xs-padding-eight-half-lr {
        padding-left: 8.5%;
        padding-right: 8.5%
    }

    .xs-padding-nine-lr {
        padding-left: 9%;
        padding-right: 9%
    }

    .xs-padding-nine-half-lr {
        padding-left: 9.5%;
        padding-right: 9.5%
    }

    .xs-padding-ten-lr {
        padding-left: 10%;
        padding-right: 10%
    }

    .xs-padding-ten-half-lr {
        padding-left: 10.5%;
        padding-right: 10.5%
    }

    .xs-padding-eleven-lr {
        padding-left: 11%;
        padding-right: 11%
    }

    .xs-padding-eleven-half-lr {
        padding-left: 11.5%;
        padding-right: 11.5%
    }

    .xs-padding-twelve-lr {
        padding-left: 12%;
        padding-right: 12%
    }

    .xs-padding-twelve-half-lr {
        padding-left: 12.5%;
        padding-right: 12.5%
    }

    .xs-padding-thirteen-lr {
        padding-left: 13%;
        padding-right: 13%
    }

    .xs-padding-thirteen-half-lr {
        padding-left: 13.5%;
        padding-right: 13.5%
    }

    .xs-padding-fourteen-lr {
        padding-left: 14%;
        padding-right: 14%
    }

    .xs-padding-fourteen-half-lr {
        padding-left: 14.5%;
        padding-right: 14.5%
    }

    .xs-padding-fifteen-lr {
        padding-left: 15%;
        padding-right: 15%
    }

    .xs-padding-fifteen-half-lr {
        padding-left: 15.5%;
        padding-right: 15.5%
    }

    .xs-padding-sixteen-lr {
        padding-left: 16%;
        padding-right: 16%
    }

    .xs-padding-sixteen-half-lr {
        padding-left: 16.5%;
        padding-right: 16.5%
    }

    .xs-padding-seventeen-lr {
        padding-left: 17%;
        padding-right: 17%
    }

    .xs-padding-seventeen-half-lr {
        padding-left: 17.5%;
        padding-right: 17.5%
    }

    .xs-padding-eighteen-lr {
        padding-left: 18%;
        padding-right: 18%
    }

    .xs-padding-eighteen-half-lr {
        padding-left: 18.5%;
        padding-right: 18.5%
    }

    .xs-padding-nineteen-lr {
        padding-left: 19%;
        padding-right: 19%
    }

    .xs-padding-nineteen-half-lr {
        padding-left: 19.5%;
        padding-right: 19.5%
    }

    .xs-padding-twenty-lr {
        padding-left: 20%;
        padding-right: 20%
    }

    .xs-padding-twenty-half-lr {
        padding-left: 20.5%;
        padding-right: 20.5%
    }

    .xs-padding-twenty-one-lr {
        padding-left: 21%;
        padding-right: 21%
    }

    .xs-padding-twenty-one-half-lr {
        padding-left: 21.5%;
        padding-right: 21.5%
    }

    .xs-padding-twenty-two-lr {
        padding-left: 22%;
        padding-right: 22%
    }

    .xs-padding-twenty-two-half-lr {
        padding-left: 22.5%;
        padding-right: 22.5%
    }

    .xs-padding-twenty-three-lr {
        padding-left: 23%;
        padding-right: 23%
    }

    .xs-padding-twenty-three-half-lr {
        padding-left: 23.5%;
        padding-right: 23.5%
    }

    .xs-padding-twenty-four-lr {
        padding-left: 24%;
        padding-right: 24%
    }

    .xs-padding-twenty-four-half-lr {
        padding-left: 24.5%;
        padding-right: 24.5%
    }

    .xs-padding-twenty-lr {
        padding-left: 25%;
        padding-right: 25%
    }

    .xs-padding-5px-lr {
        padding-left: 5px !important;
        padding-right: 5px
    }

    .xs-padding-10px-lr {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .xs-padding-15px-lr {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .xs-padding-20px-lr {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .xs-padding-25px-lr {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .xs-padding-30px-lr {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .xs-padding-35px-lr {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .xs-padding-40px-lr {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .xs-padding-45px-lr {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .xs-padding-50px-lr {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .xs-padding-55px-lr {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .xs-padding-60px-lr {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .xs-padding-65px-lr {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .xs-padding-70px-lr {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .xs-padding-75px-lr {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .xs-padding-80px-lr {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .xs-padding-85px-lr {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .xs-padding-90px-lr {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .xs-padding-95px-lr {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .xs-padding-100px-lr {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .xs-width-10 {
        width: 10%
    }

    .xs-width-15 {
        width: 15%
    }

    .xs-width-20 {
        width: 20%
    }

    .xs-width-25 {
        width: 25%
    }

    .xs-width-30 {
        width: 30%
    }

    .xs-width-35 {
        width: 35%
    }

    .xs-width-40 {
        width: 40%
    }

    .xs-width-45 {
        width: 45%
    }

    .xs-width-50 {
        width: 50%
    }

    .xs-width-55 {
        width: 55%
    }

    .xs-width-60 {
        width: 60%
    }

    .xs-width-65 {
        width: 65%
    }

    .xs-width-70 {
        width: 70%
    }

    .xs-width-75 {
        width: 75%
    }

    .xs-width-80 {
        width: 80%
    }

    .xs-width-85 {
        width: 85%
    }

    .xs-width-90 {
        width: 90%
    }

    .xs-width-95 {
        width: 95%
    }

    .xs-width-100 {
        width: 100%
    }

    .xs-width-auto {
        width: auto
    }

    .xs-width-50px {
        width: 50px
    }

    .xs-width-70px {
        width: 70px
    }

    .xs-width-75px {
        width: 75px
    }

    .xs-width-80px {
        width: 80px
    }

    .xs-width-85px {
        width: 85px
    }

    .xs-width-90px {
        width: 90px
    }

    .xs-width-95px {
        width: 95px
    }

    .xs-width-100px {
        width: 100px
    }

    .xs-width-120px {
        width: 120px
    }

    .xs-width-130px {
        width: 130px
    }

    .xs-width-150px {
        width: 150px
    }

    .xs-width-180px {
        width: 180px
    }

    .xs-width-200px {
        width: 200px
    }

    .xs-width-250px {
        width: 250px
    }

    .xs-width-300px {
        width: 300px
    }

    .xs-width-350px {
        width: 350px
    }

    .xs-width-400px {
        width: 400px
    }

    .xs-width-450px {
        width: 450px
    }

    .xs-width-500px {
        width: 500px
    }

    .xs-width-550px {
        width: 550px
    }

    .xs-width-600px {
        width: 600px
    }

    .xs-width-650px {
        width: 650px
    }

    .xs-width-700px {
        width: 700px
    }

    .xs-width-750px {
        width: 750px
    }

    .xs-width-800px {
        width: 800px
    }

    .xs-width-850px {
        width: 850px
    }

    .xs-width-900px {
        width: 900px
    }

    .xs-width-950px {
        width: 950px
    }

    .xs-width-1000px {
        width: 1000px
    }

    .xs-display-block {
        display: block !important
    }

    .xs-overflow-hidden {
        overflow: hidden !important
    }

    .xs-overflow-auto {
        overflow: auto !important
    }

    .xs-display-inline-block {
        display: inline-block !important
    }

    .xs-display-inline {
        display: inline !important
    }

    .xs-display-none {
        display: none !important
    }

    .xs-display-inherit {
        display: inherit !important
    }

    .xs-display-table {
        display: table !important
    }

    .xs-display-table-cell {
        display: table-cell !important
    }

    .xs-position-inherit {
        position: inherit
    }

    .xs-position-relative {
        position: relative;
        z-index: 5
    }

    .xs-position-absolute {
        position: absolute
    }

    .xs-position-fixed {
        position: fixed
    }

    .xs-border-top {
        border-top: 1px solid
    }

    .xs-border-bottom {
        border-bottom: 1px solid
    }

    .xs-border-left {
        border-left: 1px solid
    }

    .xs-border-right {
        border-right: 1px solid
    }

    .xs-border-lr {
        border-left: 1px solid;
        border-right: 1px solid
    }

    .xs-border-tb {
        border-top: 1px solid;
        border-bottom: 1px solid
    }

    .xs-no-border-top {
        border-top: 0 !important
    }

    .xs-no-border-bottom {
        border-bottom: 0 !important
    }

    .xs-no-border-right {
        border-right: 0 !important
    }

    .xs-no-border-left {
        border-left: 0 !important
    }

    .xs-no-border {
        border: 0 !important
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {
        background-attachment: inherit;
        background-position: center
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {
        background-attachment: inherit;
        background-position: center
    }
}
