#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fff;
    display: table;
    text-align: center
}

.loader {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 200px;
    height: 200px
}

.loader-icon {
    width: 80px;
    height: 80px;
    border: 5px solid #1bb4b9;
    border-right-color: #eee;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    margin: 0 auto
}

@keyframes loader-rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

a:hover, a:active {
    color: #1bb4b9;
    text-decoration: none
}

.bg-theme {
    background: #1bb4b9
}

.bg-theme-light {
    background: #f5fafe
}

.bg-highlight-theme {
    background: #1bb4b9
}

.theme-overlay[data-overlay-dark]:before, .theme-overlay[data-overlay-light]:before {
    background: #1bb4b9
}

.text-theme-color {
    color: #1bb4b9
}

.text-hightlight-color {
    color: #1bb4b9
}

.cursor-pointer {
    cursor: pointer
}

.scroll-to-top {
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 50%;
    background: #000;
    border: 1px solid #2a2a2a;
    width: 35px;
    height: 35px;
    line-height: 30px;
    z-index: 9999;
    outline: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all 0.3s ease
}

.scroll-to-top i {
    color: #fff
}

.scroll-to-top:hover {
    color: #232323;
    background: #fff
}

.scroll-to-top:hover i {
    color: #232323
}

.scroll-to-top:visited {
    color: #232323;
    text-decoration: none
}

.butn {
    display: inline-block;
    font-size: 14px;
    padding: 10px 20px;
    background: #fff;
    border: 2px solid #1bb4b9;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px !important;
    color: #1bb4b9;
    letter-spacing: .5px;
    line-height: 1.5;
    cursor: pointer;
    width: auto;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out
}

.butn:hover, .butn:active {
    background: #1bb4b9;
    color: #fff;
    border-color: #1bb4b9;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 8px !important
}

.butn:hover span, .butn:active span {
    color: #fff
}

.butn:focus {
    background: #1bb4b9;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 8px !important;
    border-color: #1bb4b9
}

.butn:focus span {
    color: #fff
}

.butn span {
    color: #1bb4b9
}

.butn i {
    vertical-align: middle
}

.butn.theme {
    background: #1bb4b9;
    border: 2px solid #1bb4b9;
    color: #fff;
    width: auto
}

.butn.theme:hover, .butn.theme:active {
    background: transparent;
    color: #FFFFFF;
    border-color: #1bb4b9
}

.butn.theme span {
    color: #fff
}

.butn.theme:hover span, .butn.theme:active span {
    color: #FFFFFF;
}

.butn.theme:focus {
    background: transparent;
    color: #FFFFFF;
    border-color: #1bb4b9
}

.butn.theme:focus span {
    color: #fff
}

.butn.theme:focus i {
    color: #FFFFFF;
}

.butn.theme i {
    color: #fff
}

.butn.theme:hover i {
    color: #FFF
}

.butn.fill {
    background: #1bb4b9;
    border: 2px solid #1bb4b9;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px !important;
    color: #fff
}

.butn.fill:hover, .butn.fill:active {
    background: #fff;
    color: #FFF;
    border-color: #1bb4b9
}

.butn.fill:hover span, .butn.fill:active span {
    color: #FFF
}

.butn.fill:focus {
    background: #fff;
    color: #FFF;
    border-color: #1bb4b9
}

.butn.fill:focus span {
    color: #FFF
}

.butn.fill span {
    color: #fff
}

.butn.small {
    padding: 6px 18px
}

@media screen and (max-width: 767px) {
    .butn {
        padding: 9px 18px
    }

    body {
        font-size: 14px;
        line-height: 26px
    }
}

.btn-style-2 {
    background: #1bb4b9;
    color: #fff;
    text-align: center;
    padding: 12px 30px;
    line-height: normal;
    font-weight: 500;
    text-transform: none !important;
    position: relative;
    z-index: 9999;
    display: inline-block;
    white-space: nowrap;
    border: 1px solid #1bb4b9;
    cursor: pointer
}

.btn-style-2.small {
    font-size: 12px;
    padding: 8px 20px
}

.btn-style-2.medium {
    font-size: 14px;
    padding: 10px 20px
}

.btn-style-2.large {
    font-size: 16px;
    padding: 16px 36px
}

.btn-style-2.fill {
    border: 1px solid #1bb4b9;
    background-color: #1bb4b9;
    color: #ffffff
}

.btn-style-2.fill:hover, .btn-style-2.fill:active, .btn-style-2.fill:focus {
    border: 1px solid #1bb4b9;
    background-color: #ffffff;
    color: #1bb4b9
}

.btn-style-2:hover, .btn-style-2:active, .btn-style-2:focus {
    border: 1px solid #ffffff;
    background-color: #ffffff;
    color: #1bb4b9
}

.list-style2 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style2 li {
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.list-style2 li:after {
    content: '\f14a';
    font-family: Font Awesome\ 5 Free;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: 1;
    color: #232323
}

.list-style2 li:last-child {
    margin-bottom: 0
}

.list-style3 {
    margin-bottom: 0
}

.list-style3 li {
    text-align: center;
    margin-right: 5px;
    display: inline-block
}

.list-style3 li:last-child {
    margin-right: 0
}

.list-style3 li a {
    border: 1px solid #6f6f6f;
    color: #6f6f6f;
    border-radius: 35px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    display: inline-block
}

.list-style3 li a:hover {
    background: #232323;
    color: #fff
}

.list-style5 {
    margin-bottom: 0
}

.list-style5 li {
    font-size: 15px;
    line-height: 34px;
    padding-left: 30px;
    position: relative
}

.list-style5 li:last-child {
    margin-bottom: 0
}

.list-style5 li:before {
    content: "\e64c";
    font-family: 'themify';
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 35px;
    color: #06df9e
}

@media screen and (max-width: 767px) {
    .list-style5 li {
        font-size: 15px;
        line-height: 32px
    }
}

.common-block {
    border: 2px solid #efefef
}

@media screen and (max-width: 575px) {
    .no-mobile-margin-bottom {
        margin-bottom: 0 !important
    }

    .mobile-margin-15px-bottom {
        margin-bottom: 15px !important
    }

    .mobile-margin-20px-bottom {
        margin-bottom: 20px !important
    }

    .mobile-margin-25px-bottom {
        margin-bottom: 25px !important
    }

    .mobile-margin-30px-bottom {
        margin-bottom: 30px !important
    }

    .mobile-margin-50px-bottom {
        margin-bottom: 50px !important
    }

    .mobile-margin-10px-bottom {
        margin-bottom: 10px !important
    }

    .mobile-margin-5px-bottom {
        margin-bottom: 5px !important
    }
}

.bg-yellow {
    background: #ecc354
}

.bg-blue {
    background: #1bb4b9
}
.btn-primary {

    background: #1bb4b9 !important;
}

.bg-sky {
    background: #1ec1d9
}

.bg-green {
    background: #1bb4b9
}

.bg-pink {
    background: #d94da6
}

.section-title {
    position: relative;
    margin-bottom: 0;
}

.title-border {
    vertical-align: top;
    text-align: center
}

.title-border span {
    display: inline-block;
    vertical-align: middle;
    height: 3px;
    border-radius: 3px;
    background: #1bb4b9;
    margin: 0 4px
}

.title-border span.lg {
    width: 40px
}

.title-border span.md {
    width: 30px;
    opacity: 0.8
}

.title-border span.sm {
    width: 12px;
    opacity: 0.6
}

.navbar-nav > li > a {
    letter-spacing: 1px
}

.navbar-nav li.current > a {
    color: #1bb4b9
}

.navbar > ul > li.current > a:after {
    border-color: transparent #1bb4b9 #1bb4b9 transparent
}

.attr-nav .search {
    margin-top: 3px
}

.navbar > ul > li.current > a:after {
    border-color: transparent #1bb4b9 #1bb4b9 transparent
}

.navbar ul ul li.active > a {
    color: #1bb4b9
}

@media screen and (min-width: 992px) {
    .menu_area-light .navbar-nav li a {
        color: #6f6f6f
    }

    .menu_area-light .navbar-nav li > ul {
        background: #fff
    }

    .menu_area-light .navbar-nav > li > a {
        font-size: 13px;
        color: #fff
    }

    .menu_area-light .navbar ul ul li.active > a {
        color: #1bb4b9
    }

    .menu_area-light .navbar-nav li.has-sub a:hover {
        color: #232323
    }

    .menu_area-light .navbar-nav li.current > a, .menu_area-light .navbar-nav li.active > a {
        color: #1bb4b9
    }

    .menu_area-light .navbar .sub-title {
        color: #232323
    }

    .menu_area-light .navbar-nav > li.has-sub > a:hover {
        color: #fff
    }

    .menu_area-light .navbar > ul > li.has-sub > a:hover:after, .menu_area-light .navbar > ul > li.has-sub > a:after {
        border-color: transparent #fff #fff transparent
    }

    .menu_area-light .navbar > ul > li.current > a:after {
        border-color: transparent #1bb4b9 #1bb4b9 transparent
    }

    .menu_area-light.scrollHeader .navbar-nav > li > a {
        color: #FFF;
    }

    .menu_area-light.scrollHeader .navbar-nav > li.has-sub > a:hover {
        color: #1bb4b9
    }

    .menu_area-light.scrollHeader .navbar > ul > li.has-sub > a:after {
        border-color: transparent #232323 #232323 transparent
    }

    .menu_area-light.scrollHeader .navbar-nav > li.has-sub > a:hover:after {
        border-color: transparent #1bb4b9 #1bb4b9 transparent
    }

    .menu_area-light.scrollHeader .navbar-nav li.current > a {
        color: #1bb4b9
    }

    .menu_area-light.scrollHeader .navbar-nav li.current > a:hover {
        color: #1bb4b9
    }

    .menu_area-light.scrollHeader .navbar > ul > li.current > a:after {
        border-color: transparent #1bb4b9 #1bb4b9 transparent
    }

    .menu_area-light.scrollHeader .navbar-nav li.active > a {
        color: #1bb4b9
    }

    .menu_area-light.scrollHeader .navbar-nav li.active > a:hover {
        color: #1bb4b9
    }
}

@media screen and (max-width: 1199px) {
    .navbar-header-custom {
        padding: 12px 0 10px 0
    }
}

@media screen and (max-width: 991px) {
    .navbar-header-custom {
        padding: 4px 0 8px
    }

    .navbar-nav > li > a {
        font-size: 13px
    }

    .navbar-nav li.active > a {
        color: #1bb4b9
    }

    .header-style1 .navbar-toggler, .navbar-toggler {
        background: #efefef
    }

    .navbar ul ul li.active > a {
        color: #1bb4b9
    }

    .navbar-toggler:after {
        border-top: 2px solid #1bb4b9;
        border-bottom: 2px solid #1bb4b9
    }

    .navbar-toggler:before {
        background: #1bb4b9
    }

    .navbar-toggler.menu-opened:after, .navbar-toggler.menu-opened:before {
        background: #1bb4b9
    }

    .header-style1 .navbar-toggler {
        background: #1bb4b9
    }

    .header-style1 .navbar-toggler:after {
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff
    }

    .header-style1 .navbar-toggler:before {
        background: #fff
    }

    .header-style1 .navbar-toggler.menu-opened:after {
        background: #fff;
        border-bottom: none
    }

    .header-style1 .navbar-toggler.menu-opened:before {
        background: #fff
    }
}

.slider-fade .owl-carousel .caption h1 {
    margin-bottom: 30px
}

.slider-fade .owl-item {
    height: 70vh;
    position: relative
}

.slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center
}

.slider-fade .item .caption {
    width: 100%;
    padding: 0 15px;
    z-index: 9
}

.slider-fade .owl-carousel.owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto
}

.slider-fade .owl-theme .owl-nav {
    margin-top: 0
}

.slider-fade .owl-nav i, .slider-fade .owl-nav span {
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    color: #fff;
    font-size: 26px;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 40px;
    text-align: center
}

.slider-fade .owl-theme .owl-nav .owl-prev {
    position: absolute;
    right: inherit;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
    border-radius: 50px;
    left: 5vh;
    text-align: center;
    margin: auto;
    opacity: 1
}

.slider-fade .owl-theme .owl-nav .owl-next {
    position: absolute;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
    border-radius: 50px;
    top: 0;
    bottom: 0;
    left: inherit;
    right: 5vh;
    text-align: center;
    margin: auto;
    opacity: 1
}

.slider-fade .owl-theme .owl-nav .owl-next:hover {
    opacity: 1
}

.slider-fade .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1
}

.slider-fade .owl-theme .owl-dots .owl-dot span {
    border: 2px solid #fff;
    background: none;
    width: 12px;
    height: 12px
}

.slider-fade .owl-theme .owl-dots .owl-dot.active span {
    background: #1bb4b9
}

.slider-fade .fadeInUpSmall {
    animation-name: fadeInUpSmall;
    transform-origin: 50% 100%
}

@keyframes fadeInUpSmall {
    0% {
        opacity: 0;
        transform: translateY(60px) scale(0.9)
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1)
    }
}

@media screen and (max-width: 1199px) {
    .slider-fade .owl-carousel.owl-theme .owl-dots {
        bottom: 17vh
    }
}

@media screen and (max-width: 991px) {
    .slider-fade .owl-theme .owl-nav .owl-next {
        right: 3vh
    }

    .slider-fade .owl-theme .owl-nav .owl-prev {
        left: 3vh
    }
}

@media screen and (max-width: 767px) {
    .slider-fade .owl-theme .owl-nav .owl-next {
        right: 1vh
    }

    .slider-fade .owl-theme .owl-nav .owl-prev {
        left: 1vh
    }

    .slider-fade .owl-nav i, .slider-fade .owl-nav span {
        font-size: 18px;
        width: 36px;
        height: 36px;
        line-height: 26px
    }
}

.banner-slider .owl-item {
    height: 100vh;
    position: relative
}

.banner-slider .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center
}

.banner-slider .item .caption {
    width: 100%;
    padding: 0 15px;
    z-index: 9
}

.banner-slider .owl-carousel.owl-theme .owl-dots {
    position: absolute;
    bottom: 3vh;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto
}

.banner-slider .owl-theme .owl-nav {
    margin-top: 0
}

.banner-slider .owl-nav i, .banner-slider .owl-nav span {
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    color: #fff;
    font-size: 26px;
    border: 2px solid #fff;
    border-radius: 8px;
    width: 45px;
    height: 45px;
    line-height: 35px;
    text-align: center
}

.banner-slider .owl-theme .owl-nav .owl-prev {
    position: absolute;
    right: inherit;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
    border-radius: 50px;
    left: 5vh;
    text-align: center;
    margin: auto;
    opacity: 0.3
}

.banner-slider .owl-theme .owl-nav .owl-next {
    position: absolute;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
    border-radius: 50px;
    top: 0;
    bottom: 0;
    left: inherit;
    right: 5vh;
    text-align: center;
    margin: auto;
    opacity: 0.3
}

.banner-slider .owl-theme .owl-nav .owl-next:hover {
    opacity: 1
}

.banner-slider .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1
}

.banner-slider .owl-theme .owl-dots .owl-dot span {
    border: 2px solid #fff;
    background: none;
    width: 13px;
    height: 13px
}

.banner-slider .owl-theme .owl-dots .owl-dot.active span {
    background: #1bb4b9
}

@media screen and (max-width: 991px) {
    .banner-slider .owl-theme .owl-nav .owl-next {
        right: 3vh
    }

    .banner-slider .owl-theme .owl-nav .owl-prev {
        left: 3vh
    }
}

@media screen and (max-width: 767px) {
    .banner-slider .owl-theme .owl-nav .owl-next {
        right: 1vh
    }

    .banner-slider .owl-theme .owl-nav .owl-prev {
        left: 1vh
    }

    .banner-slider .owl-nav i, .banner-slider .owl-nav span {
        font-size: 22px
    }

    .banner-slider .owl-theme .owl-dots .owl-dot span {
        width: 12px;
        height: 12px
    }
}

.page-title-section {
    padding: 225px 0 150px;
    text-align: center
}

.page-title-section h1 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1;
    color: #fff;
    margin-bottom: 0;
    text-transform: uppercase
}

.page-title-section ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center
}

.page-title-section ul li {
    display: inline-block
}

.page-title-section ul li:last-child a {
    color: #FFF;
    opacity: 0.65
}

.page-title-section ul li:after {
    content: '\f105';
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
    font-family: Font Awesome\ 5 Free;
    padding: 1px 5px 0 10px
}

.page-title-section ul li:last-child:after {
    content: none
}

.page-title-section ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.page-title-section .active a, .page-title-section li.active:last-child a {
    color: #fff
}

@media screen and (max-width: 1399px) {
    .page-title-section {
        padding: 200px 0 125px
    }
}

@media screen and (max-width: 1199px) {
    .page-title-section {
        padding: 150px 0 85px
    }

    .page-title-section h1 {
        font-size: 36px;
        line-height: 42px
    }
}

@media screen and (max-width: 991px) {
    .page-title-section {
        padding: 125px 0 50px
    }

    .page-title-section h1 {
        font-size: 30px;
        line-height: 40px
    }

    .page-title-section ul {
        margin-top: 5px
    }

    .page-title-section ul li a {
        font-size: 12px
    }
}

.testimonial-style1 p {
    font-style: italic;
    letter-spacing: 1px
}

.testimonial-style1 p:before {
    margin-right: 20px;
    content: "\f10d";
    font-weight: 700;
    font-family: Font Awesome\ 5 Free;
    color: #1bb4b9;
    font-size: 24px
}

.testimonial-style1 p:after {
    margin-left: 20px;
    content: "\f10d";
    font-weight: 700;
    font-family: Font Awesome\ 5 Free;
    color: #1bb4b9;
    font-size: 24px
}

.testimonial-style1 .testmonial-text {
    padding: 45px 25px 70px;
    background: #f7f7f7;
    border-radius: 6px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 320px;
    position: relative
}

.owl-thumbs {
    margin-top: -25px;
    z-index: 9;
    position: relative
}

.owl-thumbs button {
    border: none
}

.owl-thumbs button img {
    opacity: .5
}

.owl-thumbs button.active img {
    opacity: 1
}

@media screen and (max-width: 1199px) {
    .testimonial-style1 .testmonial-text {
        padding: 40px 25px 70px
    }
}

@media screen and (max-width: 991px) {
    .testimonial-style1 .testmonial-text {
        padding: 30px 25px 60px
    }
}

@media screen and (max-width: 767px) {
    .testimonial-style1 .testmonial-text {
        padding: 25px 20px 60px
    }

    .testimonial-style1 p {
        font-size: 14px
    }

    .testimonial-style1 p:before {
        font-size: 22px
    }
}

@media screen and (max-width: 575px) {
    .owl-thumbs {
        margin-top: -30px
    }

    .testimonial-style1 p:before {
        font-size: 20px;
        margin-right: 15px
    }
}

.testimonial-style2 .testmonial-text {
    padding: 55px 25px 45px 25px;
    background: rgba(25, 47, 89, 0.02);
    border: 2px solid #efefef;
    max-width: 750px;
    width: 100%;
    margin: 0 auto
}

.testimonial-style2 .testi-img {
    margin-bottom: -35px
}

@media screen and (max-width: 991px) {
    .testimonial-style2 .testmonial-text {
        padding: 50px 20px 40px 20px;
        max-width: 560px
    }
}

@media screen and (max-width: 767px) {
    .testimonial-style2 .testmonial-text {
        padding: 45px 20px 40px 20px
    }
}

.team-block {
    margin: 0 10px;
    border-radius: 6px
}

.team-block .team-image img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.team-block .overlay-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
    text-align: center
}

.team-block .overlay-box:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
    opacity: 0;
    background: rgba(25, 47, 89, 0.6);
    transition: all 0.9s ease;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.team-block .social-icons {
    position: absolute;
    left: 0;
    bottom: 20px;
    opacity: 0;
    padding: 0 10px;
    width: 100%
}

.team-block .social-icons li {
    position: relative;
    margin-right: 10px;
    display: inline-block
}

.team-block .social-icons li a {
    position: relative;
    color: #ffffff;
    font-size: 14px;
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 50%;
    transition: all 400ms ease
}

.team-block .social-icons li a:hover {
    color: #fff;
    border: 1px solid #1bb4b9;
    background: #1bb4b9
}

.team-block:hover .overlay-box:before {
    opacity: 1
}

.team-block:hover .social-icons {
    opacity: 1;
    transition: all 0.8s ease 500ms
}

.team-info {
    border: 1px solid #efefef;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

@media screen and (max-width: 1199px) {
    .team-block .social-icons li a {
        width: 38px;
        height: 38px;
        line-height: 38px
    }
}

@media screen and (max-width: 991px) {
    .team-block {
        margin: 0
    }
}

@media screen and (max-width: 575px) {
    .team-block .social-icons li a {
        width: 36px;
        height: 36px;
        line-height: 36px
    }
}

.progress-text {
    font-size: 13px;
    font-weight: 500;
    color: #232323;
    margin-bottom: 5px
}

.custom-progress {
    height: 4px;
    border-radius: 50px;
    box-shadow: none;
    margin-bottom: 15px
}

.custom-bar {
    height: 100%;
    background-color: #1bb4b9;
    box-shadow: none
}

.profile-info .social-icon i {
    color: #1bb4b9;
    font-size: 17px;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out
}

.profile-info .social-icon i:hover {
    color: #1bb4b9
}

.social-icon ul li {
    display: inline-block;
    margin-right: 12px
}

.profile-info h6 {
    color: #1bb4b9
}

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 30px
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #1bb4b9
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1)
}

.owl-nav i, .owl-nav span {
    color: #232323;
    font-size: 18px;
    padding-top: 4px
}

.owl-carousel .owl-item img {
    width: auto;
    display: inline-block
}

.owl-theme .owl-nav {
    margin-top: 0
}

.owl-carousel .caption .overflow-hidden {
    display: inline-block
}

.owl-carousel .caption h3 {
    font-weight: 200;
    animation-delay: 0.5s;
    position: relative;
    display: inline-block
}

.owl-carousel .caption h1 {
    margin-bottom: 40px;
    font-size: 55px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 60px;
    animation-delay: 0.8s;
    margin-bottom: 10px
}

.owl-carousel .caption p {
    font-size: 18px;
    color: #eee;
    word-spacing: 2px;
    max-width: 800px;
    font-weight: 400;
    letter-spacing: 1px;
    width: 100%;
    animation-delay: 1.2s;
    line-height: normal
}

.owl-carousel .caption span {
    display: inline-block;
    padding: .2em 0;
    vertical-align: middle
}

.owl-carousel .caption .butn {
    animation-delay: 1.4s
}

.owl-carousel .caption .butn i {
    position: relative;
    z-index: 9
}

.slider-fade .owl-carousel .active h1, .slider-fade .owl-carousel .active p, .slider-fade .owl-carousel .active .butn {
    -webkit-animation: anislide 1s ease 100ms both;
    animation: anislide 1s ease 100ms both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    visibility: visible;
    opacity: 1
}

.slider-fade .owl-carousel .active h1 {
    -webkit-animation-delay: 300ms;
    animation-delay: 300ms
}

.slider-fade .owl-carousel .active p {
    -webkit-animation-delay: 900ms;
    animation-delay: 900ms
}

.slider-fade .owl-carousel .active .butn {
    -webkit-animation-delay: 1400ms;
    animation-delay: 1400ms
}

@-webkit-keyframes anislide {
    from {
        opacity: 0;
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@media screen and (max-width: 1399px) {
    .owl-carousel .caption h1 {
        font-size: 64px
    }
}

@media screen and (max-width: 1199px) {
    .owl-carousel .caption h1 {
        font-size: 60px;
        margin-bottom: 30px;
        line-height: 55px
    }

    .owl-carousel .caption p {
        font-size: 16px;
        max-width: 700px
    }
}

@media screen and (max-width: 991px) {
    .owl-carousel .caption h1 {
        font-size: 48px;
        line-height: 45px
    }

    .owl-carousel .caption p {
        font-size: 16px;
        max-width: 560px
    }
}

@media screen and (max-width: 767px) {
    .owl-carousel .caption h1 {
        font-size: 44px;
        margin-bottom: 25px
    }

    .owl-carousel .caption h3 {
        letter-spacing: 1px
    }

    .owl-carousel .caption p {
        font-size: 14px
    }
}

@media screen and (max-width: 575px) {
    .owl-carousel .caption h1 {
        font-size: 32px;
        line-height: 30px
    }

    .owl-carousel .caption p {
        display: none
    }
}

.accordion-style .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    margin-bottom: 10px;
    border: none
}

.accordion-style .card:last-child {
    margin-bottom: 0
}

.accordion-style .card-header {
    border: 0;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none
}

.accordion-style .btn-link {
    border: 1px solid #efefef;
    color: #1bb4b9;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 0;
    padding: 15px 50px 15px 15px;
    text-decoration: none;
    font-size: 15px
}

.accordion-style .btn-link:hover {
    text-decoration: none
}

.accordion-style .btn-link.collapsed {
    background: #fff !important;
    color: #616161
}

.accordion-style .btn-link.collapsed:after {
    content: "\f0da";
    font-family: Font Awesome\ 5 Free;
    font-weight: 700;
    right: 20px;
    left: inherit;
    font-size: 24px;
    transform: none;
    top: 9px;
    color: #1bb4b9
}

.accordion-style .btn-link:after {
    content: "\f0d7";
    font-family: Font Awesome\ 5 Free;
    font-weight: 700;
    right: 20px;
    left: inherit;
    font-size: 24px;
    transform: none;
    top: 9px;
    position: absolute;
    color: #1bb4b9
}

.accordion-style .card-body {
    padding: 20px;
    text-align: left;
    border: 1px solid #efefef;
    border-top: none;
    margin-top: -1px
}

@media screen and (max-width: 767px) {
    .accordion-style .btn-link {
        font-size: 14px;
        padding: 12px 50px 12px 15px
    }

    .accordion-style .btn-link:after, .accordion-style .btn-link.collapsed:after {
        top: 10px
    }
}

.accordion-style1 .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    margin-bottom: 10px;
    border: none
}

.accordion-style1 .card-header {
    border: 0;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none
}

.accordion-style1 .btn-link {
    background: #f4f4f4 !important;
    color: #616161;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 0;
    padding: 15px 50px 15px 15px;
    text-decoration: none;
    font-size: 15px
}

.accordion-style1 .btn-link:hover {
    border: none;
    text-decoration: none
}

.accordion-style1 .btn-link.collapsed {
    background: #f4f4f4 !important;
    color: #616161
}

.accordion-style1 .btn-link.collapsed:after {
    content: "\f0da";
    font-family: Font Awesome\ 5 Free;
    font-weight: 700;
    right: 20px;
    left: inherit;
    font-size: 24px;
    transform: none;
    top: 9px;
    color: #1bb4b9
}

.accordion-style1 .btn-link:after {
    content: "\f0d7";
    font-family: Font Awesome\ 5 Free;
    font-weight: 700;
    right: 20px;
    left: inherit;
    font-size: 24px;
    transform: none;
    top: 9px;
    position: absolute;
    color: #1bb4b9
}

.accordion-style1 .card-body {
    padding: 20px;
    text-align: left;
    border: 2px solid #f4f4f4;
    border-top: none;
    margin-top: -1px
}

@media screen and (max-width: 767px) {
    .accordion-style1 .btn-link {
        font-size: 14px;
        padding: 12px 50px 12px 15px
    }

    .accordion-style1 .btn-link:after, .accordion-style1 .btn-link.collapsed:after {
        top: 10px
    }
}

ul.resp-tabs-list {
    margin: 0;
    padding: 0
}

.resp-tabs-list li {
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    padding: 13px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer
}

.resp-tabs-list li:last-child {
    margin-right: 0
}

.resp-tabs-list li i {
    font-size: 20px;
    padding-right: 5px;
    vertical-align: text-bottom
}

.resp-tabs-container {
    padding: 0;
    background-color: #fff;
    clear: left
}

.resp-tab-content {
    display: none;
    padding: 20px
}

.resp-tabs-list li.resp-tab-active {
    border: 1px solid #1bb4b9;
    border-bottom: none;
    border-color: #1bb4b9 !important;
    margin-bottom: -1px;
    padding: 12px 14px 14px 14px;
    border-top: 4px solid #1bb4b9 !important;
    border-bottom: 0 #fff solid;
    border-bottom: none;
    background-color: #fff;
    color: #1bb4b9
}

.resp-content-active, .resp-accordion-active {
    display: block
}

.resp-tab-content {
    border: 1px solid #c1c1c1;
    border-top-color: #c1c1c1;
    float: left;
    width: 100%
}

h2.resp-accordion {
    cursor: pointer;
    display: none;
    font-size: 14px;
    border: 1px solid #c1c1c1;
    border-top: 0 solid #c1c1c1;
    margin: 0;
    padding: 14px 15px;
    float: left;
    width: 100%
}

h2.resp-tab-active {
    border-bottom: 0 solid #c1c1c1 !important;
    background-color: #1bb4b9 !important;
    color: #fff
}

h2.resp-tab-title:last-child {
    border-bottom: 12px solid #c1c1c1 !important;
    background: blue
}

.resp-arrow {
    border-color: transparent #1bb4b9 #1bb4b9 transparent;
    border-style: solid;
    border-width: 0 1px 1px 0;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 4px
}

h2.resp-tab-active span.resp-arrow {
    border-color: #1bb4b9 transparent transparent #1bb4b9;
    border-style: solid;
    border-width: 1px 0 0 1px;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 7px
}

.tab-style1 .resp-tabs-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08)
}

.tab-style1 .resp-tabs-list li {
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding: 0 15px 15px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer;
    min-width: auto;
    color: #000;
    background: none !important
}

.tab-style1 .resp-tabs-list li.resp-tab-active {
    border: 1px solid #1bb4b9;
    border-bottom: none;
    border-color: #1bb4b9 !important;
    margin-bottom: -1px;
    border-top: 4px solid #1bb4b9 !important;
    border-bottom: 0 #fff solid;
    border-bottom: none;
    background-color: #fff;
    color: #1bb4b9;
    color: #5e2ced;
    -ms-border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    -ms-border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -o-border-top-right-radius: 5px;
    -ms-border-radius-top-left: 5px;
    -webkit-border-radius-top-left: 5px;
    -moz-border-radius-top-left: 5px;
    -o-border-radius-top-left: 5px;
    -ms-border-radius-topright: 5px;
    -webkit-border-radius-topright: 5px;
    -moz-border-radius-topright: 5px;
    -o-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important
}

.tab-style1 .resp-tabs-list li.resp-tab-active:after {
    content: "";
    background: #1bb4b9;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    margin: 0 auto;
    right: 0
}

.tab-style1 .img-effect:hover {
    transform: translateY(-8px)
}

.tab-style1 .box-shadow-primary {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1)
}

@media screen and (min-width: 992px) {
    .tab-style1 ul.resp-tabs-list {
        text-align: center
    }

    .tab-style1 ul.resp-tabs-list.text-left {
        padding-left: 15px
    }

    .tab-style1 ul.resp-tabs-list li:last-child {
        margin-right: 0
    }

    .tab-style1 ul.resp-tabs-list li.resp-tab-active {
        color: #1bb4b9
    }

    .tab-style1 .resp-tab-content {
        margin-top: 40px;
        border: none;
        padding: 0
    }
}

@media screen and (max-width: 991px) {
    ul.resp-tabs-list {
        display: none
    }

    h2.resp-accordion {
        display: block
    }

    h2.resp-accordion i {
        margin-right: 8px;
        font-size: 18px
    }

    .resp-tabs-container {
        border-top: 1px solid #c1c1c1
    }

    .resp-accordion-closed {
        display: none !important
    }

    .resp-tab-content {
        background-color: #fff
    }

    .tab-style1 ul.resp-tabs-list {
        display: none
    }

    .tab-style1 h2.resp-accordion {
        display: block
    }

    .tab-style1 h2.resp-accordion i {
        margin-right: 8px;
        font-size: 18px
    }

    .tab-style1 .resp-accordion-closed {
        display: none !important
    }

    .tab-style1 .resp-tab-content {
        background-color: #fff
    }
}

.tab-style2 .resp-tabs-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09)
}

.tab-style2 .resp-tabs-list li {
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    padding: 10px 15px 10px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer;
    min-width: auto;
    color: #000
}

.tab-style2 .resp-tabs-list li.resp-tab-active {
    border: 1px solid #1bb4b9;
    border-bottom: none;
    border-color: #1bb4b9 !important;
    margin-bottom: -1px;
    border-top: 4px solid #1bb4b9 !important;
    border-bottom: 0 #fff solid;
    border-bottom: none;
    background-color: #1bb4b9;
    color: #1bb4b9;
    color: #5e2ced;
    -ms-border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    -ms-border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -o-border-top-right-radius: 5px;
    -ms-border-radius-top-left: 5px;
    -webkit-border-radius-top-left: 5px;
    -moz-border-radius-top-left: 5px;
    -o-border-radius-top-left: 5px;
    -ms-border-radius-topright: 5px;
    -webkit-border-radius-topright: 5px;
    -moz-border-radius-topright: 5px;
    -o-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important
}

.tab-style2 .resp-tabs-list li.resp-tab-active:after {
    content: "";
    background: #1bb4b9;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    margin: 0 auto;
    right: 0
}

.tab-style2 .img-effect:hover {
    transform: translateY(-8px)
}

.tab-style2 .box-shadow-primary {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1)
}

@media screen and (min-width: 992px) {
    .tab-style2 ul.resp-tabs-list {
        text-align: left
    }

    .tab-style2 ul.resp-tabs-list.text-left {
        padding-left: 15px
    }

    .tab-style2 ul.resp-tabs-list li:last-child {
        margin-right: 0
    }

    .tab-style2 ul.resp-tabs-list li.resp-tab-active {
        color: #fff
    }

    .tab-style2 .resp-tab-content {
        margin-top: 40px;
        border: none;
        padding: 0
    }
}

@media screen and (max-width: 991px) {
    .tab-style2 ul.resp-tabs-list {
        display: none
    }

    .tab-style2 h2.resp-accordion {
        display: block
    }

    .tab-style2 h2.resp-accordion i {
        margin-right: 8px;
        font-size: 18px
    }

    .tab-style2 .resp-accordion-closed {
        display: none !important
    }

    .tab-style2 .resp-tab-content {
        background-color: #fff
    }
}

.filtering {
    margin-bottom: 20px
}

.filtering span {
    color: #1bb4b9;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-right: 20px;
    display: inline-block;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px !important;
    border: 2px solid #1bb4b9;
    padding: 10px 18px;
    border-radius: 6px
}

.filtering span:last-child {
    margin: 0
}

.filtering .active {
    color: #fff;
    background-color: #1bb4b9;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 1px !important
}

@media screen and (max-width: 767px) {
    .filtering span {
        margin-right: 5px
    }
}

@media screen and (max-width: 575px) {
    .filtering span {
        margin-right: 10px;
        margin-bottom: 15px;
        padding: 7px 14px
    }
}

.gallary-block {
    position: relative
}

.gallary-block .position-relative {
    width: 100%;
    overflow: hidden;
    height: 100%
}

.gallary-block img {
    -webkit-transition: all 500ms;
    transition: all 500ms
}

.gallary-block:hover img {
    transform: scale(1.2)
}

.gallary-block .overlay-box {
    align-items: center;
    position: absolute;
    background: rgba(25, 47, 89, 0.7);
    overflow: hidden;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    opacity: 0;
    transform: scale3d(0, 0, 0);
    transition: all 0.7s
}

.gallary-block:hover .overlay-box, .gallary-block:hover .popimg {
    transform: scale3d(1, 1, 1);
    opacity: 1
}

.gallary-block .popimg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale3d(0, 0, 0);
    transition: all 0.5s;
    color: #fff;
    font-size: 36px;
    z-index: 9;
    opacity: 0;
    width: 32px;
    height: 35px;
    margin: auto
}

.gallary-block .popimg i {
    transition: all 500ms
}

.gallary-block .popimg:hover i {
    color: #1bb4b9
}

.about-us-img {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -0.3rem
}

.about-us-img img {
    border: 20px solid #fff
}

.about-img {
    margin-top: -40px
}

.about-section {
    padding-top: 130px
}

@media screen and (max-width: 1199px) {
    .about-section {
        padding-top: 170px;
        margin-top: -65px
    }

    .about-img {
        margin-top: -35px
    }
}

@media screen and (max-width: 991px) {
    .about-section {
        padding-top: 150px
    }
}

@media screen and (max-width: 767px) {
    .about-section {
        margin-top: 0;
        padding-top: 50px
    }

    .about-img {
        margin-top: 0
    }
}

.courses-area .courses-box {
    background-color: #fff;
    border: 1px solid #efefef;
    position: relative;
    margin: 0 15px 10px 15px;
    border-radius: 6px
}

.courses-area .courses-box .courses-pic img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.courses-area .course-review i {
    font-size: 12px;
    color: #ffaa30
}

.courses-area .courses-desc {
    padding: 25px;
    position: relative
}

.courses-area .card-footer {
    border-top: 1px solid #efefef;
    padding: 25px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

.courses-area .card-footer .text-right {
    font-weight: 500;
    color: #1bb4b9;
    font-size: 24px
}

.courses-area .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 20px
}

.courses-area .owl-thumbs {
    margin-top: 0
}

@media screen and (max-width: 991px) {
    .courses-area .card-footer .text-right {
        font-size: 22px
    }
}

@media screen and (max-width: 767px) {
    .courses-area .courses-box {
        margin: 0
    }
}

.top-detail {
    margin-top: -45px
}

.course-details-single {
    background: #1bb4b9
}

.info-box {
    background: rgba(25, 47, 89, 0.9);
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 20px;
    text-align: center;
    font-size: 17px
}

.info-box li {
    display: inline-block;
    color: #fff;
    margin-right: 30px
}

.info-box li:last-child {
    margin-right: 0
}

.info-box li i {
    padding-right: 15px
}

@media screen and (max-width: 1199px) {
    .info-box {
        font-size: 16px
    }

    .info-box li {
        margin-right: 25px
    }
}

@media screen and (max-width: 991px) {
    .top-detail {
        margin-top: -25px
    }
}

.comment-list-wrapper {
    margin-bottom: 30px
}

.comment-list-wrapper:last-child {
    margin-bottom: 0
}

.comment-list {
    display: flex;
    justify-content: space-between;
    padding: 30px 15px 25px;
    border-radius: 0;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 9px 16px 46px 0 rgba(47, 47, 47, 0.05);
    align-items: center;
    width: 100%
}

.comment-list:last-child {
    margin-bottom: 0
}

.comment-list .commnet_img {
    width: 80px
}

.comment-list .commnet_img img {
    border-radius: 50%
}

.comment-list .comment-text {
    padding: 0 15px 0 30px;
    width: 87%
}

.comment-text .author_info {
    line-height: 14px
}

.comment-text .author_info .author_name {
    font-size: 14px;
    font-weight: 600
}

.comment-text .author_info span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: block
}

.comment-text .rating ul {
    margin: 0
}

.comment-text .rating ul li {
    padding: 0 1px
}

.comment-text .rating ul li i:before {
    font-size: 14px;
    color: #1bb4b9;
    margin-left: 0
}

.comment-text p {
    margin: 15px 0 0
}

@media screen and (max-width: 767px) {
    .comment-text .author_info {
        display: block !important
    }

    .comment-list {
        display: block
    }

    .comment-list .comment-text {
        width: 100%;
        padding: 0
    }

    .comment-list .commnet_img {
        margin-bottom: 20px
    }
}

.courses-feature ul li:first-child {
    padding-top: 0
}

.courses-feature ul li:last-child {
    border-bottom: none;
    padding-bottom: 0
}

.courses-feature ul li span i {
    color: #1bb4b9
}

.course-details-icon {
    color: #fff;
    font-size: 24px;
    margin-right: 15px
}

.course-details-content {
    width: calc(100% - 40px);
    margin-top: -3px
}

.item.author .thumb h4, .item.author .desc h4 {
    margin-bottom: 0
}

.item.author .thumb img {
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    height: 55px;
    margin-right: 10px;
    margin-top: -3px;
    padding: 2px;
    width: 55px
}

.courses_price .price span {
    color: red;
    font-weight: 500;
    padding-right: 15px
}

.courses_price .price .new_price {
    color: #1bb4b9;
    padding-right: 0;
    letter-spacing: 1px
}

@media screen and (max-width: 1199px) {
    .course-details-icon {
        font-size: 22px
    }
}

.page-container .title {
    font-size: 220px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: 500
}

@media screen and (max-width: 1199px) {
    .page-container .title {
        font-size: 200px
    }
}

@media screen and (max-width: 991px) {
    .page-container .title {
        font-size: 150px
    }
}

@media screen and (max-width: 767px) {
    .page-container .title {
        font-size: 110px
    }
}

ul.countdown li {
    border-right: 1px solid #626262;
    display: inline-block;
    padding: 0 30px;
    text-align: center
}

ul.countdown li:first-child {
    padding-left: 0
}

ul.countdown li:last-child {
    border: medium none;
    padding-right: 0
}

ul.countdown li span {
    font-size: 50px;
    font-weight: 600;
    line-height: normal;
    position: relative
}

ul.countdown li span:before {
    content: "";
    height: 1px;
    position: absolute;
    width: 100%
}

ul.countdown li p.timeRefDays, ul.countdown li p.timeRefHours, ul.countdown li p.timeRefMinutes, ul.countdown li p.timeRefSeconds {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

@media screen and (max-width: 767px) {
    ul.countdown li {
        padding: 0 16px
    }

    .social-links li {
        margin-right: 15px
    }

    ul.countdown li span {
        font-size: 40px
    }
}

@media screen and (max-width: 575px) {
    ul.countdown li {
        padding: 0 10px
    }

    ul.countdown li span {
        font-size: 30px
    }
}

.events-date {
    text-align: center;
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: rgba(25, 47, 89, 0.9);
    color: #fff;
    padding: 12px 20px 8px 20px;
    text-transform: uppercase
}

.event-time li {
    display: inline-block;
    margin-right: 20px
}

.event-time li:last-child {
    margin-right: 0
}

.event-time li i {
    color: #1bb4b9
}

.event-block {
    box-shadow: 0 0 16px 0 rgba(187, 187, 187, 0.48)
}

.event-block ul li i {
    color: #1bb4b9
}

@media screen and (max-width: 1199px) {
    .event-date {
        padding: 10px 18px 6px 18px
    }
}

@media screen and (max-width: 575px) {
    .event-date {
        padding: 8px 15px 4px 15px
    }

    .events-date {
        padding: 10px 15px 6px 15px
    }
}

.contact-info {
    border: 1px solid #efefef;
    position: relative
}

.courses-info h4:after {
    content: '+'
}

.skills-block .row i {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    font-size: 30px;
    background: rgba(25, 47, 89, 0.071);
    color: #1bb4b9;
    margin: 0 auto
}

@media screen and (max-width: 1199px) {
    .skills-block .row i {
        width: 65px;
        height: 65px;
        line-height: 65px;
        font-size: 28px
    }
}

@media screen and (max-width: 991px) {
    .skills-block .row i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 26px
    }
}

.map {
    height: 400px;
    width: 100%;
    vertical-align: top
}

.search-form_input {
    color: #1bb4b9
}

.search-frame h4 a:hover {
    color: #1bb4b9
}

.search-frame .search_list .match {
    color: #1bb4b9
}

.search-frame .search_list li:before {
    color: #1bb4b9
}

.search-frame .search_list li + li {
    border-top: 3px solid #1bb4b9
}

.search-frame .search {
    color: #1bb4b9
}

.facility-box {
    position: relative;
    margin-top: -60px
}

.facility-block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%
}

.facility-block .step {
    padding: 15px;
    min-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
    border-radius: 6px
}

.facility-block .step i {
    display: block;
    margin-bottom: 20px;
    font-size: 60px;
    color: #fff
}

.facility-block .step .step-title {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0
}

@media screen and (max-width: 1199px) {
    .facility-block .step {
        min-height: 180px
    }

    .facility-block .step .step-title {
        font-size: 18px
    }

    .facility-block .step i {
        font-size: 50px;
        margin-bottom: 15px
    }
}

@media screen and (max-width: 991px) {
    .facility-block .step {
        min-height: 180px;
        align-items: normal;
    }

    .facility-block .step i {
        font-size: 48px
    }
}

@media screen and (max-width: 575px) {
    .facility-block .step i {
        font-size: 44px
    }

    .facility-block .step .step-title {
        font-size: 16px
    }
}

.events-block {
    overflow: hidden
}

.events-block .col-left {
    float: left;
    width: 37%;
    position: relative;
    overflow: hidden
}

.events-block .col-right {
    float: left;
    width: 63%;
    display: table;
    height: 100%
}

.events-block .col-left img {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    transition: all 500ms ease
}

.events-block .event-date {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 8px 12px;
    display: inline-block;
    background: #1bb4b9;
    border-radius: 6px;
    text-align: center;
    color: #fff
}

@media screen and (max-width: 1199px) {
    .events-block .event-date {
        padding: 6px 10px
    }
}

@media screen and (max-width: 991px) {
    .events-block .image-date {
        padding: 5px 10px
    }
}

@media screen and (max-width: 575px) {
    .events-block .col-left {
        width: 100%;
        max-height: 300px
    }

    .events-block .col-right {
        width: 100%;
        height: auto
    }
}

.story-video {
    height: 100%
}

.video_btn {
    position: relative;
    height: 80px;
    width: 80px;
    background: #1bb4b9;
    text-align: center;
    display: inline-block;
    line-height: 85px;
    color: #fff;
    border-radius: 50%;
    transition-duration: 0s;
    -ms-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s
}

.video_btn:hover i, .video_btn:focus i {
    color: #fff
}

.video_btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.video_btn:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 95px;
    width: 95px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.video_btn.small {
    width: 50px;
    height: 50px;
    line-height: 50px
}

.video_btn.small:after {
    height: 50px;
    width: 50px
}

.video_btn.small:before {
    height: 65px;
    width: 65px
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0
    }
}

@media screen and (max-width: 1199px) {
    .video_btn {
        height: 75px;
        width: 75px;
        line-height: 80px
    }

    .video_btn:after {
        height: 75px;
        width: 75px
    }

    .video_btn:before {
        height: 90px;
        width: 90px
    }
}

@media screen and (max-width: 991px) {
    .video_btn {
        height: 70px;
        width: 70px;
        line-height: 70px
    }

    .video_btn:after {
        height: 70px;
        width: 70px
    }

    .video_btn:before {
        height: 85px;
        width: 85px
    }

    @keyframes pulse-border {
        0% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1)
        }
        100% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2)
        }
    }
}

@media screen and (max-width: 767px) {
    .story-video-height {
        min-height: 250px
    }

    .video_btn {
        height: 60px;
        width: 60px;
        line-height: 62px
    }

    .video_btn:after {
        height: 60px;
        width: 60px
    }

    .video_btn:before {
        height: 75px;
        width: 75px
    }
}

.courses-block {
    border: 1px solid rgba(0, 0, 0, 0.1)
}

.courses-block .courses-content {
    padding: 30px
}

.courses-block .courses-pricing-3 span {
    font-size: 16px;
    font-weight: 500;
    padding: 6px 12px;
    background: #1bb4b9;
    border-radius: 0;
    color: #fff
}

.courses-icon span i {
    color: #faa603
}

.courses-block .courses-box-bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px 30px
}

@media screen and (max-width: 1199px) {
    .courses-block .courses-content, .courses-block .courses-box-bottom {
        padding: 25px
    }
}

.course-categories {
    position: relative;
    display: block;
    overflow: hidden
}

.course-categories .overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 5;
    text-align: center
}

.course-categories:after {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.royal-course {
    padding: 30px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1)
}

.royal-course .icon {
    width: 85px;
    height: 85px;
    line-height: 92px;
    text-align: center;
    border-radius: 50%;
    font-size: 40px;
    border: 1px solid #dee2e6;
    background: rgba(25, 47, 89, 0.02);
    color: #1bb4b9;
    margin: 0 auto;
    margin-bottom: 25px
}

@media screen and (max-width: 992px) {
    .royal-course {
        padding: 25px
    }

    .royal-course .icon {
        width: 80px;
        height: 80px;
        line-height: 87px;
        font-size: 34px;
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767px) {
    .royal-course .icon {
        width: 75px;
        height: 75px;
        line-height: 78px;
        font-size: 30px
    }
}

@media screen and (max-width: 575px) {
    .royal-course {
        padding: 20px
    }

    .royal-course .icon {
        font-size: 28px
    }
}

.university-blog {
    background: #f7f7f7
}

.university-blog .col-left {
    float: left;
    width: 33%;
    position: relative
}

.university-blog .col-right {
    float: left;
    width: 67%;
    display: table;
    height: 100%
}

.university-blog .blog-date {
    color: #1bb4b9;
    font-size: 18px;
    font-weight: 500
}

@media screen and (max-width: 1199px) {
    .university-blog .col-left {
        width: 35%
    }

    .university-blog .col-right {
        width: 65%
    }
}

@media screen and (max-width: 767px) {
    .university-blog .col-left {
        width: 100%;
        max-height: 300px;
        overflow: hidden
    }

    .university-blog .col-right {
        width: 100%;
        height: auto
    }
}

.blog-list-simple {
    margin: 0 0 30px 0;
    padding-bottom: 30px
}

.blog-list-simple:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0
}

.blog-list-simple-text > span {
    color: #1bb4b9;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px
}

.blog-list-simple-text p {
    border-top: 1px solid #ececec;
    margin-bottom: 0;
    padding: 15px 0
}

.blog-list-simple-text .meta {
    margin: 0
}

.blog-list-simple-text li {
    display: inline-block;
    font-size: 12px;
    color: #777
}

.blog-list-simple-text h4 {
    color: #232323;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: normal
}

.blog-list-simple-text ul {
    margin: 10px 0
}

.blog-list-simple-text ul li {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 0;
    margin-right: 15px
}

.blog-list-simple-text ul li i {
    color: #1bb4b9;
    margin-right: 5px;
    font-size: 14px
}

.blog-list-simple-text .blog-date-info {
    position: relative;
    background-color: rgba(25, 47, 89, 0.9);
    color: #fff;
    padding: 12px 20px 8px 20px;
    text-transform: uppercase;
    text-align: center
}

.blogs .post {
    margin-bottom: 50px
}

.blogs .post .post-title h5 {
    font-size: 28px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 0
}

.blogs .post .meta {
    margin-bottom: 20px
}

.blogs .post .meta li {
    display: inline-block;
    font-size: 13px;
    color: #777;
    margin: 5px 20px 0 5px
}

.blogs .post .meta li i {
    padding-right: 4px
}

.blogs .post .content .special {
    padding: 15px;
    margin: 30px 0 30px 50px;
    border-left: 2px solid #111;
    background: #f7f7f7;
    font-size: 16px
}

.blogs .post .share-post {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    text-align: left
}

.blogs .post .share-post span {
    font-weight: 700
}

.blogs .post .share-post ul {
    float: right
}

.blogs .post .share-post ul li {
    display: inline-block;
    margin: 0 10px
}

.blogs .post .post-img img {
    width: 100%
}

.blogs .posts .title-g h3 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px
}

.blogs .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee
}

.blogs .comments-area .comment-box:nth-child(odd) {
    margin-left: 80px
}

.blogs .comments-area .comment-box:last-child {
    margin-bottom: 30px
}

.blogs .comment-box .author-thumb {
    width: 80px;
    float: left
}

.blogs .comment-box .comment-info {
    margin-left: 100px
}

.blogs .comment-box .comment-info h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px
}

.blogs .comment-box .comment-info .reply {
    margin-top: 10px;
    font-weight: 600
}

.blogs .comment-box .comment-info .reply i {
    padding-right: 5px;
    font-size: 12px
}

.pagination {
    border-radius: 0;
    padding: 0;
    margin: 0
}

.pagination ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff
}

.pagination a:hover {
    background-color: #232323;
    color: #fff
}

.pagination .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default
}

.pagination .disabled span {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a:hover {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination li:first-child a {
    border-left-width: 1px
}

.side-bar .widget {
    margin-bottom: 30px;
    border: 1px solid #efefef;
    padding: 15px
}

.side-bar .widget:last-child {
    margin-bottom: 0
}

.side-bar .widget .widget-title h6 {
    position: relative;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px
}

.side-bar .widget .widget-title h6:after {
    content: '';
    width: 30px;
    height: 1px;
    background: #1bb4b9;
    position: absolute;
    bottom: 0;
    left: 0
}

.side-bar .widget li {
    position: relative;
    padding: 0 0 0 12px;
    margin: 0 0 9px 0;
    color: #6f6f6f
}

.side-bar .widget li:last-child {
    margin: 0
}

.side-bar .widget li:after {
    content: "";
    width: 5px;
    height: 1px;
    background-color: #6f6f6f;
    position: absolute;
    top: 12px;
    left: 0
}

.side-bar .widget .default-style li {
    padding-left: 0
}

.side-bar .widget .default-style li:after {
    content: none
}

.side-bar .widget .social-listing {
    margin-bottom: 0
}

.side-bar .widget .social-listing li {
    list-style: none;
    display: inline-block;
    margin-bottom: 0
}

.side-bar .widget .social-listing li:after {
    background: none
}

.side-bar .widget .social-listing li:first-child {
    padding-left: 0
}

.side-bar .single-post {
    display: flex;
    border-radius: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out
}

.side-bar .single-post:last-child {
    margin-bottom: 0
}

.side-bar .single-post .post_title {
    width: 75%;
    padding-left: 15px;
    font-size: 14px;
    line-height: 22px;
    margin-top: -3px
}

.side-bar .search form input {
    width: calc(100% - 50px);
    height: 50px;
    padding: 0 10px;
    margin: 0;
    border: 1px solid #d1d1d1;
    background: #f7f7f7
}

.side-bar .search form button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    border: 0;
    float: right;
    border-radius: 0;
    padding: 0
}

.side-bar .search form button:hover:before {
    border-radius: 0;
    padding: 0
}

.side-bar .post-img {
    float: left;
    margin-right: 5%;
    width: 25%;
    display: block
}

@media screen and (max-width: 1199px) {
    .blogs .post .post-title h5 {
        font-size: 22px
    }

    .blogs .post .content .special {
        font-size: 16px
    }

    .blogs .posts .title-g h3 {
        font-size: 22px
    }
}

@media screen and (max-width: 1199px) {
    .side-bar .post-img {
        margin-right: 0;
        width: 18%
    }
}

@media screen and (max-width: 767px) {
    .blog-list-simple {
        margin-bottom: 20px;
        padding-bottom: 20px
    }

    .blog-list-simple-text > span {
        font-size: 12px
    }

    .blog-list-simple-text p {
        font-size: 14px
    }

    .blog-list-simple-text h4 {
        font-size: 18px
    }

    .blog-list-simple-text ul li {
        font-size: 12px
    }

    .blogs .post .post-title h5 {
        font-size: 20px
    }

    .blogs .post .content .special {
        font-size: 14px;
        margin: 30px 0 30px 35px
    }

    .blogs .posts .title-g h3 {
        font-size: 18px
    }

    .side-bar .post-img {
        margin-right: 20px
    }

    .blog-list-simple-text .blog-date-info {
        padding: 10px 15px 6px 15px
    }
}

@media screen and (max-width: 575px) {
    .blog-list-simple-text .blog-date-info {
        padding: 8px 10px 4px 10px
    }

    .xs-padding-20px-left {
        padding-left: 10px !important
    }

    .blogs .post .content .special {
        margin: 30px 0 30px 20px
    }
}

.blog-block img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.blog-block .blog-text {
    color: #1bb4b9
}

.blog-content {
    padding: 25px;
    border: 1px solid #efefef;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

@media screen and (max-width: 1199px) {
    .blog-content {
        padding: 20px
    }
}

footer {
    padding: 70px 0 0;
    background: #1f1f1f;
    word-wrap: break-word;
    color: #939393
}

footer p {
    margin-bottom: 0
}

footer h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1;
    position: relative
}

footer h3:after {
    content: "";
    display: block;
    width: 70px;
    height: 2px;
    background: #1bb4b9;
    margin-top: 12px
}

.footer-social-icons ul {
    margin-bottom: 0
}

.footer-social-icons ul li {
    display: inline-block;
    border: 1px solid #939393;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 5px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.footer-social-icons ul li a {
    color: #939393;
    display: block;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px
}

.footer-social-icons ul li:hover {
    background: #1bb4b9;
    border-color: #1bb4b9
}

.footer-social-icons ul li:hover i {
    color: #fff
}

.footer-social-icons.small ul li {
    margin-bottom: 0
}

.footer-social-icons.small ul li a {
    font-size: 12px;
    height: 25px;
    line-height: 26px;
    width: 25px
}

.footer-social-icons1 ul {
    margin-bottom: 0
}

.footer-social-icons1 ul li {
    display: inline-block;
    border: 1px solid #626262;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.footer-social-icons1 ul li:last-child {
    margin-right: 0
}

.footer-social-icons1 ul li a {
    color: #626262;
    display: block;
    font-size: 13px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px
}

.footer-social-icons1 ul li:hover {
    background: #1bb4b9;
    border-color: #1bb4b9
}

.footer-social-icons1 ul li:hover i {
    color: #fff
}

.footer-social-icons1.small ul li {
    margin-bottom: 0
}

.footer-social-icons1.small ul li a {
    font-size: 12px;
    height: 25px;
    line-height: 26px;
    width: 25px
}

.footer-social-icons2 ul {
    margin-bottom: 0
}

.footer-social-icons2 ul li {
    display: inline-block;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 0;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s
}

.footer-social-icons2 ul li a {
    color: #232323;
    display: block;
    font-size: 15px;
    height: 35px;
    line-height: 36px;
    text-align: center;
    width: 35px
}

.footer-social-icons2 ul li:hover {
    background: #1bb4b9;
    border: 1px solid #1bb4b9
}

.footer-social-icons2 ul li:hover i {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .footer-social-icons2 ul li a {
        font-size: 14px;
        width: 30px;
        height: 30px;
        line-height: 30px
    }
}

.contact-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400
}

.contact-list li .icon {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 24px;
    font-size: 14px
}

.footer-list {
    margin: 0
}

.footer-list li {
    list-style-type: none;
    padding: 5px 0
}

.footer-list li:first-child {
    padding-top: 0
}

.footer-list li:last-child {
    padding-bottom: 0
}

.footer-list li a {
    color: #939393
}

.footer-list li a:before {
    content: "";
    font-weight: 700;
    vertical-align: bottom;
    font-family: Font Awesome\ 5 Free;
    color: #939393;
    padding-right: 8px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.footer-list li a:hover {
    color: #fff
}

.footer-list li a:hover:before {
    color: #fff
}

.footer-list2 {
    margin: 0
}

.footer-list2 li {
    list-style-type: none;
    padding: 5px 0
}

.footer-list2 li:first-child {
    padding-top: 0
}

.footer-list2 li:last-child {
    padding-bottom: 0
}

.footer-list2 li a {
    color: #626262
}

.footer-list2 li a:before {
    content: "";
    font-weight: 700;
    vertical-align: bottom;
    font-family: Font Awesome\ 5 Free;
    color: #626262;
    padding-right: 8px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.footer-list2 li a:hover {
    color: #1bb4b9
}

.footer-list2 li a:hover:before {
    color: #1bb4b9
}

.footer-bar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 70px;
    text-align: center;
    background: #191919;
    color: #939393
}

.footer-bar p {
    margin-bottom: 0
}

.footer-bar span {
    color: #1bb4b9;
    font-size: 15px;
    font-weight: 400
}

footer .footer-title-style2 h3 {
    margin-bottom: 0
}

.footer-title-style2 h3:after {
    display: none
}

.footer-title-style2 .title-border {
    vertical-align: top;
    text-align: left
}

.footer-title-style2 .title-border span {
    display: inline-block;
    vertical-align: middle;
    height: 2px;
    border-radius: 3px;
    background: #fff;
    margin: 0 4px
}

.footer-title-style2 .title-border span.lg {
    width: 40px
}

.footer-title-style2 .title-border span.md {
    width: 30px;
    opacity: 0.8
}

.footer-title-style2 .title-border span.sm {
    width: 12px;
    opacity: 0.6
}

footer .footer-title-style3 h3 {
    margin-bottom: 0
}

.footer-title-style3 h3:after {
    display: none
}

.footer-title-style3 .title-border {
    vertical-align: top;
    text-align: left
}

.footer-title-style3 .title-border span {
    display: inline-block;
    vertical-align: middle;
    height: 2px;
    border-radius: 3px;
    background: #1bb4b9;
    margin: 0 4px
}

.footer-title-style3 .title-border span.lg {
    width: 40px
}

.footer-title-style3 .title-border span.md {
    width: 30px;
    opacity: 0.8
}

.footer-title-style3 .title-border span.sm {
    width: 12px;
    opacity: 0.6
}

.footer-subscribe {
    margin-top: 20px;
    margin-bottom: 0;
    position: relative
}

.footer-subscribe input {
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    border: 0 solid;
    height: 40px;
    outline: none;
    box-shadow: none;
    padding: 6px 95px 6px 12px;
    margin-bottom: 0
}

.footer-subscribe .butn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    line-height: 15px
}

.footer-subscribe2 {
    margin-top: 20px;
    margin-bottom: 0;
    position: relative
}

.footer-subscribe2 input {
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid #edeff3;
    height: 40px;
    outline: none;
    box-shadow: none;
    padding: 6px 95px 6px 12px;
    margin-bottom: 0
}

.footer-subscribe2 .butn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #fff;
    line-height: 15px;
    box-shadow: none !important
}

@media screen and (max-width: 991px) {
    footer {
        padding: 50px 0 0
    }

    .footer-bar {
        margin-top: 50px
    }
}

@media screen and (max-width: 767px) {
    .footer-bar p {
        font-size: 13px
    }
}
.content-pad {
    padding-bottom: 20px;
}
.member-item {
    margin-bottom: 30px;
}
.member-item-inner {
    background: #f6f6f6;
}
.item-thumbnail {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.item-thumbnail a {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: block;
    transform: translate3d(0,0,0);
}
.item-thumbnail img {
    width: 100%;
    height: auto;
    transition: all .4s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
#content img {
    max-width: 100%;
    height: auto;
}
@media (min-width: 992px) {
    .item-content {
        border-top: solid 1px #eaeaea;
        padding-top: 17px;
    }
}
.item-content a {
    color: inherit;
    transition: all .2s;
}
.small-text {
    font-size: 10px;
    color: #343434;
    text-transform: uppercase;
}
.member-item .item-content p {
    margin-top: 10px;
    margin-bottom: 15px;
}
.social-light {
    margin-top: -12px;
}
.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
}
.social-light li {
    margin-top: 12px;
}
.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
.item-content a {
    color: inherit;
    transition: all .2s;
}
.admission_links{
    padding-top: 100px !important;
    padding-bottom: 60px !important;
    background-color: #FFF !important;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.icon-block {
    margin-bottom: 30px;
}
.icon-block.icon-position-center .tt-icon {
    float: none;
    text-align: center;
    margin-bottom: 15px;
}
.icon-block.icon-position-center .tt-content {
    text-align: center;
}
.icon-block .tt-icon a, .icon-block h3 a {
    color: #202020;
}
.icon-block.icon-position-center .tt-icon img {
    margin: 0 auto;
}
.icon-block h3 {
    font-size: 25px;
    position: relative;
}
.icon-block .tt-content p {
    color: #787878;
    font-size: 18px;
}
.service-read-more a {
    color: #999999;
    font-size: 12px;
    font-family: 'Cabin', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}
.col-md-1-5{
    flex: 0 0 10% !important;
}
ul#admission {
    list-style: none;
    padding: 0;
    display: block;
    text-align: center;
}
ul#admission li {
    display: inline-block;
}
ul#admission li span {
    font-size: 60px;
    font-weight: 300;
    line-height: 60px;
}
ul#admission li.seperator {
    font-size: 60px;
    line-height: 50px;
    vertical-align: top;
}
ul#admission li p {
    color: #a7abb1;
    font-size: 20px;
}
.link-admission{
    font-size: 28px;
    color: #FFFFFF;
    text-align: center;
    vertical-align: middle;
}

.link-admission:hover{
    font-size: 28px;
    color: #FFFFFF;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
}
@media screen and (max-width: 991px) {
    .vertical-middle {
        position:relative;
        top: 0;
    }
    .col-sm-6 {
        width: 50% !important;
    }
}
.col-lg-2-9{
    max-width: 11.1% !important;
}
.date-block {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 15px 10px;
    font-size: 14px;
    line-height: 18px;
    min-width: 66px;
}
.date-block:not(.main-color-2-bg) {
    background: rgba(0,0,0,.7);
    color: rgba(255,255,255,.75);
}
.grid-item .event-item {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.dark-div, .dark-div a {
    color: rgba(255,255,255,.75);
    text-decoration: none !important;
}
.event-thumbnail {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
#content img {
    max-width: 100%;
    height: auto;
}
.event-thumbnail img {
    width: 100%;
    height: auto;
    transition: all .4s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.date-block .month {
    text-transform: uppercase;
    text-align: center;
}
.date-block .day {
    font-size: 20px;
    text-align: center;
}
.event-overlay {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    color: rgba(255,255,255,.75);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.overlay-top {
    display: block;
    padding: 15px 28px 13px 20px;
    background: rgba(0,0,0,.7);
    border-bottom: solid 1px rgba(255,255,255,.2);
    position: relative;
}
.overlay-top h4 {
    margin-bottom: 0px;
    color: #FFFFFF !important;
}
.overlay-bottom, .no-overlay-bottom .event-item:hover .overlay-bottom, .overlay-bottom, .no-overlay-bottom .event-item.hover_effect .overlay-bottom {
    height: 0;
    padding: 0 20px;
    background: rgba(0,0,0,.5);
    overflow: hidden;
    transition: all .25s ease-out .1s;
}
.overlay-bottom div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.overlay-bottom * {
    opacity: 0;
    transform: translateY(25px);
    -webkit-transform: translateY(25px);
    transition: all .25s ease-out .2s;
}
.bg-news{
    background-color: #1bb4b9;
}
* {
    font-family: "Poppins", serif;
}
.util_links{
    color: #FFF !important;
}
.util_links a{
    color: #FFF !important;
}
.util_links a:hover{
    color: #FFF !important;
}
.footer-list2 .util_links a:before{
    color: #FFF !important;
}
.link_papers{
    color: #FFF !important;
}
.col-md-3-5{
    width: 18% !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
}
.gallery-carousel{
    height: 500px;
}
.gallery-carousel .owl-item img{
    height: 500px;
    margin: auto;
}
.gallery-carousel .owl-item{
    margin: auto;
    text-align: center;
}
.owl-nav {
    text-align: center;
}


@media (max-width: 702px) {
    .col-md-3-5 {
        width: 48% !important;
        margin-left: 1% !important;
        margin-right: 1% !important;
    }
    .col-lg-2-9 {
        max-width: 50% !important;
    }
    .testimonial-style1 .testmonial-text
    {
        height: 500px;
    }
    .gallery-carousel .owl-item img {
        height: 200px;
    }
}